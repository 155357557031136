import React from 'react';
import { Card, Col, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { isAlphanumeric, isIpValid } from '../../../utils/validations';


const ConfigCredentials = ({ formState, setFormState }) => {
    const handleChange = (e) => {
        let newFormState = { ...formState }
        newFormState["configCredentials"] = { ...newFormState["configCredentials"], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }

    return (
        <Card
            body
            color="light"
        >
            <FormGroup row>
                <Label bsSize="sm" for={`address`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    IP
                    <span
                        id="cred-address-t"
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target="cred-address-t">
                        Specify public ip of Thunder to connect and apply configuration. IP should belong from management interface.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input
                        bsSize="sm"
                        id={`address`}
                        name="address"
                        placeholder="127.0.0.1"
                        type="text"
                        value={formState['configCredentials']["address"]}
                        valid={isIpValid(formState['configCredentials']["address"])}
                        invalid={!isIpValid(formState['configCredentials']["address"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label bsSize="sm" for={`username`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Username
                    <span
                        id="cred-username-t"
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target="cred-username-t">
                        Specify username of Thunder to connect and apply configuration. Default value is 'admin'.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input
                        bsSize="sm"
                        id={`username`}
                        name="username"
                        placeholder="admin"
                        type="text"
                        value={formState['configCredentials']["username"]}
                        valid={isAlphanumeric(formState['configCredentials']["username"])}
                        invalid={!isAlphanumeric(formState['configCredentials']["username"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            {/* <FormGroup row>
                <Label bsSize="sm" for={`password`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Password
                    <span
                        id="password-t"
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target="password-t">
                        Specify password to login.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input
                        bsSize="sm"
                        id={`password`}
                        name="password"
                        placeholder="<password>"
                        type="text"
                        disabled
                    />
                </Col>
            </FormGroup> */}
            {/* <hr />
            <span className='note'>Note: Password needs to be provided during execution.</span> */}
        </Card>
    )
}

export default ConfigCredentials
