import React from "react";

const Footer = () => {
  return (
    <div style={{ background: "#000", color: "white" , padding:10}}>
    <span style={{ textAlign: "center",display: "block" }}>©2023 A10 Networks, Inc. All rights reserved.</span>
    <div style={{textAlign:"center"}}>
      <u><a className="text-secondary" href="https://www.a10networks.com/company/legal/" >Legal Notices</a></u> &nbsp;
      <u><a className="text-secondary"href="https://www.a10networks.com/company/legal/trademarks/">Trademarks</a></u> &nbsp;
      <u><a className="text-secondary"href="https://www.a10networks.com/company/legal/privacy-policy/">Privacy Policy</a></u> &nbsp;
      <u><a className="text-secondary"href="https://www.a10networks.com/company/legal/supplemental-eea-privacy-notice/">EEA+ Privacy Notice</a></u> &nbsp;
      <u><a className="text-secondary"href="https://www.a10networks.com/company/legal/cookie-policy/">Cookie Policy</a></u> &nbsp;
      <u><a className="text-secondary"href="https://www.a10networks.com/company/legal/website-terms-of-service/">Terms of Service</a></u> &nbsp;
      <u><a className="text-secondary"href="https://www.a10networks.com/company/legal/gdpr/">GDPR</a></u> &nbsp;
      <u><a className="text-secondary" href="https://www.a10networks.com/company/legal/a10-networks-california-consumer-privacy-act-privacy-policy/">CCPA&nbsp;Privacy Policy</a></u> &nbsp;
      <u><a className="text-secondary" href="https://www.a10networks.com/company/legal/do-not-sell-or-share-my-personal-information-your-opt-out-rights/">Do&nbsp;Not Sell My Personal Information</a></u> &nbsp;
      <u><a className="text-secondary" href="https://www.a10networks.com/company/legal/contacts/">Business Contacts Privacy Statement</a></u>
    </div>
  </div>
  );
};

export default Footer;
