const alphanumcericRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
const subnetRegex = /^(?:\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/
const alphanumericSmallCaseRegex = /^[a-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

const isIpValid = (ip) => {
    if (ip === undefined) return false;
    return ip.length > 0 && ipRegex.test(ip)
}
const isNotEmpty = (value) => {
    return value.length > 0
}
const isAlphanumeric = (value) => {
    if (value === undefined) return false;
    if (typeof (value) === "number") {
        return value >= 0 && alphanumcericRegex.test(value)
    }
    else return value.length > 0 && alphanumcericRegex.test(value)
}

const isSubnetValid = (subnet) => {
    if (subnet === undefined) return false
    return subnet.length > 0 && subnetRegex.test(subnet)
}

const isSmallCase = (value) => {
    if (value === undefined) return false;
    if (typeof (value) === "number") {
        return value >= 0 && alphanumericSmallCaseRegex.test(value)
    }
    else return value.length > 0 && alphanumericSmallCaseRegex.test(value)
}

const checkGCP = (vThunderCount, data) => {
    let check = true
    if (vThunderCount === "custom") {
        return false
    }

    const firstVM = data.vm1
    check = check && isAlphanumeric(firstVM.project) && isAlphanumeric(firstVM.region) && isAlphanumeric(firstVM.zone) && isAlphanumeric(firstVM.image_size) && isSmallCase(firstVM.machine_name) && isAlphanumeric(firstVM.machine_type) && isAlphanumeric(firstVM.credentials) && isAlphanumeric(firstVM.mgmt_subnet_id) && isAlphanumeric(firstVM.data_in_subnet_id) && isAlphanumeric(firstVM.data_out_subnet_id) && isAlphanumeric(firstVM.tags)

    if (vThunderCount !== "standalone") {
        const secondVM = data.vm2
        check = check && isAlphanumeric(secondVM.project) && isAlphanumeric(secondVM.region) && isAlphanumeric(secondVM.zone) && isAlphanumeric(secondVM.image_size) && isSmallCase(secondVM.machine_name) && isAlphanumeric(secondVM.machine_type) && isAlphanumeric(secondVM.credentials) && isAlphanumeric(secondVM.mgmt_subnet_id) && isAlphanumeric(secondVM.data_in_subnet_id) && isAlphanumeric(secondVM.data_out_subnet_id) && isAlphanumeric(secondVM.tags)
    }
    if (vThunderCount === "gslb") {
        const thirdVM = data.vm3
        check = check && isAlphanumeric(thirdVM.project) && isAlphanumeric(thirdVM.region) && isAlphanumeric(thirdVM.zone) && isAlphanumeric(thirdVM.image_size) && isSmallCase(thirdVM.machine_name) && isAlphanumeric(thirdVM.machine_type) && isAlphanumeric(thirdVM.credentials) && isAlphanumeric(thirdVM.mgmt_subnet_id) && isAlphanumeric(thirdVM.nic_type) && isAlphanumeric(thirdVM.data_in_subnet_id) && isAlphanumeric(thirdVM.data_out_subnet_id) && isAlphanumeric(thirdVM.tags)
    }
    return check
}

const checkAzure = (vThunderCount, data) => {
    let check = true

    if (vThunderCount === "custom") {
        const custom = data.custom
        check = check && isAlphanumeric(custom.vm_count) && parseInt(custom.vm_count) > 0 && isNotEmpty(custom.location) && isAlphanumeric(custom.image)
            && isAlphanumeric(custom.product) && isAlphanumeric(custom.size) && isAlphanumeric(custom.admin_username)
            && isAlphanumeric(custom.nsg_mgmt) && isAlphanumeric(custom.nsg_datain) && isAlphanumeric(custom.nsg_dataout) && isAlphanumeric(custom.vnet_nsg_rg)
            && isAlphanumeric(custom.vnet_name) && isAlphanumeric(custom.subnet_mgmt) && isAlphanumeric(custom.subnet_datain) && isAlphanumeric(custom.subnet_dataout)

    } else {
        const firstVm = data.standalone
        check = check && isAlphanumeric(firstVm.resource_group) && isNotEmpty(firstVm.location) && isAlphanumeric(firstVm.image) &&
            isAlphanumeric(firstVm.product) && isAlphanumeric(firstVm.instance_name1) && isAlphanumeric(firstVm.size) &&
            isAlphanumeric(firstVm.admin_username) && isAlphanumeric(firstVm.publicIP_name1) && isAlphanumeric(firstVm.vnet_nsg_rg) &&
            isAlphanumeric(firstVm.vnet_name) && isAlphanumeric(firstVm.subnet_mgmt) && isAlphanumeric(firstVm.nic_mgmt1) && isAlphanumeric(firstVm.subnet_datain) &&
            isAlphanumeric(firstVm.nic_datain1) && isAlphanumeric(firstVm.subnet_dataout) && isAlphanumeric(firstVm.nic_dataout1) && isAlphanumeric(firstVm.nsg_mgmt) && isAlphanumeric(firstVm.nsg_datain)
            && isAlphanumeric(firstVm.nsg_dataout)

        if (vThunderCount !== "standalone") {
            const secondVm = data.ha
            check = check && isAlphanumeric(secondVm.resource_group) && isNotEmpty(secondVm.location) && isAlphanumeric(secondVm.image) &&
                isAlphanumeric(secondVm.product) && isAlphanumeric(secondVm.instance_name1) && isAlphanumeric(secondVm.instance_name2) && isAlphanumeric(secondVm.size) &&
                isAlphanumeric(secondVm.admin_username) && isAlphanumeric(secondVm.publicIP_name1) && isAlphanumeric(secondVm.publicIP_name2) && isAlphanumeric(secondVm.vnet_nsg_rg) &&
                isAlphanumeric(secondVm.vnet_name) && isAlphanumeric(secondVm.subnet_mgmt) && isAlphanumeric(secondVm.nic_mgmt1) && isAlphanumeric(secondVm.nic_mgmt2) && isAlphanumeric(secondVm.subnet_datain) &&
                isAlphanumeric(secondVm.nic_datain1) && isAlphanumeric(secondVm.nic_datain2) && isAlphanumeric(secondVm.subnet_dataout) && isAlphanumeric(secondVm.nic_dataout1) && isAlphanumeric(secondVm.nic_dataout2)
                && isAlphanumeric(secondVm.nsg_mgmt) && isAlphanumeric(secondVm.nsg_datain)
                && isAlphanumeric(secondVm.nsg_dataout)
        }

        if (vThunderCount === "gslb") {
            const thirdVm = data.gslb
            check = check && (thirdVm.resource_group) && isNotEmpty(thirdVm.location) && isAlphanumeric(thirdVm.image) &&
                isAlphanumeric(thirdVm.product) && isAlphanumeric(thirdVm.instance_name1) && isAlphanumeric(thirdVm.instance_name2) && isAlphanumeric(thirdVm.instance_name3) && isAlphanumeric(thirdVm.size) &&
                isAlphanumeric(thirdVm.admin_username) && isAlphanumeric(thirdVm.publicIP_name1) && isAlphanumeric(thirdVm.publicIP_name2)
                && isAlphanumeric(thirdVm.publicIP_name3) && isAlphanumeric(thirdVm.sec_publicIP_name1) && isAlphanumeric(thirdVm.sec_publicIP_name2) && isAlphanumeric(thirdVm.sec_publicIP_name3) && isAlphanumeric(thirdVm.vnet_nsg_rg) &&
                isAlphanumeric(thirdVm.vnet_name) && isAlphanumeric(thirdVm.subnet_mgmt) && isAlphanumeric(thirdVm.nic_mgmt1) && isAlphanumeric(thirdVm.nic_mgmt2)
                && isAlphanumeric(thirdVm.nic_mgmt3) && isAlphanumeric(thirdVm.subnet_datain) && isAlphanumeric(thirdVm.nic_datain1) && isAlphanumeric(thirdVm.nic_datain2) &&
                isAlphanumeric(thirdVm.nic_datain3) && isAlphanumeric(thirdVm.subnet_dataout) && isAlphanumeric(thirdVm.nic_dataout1) && isAlphanumeric(thirdVm.nic_dataout2) && isAlphanumeric(thirdVm.nic_dataout3) && isAlphanumeric(thirdVm.nsg_mgmt) && isAlphanumeric(thirdVm.nsg_datain)
                && isAlphanumeric(thirdVm.nsg_dataout)

        }
    }

    return check
}
const checkVMware = (vThunderCount, data) => {

    if (vThunderCount === "custom") {
        return false
    }
    let check = true
    check = check && isIpValid(data.esxi_host) && isIpValid(data.vsphere_vcenter_server) && isAlphanumeric(data.vsphere_user) && data["vsphere_datacenter"].length > 0 && isAlphanumeric(data.vsphere_datastore) && data["vsphere_cluster"].length > 0 && isAlphanumeric(data.local_ovf_path)

    const firstVM = data.vm1
    check = check && isAlphanumeric(firstVM.vm_name) && isAlphanumeric(firstVM.cpu) && isAlphanumeric(firstVM.memory) && isAlphanumeric(firstVM.disk_label) && isAlphanumeric(firstVM.disk_size) && isAlphanumeric(firstVM.disk_provisioning) && isIpValid(firstVM.eth0_ipAddress) && isIpValid(firstVM.mgmt_subnet_mask) && isIpValid(firstVM.mgmt_subnet_gateway) && firstVM["vsphere_mgmt_network"].length > 0 && isAlphanumeric(firstVM.mgmt_adaptor) && isIpValid(firstVM.eth1_ipAddress) && isIpValid(firstVM.data_in_subnet_mask) && isIpValid(firstVM.data1_subnet_gateway) && firstVM["vsphere_data1_network"].length > 0 && isAlphanumeric(firstVM.data_adaptor_in) && isIpValid(firstVM.eth2_ipAddress) && isIpValid(firstVM.data_out_subnet_mask) && isIpValid(firstVM.data2_subnet_gateway) && firstVM["vsphere_data2_network"].length > 0 && isAlphanumeric(firstVM.data_adaptor_out)

    if (vThunderCount !== "standalone") {
        const secondVM = data.vm2
        check = check && isAlphanumeric(secondVM.vm_name) && isAlphanumeric(secondVM.cpu) && isAlphanumeric(secondVM.memory) && isAlphanumeric(secondVM.disk_label) && isAlphanumeric(secondVM.disk_size) && isAlphanumeric(secondVM.disk_provisioning) && isIpValid(secondVM.eth0_ipAddress) && isIpValid(secondVM.mgmt_subnet_mask) && isIpValid(secondVM.mgmt_subnet_gateway) && secondVM["vsphere_mgmt_network"].length > 0 && isAlphanumeric(secondVM.mgmt_adaptor) && isIpValid(secondVM.eth1_ipAddress) && isIpValid(secondVM.data_in_subnet_mask) && isIpValid(secondVM.data1_subnet_gateway) && secondVM["vsphere_data1_network"].length > 0 && isAlphanumeric(secondVM.data_adaptor_in) && isIpValid(secondVM.eth2_ipAddress) && isIpValid(secondVM.data_out_subnet_mask) && isIpValid(secondVM.data2_subnet_gateway) && secondVM["vsphere_data2_network"].length > 0 && isAlphanumeric(secondVM.data_adaptor_out)
    }

    if (vThunderCount === "gslb") {
        const thirdVM = data.vm3
        check = check && isAlphanumeric(thirdVM.vm_name) && isAlphanumeric(thirdVM.cpu) && isAlphanumeric(thirdVM.memory) && isAlphanumeric(thirdVM.disk_label) && isAlphanumeric(thirdVM.disk_size) && isAlphanumeric(thirdVM.disk_provisioning) && isIpValid(thirdVM.eth0_ipAddress) && isIpValid(thirdVM.mgmt_subnet_mask) && isIpValid(thirdVM.mgmt_subnet_gateway) && thirdVM["vsphere_mgmt_network"].length > 0 && isAlphanumeric(thirdVM.mgmt_adaptor) && isIpValid(thirdVM.eth1_ipAddress) && isIpValid(thirdVM.data_in_subnet_mask) && isIpValid(thirdVM.data1_subnet_gateway) && thirdVM["vsphere_data1_network"].length > 0 && isAlphanumeric(thirdVM.data_adaptor_in) && isIpValid(thirdVM.eth2_ipAddress) && isIpValid(thirdVM.data_out_subnet_mask) && isIpValid(thirdVM.data2_subnet_gateway) && thirdVM["vsphere_data2_network"].length > 0 && isAlphanumeric(thirdVM.data_adaptor_out)
    }
    return check
}

const checkOracle = (vThunderCount, data) => {
    let check = true;

    if (vThunderCount === "standalone") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.tenancy_ocid) && isAlphanumeric(val.user_ocid) && isAlphanumeric(val.fingerprint) && isAlphanumeric(val.private_key_path) && isAlphanumeric(val.region) && isAlphanumeric(val.compartment_id) && isAlphanumeric(val.availability_domain) && isAlphanumeric(val.thunder_shape) && isAlphanumeric(val.thunder_image)
            && isAlphanumeric(val.thunder_active_memory_in_gbs) && parseInt(val.thunder_active_memory_in_gbs) > 0 && isAlphanumeric(val.thunder_active_ocpus) && parseInt(val.thunder_active_ocpus) > 0 && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id)
        check = check && val.mgmt_nsg_ids.length > 0 && val.data_nsg_ids.length > 0
    } else if (vThunderCount === "ha") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.thunder_name_1) && isAlphanumeric(val.thunder_name_2) && isAlphanumeric(val.vm_key_path) && isAlphanumeric(val.pub_vip) && isAlphanumeric(val.tenancy_ocid) && isAlphanumeric(val.user_ocid) && isAlphanumeric(val.fingerprint) && isAlphanumeric(val.private_key_path) && isAlphanumeric(val.region) && isAlphanumeric(val.compartment_id) && isAlphanumeric(val.availability_domain) && isAlphanumeric(val.thunder_shape) && isAlphanumeric(val.thunder_image) &&
            isAlphanumeric(val.thunder_active_memory_in_gbs) && parseInt(val.thunder_active_memory_in_gbs) > 0 && isAlphanumeric(val.thunder_active_ocpus) && parseInt(val.thunder_active_ocpus) > 0 && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id)
            && isAlphanumeric(val.thunder_standby_memory_in_gbs) && parseInt(val.thunder_standby_memory_in_gbs) > 0 && isAlphanumeric(val.thunder_standby_ocpus) && parseInt(val.thunder_standby_ocpus) > 0
        check = check && val.mgmt_nsg_ids.length > 0 && val.data_nsg_ids.length > 0
    } else if (vThunderCount === "gslb") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.thunder_name_1) && isAlphanumeric(val.thunder_name_2) && isAlphanumeric(val.thunder_name_3) && isAlphanumeric(val.vm_key_path) && isAlphanumeric(val.tenancy_ocid) && isAlphanumeric(val.user_ocid) && isAlphanumeric(val.fingerprint) && isAlphanumeric(val.private_key_path) && isAlphanumeric(val.region) && isAlphanumeric(val.compartment_id) && isAlphanumeric(val.availability_domain) && isAlphanumeric(val.thunder_shape) && isAlphanumeric(val.thunder_image) &&
            isAlphanumeric(val.thunder_active_memory_in_gbs) && parseInt(val.thunder_active_memory_in_gbs) > 0 && isAlphanumeric(val.thunder_active_ocpus) && parseInt(val.thunder_active_ocpus) > 0 && isAlphanumeric(val.thunder_standby_memory_in_gbs) && parseInt(val.thunder_standby_memory_in_gbs) > 0 && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id)
            && isAlphanumeric(val.thunder_standby_ocpus) && parseInt(val.thunder_standby_ocpus) > 0 && isAlphanumeric(val.instance_2_thunder_memory_in_gbs) && parseInt(val.instance_2_thunder_memory_in_gbs) > 0 && isAlphanumeric(val.instance_2_thunder_ocpus) && parseInt(val.instance_2_thunder_ocpus) > 0
        check = check && val.mgmt_nsg_ids.length > 0 && val.data_nsg_ids.length > 0
    } else if (vThunderCount === "custom") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.vm_count) && parseInt(val.vm_count) > 0 && isAlphanumeric(val.tenancy_ocid) && isAlphanumeric(val.user_ocid) && isAlphanumeric(val.fingerprint) && isAlphanumeric(val.private_key_path) && isAlphanumeric(val.region) && isAlphanumeric(val.compartment_id) && isAlphanumeric(val.availability_domain) && isAlphanumeric(val.thunder_shape) && isAlphanumeric(val.thunder_image)
            && isAlphanumeric(val.thunder_active_memory_in_gbs) && parseInt(val.thunder_active_memory_in_gbs) > 0 && isAlphanumeric(val.thunder_active_ocpus) && parseInt(val.thunder_active_ocpus) > 0 && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id)
        check = check && val.mgmt_nsg_ids.length > 0 && val.data_nsg_ids.length > 0 && val.data_out_nsg_ids.length > 0
    }
    return check
}
const checkAWS = (vThunderCount, data) => {
    let check = true;

    if (vThunderCount === "standalone") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.instance1_name) && isAlphanumeric(val.region) && isAlphanumeric(val.thunder_ami_id) && isAlphanumeric(val.thunder_instance_size) && isAlphanumeric(val.key_pair) && isAlphanumeric(val.eip_allocation_id) && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id)
        check = check && val.security_group_mgmt_ids.length > 0 && val.security_group_data_ids.length > 0
    } else if (vThunderCount === "ha") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.create_elastic_ip) && isAlphanumeric(val.instance1_name) && isAlphanumeric(val.instance2_name) && isAlphanumeric(val.region) && isAlphanumeric(val.thunder_ami_id) && isAlphanumeric(val.thunder_instance_size) && isAlphanumeric(val.key_pair) && isAlphanumeric(val.eip_allocation_id_active) && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id) && isAlphanumeric(val.eip_allocation_id_standby)
        check = check && val.data_in_private_ips_active.length === 2 && val.data_out_private_ips_active.length === 2
        if (check) {
            check = check && isIpValid(val.data_in_private_ips_active[0]) && isIpValid(val.data_in_private_ips_active[1]) && isIpValid(val.data_out_private_ips_active[1]) && isIpValid(val.data_out_private_ips_active[0])
        }
        check = check && val.security_group_mgmt_ids.length > 0 && val.security_group_data_ids.length > 0
    } else if (vThunderCount === "gslb") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.instance3_name) && isAlphanumeric(val.instance1_name) && isAlphanumeric(val.instance2_name) && isAlphanumeric(val.region) && isAlphanumeric(val.thunder_ami_id) && isAlphanumeric(val.thunder_instance_size) && isAlphanumeric(val.key_pair) && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id)
        check = check && val.data_in_private_ips_active.length === 2 && val.data_out_private_ips_active.length === 2 && isAlphanumeric(val.eip_allocation_id_master_2) && isAlphanumeric(val.eip_allocation_id_master_1)
        if (check) {
            check = check && isIpValid(val.data_in_private_ips_active[0]) && isIpValid(val.data_in_private_ips_active[1]) && isIpValid(val.data_out_private_ips_active[0]) && isIpValid(val.data_out_private_ips_active[1])
        }

        check = check && val.data_in_private_ips_site1.length === 2 && val.data_out_private_ips_sit1.length === 2 && isAlphanumeric(val.eip_allocation_id_site1_1) && isAlphanumeric(val.eip_allocation_id_site1_2)
        if (check) {
            check = check && isIpValid(val.data_in_private_ips_site1[0]) && isIpValid(val.data_in_private_ips_site1[1]) && isIpValid(val.data_out_private_ips_sit1[0]) && isIpValid(val.data_out_private_ips_sit1[1])
        }

        check = check && val.data_in_private_ips_site2.length === 2 && val.data_out_private_ips_site2.length === 2 && isAlphanumeric(val.eip_allocation_id_site2_1) && isAlphanumeric(val.eip_allocation_id_site2_2)
        if (check) {
            check = check && isIpValid(val.data_in_private_ips_site2[0]) && isIpValid(val.data_in_private_ips_site2[1]) && isIpValid(val.data_out_private_ips_site2[0]) && isIpValid(val.data_out_private_ips_site2[1])
        }
        check = check && val.security_group_mgmt_ids.length > 0 && val.security_group_data_ids.length > 0

    } else if (vThunderCount === "custom") {
        const val = data[vThunderCount]
        check = check && isAlphanumeric(val.instance_count) && parseInt(val.instance_count) > 0 && isAlphanumeric(val.region) && isAlphanumeric(val.thunder_ami_id) && isAlphanumeric(val.thunder_instance_size) && isAlphanumeric(val.key_pair) && isAlphanumeric(val.mgmt_subnet_id) && isAlphanumeric(val.data_in_subnet_id) && isAlphanumeric(val.data_out_subnet_id)
        check = check && val.security_group_mgmt_ids.length > 0 && val.security_group_data_ids.length > 0
    }
    return check
}

const checkChangePassword = (data) => {
    return isIpValid(data.address) && isAlphanumeric(data.username)
}

const checkConfigCredentials = (data) => {
    return isIpValid(data.address) && isAlphanumeric(data.username)
}

const checkSlb = (data) => {
    let checkServer = true;
    let checkVirtualServer = true;
    let checkHttpTemplate = true;
    let checkServiceGroup = true;

    data.configServer.forEach(element => {
        const { name, host, health_check_disable, port_list } = { ...element }
        checkServer = checkServer && isAlphanumeric(name) && isIpValid(host) && isAlphanumeric(health_check_disable)
        port_list.forEach(portData => {
            const { port_number, protocol, health_check_disable } = { ...portData }
            checkServer = checkServer && isAlphanumeric(port_number) && isAlphanumeric(protocol) && isAlphanumeric(health_check_disable)
        })
    });

    data.configServiceGroup.forEach(element => {
        const { name, protocol, member_list } = { ...element }
        checkServiceGroup = checkServiceGroup && isAlphanumeric(name) && isAlphanumeric(protocol)
        member_list.forEach(member => {
            const { name, port } = { ...member }
            checkServiceGroup = checkServiceGroup && isAlphanumeric(name) && isAlphanumeric(port)
        })
    })

    data.configHttp.forEach(element => {
        const { name, switching } = { ...element }
        checkHttpTemplate = checkHttpTemplate && isAlphanumeric(name)
        switching.forEach(member => {
            const { switchingType, conditionType, conditionValue, serviceGrpName } = { ...member }
            checkHttpTemplate = checkHttpTemplate && isAlphanumeric(switchingType) && isAlphanumeric(conditionType) && isAlphanumeric(conditionValue) && isAlphanumeric(serviceGrpName)
        });
    })

    data.configVirtualServer.forEach(element => {
        const { name, ip_address, port_list } = { ...element }
        checkVirtualServer = checkVirtualServer && isAlphanumeric(name) && isIpValid(ip_address)
        port_list.forEach(member => {
            const { port_number, protocol, ha_conn_mirror, auto, service_group, template_http, support_http2 } = { ...member }
            checkVirtualServer = checkVirtualServer && isAlphanumeric(port_number) && isAlphanumeric(protocol) && isAlphanumeric(ha_conn_mirror) && isAlphanumeric(auto) && isAlphanumeric(service_group) && isAlphanumeric(support_http2)
        })
    })

    return checkServer && checkServiceGroup && checkHttpTemplate && checkVirtualServer
}

const checkHA = (data) => {
    let check = true

    const { thunder1, thunder2, common } = { ...data.prerequisite }
    check = check && isAlphanumeric(thunder1.address) && isAlphanumeric(thunder1.username) && isAlphanumeric(thunder1.password) && isAlphanumeric(thunder1.set_id) && isAlphanumeric(thunder1.device_id) && isIpValid(thunder1.primaryIP) && isAlphanumeric(thunder1.priority)

    check = check && isAlphanumeric(thunder2.address) && isAlphanumeric(thunder2.username) && isAlphanumeric(thunder2.password) && isAlphanumeric(thunder2.set_id) && isAlphanumeric(thunder2.device_id) && isIpValid(thunder2.primaryIP) && isAlphanumeric(thunder2.priority)

    check = check && isIpValid(common.ip_v4_addr) && isIpValid(common.ip_address) && isAlphanumeric(common.idle_timeout)

    const { iPRoute } = { ...data }

    iPRoute.forEach(element => {
        check = check && isIpValid(element.ip_dest_addr) && isAlphanumeric(element.ip_mask) && element.ip_next_hop.length > 0
    })

    return check
}

const checkGSLB = (data) => {
    let checkMaster = true
    let checkMember = true
    let checkGSLBSetting = true
    let checkMasterSiteSetting = true
    let checkMemberSiteSetting = true

    const masterController = data.master.masterController
    checkMaster = checkMaster && isAlphanumeric(masterController.master_geo_region) && isAlphanumeric(masterController.master_geo_city) && isIpValid(masterController.master_publicIPMgmt) && isIpValid(masterController.master_publicIPdata) && isIpValid(masterController.master_privateIP) && isAlphanumeric(masterController.master_username) && isSubnetValid(masterController.master_subnetMgmt) && isSubnetValid(masterController.master_subnetdata)
        && isIpValid(masterController.master_dns) && isAlphanumeric(masterController.master_group_name) && isIpValid(masterController.master_destination_ip) && masterController.master_next_hop_ip.length>0 && isAlphanumeric(masterController.master_destination_ip_mask)

    data.master.sites.forEach(ele => {
        checkMaster = checkMaster && isAlphanumeric(ele.site_name) && isNotEmpty(ele.site_geolocation)
            && isIpValid(ele.site_publicIPMgmt) && isAlphanumeric(ele.site_username) && isIpValid(ele.site_publicIPdata)
            && isSubnetValid(ele.site_subnetMgmt) && isSubnetValid(ele.site_subnetdata) && isIpValid(ele.site_privateIP)
            && isIpValid(ele.site_destination_ip) && isAlphanumeric(ele.site_destination_ip_mask) && ele.site_next_hop_ip.length>0
    })

    const memberController = data.member.memberController
    checkMember = checkMember && isAlphanumeric(memberController.member_geo_region) && isAlphanumeric(memberController.member_geo_city) && isIpValid(memberController.member_publicIPMgmt) && isIpValid(memberController.member_publicIPdata) && isIpValid(memberController.member_privateIP) && isAlphanumeric(memberController.member_username) && isSubnetValid(memberController.member_subnetMgmt) && isSubnetValid(memberController.member_subnetdata)
        && isIpValid(memberController.member_dns) && isAlphanumeric(memberController.member_group_name) && isIpValid(memberController.member_destination_ip) && memberController.member_next_hop_ip.length >0 && isAlphanumeric(memberController.member_destination_ip_mask)

    data.member.sites.forEach(ele => {
        checkMember = checkMember && isAlphanumeric(ele.site_name) && isNotEmpty(ele.site_geolocation)
            && isIpValid(ele.site_publicIPMgmt) && isAlphanumeric(ele.site_username) && isIpValid(ele.site_publicIPdata)
            && isSubnetValid(ele.site_subnetMgmt) && isSubnetValid(ele.site_subnetdata) && isIpValid(ele.site_privateIP)
            && isIpValid(ele.site_destination_ip) && isAlphanumeric(ele.site_destination_ip_mask) && ele.site_next_hop_ip.length>0
    })

    const gslbSetting = data.gslbSetting
    checkGSLBSetting = checkGSLBSetting && isAlphanumeric(gslbSetting.gslbpolicy.name)
    checkGSLBSetting = checkGSLBSetting && isAlphanumeric(gslbSetting.gslbzone.name) && isAlphanumeric(gslbSetting.gslbzone.servicePort) && isAlphanumeric(gslbSetting.gslbzone.serviceName)
    checkGSLBSetting = checkGSLBSetting && isAlphanumeric(gslbSetting.virtualServer.name)
    gslbSetting.virtualServer.portList.forEach(pro => {
        checkGSLBSetting = checkGSLBSetting && isAlphanumeric(pro.portNumber) && isAlphanumeric(pro.protocol)
    })
    checkGSLBSetting = checkGSLBSetting && isAlphanumeric(gslbSetting.virtualServer_member.name)
    gslbSetting.virtualServer_member.portList.forEach(pro => {
        checkGSLBSetting = checkGSLBSetting && isAlphanumeric(pro.portNumber) && isAlphanumeric(pro.protocol)
    })
    gslbSetting.serviceIP_master.forEach(ele => {
        checkGSLBSetting = checkGSLBSetting && isAlphanumeric(ele.name)
        ele.portList.forEach(pro => {
            checkGSLBSetting = checkGSLBSetting && isAlphanumeric(pro.portNumber) && isAlphanumeric(pro.protocol)
        })
    })
    gslbSetting.serviceIP_member.forEach(ele => {
        checkGSLBSetting = checkGSLBSetting && isAlphanumeric(ele.name)
        ele.portList.forEach(pro => {
            checkGSLBSetting = checkGSLBSetting && isAlphanumeric(pro.portNumber) && isAlphanumeric(pro.protocol)
        })
    })

    data.master_site_setting.forEach(ele => {

        ele.server.forEach(serverEle => {
            checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(serverEle.name) && isIpValid(serverEle.host)
            serverEle.port_list.forEach(member => {
                checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(member.port_number) && isAlphanumeric(member.protocol)
            })
        })

        ele.service_group.forEach(serviceGrpEle => {
            checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(serviceGrpEle.name) && isAlphanumeric(serviceGrpEle.protocol)
            serviceGrpEle.member_list.forEach(member => {
                checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(member.server_name) && isAlphanumeric(member.port)
            })
        })

        const vs = ele.virtualServer
        checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(vs.name) && isIpValid(vs.host)
        vs.port_list.forEach(member => {
            checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(member.port_number) && isAlphanumeric(member.protocol) && isAlphanumeric(member.service_group)
        })
    })

    data.member_site_setting.forEach(ele => {

        ele.server.forEach(serverEle => {
            checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(serverEle.name) && isIpValid(serverEle.host)
            serverEle.port_list.forEach(member => {
                checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(member.port_number) && isAlphanumeric(member.protocol)
            })
        })

        ele.service_group.forEach(serviceGrpEle => {
            checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(serviceGrpEle.name) && isAlphanumeric(serviceGrpEle.protocol)
            serviceGrpEle.member_list.forEach(member => {
                checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(member.server_name) && isAlphanumeric(member.port)
            })
        })

        const vs = ele.virtualServer
        checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(vs.name) && isIpValid(vs.host)
        vs.port_list.forEach(member => {
            checkMasterSiteSetting = checkMasterSiteSetting && isAlphanumeric(member.port_number) && isAlphanumeric(member.protocol) && isAlphanumeric(member.service_group)
        })
    })

    return checkMaster && checkMember && checkGSLBSetting && checkMasterSiteSetting && checkMemberSiteSetting
}

const checkSSL = (data) => {
    return isAlphanumeric(data.host) && isAlphanumeric(data.username) && isAlphanumeric(data.password) && isAlphanumeric(data.protocol) && isAlphanumeric(data.name) && isAlphanumeric(data.path) && isAlphanumeric(data.certificate_type)

}

const checkGLM = (data) => {
    return isAlphanumeric(data.token) && isIpValid(data.dns_ip)
}

const isValid = (product, cloud, config, vThunderCount, formState) => {
    if (config === undefined) {
        if (cloud === "gcp") {
            return checkGCP(vThunderCount, formState.thunderADC.gcp);
        } else if (cloud === "vmware") {
            return checkVMware(vThunderCount, formState.thunderADC.vmware);
        } else if (cloud === "aws") {
            return checkAWS(vThunderCount, formState.thunderADC.aws)
        } else if (cloud === "azure") {
            return checkAzure(vThunderCount, formState.thunderADC.azure);
        } else if (cloud === "oracle") {
            return checkOracle(vThunderCount, formState.thunderADC.oracle);
        } else {
            return false;
        }
    } else {
        if (config === 'changepassword') {
            return checkConfigCredentials(formState["configCredentials"])
        }
        else if (config === 'slb') {
            return checkConfigCredentials(formState["configCredentials"]) && checkSlb(formState[config])
        } else if (config === 'ha') {
            return checkHA(formState[config])
        } else if (config === 'glm') {
            return checkConfigCredentials(formState["configCredentials"]) && checkGLM(formState[config])
        } else if (config === 'ssl') {
            return checkConfigCredentials(formState["configCredentials"]) && checkSSL(formState[config])
        } else if (config === "gslb") {
            return checkGSLB(formState["gslb"])
        }

    }
}

export { isAlphanumeric, isIpValid, isNotEmpty, isSubnetValid, isValid, isSmallCase };

