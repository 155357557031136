import React from "react";
import { Col, Input, AccordionItem, AccordionHeader, AccordionBody, FormGroup, Card, Label, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric } from "../../../../utils/validations";

const GSLBZone = ({ formState, setFormState }) => {
    const handleChange = (e,) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"]["gslbzone"] = { ...newFormState["gslb"]["gslbSetting"]["gslbzone"], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }
    return (
        <AccordionItem>
        <AccordionHeader targetId="1_2_2">
           Configure GSLB Zone
        </AccordionHeader>
        <AccordionBody accordionId="1_2_2">
            <Card body color="light">
            <FormGroup row>
                    <Label bsSize="sm" for="name" sm={4}
                        style={{ fontSize: "13px", textAlign: "end" }}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Name
                        <span id="gslbname-t" style={{ fontSize: "14px" }}>
                            <strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target="gslbname-t">
                        Specify the DNS zone name for which global SLB is provided.
                        </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                        <Input
                            bsSize="sm"
                            id="name"
                            name="name"
                            type="text"
                            placeholder="gslb.a10networks.com"
                            value={formState["gslb"]["gslbSetting"]["gslbzone"]["name"]}
                            valid={isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbzone"]["name"])}
                            invalid={!isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbzone"]["name"])}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label bsSize="sm" for="policy" sm={4}
                        style={{ fontSize: "13px", textAlign: "end" }}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Policy
                        <span id="policy-t" style={{ fontSize: "14px" }}>
                            <strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target="policy-t">
                            {`The policy name is same as the name defined in Configure Region1 -> Master Controller -> Configure GSLB Settings -> Configure GSLB Policy -> Name for zone "${formState["gslb"]["gslbSetting"]["gslbzone"]["name"]}".`}
                        </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                        <Input
                            bsSize="sm"
                            id="policy"
                            placeholder="a10"
                            disabled
                            value={formState["gslb"]["gslbSetting"]["gslbpolicy"]["name"]}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label bsSize="sm" for="servicePort" sm={4}
                        style={{ fontSize: "13px", textAlign: "end" }}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Service Port
                        <span id="servicePort-t" style={{ fontSize: "14px" }}>
                            <strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target="servicePort-t">
                        Specify the service port for the zone "{formState["gslb"]["gslbSetting"]["gslbzone"]["name"]}".
                        </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                        <Input
                            bsSize="sm"
                            id="servicePort"
                            name="servicePort"
                            type="number"
                            placeholder="80"
                            value={formState["gslb"]["gslbSetting"]["gslbzone"]["servicePort"]}
                            valid={isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbzone"]["servicePort"])}
                            invalid={!isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbzone"]["servicePort"])}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label bsSize="sm" for="serviceName" sm={4}
                        style={{ fontSize: "13px", textAlign: "end" }}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Service Name
                        <span id="serviceName-t" style={{ fontSize: "14px" }}>
                            <strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target="serviceName-t">
                        Specify the service name for the zone "{formState["gslb"]["gslbSetting"]["gslbzone"]["name"]}".
                        </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                        <Input
                            bsSize="sm"
                            id="serviceName"
                            name="serviceName"
                            type="text"
                            placeholder="www"
                            value={formState["gslb"]["gslbSetting"]["gslbzone"]["serviceName"]}
                            valid={isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbzone"]["serviceName"])}
                            invalid={!isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbzone"]["serviceName"])}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
            </Card>
        </AccordionBody>
    </AccordionItem>
    )

}
export default GSLBZone