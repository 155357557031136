import axios from "axios";
import { baseUrl } from "../data/parameters";

const getVMwareFile = (data) => {
    return axios.post(`${baseUrl}/deployments/vmware`, data, {
        responseType: 'blob',
    });
};
const getGcpFile = (data) => {
    return axios.post(`${baseUrl}/deployments/gcp`, data, {
        responseType: 'blob',
    });
};
const getAwsFile = (data) => {
    return axios.post(`${baseUrl}/deployments/aws`, data, {
        responseType: 'blob',
    });
};
const getOracleFile = (data) => {
    return axios.post(`${baseUrl}/deployments/oracle`, data, {
        responseType: 'blob',
    });
};
const getAzureFile = (data) => {
    return axios.post(`${baseUrl}/deployments/azure`, data, {
        responseType: 'blob',
    });
};

export { getVMwareFile, getAwsFile, getAzureFile, getGcpFile, getOracleFile }