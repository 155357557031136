import React from 'react'

const OracleGslbEditor = ({ formState }) => {
  return (
    <div>
      {/* <-----VM-1------> */}
      <>
        <code className="d-block" style={{ fontSize: "13px" }}>
          Configure_VM_1:
        </code>
        {/* {formState["thunderADC"]["oracle"]["gslb"]["tenancy_ocid"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\ttenancy_ocid`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["tenancy_ocid"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["user_ocid"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tuser_ocid`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["user_ocid"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["fingerprint"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tfingerprint`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["fingerprint"]}</span>
        </code>} */}
        
        {formState["thunderADC"]["oracle"]["gslb"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["region"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["compartment_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tcompartment_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["compartment_id"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["availability_domain"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tavailability_domain`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["availability_domain"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_name_1"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_name_1`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_name_1"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_image"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_image`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_image"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_shape"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_shape`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_shape"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_active_memory_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_memory_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_active_memory_in_gbs"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_active_ocpus"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_ocpus`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_active_ocpus"]}</span>
        </code>}
        {/* {formState["thunderADC"]["oracle"]["gslb"]["size_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_instance_master_vol_size_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["size_in_gbs"]}</span>
        </code>} */}
        {formState["thunderADC"]["oracle"]["gslb"]["private_key_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tprivate_key_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["private_key_path"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["vm_key_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tvm_key_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["vm_key_path"]}</span>
        </code>}
        {/* {formState["thunderADC"]["oracle"]["gslb"]["block_vol_attachment_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tblock_vol_attachment_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["block_vol_attachment_type"]}</span>
        </code>} */}

        {/* <-------- Management --------->*/}
        <code className="d-block" style={{ fontSize: "13px" }}>
          {"\tConfigure_Network_Settings:"}
        </code>
        {formState["thunderADC"]["oracle"]["gslb"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["mgmt_subnet_id"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["data_in_subnet_id"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["data_out_subnet_id"]}</span>
        </code>}

        {/* <-------- Security --------->*/}
        <code className="d-block" style={{ fontSize: "13px" }}>
          {"\tConfigure_Security:"}
        </code>
        {formState["thunderADC"]["oracle"]["gslb"]["mgmt_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tmgmt_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
            {formState["thunderADC"]["oracle"]["gslb"]["mgmt_nsg_ids"].map((ele, idx) => {
              return <>{idx > 0 && ", "}{ele}</>
            })}
            ]</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["data_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tdata_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
            {formState["thunderADC"]["oracle"]["gslb"]["data_nsg_ids"].map((ele, idx) => {
              return <>{idx > 0 && ", "}{ele}</>
            })}
            ]</span>
        </code>}
      </>

      <br />
      {/* <-----VM-2------> */}
      <>
        <code className="d-block" style={{ fontSize: "13px" }}>
          Configure_VM_2:
        </code>
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_name_2"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_name_2`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_name_2"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_memory_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_memory_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_memory_in_gbs"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_ocpus"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_ocpus`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_ocpus"]}</span>
        </code>}
        {/* {<code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder1_vol_size_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder1_vol_size_in_gbs"]}</span>
        </code>} */}
      </>

      <br />
      {/* <-----VM-3------> */}
      <>
        <code className="d-block" style={{ fontSize: "13px" }}>
          Configure_VM_3:
        </code>
        {formState["thunderADC"]["oracle"]["gslb"]["thunder_name_3"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_name_3`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder_name_3"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_memory_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_memory_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_memory_in_gbs"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_ocpus"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_ocpus`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_ocpus"]}</span>
        </code>}
        {/* {<code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder2_vol_size_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["gslb"]["thunder2_vol_size_in_gbs"]}</span>
        </code>} */}
      </>
    </div>
  )
}

export default OracleGslbEditor