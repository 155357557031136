import React from "react";
import { FaTrashAlt } from 'react-icons/fa';
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardColumns, Col, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric } from "../../../../utils/validations";

const ServiceIP = ({ formState, setFormState, siteCount }) => {
    const handleChange = (e, idx, thunder) => {
        let newFormState = { ...formState };
        newFormState["gslb"]["gslbSetting"][thunder][idx] = {
            ...newFormState["gslb"]["gslbSetting"][thunder][idx],
            [e.target.name]: e.target.value
        };
        setFormState(newFormState);
    };
    const handleDeletePortList = (idx, pidx, thunder) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"][thunder][idx]["portList"].splice(pidx, 1)
        setFormState(newFormState);
    }
    const handlePortChange = (e, idx, pidx, thunder) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"][thunder][idx]["portList"][pidx] = { ...newFormState["gslb"]["gslbSetting"][thunder][idx]["portList"][pidx], [e.target.name]: [e.target.value] }
        setFormState(newFormState);
    }
    const handleAddPortList = (idx, thunder) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"][thunder][idx]["portList"].push({ "portNumber": "80", "protocol": "tcp" })
        setFormState(newFormState);
    }

    return (
        <AccordionItem>
            <AccordionHeader targetId="1_2_4">
                Configure Service IP - Site
            </AccordionHeader>
            <AccordionBody accordionId="1_2_4">
                <CardColumns>
                    {Array.from({ length: siteCount }).map((item, idx) => (
                        <Card key={idx} body color="light" className="mb-1 p-0">
                            {/* <CardHeader className="m-0">
                                {`For Region 1 Site ${idx+1}`}
                            </CardHeader> */}
                            <CardBody>
                                <FormGroup row>
                                    <Label bsSize="sm" for="name" sm={4} style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Name
                                        <span id={`servicename_master-t-${idx}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`servicename_master-t-${idx}`}>
                                            Specify the name of the service IP for the GSLB group.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`name-${idx}`}
                                            name="name"
                                            type="text"
                                            placeholder="site1"
                                            value={formState["gslb"]["gslbSetting"]["serviceIP_master"][idx]["name"]}
                                            valid={isAlphanumeric(formState["gslb"]["gslbSetting"]["serviceIP_master"][idx]["name"])}
                                            invalid={!isAlphanumeric(formState["gslb"]["gslbSetting"]["serviceIP_master"][idx]["name"])}
                                            onChange={(e) => handleChange(e, idx, "serviceIP_master")}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label bsSize="sm" for="siteName" sm={4} style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Site
                                        <span id={`siteName_master-t-${idx}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`siteName_master-t-${idx}`}>
                                           {`Site is the combination of site name and private IP to the site with which service IP "${formState["gslb"]["gslbSetting"]["serviceIP_master"][idx]["name"]}" is mapped.`}<br/> {`The site name and private IP are located in Configure Region1-> Site-${idx+1} -> Name and Configure Region1-> Site-${idx+1} -> Private IP respectively.`}
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`siteName-${idx}`}
                                            name="site_name"
                                            type="text"
                                            placeholder="site1"
                                            disabled
                                            value={`${formState["gslb"]["master"]["sites"][idx]["site_name"]}-${formState["gslb"]["master"]["sites"][idx]["site_privateIP"]}`}
                                        />
                                    </Col>
                                </FormGroup>
                                {formState["gslb"]["gslbSetting"]["serviceIP_master"][idx]["portList"].map((portList, pidx) => {
                                    return (
                                        <div key={`${idx}_${pidx}`}>
                                            <div className="d-flex justify-content-end">
                                                {pidx > 0 && <Button className='mb-1' size="sm" disabled={pidx === 0} onClick={() => handleDeletePortList(idx, pidx, "serviceIP_master")}><FaTrashAlt /></Button>}
                                            </div>
                                            <FormGroup row>
                                                <Label bsSize="sm" for={`portNumberSIP${pidx + 1}`} sm={4}
                                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                    Port
                                                    <span id={`portNumberSIP_master${pidx + 1}`} style={{ fontSize: "14px" }}>
                                                        <strong> ⓘ</strong>
                                                    </span>
                                                    <UncontrolledTooltip placement="top" target={`portNumberSIP_master${pidx + 1}`}>
                                                        Specify port number for service IP.
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                        bsSize="sm"
                                                        id={`portNumberSIP-${pidx + 1}`}
                                                        name="portNumber"
                                                        placeholder="80"
                                                        type="number"
                                                        value={portList["portNumber"]}
                                                        valid={isAlphanumeric(portList["portNumber"])}
                                                        invalid={!isAlphanumeric(portList["portNumber"])}
                                                        onChange={(e) => handlePortChange(e, idx, pidx, "serviceIP_master")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label bsSize="sm" for={`protocolSIP${pidx + 1}`} sm={4}
                                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                    Protocol
                                                    <span id={`protocolSIP_master${pidx + 1}`} style={{ fontSize: "14px" }}>
                                                        <strong> ⓘ</strong>
                                                    </span>
                                                    <UncontrolledTooltip placement="top" target={`protocolSIP_master${pidx + 1}`}>
                                                        Select protocol for service IP port "{portList["portNumber"]}".
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                        bsSize="sm"
                                                        id={`protocolSIP-${pidx + 1}`}
                                                        name="protocol"
                                                        placeholder="Select"
                                                        type="select"
                                                        value={portList["protocol"]}
                                                        onChange={(e) => handlePortChange(e, idx, pidx, "serviceIP_master")} >
                                                        <option>tcp</option>
                                                        <option>udp</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <div className="d-flex justify-content-end">
                                                {pidx === (formState["gslb"]["gslbSetting"]["serviceIP_master"][idx]["portList"].length - 1) && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddPortList(idx, "serviceIP_master")}>Add Port List</Button>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </CardBody>
                        </Card>
                    ))}
                    {/* {Array.from({ length: siteCount }).map((item, idx) => (
                        <Card key={idx} body color="light" className="mb-1 p-0">
                            <CardBody>
                                <FormGroup row>
                                    <Label bsSize="sm" for="name" sm={4} style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Name
                                        <span id={`servicename_member-t-${idx}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`servicename_member-t-${idx}`}>
                                            Specify the name of the service IP for the GSLB group in region 2.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`name-${idx}`}
                                            name="name"
                                            type="text"
                                            placeholder="site1"
                                            value={formState["gslb"]["gslbSetting"]["serviceIP_member"][idx]["name"]}
                                            valid={isAlphanumeric(formState["gslb"]["gslbSetting"]["serviceIP_member"][idx]["name"])}
                                            invalid={!isAlphanumeric(formState["gslb"]["gslbSetting"]["serviceIP_member"][idx]["name"])}
                                            onChange={(e) => handleChange(e, idx, "serviceIP_member")}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label bsSize="sm" for="siteName" sm={4} style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Site
                                        <span id={`siteName_member-t-${idx}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`siteName_member-t-${idx}`}>
                                            Specify the site name for the service IP for the GSLB group.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`siteName-${idx}`}
                                            name="site_name"
                                            type="text"
                                            placeholder="site1"
                                            disabled
                                            value={`${formState["gslb"]["member"]["sites"][idx]["site_name"]}-${formState["gslb"]["member"]["sites"][idx]["site_privateIP"]}`}
                                        />
                                    </Col>
                                </FormGroup>
                                {formState["gslb"]["gslbSetting"]["serviceIP_member"][idx]["portList"].map((portList, pidx) => {
                                    return (
                                        <div key={`${idx}_${pidx}`}>
                                            <div className="d-flex justify-content-end">
                                                {pidx > 0 && <Button className='mb-1' size="sm" disabled={pidx === 0} onClick={() => handleDeletePortList(idx, pidx, "serviceIP_member")}><FaTrashAlt /></Button>}
                                            </div>
                                            <FormGroup row>
                                                <Label bsSize="sm" for={`portNumberSIP${pidx + 1}`} sm={4}
                                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                    Port
                                                    <span id={`portNumberSIP_member${pidx + 1}`} style={{ fontSize: "14px" }}>
                                                        <strong> ⓘ</strong>
                                                    </span>
                                                    <UncontrolledTooltip placement="top" target={`portNumberSIP_member${pidx + 1}`}>
                                                        Specify port number for service ip.
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                        bsSize="sm"
                                                        id={`portNumberSIP-${pidx + 1}`}
                                                        name="portNumber"
                                                        placeholder="80"
                                                        type="number"
                                                        value={portList["portNumber"]}
                                                        valid={isAlphanumeric(portList["portNumber"])}
                                                        invalid={!isAlphanumeric(portList["portNumber"])}
                                                        onChange={(e) => handlePortChange(e, idx, pidx, "serviceIP_member")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label bsSize="sm" for={`protocolSIP${pidx + 1}`} sm={4}
                                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                    Protocol
                                                    <span id={`protocolSIP_member${pidx + 1}`} style={{ fontSize: "14px" }}>
                                                        <strong> ⓘ</strong>
                                                    </span>
                                                    <UncontrolledTooltip placement="top" target={`protocolSIP_member${pidx + 1}`}>
                                                        Select protocol for service ip port "{portList["portNumber"]}".
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                        bsSize="sm"
                                                        id={`protocolSIP-${pidx + 1}`}
                                                        name="protocol"
                                                        placeholder="Select"
                                                        type="select"
                                                        value={portList["protocol"]}
                                                        onChange={(e) => handlePortChange(e, idx, pidx, "serviceIP_member")} >
                                                        <option>tcp</option>
                                                        <option>udp</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <div className="d-flex justify-content-end">
                                                {pidx === (formState["gslb"]["gslbSetting"]["serviceIP_member"][idx]["portList"].length - 1) && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddPortList(idx, "serviceIP_member")}>Add Port List</Button>}
                                            </div>
                                        </div>
                                    )

                                })}
                            </CardBody>
                        </Card>
                    ))} */}
                </CardColumns>
            </AccordionBody>
        </AccordionItem>
    );
}

export default ServiceIP;
