import React, { useState } from "react";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, UncontrolledAccordion, UncontrolledTooltip } from "reactstrap";
import { awsInstanceSize, awsRegionImages } from '../../../../data/parameters';
import { isAlphanumeric, isIpValid } from '../../../../utils/validations';

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} className='ms-1 mb-2'>
    <div style={{ fontSize: '14px' }}>{label}</div>
    {(data.version && data.link) && <Row className="m-0" style={{ fontSize: '12px', color: '#888' }}>
      <Col>version-{data.version}</Col>
      <Col className='text-end me-2'><a target="_blank" href={data.link} style={{ "textDecoration": "none" }}>More Details</a></Col>
    </Row>}
  </div>
)
const FormatOptionLabel = ({ label, version, link }) => {
  return (
    <div className='m-0 p-0'>
      <div style={{ fontSize: '14px' }}>{label}</div>
      {(version && link) &&
        <Row style={{ fontSize: '12px', color: '#888' }}>
          <Col>version-{version}</Col>
          <Col className='text-end me-2'><a target="_blank" href={link} style={{ "textDecoration": "none" }}>More Details</a></Col>
        </Row>
      }
    </div>
  );
}

const AwsGslbForm = ({ formState, setFormState, version_thunder }) => {
  const [securityGrpFields, setSecurityGrpFields] = useState({
    "data_in_private_ips_active": [...formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"]].map((value) => ({ value, label: value })),
    "data_out_private_ips_active": [...formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"]].map((value) => ({ value, label: value })),
    "data_in_private_ips_site1": [...formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"]].map((value) => ({ value, label: value })),
    "data_out_private_ips_sit1": [...formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"]].map((value) => ({ value, label: value })),
    "data_in_private_ips_site2": [...formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"]].map((value) => ({ value, label: value })),
    "data_out_private_ips_site2": [...formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"]].map((value) => ({ value, label: value })),
    "security_group_mgmt_ids": [...formState["thunderADC"]["aws"]["gslb"]["security_group_mgmt_ids"]].map((value) => ({ value, label: value })),
    "security_group_data_ids": [...formState["thunderADC"]["aws"]["gslb"]["security_group_data_ids"]].map((value) => ({ value, label: value })),
  });

  const handleChange = (e) => {
    const newFormState = { ...formState };
    if (e.target.name === "region" && version_thunder === "v6.x") {
      newFormState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"] = awsRegionImages[e.target.value]["ami"][version_thunder][0]["value"]
      if (e.target.value === "eu-north-1" || e.target.value === "eu-west-3") {
        newFormState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] = "m5.xlarge"
      } else {
        newFormState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] = "m4.xlarge"
      }
    } else if (e.target.name === "thunder_ami_id") {
      if (e.target.value === "eu-north-1" || e.target.value === "eu-west-3") {
        newFormState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] = "m5.xlarge"
      } else {
        newFormState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] = "m4.xlarge"
      }
    }
    newFormState["thunderADC"]["aws"]["gslb"] = { ...newFormState["thunderADC"]["aws"]["gslb"], [e.target.name]: e.target.value }
    setFormState(newFormState)
  }
  const handleArrayChange = (e, idx) => {
    const newFormState = { ...formState };
    newFormState["thunderADC"]["aws"]["gslb"][e.target.name][idx] = e.target.value
    setFormState(newFormState)
  }
  const handleSecurityChange = (selectedList, fieldName) => {
    if (selectedList.length > 2 && fieldName !== "security_group_mgmt_ids" && fieldName !== "security_group_data_ids") {
      return
    }
    const valuesArray = selectedList.map(item => item.value);

    let newSecurityGrpFields = { ...securityGrpFields }
    newSecurityGrpFields[fieldName] = selectedList
    setSecurityGrpFields(newSecurityGrpFields)

    let newFormState = { ...formState };
    newFormState["thunderADC"]["aws"]["gslb"][fieldName] = valuesArray;
    setFormState(newFormState);
  };

  return (
    <UncontrolledAccordion stayOpen className="pb-2">
      <AccordionItem>
        <AccordionHeader targetId="1">
          Configure Virtual Machine-1
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <Card body color="light" className="pb-0">

            <FormGroup row>
              <Label bsSize="sm" for="region" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Region
                <span id="aws-3vm-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-region_t">
                  {`Choose the AWS region where you want to deploy the resources.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input
                  bsSize="sm"
                  id="region"
                  name="region"
                  required
                  type="select"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["region"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["region"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["region"]}
                  onChange={handleChange}
                >
                  {Object.keys(awsRegionImages).map((region, idx) => {
                    return (
                      <option key={idx} value={region} >{awsRegionImages[region]["label"]}</option>
                    )
                  })}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="instance1_name" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                VM Name
                <span id="aws-3vm-instance1_name_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-instance1_name_t">
                  {`Specify the Thunder instance name.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="instance1_name"
                  name="instance1_name"
                  placeholder="vth-inst-1"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["instance1_name"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["instance1_name"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["instance1_name"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            {/* <FormGroup row>
              <Label bsSize="sm" for="thunder_instance_size" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Instance Size
                <span id="aws-3vm-instance_size_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-instance_size_t">
                  {`Specify the available instance or compute type supported for vThunder. The default instance type is m4.xlarge, which contains 4 vCPUs and 16 GiB of memory. vThunder requires a minimum of 4 vCPUs , 16 GiB of memory and support of 3NICs attachment. For more information, check the AWS Marketplace product pricing section. Go to AWS -> EC2 -> Launch an instance -> Instance type -> Compare instance types -> Find and copy relevant instance type.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_instance_size"
                  name="thunder_instance_size"
                  placeholder="m4.xlarge"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"]}
                  onChange={handleChange}
                />
                <a href="https://aws.amazon.com/ec2/instance-types/" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_ami_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Image
                <span id="aws-3vm-thunder_ami_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-thunder_ami_id_t">
                  {`Choose from the available AMI IDs for a10 thunder instance. A10 Thunder ADC for Advanced Load Balancing - BYOL is set as the default AMI ID for us-east-1 region. Find more types of thunder images available with different capacities in the respective regions. Go to AWS -> EC2 -> Launch an instance -> Application and OS Images (Amazon Machine Image) -> search for vThunder -> AWS Marketplace AMIs -> select the relevant thunder image -> click on continue -> copy ami ID.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input
                  bsSize="sm"
                  id="thunder_ami_id"
                  name="thunder_ami_id"
                  required
                  type="select"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]}
                  onChange={handleChange}
                >
                  {awsRegionImages[formState["thunderADC"]["aws"]["gslb"]["region"]]["ami"].map((ami, idx) => {
                    return (
                      <option key={idx} value={ami["value"]} >{ami["label"]}</option>
                    )
                  })}
                </Input>
              </Col>
            </FormGroup> */}

            <FormGroup row>
              <Label bsSize="sm" for="thunder_ami_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Image
                <span id="aws-2vm-thunder_ami_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-2vm-thunder_ami_id_t">
                  {`Specify/Choose from the available AMI IDs for a10 thunder instance. A10 Thunder ADC for Advanced Load Balancing - BYOL is set as the default AMI ID for us-east-1 region. Find more types of thunder images available with different capacities in the respective regions. Go to AWS -> EC2 -> Launch an instance -> Application and OS Images (Amazon Machine Image) -> search for vThunder -> AWS Marketplace AMIs -> select the relevant thunder image -> click on continue -> copy ami ID.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <CreatableSelect
                  inputId="thunder_ami_id"
                  name="thunder_ami_id"
                  placeholder="Select an image"
                  isSearchable
                  isValidNewOption={(inputValue, selectValue, selectOptions) => {
                    return isAlphanumeric(inputValue)
                  }}
                  options={awsRegionImages[formState["thunderADC"]["aws"]["gslb"]["region"]]["ami"][version_thunder]}
                  components={{ Option: CustomOption }}
                  value={awsRegionImages[formState["thunderADC"]["aws"]["gslb"]["region"]]["ami"][version_thunder]?.find((ami) => ami?.value === formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]) || { "label": formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"], "value": formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"] }}
                  onChange={(selectedOption) => handleChange({ target: { name: 'thunder_ami_id', value: selectedOption.value } })}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px', // Adjust the font size
                      lineHeight: '1', // Adjust the line height
                      padding: '8px', // Adjust the padding
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      fontSize: "14px",
                    })
                  }}
                  formatOptionLabel={FormatOptionLabel}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_instance_size" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Size
                <span id="aws-2vm-thunder_instance_size_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-2vm-thunder_instance_size_t">
                  {`Specify/Choose the available instance or compute type supported for vThunder. The default instance type is m4.xlarge, which contains 4 vCPUs and 16 GiB of memory. vThunder requires a minimum of 4 vCPUs , 16 GiB of memory and support of 3NICs attachment. For more information, check the AWS Marketplace product pricing section. Go to AWS -> EC2 -> Launch an instance -> Instance type -> Compare instance types -> Find and copy relevant instance type.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <CreatableSelect
                  inputId="thunder_instance_size"
                  name="thunder_instance_size"
                  placeholder="Select a size"
                  isSearchable
                  isValidNewOption={(inputValue, selectValue, selectOptions) => {
                    return isAlphanumeric(inputValue)
                  }}
                  options={awsInstanceSize[formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]]}
                  value={!awsInstanceSize[formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]] ? { "label": formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"], "value": formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] } : awsInstanceSize[formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]].find((size) => size.value === formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"])}
                  onChange={(selectedOption) => handleChange({ target: { name: 'thunder_instance_size', value: selectedOption.value } })}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px', // Adjust the font size
                      lineHeight: '1', // Adjust the line height
                      padding: '8px', // Adjust the padding
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      fontSize: "14px",
                    })
                  }}
                />

                <a
                  href={awsRegionImages[formState["thunderADC"]["aws"]["gslb"]["region"]]["ami"][version_thunder].find(({ value }) => value === formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"])?.link}
                  target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}>
                  Help
                </a>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label bsSize="sm" for="key_pair" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Key Pair
                <span id="aws-3vm-key_pair_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-key_pair_t">
                  {`Specify the key pair name. A key pair, consisting of a public key and a private key, is a set of security credentials that you use to prove your identity when connecting to an Amazon EC2 instance.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="key_pair"
                  name="key_pair"
                  placeholder="keypair"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["key_pair"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["key_pair"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["key_pair"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>



          </Card>
          <UncontrolledAccordion stayOpen className="mb-2">
            <AccordionItem>
              <AccordionHeader targetId={`1`}>
                Configure Network Settings
              </AccordionHeader>
              <AccordionBody accordionId={`1`}>
                <Card body color="light"
                  className="p-0"
                >

                  {/* <------------- MANAGEMENT --------------> */}
                  <CardHeader>
                    Management
                  </CardHeader>
                  <CardBody className='pb-0'>
                    <FormGroup row>
                      <Label bsSize="sm" for="eip_allocation_id_master_1" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Elastic IP
                        <span id="aws-3vm-eip_allocation_id_master_1_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="aws-3vm-eip_allocation_id_master_1_t">
                          {`Specify the available elastic IP allocation ID. This will not create a new Elastic Public IP. Go to AWS -> EC2 -> Network & Security -> Elastic IPs -> Select the IP address and then, in summary, copy the Allocation ID.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="eip_allocation_id_master_1"
                          name="eip_allocation_id_master_1"
                          placeholder="eipalloc-0aad4f76eff6a489e"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_1"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_1"])}
                          value={formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_1"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label bsSize="sm" for="mgmt_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Subnet ID
                        <span id="aws-3vm-mgmt_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="aws-3vm-mgmt_subnet_id_t">
                          {`Specify the available subnet ID for managing vThunder. This subnet should be available within a selected virtual private network (VPC). Go to AWS -> Virtual private cloud -> Subnets -> Select the subnet and then, in the details tab, copy the Subnet ID.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="mgmt_subnet_id"
                          name="mgmt_subnet_id"
                          placeholder="subnet-0a74a1fda2af5ee61"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["mgmt_subnet_id"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["mgmt_subnet_id"])}
                          value={formState["thunderADC"]["aws"]["gslb"]["mgmt_subnet_id"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                  {/* <------------- Data IN --------------> */}
                  <hr className='mb-0 pb-0' />
                  <CardHeader>
                    Data (IN)
                  </CardHeader>
                  <CardBody className="pb-0">
                    <FormGroup row>
                      <Label bsSize="sm" for="eip_allocation_id_master_2" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Elastic IP
                        <span id="aws-3vm-eip_allocation_id_master_2_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="aws-3vm-eip_allocation_id_master_2_t">
                          {`Specify the available elastic IP allocation ID. This will not create a new Elastic Public IP. Go to AWS -> EC2 -> Network & Security -> Elastic IPs -> Select the IP address and then, in summary, copy the Allocation ID.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="eip_allocation_id_master_2"
                          name="eip_allocation_id_master_2"
                          placeholder="eipalloc-0aad4f76eff6a489e"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_2"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_2"])}
                          value={formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_2"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label bsSize="sm" for="data_in_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Subnet ID
                        <span id="aws-3vm-data_in_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="aws-3vm-data_in_subnet_id_t">
                          {`Specify the available subnet ID for data traffic flow inward to vThunder. This subnet should be available within a selected virtual private network (VPC). Go to AWS -> Virtual private cloud -> Subnets -> Select the subnet and then, in the details tab, copy the Subnet ID.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_in_subnet_id"
                          name="data_in_subnet_id"
                          placeholder="subnet-003392abd969f5303"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["data_in_subnet_id"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["data_in_subnet_id"])}
                          value={formState["thunderADC"]["aws"]["gslb"]["data_in_subnet_id"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label bsSize="sm" for={`data_in_private_ips_active`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Private IP (Data-In)
                        <span id={`aws-3vm-data_in_private_ips_active_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target={`aws-3vm-data_in_private_ips_active_t`}>
                          {`Specify available two private IP addresses within the selected subnet ID (Data-In). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <CreatableSelect
                          isMulti
                          value={securityGrpFields["data_in_private_ips_active"]}
                          onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_in_private_ips_active")}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minHeight: "35px",
                              fontSize: "14px",
                              borderColor: securityGrpFields["data_in_private_ips_active"].length === 2 ? "#198754 !important" : "#dc3545 !important"
                            }),
                          }}
                        />
                      </Col>
                    </FormGroup> */}
                    <FormGroup row>
                      <Label bsSize="sm" for={`data_in_private_ips_active`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Primary Private IP
                        <span id={`aws-3vm-data_in_primary_private_ip_active_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target={`aws-3vm-data_in_primary_private_ip_active_t`}>
                          {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_in_private_ips_active"
                          name="data_in_private_ips_active"
                          placeholder="10.0.2.103"
                          required="true"
                          type="text"
                          valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"][0])}
                          invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"][0])}
                          value={formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"][0]}
                          onChange={(e) => handleArrayChange(e, 0)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label bsSize="sm" for={`data_in_private_ips_active`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Secondary Private IP
                        <span id={`aws-3vm-data_in_secondary_private_ip_active_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target={`aws-3vm-data_in_secondary_private_ip_active_t`}>
                          {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_in_private_ips_active"
                          name="data_in_private_ips_active"
                          placeholder="10.0.2.116"
                          required="true"
                          type="text"
                          valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"][1])}
                          invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"][1])}
                          value={formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"][1]}
                          onChange={(e) => handleArrayChange(e, 1)}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                  {/* <------------- Data OUT --------------> */}
                  <hr className='mb-0 pb-0' />
                  <CardHeader>
                    Data (OUT)
                  </CardHeader>
                  <CardBody className="pb-0">
                    <FormGroup row>
                      <Label bsSize="sm" for="data_out_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Subnet ID
                        <span id="aws-3vm-data_out_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="aws-3vm-data_out_subnet_id_t">
                          {`Specify the available subnet ID for data traffic flow outward to vThunder. This subnet should be available within a selected virtual private network (VPC). Go to AWS -> Virtual private cloud -> Subnets -> Select the subnet and then, in the details tab, copy the Subnet ID.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_out_subnet_id"
                          name="data_out_subnet_id"
                          placeholder="subnet-0ac90f2b79bb550b1"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["data_out_subnet_id"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["data_out_subnet_id"])}
                          value={formState["thunderADC"]["aws"]["gslb"]["data_out_subnet_id"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label bsSize="sm" for={`data_out_private_ips_active`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Private IP (Data-Out)
                        <span id={`aws-3vm-data_out_private_ips_active_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target={`aws-3vm-data_out_private_ips_active_t`}>
                          {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <CreatableSelect
                          isMulti
                          value={securityGrpFields["data_out_private_ips_active"]}
                          onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_out_private_ips_active")}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minHeight: "35px",
                              fontSize: "14px",
                              borderColor: securityGrpFields["data_out_private_ips_active"].length === 2 ? "#198754 !important" : "#dc3545 !important"
                            }),
                          }}
                        />
                      </Col>
                    </FormGroup> */}
                    <FormGroup row>
                      <Label bsSize="sm" for={`data_out_private_ips_active`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Primary Private IP
                        <span id={`aws-3vm-data_out_primary_private_ip_active_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target={`aws-3vm-data_out_primary_private_ip_active_t`}>
                          {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_out_private_ips_active"
                          name="data_out_private_ips_active"
                          placeholder="10.0.3.107"
                          required="true"
                          type="text"
                          valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"][0])}
                          invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"][0])}
                          value={formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"][0]}
                          onChange={(e) => handleArrayChange(e, 0)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label bsSize="sm" for={`data_out_private_ips_active`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Secondary Private IP
                        <span id={`aws-3vm-data_out_secondary_private_ip_active_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target={`aws-3vm-data_out_secondary_private_ip_active_t`}>
                          {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_out_private_ips_active"
                          name="data_out_private_ips_active"
                          placeholder="10.0.3.112"
                          required="true"
                          type="text"
                          valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"][1])}
                          invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"][1])}
                          value={formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"][1]}
                          onChange={(e) => handleArrayChange(e, 1)}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId={`2`}>
                Configure Security
              </AccordionHeader>
              <AccordionBody accordionId={`2`}>
                <Card body color="light">
                  <FormGroup row>
                    <Label bsSize="sm" for={`security_group_mgmt_ids`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Network Security Group ID (Management)
                      <span id={`aws-3vm-security_group_mgmt_ids_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-security_group_mgmt_ids_t`}>
                        {`Specify the available security group IDs within a selected virtual private network (VPC). Go to AWS -> EC2 -> Network & Security -> Security Groups -> Select the security group and then, in the details tab, copy the Security group ID.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <CreatableSelect
                        isMulti
                        isValidNewOption={(inputValue, selectValue, selectOptions) => {
                          return isAlphanumeric(inputValue)
                        }}
                        value={securityGrpFields["security_group_mgmt_ids"]}
                        onChange={(selectedOption) => handleSecurityChange(selectedOption, "security_group_mgmt_ids")}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            minHeight: "35px",
                            fontSize: "14px",
                            borderColor: securityGrpFields["security_group_mgmt_ids"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                          }),
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`security_group_data_ids`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Network Security Group ID (Data-In/Out)
                      <span id={`aws-3vm-security_group_data_ids_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-security_group_data_ids_t`}>
                        {`Specify the available security group IDs within a selected virtual private network (VPC). Go to AWS -> EC2 -> Network & Security -> Security Groups -> Select the security group and then, in the details tab, copy the Security group ID.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <CreatableSelect
                        isMulti
                        isValidNewOption={(inputValue, selectValue, selectOptions) => {
                          return isAlphanumeric(inputValue)
                        }}
                        value={securityGrpFields["security_group_data_ids"]}
                        onChange={(selectedOption) => handleSecurityChange(selectedOption, "security_group_data_ids")}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            minHeight: "35px",
                            fontSize: "14px",
                            borderColor: securityGrpFields["security_group_data_ids"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                          }),
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Card>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        </AccordionBody>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader targetId="2">
          Configure Virtual Machine-2
        </AccordionHeader>
        <AccordionBody accordionId="2">
          <Card body color="light" className="pb-0">
            <FormGroup row>
              <Label bsSize="sm" for="region" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Region
                <span id="aws-3vm-2-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-2-region_t">
                  {`Region will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="region"
                  name="region"
                  placeholder="us-east-1"
                  required="true"
                  type="text"
                  disabled
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["region"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["region"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["region"]}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="instance2_name" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                VM Name
                <span id="aws-3vm-2-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-2-region_t">
                  {`Specify the Thunder instance name.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="instance2_name"
                  name="instance2_name"
                  placeholder="vth-inst-2"
                  required="true"
                  type="text"
                  onChange={handleChange}
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["instance2_name"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["instance2_name"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["instance2_name"]}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_ami_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Image
                <span id="aws-3vm-2-thunder_ami_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-2-thunder_ami_id_t">
                  {`Image will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_ami_id"
                  name="thunder_ami_id"
                  placeholder=""
                  required="true"
                  disabled
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]}
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_instance_size" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Size
                <span id="aws-3vm-2-instance_size_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-2-instance_size_t">
                  {`Size will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_instance_size"
                  name="thunder_instance_size"
                  placeholder=""
                  required="true"
                  disabled
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"]}
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="key_pair" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Key Pair
                <span id="aws-3vm-2-key_pair_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-2-key_pair_t">
                  {`Key Pair will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="key_pair"
                  name="key_pair"
                  placeholder=""
                  required="true"
                  type="text"
                  disabled
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["key_pair"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["key_pair"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["key_pair"]}
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>


          </Card>
          <AccordionItem>
            <AccordionHeader targetId="2_1">
              Configure Network Settings
            </AccordionHeader>
            <AccordionBody accordionId="2_1">
              <Card body color="light"
                className="p-0"
              >

                {/* <------------- MANAGEMENT --------------> */}
                <CardHeader>
                  Management
                </CardHeader>
                <CardBody className='pb-0'>
                  <FormGroup row>
                    <Label bsSize="sm" for="eip_allocation_id_site1_1" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Elastic IP
                      <span id="aws-3vm-eip_allocation_id_site1_1_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target="aws-3vm-eip_allocation_id_site1_1_t">
                        {`Specify the available elastic IP allocation ID. This will not create a new Elastic Public IP. Go to AWS -> EC2 -> Network & Security -> Elastic IPs -> Select the IP address and then, in summary, copy the Allocation ID.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="eip_allocation_id_site1_1"
                        name="eip_allocation_id_site1_1"
                        placeholder="eipalloc-0aad4f76eff6a489e"
                        required="true"
                        type="text"
                        valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_1"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_1"])}
                        value={formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_1"]}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
                {/* <------------- Data IN --------------> */}
                <hr className='mb-0 pb-0' />
                <CardHeader>
                  Data (IN)
                </CardHeader>
                <CardBody className="pb-0">
                  {/* <FormGroup row>
              <Label bsSize="sm" for={`data_in_private_ips_site1`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Private IP (Data-In)
                <span id={`aws-3vm-2-data_in_private_ips_site1_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target={`aws-3vm-2-data_in_private_ips_site1_t`}>
                  {`Specify available two private IP addresses within the selected subnet ID (Data-In). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <CreatableSelect
                  isMulti
                  value={securityGrpFields["data_in_private_ips_site1"]}
                  onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_in_private_ips_site1")}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      minHeight: "35px",
                      fontSize: "14px",
                      borderColor: securityGrpFields["data_in_private_ips_site1"].length === 2 ? "#198754 !important" : "#dc3545 !important"
                    }),
                  }}
                />
              </Col>
            </FormGroup> */}
                  <FormGroup row>
                    <Label bsSize="sm" for="eip_allocation_id_site1_2" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Elastic IP
                      <span id="aws-3vm-eip_allocation_id_site1_2_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target="aws-3vm-eip_allocation_id_site1_2_t">
                        {`Specify the available elastic IP allocation ID. This will not create a new Elastic Public IP. Go to AWS -> EC2 -> Network & Security -> Elastic IPs -> Select the IP address and then, in summary, copy the Allocation ID.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="eip_allocation_id_site1_2"
                        name="eip_allocation_id_site1_2"
                        placeholder="eipalloc-0aad4f76eff6a489e"
                        required="true"
                        type="text"
                        valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_2"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_2"])}
                        value={formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_2"]}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_in_private_ips_site1`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Primary Private IP
                      <span id={`aws-3vm-data_in_primary_private_ips_site1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_in_primary_private_ips_site1`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-In). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_in_private_ips_site1"
                        name="data_in_private_ips_site1"
                        placeholder="10.0.2.103"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"][0])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"][0])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"][0]}
                        onChange={(e) => handleArrayChange(e, 0)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_in_private_ips_site1`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Secondary Private IP
                      <span id={`aws-3vm-data_in_secondary_private_ips_site1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_in_secondary_private_ips_site1`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-In). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_in_private_ips_site1"
                        name="data_in_private_ips_site1"
                        placeholder="10.0.2.104"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"][1])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"][1])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"][1]}
                        onChange={(e) => handleArrayChange(e, 1)}
                      />
                    </Col>
                  </FormGroup>

                </CardBody>
                {/* <------------- Data OUT --------------> */}
                <hr className='mb-0 pb-0' />
                <CardHeader>
                  Data (OUT)
                </CardHeader>
                <CardBody>
                  {/* <FormGroup row>
              <Label bsSize="sm" for={`data_out_private_ips_sit1`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Private IP (Data-Out)
                <span id={`aws-3vm-2-data_out_private_ips_sit1_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target={`aws-3vm-2-data_out_private_ips_sit1_t`}>
                  {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <CreatableSelect
                  isMulti
                  value={securityGrpFields["data_out_private_ips_sit1"]}
                  onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_out_private_ips_sit1")}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      minHeight: "35px",
                      fontSize: "14px",
                      borderColor: securityGrpFields["data_out_private_ips_sit1"].length === 2 ? "#198754 !important" : "#dc3545 !important"
                    }),
                  }}
                />
              </Col>
            </FormGroup> */}
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_out_private_ips_sit1`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Primary Private IP
                      <span id={`aws-3vm-data_out_primary_private_ips_site1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_out_primary_private_ips_site1`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_out_private_ips_sit1"
                        name="data_out_private_ips_sit1"
                        placeholder="10.0.3.103"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"][0])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"][0])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"][0]}
                        onChange={(e) => handleArrayChange(e, 0)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_out_private_ips_sit1`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Secondary Private IP
                      <span id={`aws-3vm-data_out_secondary_private_ips_site1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_out_secondary_private_ips_site1`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_out_private_ips_sit1"
                        name="data_out_private_ips_sit1"
                        placeholder="10.0.3.104"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"][1])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"][1])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"][1]}
                        onChange={(e) => handleArrayChange(e, 1)}
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </AccordionBody>
          </AccordionItem>
        </AccordionBody>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader targetId="3">
          Configure Virtual Machine-3
        </AccordionHeader>
        <AccordionBody accordionId="3">
          <Card body color="light" className="pb-0">
            <FormGroup row>
              <Label bsSize="sm" for="region" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Region
                <span id="aws-3vm-3-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-3-region_t">
                  {`Region will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="region"
                  name="region"
                  placeholder="us-east-1"
                  required="true"
                  type="text"
                  disabled
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["region"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["region"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["region"]}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="instance3_name" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                VM Name
                <span id="aws-3vm-3-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-3-region_t">
                  {`Specify the Thunder instance name.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="instance3_name"
                  name="instance3_name"
                  placeholder="vth-inst-3"
                  required="true"
                  type="text"
                  onChange={handleChange}
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["instance3_name"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["instance3_name"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["instance3_name"]}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_ami_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Image
                <span id="aws-3vm-3-thunder_ami_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-3-thunder_ami_id_t">
                  {`Image will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_ami_id"
                  name="thunder_ami_id"
                  placeholder=""
                  required="true"
                  disabled
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]}
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_instance_size" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Size
                <span id="aws-3vm-3-instance_size_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-3-instance_size_t">
                  {`Size will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_instance_size"
                  name="thunder_instance_size"
                  placeholder=""
                  required="true"
                  disabled
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"]}
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="key_pair" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Key Pair
                <span id="aws-3vm-3-key_pair_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="aws-3vm-3-key_pair_t">
                  {`Key Pair will be same as specified in Virtual Machine-1.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="key_pair"
                  name="key_pair"
                  placeholder=""
                  required="true"
                  type="text"
                  disabled
                  valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["key_pair"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["key_pair"])}
                  value={formState["thunderADC"]["aws"]["gslb"]["key_pair"]}
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>


          </Card>

          <AccordionItem>
            <AccordionHeader targetId="3_1">
              Configure Network Settings
            </AccordionHeader>
            <AccordionBody accordionId="3_1">
              <Card body color="light"
                className="p-0"
              >


                {/* <------------- MANAGEMENT --------------> */}
                <CardHeader>
                  Management
                </CardHeader>
                <CardBody className='pb-0'>
                  <FormGroup row>
                    <Label bsSize="sm" for="eip_allocation_id_site2_1" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Elastic IP
                      <span id="aws-3vm-eip_allocation_id_site2_1_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target="aws-3vm-eip_allocation_id_site2_1_t">
                        {`Specify the available elastic IP allocation ID. This will not create a new Elastic Public IP. Go to AWS -> EC2 -> Network & Security -> Elastic IPs -> Select the IP address and then, in summary, copy the Allocation ID.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="eip_allocation_id_site2_1"
                        name="eip_allocation_id_site2_1"
                        placeholder="eipalloc-0aad4f76eff6a489e"
                        required="true"
                        type="text"
                        valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_1"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_1"])}
                        value={formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_1"]}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
                {/* <------------- Data IN --------------> */}
                <hr className='mb-0 pb-0' />
                <CardHeader>
                  Data (IN)
                </CardHeader>
                <CardBody className="pb-0">
                  {/* <FormGroup row>
                    <Label bsSize="sm" for={`data_in_private_ips_site2`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Private IP (Data-In)
                      <span id={`aws-3vm-3-data_in_private_ips_site2_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-3-data_in_private_ips_site2_t`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-In). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <CreatableSelect
                        isMulti
                        value={securityGrpFields["data_in_private_ips_site2"]}
                        onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_in_private_ips_site2")}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            minHeight: "35px",
                            fontSize: "14px",
                            borderColor: securityGrpFields["data_in_private_ips_site2"].length === 2 ? "#198754 !important" : "#dc3545 !important"
                          }),
                        }}
                      />
                    </Col>
                  </FormGroup> */}
                  {/* <FormGroup row>
                    <Label bsSize="sm" for={`data_out_private_ips_site2`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Private IP (Data-Out)
                      <span id={`aws-3vm-3-data_out_private_ips_site2_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-3-data_out_private_ips_site2_t`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <CreatableSelect
                        isMulti
                        value={securityGrpFields["data_out_private_ips_site2"]}
                        onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_out_private_ips_site2")}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            minHeight: "35px",
                            fontSize: "14px",
                            borderColor: securityGrpFields["data_out_private_ips_site2"].length === 2 ? "#198754 !important" : "#dc3545 !important"
                          }),
                        }}
                      />
                    </Col>
                  </FormGroup> */}
                  <FormGroup row>
                    <Label bsSize="sm" for="eip_allocation_id_site2_2" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Elastic IP
                      <span id="aws-3vm-eip_allocation_id_site2_2_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target="aws-3vm-eip_allocation_id_site2_2_t">
                        {`Specify the available elastic IP allocation ID. This will not create a new Elastic Public IP. Go to AWS -> EC2 -> Network & Security -> Elastic IPs -> Select the IP address and then, in summary, copy the Allocation ID.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="eip_allocation_id_site2_2"
                        name="eip_allocation_id_site2_2"
                        placeholder="eipalloc-0aad4f76eff6a489e"
                        required="true"
                        type="text"
                        valid={isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_2"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_2"])}
                        value={formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_2"]}
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_in_private_ips_site2`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Primary Private IP
                      <span id={`aws-3vm-data_in_primary_private_ips_site2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_in_primary_private_ips_site2`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-In). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_in_private_ips_site2"
                        name="data_in_private_ips_site2"
                        placeholder="10.0.2.105"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"][0])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"][0])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"][0]}
                        onChange={(e) => handleArrayChange(e, 0)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_in_private_ips_site2`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Secondary Private IP
                      <span id={`aws-3vm-data_in_secondary_private_ips_site2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_in_secondary_private_ips_site2`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-In). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_in_private_ips_site2"
                        name="data_in_private_ips_site2"
                        placeholder="10.0.2.106"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"][1])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"][1])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"][1]}
                        onChange={(e) => handleArrayChange(e, 1)}
                      />
                    </Col>
                  </FormGroup>

                </CardBody>
                {/* <------------- Data OUT --------------> */}
                <hr className='mb-0 pb-0' />
                <CardHeader>
                  Data (OUT)
                </CardHeader>
                <CardBody>
                  {/* <FormGroup row>
              <Label bsSize="sm" for={`data_out_private_ips_sit1`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Private IP (Data-Out)
                <span id={`aws-3vm-2-data_out_private_ips_sit1_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target={`aws-3vm-2-data_out_private_ips_sit1_t`}>
                  {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <CreatableSelect
                  isMulti
                  value={securityGrpFields["data_out_private_ips_sit1"]}
                  onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_out_private_ips_sit1")}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      minHeight: "35px",
                      fontSize: "14px",
                      borderColor: securityGrpFields["data_out_private_ips_sit1"].length === 2 ? "#198754 !important" : "#dc3545 !important"
                    }),
                  }}
                />
              </Col>
            </FormGroup> */}
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_out_private_ips_site2`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Primary Private IP
                      <span id={`aws-3vm-data_out_primary_private_ips_site2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_out_primary_private_ips_site2`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_out_private_ips_site2"
                        name="data_out_private_ips_site2"
                        placeholder="10.0.3.105"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"][0])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"][0])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"][0]}
                        onChange={(e) => handleArrayChange(e, 0)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_out_private_ips_site2`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Secondary Private IP
                      <span id={`aws-3vm-data_out_secondary_private_ips_site2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`aws-3vm-data_out_secondary_private_ips_site2`}>
                        {`Specify available two private IP addresses within the selected subnet ID (Data-Out). The first private IP will serve as the primary IP, and the second will function as the secondary IP.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input bsSize="sm"
                        id="data_out_private_ips_site2"
                        name="data_out_private_ips_site2"
                        placeholder="10.0.3.106"
                        required="true"
                        type="text"
                        valid={isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"][1])}
                        invalid={!isIpValid(formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"][1])}
                        value={formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"][1]}
                        onChange={(e) => handleArrayChange(e, 1)}
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </AccordionBody>
          </AccordionItem>

        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  )
}

export default AwsGslbForm