import React from "react";
import { FaRegAngry, FaRegFrown, FaRegGrinStars, FaRegMeh, FaRegSmile } from "react-icons/fa";
import { RxDividerVertical } from "react-icons/rx";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form, FormGroup, Input, Label, NavLink, Navbar, NavbarBrand, PopoverBody, PopoverHeader, UncontrolledPopover, UncontrolledTooltip } from "reactstrap";
import { submitFeedback } from "../../services/FeedbackService";
import { baseUrl } from "../../data/parameters";

const Header = () => {

  const [selectedSmiley, setSelectedSmiley] = React.useState(null);

  const handleSmileyClick = (index) => {
    setSelectedSmiley(index);
  };

  const [smileyColors, setSmileyColors] = React.useState({
    1: "#FF5733",
    2: "#FFC107",
    3: "#4CAF50",
    4: "#008CBA",
    5: "#5E35B1",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const feedbackValue = selectedSmiley;
    const feedbackData = {
      feedbackScore: feedbackValue,
      userMailID: formData.get("email"),
      msgBody: formData.get("text"),
    };
    e.target.reset();
    setSelectedSmiley(null)

    submitFeedback(feedbackData)
      .then((response) => {
        console.log(response)
        toast.success(<>Submitted Successfully. &#128578;</>, {
          position: "bottom-right",
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error submitting feedback. Please try later.", {
          position: "bottom-right",
          autoClose: 2000,
        });
      });
  };


  return (
    <>
      <Navbar className="mb-2 py-1 text-center" style={{ background: "#2A3D56", color: "#fff" }}>

        <NavbarBrand href="/" className="fs-5">&nbsp;
          <img
            alt="A10 CLOUD BUILDER"
            src="/logo.webp"
            style={{
              height: "24px",
              paddingBottom: "2px"
            }}
          />

          <span className="fs-6 fw-bold" style={{ color: "#fff" }} >
            <RxDividerVertical style={{ fontSize: "30px" }} />
            <span style={{ fontSize: "20px" }}>CONFIGURATION BUILDER<sup className="fw-light"> βeta2</sup></span>
          </span>
        </NavbarBrand>
        <NavLink id="commandexectuor-desc" className="ms-auto me-0 " style={{ fontSize: "15px", fontWeight: "bold" }} href={`${baseUrl}/tools/commandexecutor`}>
          Command Executor
          <UncontrolledTooltip placement="bottom" target={`commandexectuor-desc`}>
            This tool can execute list of Thunder configuration commands provided in text file.
          </UncontrolledTooltip>
        </NavLink>
        <RxDividerVertical style={{ fontSize: "24px" }} />
        <NavLink className="ms-0 me-0 " style={{ fontSize: "15px", fontWeight: "bold" }} href="https://www.a10networks.com/solutions/multi-cloud/" target="_blank">Hybrid Cloud Application Solutions </NavLink>
        <RxDividerVertical style={{ fontSize: "24px" }} />
        <NavLink className="ms-0 " style={{ fontSize: "15px", fontWeight: 'bold' }} href="https://www.a10networks.com/" target="_blank">A10 Networks</NavLink>
        <RxDividerVertical style={{ fontSize: "24px" }} />
        <div>
          <FaRegSmile style={{ fontSize: "15px", marginBottom: "4px", fontWeight: 'bold' }} id="UncontrolledPopover" type="button" />
          <UncontrolledPopover
            placement="bottom"
            target="UncontrolledPopover"
          >
            <PopoverHeader>
              We'd love your feedback
            </PopoverHeader>
            <PopoverBody>
              <Form onSubmit={handleSubmit}>
                <p>How was your experience?</p>
                <FormGroup>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <Label key={index} check style={{ marginRight: "10px" }}>
                      <input
                        type="checkbox"
                        style={{ display: "none" }}
                        checked={selectedSmiley === index}
                        onChange={() => handleSmileyClick(index)}
                      />
                      {index === 1 && <FaRegAngry
                        style={{
                          fontSize: "25px",
                          color: smileyColors[index] || "#ccc",
                          cursor: "pointer",
                          boxShadow: selectedSmiley === index ? `0px 0px 5px 5px ${smileyColors[index]}` : "none",
                          borderRadius: "50%",
                        }}
                        id={`smiley-${index}`}
                      />}
                      {index === 2 && <FaRegFrown
                        style={{
                          fontSize: "25px",
                          color: smileyColors[index] || "#ccc",
                          cursor: "pointer",
                          boxShadow: selectedSmiley === index ? `0px 0px 5px 5px ${smileyColors[index]}` : "none",
                          borderRadius: "50%",
                        }}
                        id={`smiley-${index}`}
                      />}
                      {index === 3 && <FaRegMeh
                        style={{
                          fontSize: "25px",
                          color: smileyColors[index] || "#ccc",
                          cursor: "pointer",
                          boxShadow: selectedSmiley === index ? `0px 0px 5px 5px ${smileyColors[index]}` : "none",
                          borderRadius: "50%",
                        }}
                        id={`smiley-${index}`}
                      />}
                      {index === 4 && <FaRegSmile
                        style={{
                          fontSize: "25px",
                          color: smileyColors[index] || "#ccc",
                          cursor: "pointer",
                          boxShadow: selectedSmiley === index ? `0px 0px 5px 5px ${smileyColors[index]}` : "none",
                          borderRadius: "50%",
                        }}
                        id={`smiley-${index}`}
                      />}
                      {index === 5 && <FaRegGrinStars
                        style={{
                          fontSize: "25px",
                          color: smileyColors[index] || "#ccc",
                          cursor: "pointer",
                          boxShadow: selectedSmiley === index ? `0px 0px 5px 5px ${smileyColors[index]}` : "none",
                          borderRadius: "50%",
                        }}
                        id={`smiley-${index}`}
                      />}
                      <UncontrolledTooltip placement="bottom" target={`smiley-${index}`}>
                        {index === 1 ? "Very Bad" : index === 2 ? "Bad" : index === 3 ? "Good" : index === 4 ? "Very Good" : index === 5 ? "Excellent" : ""}
                      </UncontrolledTooltip>
                    </Label>
                  ))}
                </FormGroup>
                <br />
                <FormGroup>
                  <Label for="exampleEmail" className="m-1" hidden>
                    Email
                  </Label>
                  <Input
                    bsSize="sm"
                    id="exampleEmail"
                    name="email"
                    required
                    placeholder="Email"
                    type="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    bsSize="sm"
                    id="exampleText"
                    name="text"
                    type="textarea"
                    required
                    placeholder="Tell us more about your experience. Report any specific concerns & issues that you faced regarding the wizard."
                    style={{ height: "120px" }}
                  />
                </FormGroup>

                <Button style={{ background: "#2A3D56" }} type="submit">Submit</Button>
              </Form>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
        <RxDividerVertical style={{ fontSize: "24px" }} />
        <NavLink
          id="sign-in-desc"
          className="ms-0"
          disabled
          style={{ color: "#808080", fontSize: "13px", fontWeight: 'bold', pointerEvents: "auto", cursor: "pointer" }}
          href="https://glm.a10networks.com/"
          target="_blank">
          Sign In
          <UncontrolledTooltip placement="bottom" target={`sign-in-desc`}>
            coming soon..
          </UncontrolledTooltip>
        </NavLink>
      </Navbar>
    </>
  );
};

export default Header;
