import { getChangepasswordFile, getGlmFile, getGslbFile, getHaFile, getSlbFile, getSslFile } from "../services/ConfigurationService"
import { getAwsFile, getAzureFile, getGcpFile, getOracleFile, getVMwareFile } from "../services/DeploymentService"

const getDownloadFile = (platform, product, cloud, config, formState, vThunderCount) => {

    if (config === undefined) {
        let data = { "platform": platform, "action": "download", "deploymentType": vThunderCount }
        if (cloud === "vmware") {
            data = { ...data, ...formState[product][cloud] }
            let vms = [data.vm1, data.vm2, data.vm3]
            delete data.vm1
            delete data.vm2
            delete data.vm3
            data = { ...data, "vms": vms }
            if (vThunderCount !== "gslb") {
                data.vms.pop()
                if (vThunderCount === "standalone") {
                    data.vms.pop()
                }
            }
            return getVMwareFile(data)
        } else if (cloud === "gcp") {
            data = { ...data, ...formState[product][cloud] }
            let vms = [data.vm1, data.vm2, data.vm3]
            delete data.vm1
            delete data.vm2
            delete data.vm3
            data = { ...data, "vmsList": vms }
            if (vThunderCount !== "gslb") {
                data.vmsList.pop()
                if (vThunderCount === "standalone") {
                    data.vmsList.pop()
                }
            }
            return getGcpFile(data)
        } else if (cloud === "aws") {
            data = { ...data, ...formState[product][cloud][vThunderCount] }
            return getAwsFile(data)
        } else if (cloud === "azure") {
            data = { ...data, ...formState[product][cloud][vThunderCount] }
            return getAzureFile(data)
        } else if (cloud === "oracle") {
            data = {...data, ...formState[product][cloud][vThunderCount]}
            return getOracleFile(data)
        }
    } else {
        let configCredentials = { ...formState["configCredentials"] }
        let data = { platform, "action": "download" }
        if (config === "slb") {
            data = { ...data, ...formState[config], configCredentials }
            return getSlbFile(data)
        } else if (config === "ha") {
            data = { ...data, ...formState[config] }
            return getHaFile(data)
        } else if (config === "changepassword") {
            data = { ...data, ...configCredentials }
            return getChangepasswordFile(data)
        } else if (config === "glm") {
            let requestData = { ...formState[config], configCredentials }
            data = { ...data, ...requestData }
            return getGlmFile(data)
        } else if (config === "ssl") {
            let requestData = { ...formState[config], configCredentials }
            data = { ...data, ...requestData }
            return getSslFile(data)
        } else if (config === "gslb") {
            let requestData = { ...formState[config] }
            data = { ...data, ...requestData }
            return getGslbFile(data)
        }
    }
}

export { getDownloadFile }