import React from 'react';
import GCPForm from './GCP/GCPForm';
import VMwareForm from './VMware/VMwareForm';
import AwsForm from './AWS/AwsForm';
import AzureForm from './Azure/AzureForm';
import OracleForm from './Oracle/OracleForm';


const DeploymentForm = ({ product, cloud, formState, setFormState, vThunderCount, version_thunder }) => {

    if (cloud === 'vmware') {
        return <VMwareForm formState={formState} setFormState={setFormState} vThunderCount={vThunderCount} />
    } else if (cloud === 'gcp') {
        return <GCPForm formState={formState} setFormState={setFormState} vThunderCount={vThunderCount} version_thunder={version_thunder} />
    } else if (cloud === 'aws') {
        return <AwsForm formState={formState} setFormState={setFormState} vThunderCount={vThunderCount} version_thunder={version_thunder} />
    } else if (cloud === 'azure') {
        return <AzureForm formState={formState} setFormState={setFormState} vThunderCount={vThunderCount} version_thunder={version_thunder} />
    } else if (cloud === 'oracle') {
        return <OracleForm formState={formState} setFormState={setFormState} vThunderCount={vThunderCount} version_thunder={version_thunder} />
    }

    return <>Stay tuned, coming soon...</>
}


export default DeploymentForm
