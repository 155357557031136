import React, { useState } from "react";
import { Button, UncontrolledAccordion } from "reactstrap";
import Prerequisite from "./Prerequisite";

const HA = ({ formState, setFormState }) => {

    const [showMore, setShowMore] = useState(false)
    return (
        <div>
            <ul className='note'>
                <li>This section will help to generate and download automated scripts to apply High Availability as a configuration into Thunder using selected infrastructure as a tool (IaC).</li>
                <li>Passwords need to be provided during execution, we do not store any password information.</li>
                <li>Please test this out before applying to production.</li>
                {showMore && <>
                    <li>Thunder ADC should be deployed and in running state.</li>
                    <li>Internally it publish via Thunder aXAPI interface.</li>
                    <li>User should have valid credential file according to cloud.</li>
                    <li>High availability can be configured using VRRP among set of two Thunder instances with priority.</li>
                    <ul>
                        <li>Thunder 01 - First Thunder instance considering active mode with high priority.</li>
                        <li>Thunder 02 - Second Thunder instance considering standby mode.</li>
                    </ul>
                    <li>This configuration works for VMware, Azure and AWS platform.</li>
                    <li>Each configuration should be downloaded and executed separately.</li>
                    <li>For more Thunder configuration using terraform, please check <a href="https://github.com/a10networks/terraform-provider-thunder/tree/master" target="_blank">here</a></li>
                </>}
                <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
            </ul>
            <hr />
            <UncontrolledAccordion stayOpen className='mb-2'>
                <Prerequisite formState={formState} setFormState={setFormState} />
            </UncontrolledAccordion>
        </div>
    )
}
export default HA