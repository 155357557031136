import React from 'react'

const OracleCustomEditor = ({ formState }) => {
  return (
    <div>
      <code className="d-block" style={{ fontSize: "13px" }}>
        Configure_Custom_VMs:
      </code>
      {formState["thunderADC"]["oracle"]["custom"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["region"]}</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["compartment_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tcompartment_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["compartment_id"]}</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["availability_domain"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tavailability_domain`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["availability_domain"]}</span>
      </code>}
      {<code className="d-block" style={{ fontSize: "13px" }}>
        {`\tinstance_count`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["vm_count"]}</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["vm_names"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tinstance_names`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
          {formState["thunderADC"]["oracle"]["custom"]["vm_names"].map((ele, idx) => {
            return <>{idx > 0 && ", "}{ele}</>
          })}
          ]</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["thunder_image"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["thunder_image"]}</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["thunder_shape"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tthunder_shape`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["thunder_shape"]}</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["thunder_active_memory_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tmemory`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["thunder_active_memory_in_gbs"]}</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["thunder_active_ocpus"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tocpus`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["thunder_active_ocpus"]}</span>
      </code>}
      {formState["thunderADC"]["oracle"]["custom"]["private_key_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\toci_key_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["private_key_path"]}</span>
      </code>}
      {/* <-------- Network --------->*/}
      <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network:"}
            </code>
            {formState["thunderADC"]["oracle"]["custom"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tmanagement_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["mgmt_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["custom"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["data_in_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["custom"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["custom"]["data_out_subnet_id"]}</span>
            </code>}
            {/* <-------- Security --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Security:"}
            </code>
            {formState["thunderADC"]["oracle"]["custom"]["mgmt_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tmanagement_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["oracle"]["custom"]["mgmt_nsg_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
            {formState["thunderADC"]["oracle"]["custom"]["data_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_in_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["oracle"]["custom"]["data_nsg_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
            {formState["thunderADC"]["oracle"]["custom"]["data_out_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_out_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["oracle"]["custom"]["data_out_nsg_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
    </div>
  )
}

export default OracleCustomEditor