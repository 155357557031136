import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, Col, FormGroup, Input, Label, UncontrolledAccordion, UncontrolledTooltip } from 'reactstrap';
import { isIpValid } from "../../../utils/validations";
import ConfigCredentials from "./ConfigCredentials";

const ChangePasswordForm = ({ formState, setFormState }) => {

    const [showMore, setShowMore] = useState(false)

    const handleChange = (e) => {
        let updatedFormState = { ...formState }
        updatedFormState["changePassword"] = { ...updatedFormState["changePassword"], [e.target.name]: e.target.value }
        setFormState(updatedFormState)
    }
    return (
        <div>
            <ul className='note'>
                <li>This section will help to generate and download automated scripts to apply change password as a configuration into Thunder using selected infrastructure as a tool (IaC).</li>
                {/* <li>This configuration will change Thunder password on demand.</li> */}
                <li>Passwords need to be provided during execution, we do not store any password information.</li>
                <li>Please test this out before applying to production.</li>
                {showMore && <>
                    <li>Thunder ADC should be deployed and in running state.</li>
                    <li>Internally it publish via Thunder aXAPI interface.</li>
                    <li>Each configuration should be downloaded and executed separately.</li>
                    <li>For more Thunder configuration using terraform, please check <a href="https://github.com/a10networks/terraform-provider-thunder/tree/master" target="_blank">here</a></li>
                </>}
                <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
            </ul>
            <hr />
            <UncontrolledAccordion
                stayOpen
                className='mb-2'
            >
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        Configure Credentials
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <ConfigCredentials formState={formState} setFormState={setFormState} />
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </div>
    )
}
export default ChangePasswordForm;