import React, { useEffect, useState } from "react";
import { FaDownload } from 'react-icons/fa';
import { toast } from "react-toastify";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { configurations, deploymentType, downloadType, platformName } from "../../../data/parameters";
import { getDownloadFile } from "../../../utils/utils";
import { isValid } from '../../../utils/validations';
import ExecuteModal from "../../ui/ExecuteModal";
import ChangePasswordEditor from './ChangePasswordEditor';
import DeploymentEditor from './DeploymentEditor';
import GLMEditor from './GLMEditor';
import GSLBEditor from './GSLBEditor';
import HAEditor from './HAEditor';
import SLBEditor from './SLBEditor';
import SSLEditor from './SSLEditor';


const Editor = ({ formState, cloud, product, version_thunder, version_TOA, config, vThunderCount }) => {

  const [toggleDropdown, setToggleDropdown] = useState(false)
  const [dropdown, setDropdown] = useState(downloadType[cloud])
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (config === undefined) {
      setDropdown(downloadType[cloud])
    } else {
      setDropdown(downloadType['config'])
    }
  }, [cloud, config])

  const handleDownload = (platform) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString().replace(/[-T:., /]/g, '') + currentDate.getHours() + currentDate.getMinutes() + currentDate.getSeconds() + currentDate.getMilliseconds();

    getDownloadFile(platform, product, cloud, config, formState, vThunderCount)
      .then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        const filename = response.headers['content-disposition'].split('filename=')[1]
        a.href = url;
        a.download = filename + `-${formattedDate}.zip`;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove();
        toast.success("Successfully downloaded.", {
          position: "bottom-right",
          autoClose: 2000
        })
      })
      .catch((error) => {
        console.log(error);
        toast.error("Server Error: Please try later.", {
          position: "bottom-right",
          autoClose: 2000
        })
      });
  }

  const renderEditor = () => {
    if (config === undefined) {
      return <DeploymentEditor formState={formState} product={product} cloud={cloud} vThunderCount={vThunderCount} />
    }
    else {
      if (config === 'slb') {
        return <SLBEditor formState={formState} />
      }
      else if (config === 'ha') {
        return <HAEditor formState={formState} />
      }
      else if (config === 'glm') {
        return <GLMEditor formState={formState} />
      }
      else if (config === 'changepassword') {
        return <ChangePasswordEditor formState={formState} />
      }
      else if (config === 'ssl') {
        return <SSLEditor formState={formState} />
      }
      else if (config === 'gslb') {
        return <GSLBEditor formState={formState} />
      }
      else {
        return <></>
      }
    }
  }

  return (
    <div className="mt-3">
      <Row
        className="overflow-y-auto"
        style={{
          backgroundColor: "rgb(43, 43, 43)",
          color: "rgb(248, 248, 242)",
          maxHeight: "65vh",
          minHeight: "65vh",
        }}
      >
        <pre className="m-0 p-2">
          <code style={{ fontSize: "14px", color: "#d63384" }}>
            {config ? `CONFIGURATION : ${configurations[product][config].replace(/ /g, "_")}` : `DEPLOYMENT : ${deploymentType[vThunderCount].replace(/ /g, "_")}`}
          </code>
          {renderEditor()}
        </pre>
      </Row>
      <ExecuteModal cloud={cloud} modal={modal} setModal={setModal} />
      <Dropdown size="sm" className="d-inline" isOpen={toggleDropdown} toggle={() => setToggleDropdown(!toggleDropdown)}>
        <DropdownToggle style={isValid(product, cloud, config, vThunderCount, formState) ? { background: "#2A3D56" } : { background: "#212529" }} className="mt-2" caret disabled={!isValid(product, cloud, config, vThunderCount, formState)}>
          <FaDownload className='mb-1' /> &nbsp; DOWNLOAD
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: "auto", width: "max-content" }} color="primary" container="body">
          {dropdown.map((item) => {
            return (
              <DropdownItem style={{ fontSize: "12px" }} onClick={() => { handleDownload(item) }}>
                {platformName[item]}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
      {/* <Button className='mt-2 ms-2 pe-3' style={{background: "#212529"}} size="sm" disabled><TbSettingsAutomation className='mb-1 me-2' style={{fontSize:"18px"}}/>EXECUTE</Button> */}
    </div>
  );
};

export default Editor;