import React from "react";
import { Form } from "reactstrap";
import ChangePasswordForm from "./ChangePasswordForm";
import DeploymentForm from "./DeploymentForm";
import GLMForm from "./GLMForm";
import GSLBForm from "./NewGSLB/GSLBForm";
import HAForm from "./NewHA/HAForm";
import SLBForm from "./SLB/SLBForm";
import SSLForm from "./SSLForm";

const CustomForm = ({
  formState,
  setFormState,
  config,
  product,
  cloud,
  vThunderCount,
  version_thunder
}) => {

  const renderForm = () => {
    if (config === undefined) {
      return (
        <DeploymentForm
          formState={formState}
          setFormState={setFormState}
          product={product}
          cloud={cloud}
          version_thunder={version_thunder}
          vThunderCount={vThunderCount}
        />
      );
    } else {
      if (config === "slb") {
        return <SLBForm formState={formState} setFormState={setFormState} />;
      } else if (config === "ha") {
        return <HAForm formState={formState} setFormState={setFormState} />;
      } else if (config === "changepassword") {
        return (
          <ChangePasswordForm
            formState={formState}
            setFormState={setFormState}
          />
        );
      } else if (config === "glm") {
        return <GLMForm formState={formState} setFormState={setFormState} />;
      } else if (config === "ssl") {
        return <SSLForm formState={formState} setFormState={setFormState} />;
      } else if (config === "gslb") {
        return <GSLBForm formState={formState} setFormState={setFormState} />;
      } else {
        return <>In Progress...</>;
      }
    }
  };

  return (
    <div style={{ height: "100vh" }} className="mt-2 overflow-scroll">
      <Form className="me-2">{renderForm()}</Form>
    </div>
  );
};

export default CustomForm;
