import React from "react";

const AzureGslbEditor = ({ formState, vThunderCount }) => {
    return (<>
        {/*Virtual Machine 1 */}
        <div>
            <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_VM_1:
            </code>
            {formState["thunderADC"]["azure"]["gslb"]["location"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["location"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tsize`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["size"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["instance_name1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["instance_name1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["image"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["existing_resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\texisting_resource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["existing_resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["admin_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["admin_username"]}</span>
            </code>}
            {/* <-------- Network --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["gslb"]["vnet_nsg_rg"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["vnet_nsg_rg"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["vnet_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tvirtual_network_vnet`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["vnet_name"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["publicIP_name1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tpublic_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["publicIP_name1"]}</span>
            </code>}
            {vThunderCount === "gslb" && formState["thunderADC"]["azure"]["gslb"]["sec_publicIP_name1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsecondary_public_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["sec_publicIP_name1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["accelerated_networking"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\taccelerated_networking`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["accelerated_networking"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["ip_forwarding"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_mgmt"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_mgmt1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_mgmt1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_datain"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_datain1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_datain1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_dataout"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_dataout1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_dataout1"]}</span>
            </code>}
            {/* <-------- Security --------->*/}
            {vThunderCount === "gslb" && <>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Security_Settings:"}
                </code>
                {formState["thunderADC"]["azure"]["gslb"]["nsg_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_mgmt"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["gslb"]["nsg_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_datain"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["gslb"]["nsg_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_dataout"]}</span>
                </code>}</>}
        </div>
        <br />
        {/*Virtual Machine 2 */}
        <div>
            <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_VM_2:
            </code>
            {formState["thunderADC"]["azure"]["gslb"]["location"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["location"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["existing_resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\texisting_resource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["existing_resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["instance_name2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["instance_name2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["image"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tsize`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["size"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["admin_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["admin_username"]}</span>
            </code>}
            {/* <-------- Network --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["gslb"]["vnet_nsg_rg"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["vnet_nsg_rg"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["vnet_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tvirtual_network_vnet`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["vnet_name"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["publicIP_name2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tpublic_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["publicIP_name2"]}</span>
            </code>}
            {vThunderCount === "gslb" && formState["thunderADC"]["azure"]["gslb"]["sec_publicIP_name2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsecondary_public_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["sec_publicIP_name2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["accelerated_networking"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\taccelerated_networking`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["accelerated_networking"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["ip_forwarding"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_mgmt"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_mgmt2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_mgmt2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_datain"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_datain2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_datain2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_dataout"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_dataout2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_dataout2"]}</span>
            </code>}
            {/* <-------- Security --------->*/}
            {vThunderCount === "gslb" && <>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Security_Settings:"}
                </code>
                {formState["thunderADC"]["azure"]["gslb"]["nsg_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_mgmt"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["gslb"]["nsg_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_datain"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["gslb"]["nsg_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_dataout"]}</span>
                </code>}</>}
        </div>
        <br />
        {/* Virtual MAchine 3 */}
        <div>
            <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_VM_3:
            </code>
            {formState["thunderADC"]["azure"]["gslb"]["location"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["location"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["existing_resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\texisting_resource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["existing_resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["instance_name3"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["instance_name3"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["image"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tsize`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["size"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["admin_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["admin_username"]}</span>
            </code>}
            {/* <-------- Network --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["gslb"]["vnet_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tvirtual_network_vnet`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["vnet_name"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["vnet_nsg_rg"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["vnet_nsg_rg"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["publicIP_name3"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tpublic_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["publicIP_name3"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["sec_publicIP_name3"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsecondary_public_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["sec_publicIP_name3"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["accelerated_networking"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\taccelerated_networking`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["accelerated_networking"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["ip_forwarding"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_mgmt"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_mgmt3"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_mgmt3"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_datain"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_datain3"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_datain3"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["subnet_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["subnet_dataout"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nic_dataout3"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nic_dataout3"]}</span>
            </code>}
            {/* <-------- Security --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Security_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["gslb"]["nsg_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnetwork_security_group_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_mgmt"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nsg_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnetwork_security_group_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_datain"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["gslb"]["nsg_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnetwork_security_group_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["gslb"]["nsg_dataout"]}</span>
            </code>}
        </div>
    </>)
}
export default AzureGslbEditor