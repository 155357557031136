import React, { useState } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, Col, FormGroup, Input, Label, UncontrolledAccordion, UncontrolledTooltip } from 'reactstrap'
import ConfigCredentials from './ConfigCredentials'

const SSLForm = ({ formState, setFormState }) => {
    const [showMore, setShowMore] = useState(false)

    const handleChange = (e) => {
        let updatedFormState = { ...formState }
        updatedFormState["ssl"] = { ...updatedFormState["ssl"], [e.target.name]: e.target.value }
        setFormState(updatedFormState)
    }

    return (
        <div>
            <ul className='note'>
                <li>This section will help to generate and download automated scripts to apply SSL as a configuration into Thunder using selected infrastructure as a tool (IaC).</li>
                <li>Passwords need to be provided during execution, we do not store any password information.</li>
                <li>Please test this out before applying to production.</li>
                {showMore && <>
                    <li>Thunder ADC should be deployed and in running state.</li>
                    <li>Internally it publish via Thunder aXAPI interface.</li>
                    <li>User should have valid ssl certificate.</li>
                    <li>The remote server should have apache2 installed.</li>
                    <li>The certificate file should be present on a remote server at the defined path /var/www/html/upload/server.pem.</li>
                    <li>Sample server.pem will get downloaded along with scripts.</li>
                    <li>Remote/FTP server should be reachable from the Thunder.</li>
                    <li>Password needs to be provided during execution.</li>
                    <li>Each configuration should be downloaded and executed separately.</li>
                    <li>For more Thunder configuration using terraform, please check <a href="https://github.com/a10networks/terraform-provider-thunder/tree/master" target="_blank">here</a></li>
                </>}
                <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
            </ul>
            <hr />
            <UncontrolledAccordion
                stayOpen
                className='mb-2'
            >
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        Configure Credentials
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <ConfigCredentials formState={formState} setFormState={setFormState} />
                    </AccordionBody>
                </AccordionItem >
                <AccordionItem>
                    <AccordionHeader targetId="2">
                        Upload SSL Certificate
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                        <Card body color="light">
                            <FormGroup row >
                                <Label bsSize="sm" for="host" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Host
                                    <span
                                        id={`host-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`host-t`}>
                                        Specify the remote/FTP server hostname/IP where the certificate is present.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="host"
                                        name="host"
                                        placeholder="127.0.0.1"
                                        required={true}
                                        type="text"
                                        value={formState["ssl"]["host"]}
                                        valid={formState["ssl"]["host"]}
                                        invalid={!formState["ssl"]["host"]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label bsSize="sm" for="username" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Username
                                    <span
                                        id={`ssl-username-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`ssl-username-t`}>
                                        Specify remote/FTP server host username.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="username"
                                        name="username"
                                        placeholder="admin"
                                        required={true}
                                        type="text"
                                        value={formState["ssl"]["username"]}
                                        valid={formState["ssl"]["username"]}
                                        invalid={!formState["ssl"]["username"]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            {/* <FormGroup row >
                                <Label bsSize="sm" for="password" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Password
                                    <span
                                        id={`ssl-password-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`ssl-password-t`}>
                                    Specify remote/FTP server host password.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="password"
                                        name="password"
                                        disabled
                                        required={true}
                                        type="text"
                                        value={formState["ssl"]["password"]}
                                    />
                                </Col>
                            </FormGroup> */}
                            <FormGroup row >
                                <Label bsSize="sm" for="protocol" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Protocol
                                    <span
                                        id={`protocol-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`protocol-t`}>
                                        Specify remote/FTP server host protocol.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id="protocol"
                                        name="protocol"
                                        placeholder="pem"
                                        required={true}
                                        type="select" value={formState["ssl"]["protocol"]} onChange={handleChange}
                                    >
                                        <option selected hidden>select</option>
                                        <option >http</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label bsSize="sm" for="name" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    File Name
                                    <span
                                        id={`name-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`name-t`}>
                                        Specify the name of the SSL certificate file.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="name"
                                        name="name"
                                        placeholder="server.pem"
                                        required={true}
                                        type="text"
                                        value={formState["ssl"]["name"]}
                                        valid={formState["ssl"]["name"]}
                                        invalid={!formState["ssl"]["name"]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label bsSize="sm" for="path" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    File Path
                                    <span
                                        id={`path-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`path-t`}>
                                        Specify SSL certificate location. For example, if server.pem is located at /var/www/html/upload/server.pem under apache server. Then specify /upload/server.pem. SSL certificate is a self-signed certificate that is used to create proxied certificates for SSL handshaking with clients in the SSLi, SSL Proxy or SSL offload applications.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="path"
                                        name="path"
                                        placeholder="/upload/server.pem"
                                        required={true}
                                        type="text"
                                        value={formState["ssl"]["path"]}
                                        valid={formState["ssl"]["path"]}
                                        invalid={!formState["ssl"]["path"]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label bsSize="sm" for="certificate_type" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Certificate Type
                                    <span
                                        id={`certificate_type-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`certificate_type-t`}>
                                        Currently supporting SSL certification in the .pem type only.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id="certificate_type"
                                        name="certificate_type"
                                        placeholder="pem"
                                        disabled
                                        required={true}
                                        type="select" value={formState["ssl"]["certificate_type"]} >
                                        <option selected hidden>select</option>
                                        <option >pem</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Card>
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </div>
    )
}

export default SSLForm
