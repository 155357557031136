import React from 'react'

const AwsStandaloneEditor = ({ formState }) => {
    return (
        <div>
            <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_VM:
            </code>
            {formState["thunderADC"]["aws"]["standalone"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["region"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["instance1_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["instance1_name"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["thunder_ami_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tthunder_ami_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["thunder_ami_id"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["thunder_instance_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tthunder_instance_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["thunder_instance_size"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["key_pair"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tkey_pair`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["key_pair"]}</span>
            </code>}

            {/* <-------- Management --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network_Settings:"}
            </code>
            {formState["thunderADC"]["aws"]["standalone"]["eip_allocation_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\teip_allocation_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["eip_allocation_id"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["mgmt_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["data_in_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["standalone"]["data_out_subnet_id"]}</span>
            </code>}

            {/* <-------- Security --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Security:"}
            </code>
            {formState["thunderADC"]["aws"]["standalone"]["security_group_mgmt_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsecurity_group_mgmt_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["aws"]["standalone"]["security_group_mgmt_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
            {formState["thunderADC"]["aws"]["standalone"]["security_group_data_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsecurity_group_data_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["aws"]["standalone"]["security_group_data_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
        </div>
    )
}

export default AwsStandaloneEditor