import React from 'react'
import ConfigCredentialsEditor from './ConfigCredentialsEditor'

const SLBEditor = ({ formState }) => {
    // return <></>
    return (
        <>
            <ConfigCredentialsEditor formState={formState} />
            {formState["slb"]["configServer"].map((item, idx) => {
                return (
                    <div key={idx}>
                        {(item["name"] || item["host"] || item["port_list"][0]["port_number"] || item["port_list"][0]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                            Configure_Server_{idx + 1}:
                        </code>}
                        {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tname`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                        </code>}
                        {item["host"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\thost`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["host"]}</span>
                        </code>}
                        {<code className="d-block" style={{ fontSize: "13px" }}>
                            {`\thealth_check_disable`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["health_check_disable"]}</span>
                        </code>}
                        {(item["port_list"][0]["port_number"] || item["port_list"][0]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tport_list:`}
                        </code>}
                        {item["port_list"].map((portfield, pidx) => {
                            return (
                                <div key={`${idx}_${pidx}`}>
                                    {portfield["port_number"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tport_number`}: <span style={{ color: "rgb(171, 227, 56)" }}>{portfield["port_number"]}</span>
                                    </code>}
                                    {portfield["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tprotocol`}: <span style={{ color: "rgb(171, 227, 56)" }}>{portfield["protocol"]}</span>
                                    </code>}
                                    {<code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\thealth_check_disable`}: <span style={{ color: "rgb(171, 227, 56)" }}>{portfield["health_check_disable"]}</span>
                                    </code>}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            <br />
            {formState['slb']['configServiceGroup'].map((item, idx) => {
                return (
                    <div key={idx}>
                        {(item["name"] || item["protocol"] || item["health_check_disable"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                            Configure_ServiceGroup_{idx + 1}:
                        </code>}
                        {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tname`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                        </code>}
                        {item["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tprotocol`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["protocol"]}</span>
                        </code>}
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tmember_list:`}
                        </code>
                        {item["member_list"].map((memberField, pidx) => {
                            return (
                                <div key={`${idx}_${pidx}`}>
                                    {memberField["name"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tname`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["slb"]["configServer"][memberField["name"]]["name"]}</span>
                                    </code>}
                                    {memberField["port"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tport`}: <span style={{ color: "rgb(171, 227, 56)" }}>{memberField["port"]}</span>
                                    </code>}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            <br />
            {formState['slb']['configHttp'].map((item, idx) => {
                return (
                    <div key={idx}>
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            Configure_Http_{idx + 1}:
                        </code>
                        {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tname`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                        </code>}

                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tprotocol`}:<span style={{ color: "rgb(171, 227, 56)" }}> http</span>
                        </code>
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tswitchList:`}
                        </code>
                        {item["switching"].map((switchField, sidx) => {
                            return (
                                <div key={`${idx}_${sidx}`}>
                                    {<code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tswitchingType`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["switching"][0]["switchingType"]}</span>
                                    </code>}
                                    {switchField["conditionType"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tconditionType`}: <span style={{ color: "rgb(171, 227, 56)" }}>{switchField["conditionType"]}</span>
                                    </code>}
                                    {switchField["conditionValue"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tconditionValue`}: <span style={{ color: "rgb(171, 227, 56)" }}>{switchField["conditionValue"]}</span>
                                    </code>}
                                    {switchField["serviceGrpName"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tserviceGroupName`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["slb"]["configServiceGroup"][switchField["serviceGrpName"]]["name"]}</span>
                                    </code>}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            <br />

            {formState["slb"]["configVirtualServer"].map((item, idx) => {
                return (
                    <div key={idx}>
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            Configure_VirtualServer_{idx + 1}:
                        </code>
                        {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tname`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                        </code>}
                        {item["ip_address"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tip_address`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["ip_address"]}</span>
                        </code>}
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tport_list:`}
                        </code>
                        {item["port_list"].map((sList, pidx) => {
                            return (
                                <div key={`${idx}_${pidx}`}>
                                    {sList["port_number"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tport_number`}: <span style={{ color: "rgb(171, 227, 56)" }}>{sList["port_number"]}</span>
                                    </code>}
                                    {sList["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tprotocol`}: <span style={{ color: "rgb(171, 227, 56)" }}>{sList["protocol"]}</span>
                                    </code>}
                                    <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tha_conn_mirror`}: <span style={{ color: "rgb(171, 227, 56)" }}>{sList["ha_conn_mirror"]}</span>
                                    </code>
                                    <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tauto`}: <span style={{ color: "rgb(171, 227, 56)" }}>{sList["auto"]}</span>
                                    </code>
                                    {sList["service_group"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tservice_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["slb"]["configServiceGroup"][sList["service_group"]]["name"]}</span>
                                    </code>}
                                    {sList["template_http"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\ttemplate_http`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["slb"]["configHttp"][sList["template_http"]]["name"]}</span>
                                    </code>}
                                    {<code className="d-block" style={{ fontSize: "13px" }}>
                                        {`\t\tsupport_http2`}: <span style={{ color: "rgb(171, 227, 56)" }}>{sList["support_http2"]}</span>
                                    </code>}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </>
    )
}

export default SLBEditor
