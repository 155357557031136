import React from "react"

const GCPEditor = ({ formState, vThunderCount }) => {
    return (
        <>
            {/*Virtual Machine 1 */}
            <div>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_1:
                </code>
                {formState["thunderADC"]["gcp"]["vm1"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["region"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["zone"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tzone`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["zone"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["project"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tproject`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["project"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["image"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["image_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\timage_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["image_size"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["machine_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tmachine_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["machine_name"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["machine_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tmachine_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["machine_type"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["credentials"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tcredentials`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["credentials"]}</span>
                </code>}
                <code className="d-block" style={{ fontSize: "13px" }}>
                        {"\tConfigure_Network_Settings:"}
                    </code>
                {formState["thunderADC"]["gcp"]["vm1"]["nic_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnic_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{`VIRTIO_NET`}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["tags"] && (
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\ttag`}:{" "}
                        <span style={{ color: "rgb(171, 227, 56)" }}>
                            {formState["thunderADC"]["gcp"]["vm1"]["tags"].map((tag, index) => (
                                <span key={index}>
                                    {tag}
                                    {index < formState["thunderADC"]["gcp"]["vm1"]["tags"].length - 1 ? ", " : ""}
                                </span>
                            ))}
                        </span>
                    </code>
                )}
                {formState["thunderADC"]["gcp"]["vm1"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["ip_forwarding"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["mgmt_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["data_in_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["gcp"]["vm1"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["data_out_subnet_id"]}</span>
                </code>}
            </div>
            <br/>
            {/*Virtual Machine 2 */}
            {vThunderCount !== "standalone" &&
                <div>
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        Configure_VM_2:
                    </code>
                    {formState["thunderADC"]["gcp"]["vm2"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["region"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["zone"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tzone`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["zone"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["project"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tproject`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["project"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["image"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["image_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\timage_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["image_size"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["machine_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tmachine_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["machine_name"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["machine_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tmachine_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["machine_type"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["credentials"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tcredentials`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["credentials"]}</span>
                    </code>}
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        {"\tConfigure_Network_Settings:"}
                    </code>
                    {formState["thunderADC"]["gcp"]["vm2"]["nic_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tnic_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{`VIRTIO_NET`}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["tags"] && (
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\t\ttag`}:{" "}
                            <span style={{ color: "rgb(171, 227, 56)" }}>
                                {formState["thunderADC"]["gcp"]["vm2"]["tags"].map((tag, index) => (
                                    <span key={index}>
                                        {tag}
                                        {index < formState["thunderADC"]["gcp"]["vm2"]["tags"].length - 1 ? ", " : ""}
                                    </span>
                                ))}
                            </span>
                        </code>
                    )}
                    {formState["thunderADC"]["gcp"]["vm2"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["ip_forwarding"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["mgmt_subnet_id"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["data_in_subnet_id"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm2"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm2"]["data_out_subnet_id"]}</span>
                    </code>}
                </div>}
                <br/>
            {/*Virtual Machine 3 */}
            {vThunderCount === "gslb" &&
                <div>
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        Configure_VM_3:
                    </code>
                    {formState["thunderADC"]["gcp"]["vm3"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["region"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["zone"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tzone`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["zone"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["project"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tproject`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["project"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["image"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["image_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\timage_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm1"]["image_size"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["machine_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tmachine_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["machine_name"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["machine_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tmachine_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["machine_type"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["credentials"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tcredentials`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["credentials"]}</span>
                    </code>}
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        {"\tConfigure_Network_Settings:"}
                    </code>
                    {formState["thunderADC"]["gcp"]["vm3"]["nic_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tnic_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{`VIRTIO_NET`}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["tags"] && (
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\t\ttag`}:{" "}
                            <span style={{ color: "rgb(171, 227, 56)" }}>
                                {formState["thunderADC"]["gcp"]["vm3"]["tags"].map((tag, index) => (
                                    <span key={index}>
                                        {tag}
                                        {index < formState["thunderADC"]["gcp"]["vm3"]["tags"].length - 1 ? ", " : ""}
                                    </span>
                                ))}
                            </span>
                        </code>
                    )}
                    {formState["thunderADC"]["gcp"]["vm3"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["ip_forwarding"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["mgmt_subnet_id"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["data_in_subnet_id"]}</span>
                    </code>}
                    {formState["thunderADC"]["gcp"]["vm3"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["gcp"]["vm3"]["data_out_subnet_id"]}</span>
                    </code>}
                </div>}
        </>
    )
}
export default GCPEditor