import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Input, Table, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric, isIpValid } from "../../../../utils/validations";
import IPRoute from "./IPRoute";


const Prerequisite = ({ formState, setFormState }) => {
  const handleChange = (e, thunder) => {
    let newFormState = { ...formState }
    newFormState["ha"]["prerequisite"][thunder] = { ...newFormState["ha"]["prerequisite"][thunder], [e.target.name]: e.target.value }
    setFormState(newFormState)
  }
  return (
    <AccordionItem>
      <AccordionHeader targetId="2">
        Configure High Availability
      </AccordionHeader>
      <AccordionBody accordionId="2">
        <h5 className="mt-1">Thunder 01 - Active</h5>
        <Table responsive bordered hover striped >
          <thead>
            <tr style={{ height: "30px" }}>
              <th style={{ fontSize: "14px", width: "35%" }}>Field Name</th>
              <th style={{ fontSize: "14px", width: "65%" }}>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr key="address_t1" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                IP
                <span id="address_t1-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="address_t1-t">
                  Specify public ip of Thunder to connect and apply configuration. IP should belong from management interface.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="address_t1"
                  name="address"
                  type="text"
                  placeholder="101.188.67.132"
                  value={formState['ha']['prerequisite']['thunder1']['address']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['thunder1']['address'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['thunder1']['address'])}
                  onChange={(e) => handleChange(e, "thunder1")}
                />
              </td>
            </tr>
            <tr key="username_t1" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Username
                <span id="username_t1-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="username_t1-t">
                  Specify username of Thunder to connect and apply configuration. Default value is 'admin'.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="username_t1"
                  name="username"
                  placeholder="admin"
                  type="text"
                  value={formState['ha']['prerequisite']['thunder1']['username']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['thunder1']['username'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['thunder1']['username'])}
                  onChange={(e) => handleChange(e, "thunder1")}
                />
              </td>
            </tr>
            {/* <tr key="password_t1" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Password
                <span id="password_t1-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="password_t1-t">
                  Specify password to login.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="password_t1"
                  name="password"
                  type="text"
                  disabled
                  value={formState['ha']['prerequisite']['thunder1']['password']}
                  onChange={(e) => handleChange(e, "thunder1")}
                />
              </td>
            </tr> */}
            <tr key="setID_t1" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Set ID
                <span id="setID_t1-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="setID_t1-t">
                  Specify the set id for HA configuration. Default set id is "1". Set id from 1 to 15 can be used.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="setID_t1"
                  name="set_id"
                  type="number"
                  placeholder="1"
                  value={formState['ha']['prerequisite']['thunder1']['set_id']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['thunder1']['set_id'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['thunder1']['set_id'])}
                  onChange={(e) => handleChange(e, "thunder1")}
                />
              </td>
            </tr>
            <tr key="deviceID_t1" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Device ID
                <span id="deviceID_t1-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="deviceID_t1-t">
                  Specify the unique id for each VRRP-A box. Default value is "1". Device-id number from 1 to 8 can be used.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="deviceID_t1"
                  name="device_id"
                  type="number"
                  placeholder="1"
                  disabled
                  value={formState['ha']['prerequisite']['thunder1']['device_id']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['thunder1']['device_id'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['thunder1']['device_id'])}
                  onChange={(e) => handleChange(e, "thunder1")}
                />
              </td>
            </tr>
            <tr key="primaryIP_t1" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Primary IP
                <span id="primaryIP_t1-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="primaryIP_t1-t">
                  Specify primary private ip address of data in interface.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="primaryIP_t1"
                  name="primaryIP"
                  placeholder="10.0.2.5"
                  type="text"
                  value={formState['ha']['prerequisite']['thunder1']['primaryIP']}
                  valid={isIpValid(formState['ha']['prerequisite']['thunder1']['primaryIP'])}
                  invalid={!isIpValid(formState['ha']['prerequisite']['thunder1']['primaryIP'])}
                  onChange={(e) => handleChange(e, "thunder1")}
                />
              </td>
            </tr>
            <tr key="priority_t1" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Priority
                <span id="priority_t1-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="priority_t1-t">
                  VRRP-A priorty. Priority value should be between 1 to 255.<br />Default value is "255".<br />In this 255 represent the higher priority whereas 1 represent the lower priority.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="priority_t1"
                  name="priority"
                  placeholder="255"
                  type="number"
                  disabled
                  value={formState['ha']['prerequisite']['thunder1']['priority']}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>Thunder 02 - Standby</h5>
        <Table responsive bordered hover striped >
          <thead>
            <tr style={{ height: "30px" }}>
              <th style={{ fontSize: "14px", width: "35%" }}>Field Name</th>
              <th style={{ fontSize: "14px", width: "65%" }}>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr key="address_t2" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                IP
                <span id="address_t2-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="address_t2-t">
                  Specify public ip of Thunder to connect and apply configuration. IP should belong from management interface.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="address_t2"
                  name="address"
                  placeholder="101.188.67.133"
                  type="text"
                  value={formState['ha']['prerequisite']['thunder2']['address']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['thunder2']['address'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['thunder2']['address'])}
                  onChange={(e) => handleChange(e, "thunder2")}
                />
              </td>
            </tr>
            <tr key="username_t2" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Username
                <span id="username_t2-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="username_t2-t">
                  Specify username of Thunder to connect and apply configuration. Default value is 'admin'.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="username_t2"
                  name="username"
                  placeholder="admin"
                  type="text"
                  value={formState['ha']['prerequisite']['thunder2']['username']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['thunder2']['username'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['thunder2']['username'])}
                  onChange={(e) => handleChange(e, "thunder2")}
                />
              </td>
            </tr>
            {/* <tr key="password_t2" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Password
                <span id="password_t2-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="password_t2-t">
                  Specify password to login.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="password_t2"
                  name="password"
                  type="text"
                  disabled
                  value={formState['ha']['prerequisite']['thunder2']['password']}
                  onChange={(e) => handleChange(e, "thunder2")}
                />
              </td>
            </tr> */}
            <tr key="setID_t2" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Set ID
                <span id="setID_t2-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="setID_t2-t">
                  Specify the set id for HA configuration.<br />Default set id is "1".<br />It will be identical to thunder1.<br />Set id from 1 to 15 can be used.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="setID_t2"
                  name="set_id"
                  type="number"
                  placeholder="1"
                  disabled
                  value={formState['ha']['prerequisite']['thunder1']['set_id']}
                />
              </td>
            </tr>
            <tr key="deviceID_t2" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Device ID
                <span id="deviceID_t2-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="deviceID_t2-t">
                  Specify the unique ID for each VRRP-A box.<br />Device-id number from 1 to 8 can be used.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="deviceID_t2"
                  name="device_id"
                  type="number"
                  placeholder="2"
                  disabled
                  value={formState['ha']['prerequisite']['thunder2']['device_id']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['thunder2']['device_id'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['thunder2']['device_id'])}
                  onChange={(e) => handleChange(e, "thunder2")}
                />
              </td>
            </tr>
            <tr key="primaryIP_t2" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Primary IP
                <span id="primaryIP_t2-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="primaryIP_t2-t">
                  Specify primary private ip address of data in interface.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="primaryIP_t2"
                  name="primaryIP"
                  placeholder="10.0.2.6"
                  type="text"
                  value={formState['ha']['prerequisite']['thunder2']['primaryIP']}
                  valid={isIpValid(formState['ha']['prerequisite']['thunder2']['primaryIP'])}
                  invalid={!isIpValid(formState['ha']['prerequisite']['thunder2']['primaryIP'])}
                  onChange={(e) => handleChange(e, "thunder2")}
                />
              </td>
            </tr>
            <tr key="priority_t2" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Priority
                <span id="priority_t2-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="priority_t2-t">
                  VRRP-A priorty.<br />Priority value should be between 1 to 255.<br />Default value is "1".<br />In this 255 represent the higher priority whereas 1 represent the lower priority.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="priority_t2"
                  name="priority"
                  placeholder="1"
                  type="number"
                  disabled
                  value={formState['ha']['prerequisite']['thunder2']['priority']}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>Common </h5>
        <Table responsive bordered hover striped >
          <thead>
            <tr style={{ height: "30px" }}>
              <th style={{ fontSize: "14px", width: "35%" }}>Field Name</th>
              <th style={{ fontSize: "14px", width: "65%" }}>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr key="ip_v4_addr" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Primary DNS
                <span id="ip_v4_addr-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="ip_v4_addr-t">
                  Specify the primary DNS address.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="ip_v4_addr"
                  name="ip_v4_addr"
                  type="text"
                  placeholder="8.8.8.8"
                  value={formState['ha']['prerequisite']['common']['ip_v4_addr']}
                  valid={isIpValid(formState['ha']['prerequisite']['common']['ip_v4_addr'])}
                  invalid={!isIpValid(formState['ha']['prerequisite']['common']['ip_v4_addr'])}
                  onChange={(e) => handleChange(e, "common")}
                />
              </td>
            </tr>
            <tr key="ip_address" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Floating IP
                <span id="ip_address-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="ip_address-t">
                  Specify active Thunder seconday private ip address of data out interface.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="ip_address"
                  name="ip_address"
                  type="text"
                  placeholder="10.0.3.5"
                  value={formState['ha']['prerequisite']['common']['ip_address']}
                  valid={isIpValid(formState['ha']['prerequisite']['common']['ip_address'])}
                  invalid={!isIpValid(formState['ha']['prerequisite']['common']['ip_address'])}
                  onChange={(e) => handleChange(e, "common")}
                />
              </td>
            </tr>
            <tr key="idle_timeout" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                IdleTimeout
                <span id="idle_timeout-t" style={{ fontSize: "13px" }}>
                  <strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target="idle_timeout-t">
                  {`Specify terminal idle timeout in minutes <0-60> to close connection when no input is detected, default value is "10".`}
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="idle_timeout"
                  name="idle_timeout"
                  placeholder="10"
                  type="number"
                  value={formState['ha']['prerequisite']['common']['idle_timeout']}
                  valid={isAlphanumeric(formState['ha']['prerequisite']['common']['idle_timeout'])}
                  invalid={!isAlphanumeric(formState['ha']['prerequisite']['common']['idle_timeout'])}
                  onChange={(e) => handleChange(e, "common")}
                />
              </td>
            </tr>

          </tbody>
        </Table>

        <IPRoute formState={formState} setFormState={setFormState} />
      </AccordionBody>
    </AccordionItem>
  )
}
export default Prerequisite