import React from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, UncontrolledAccordion, UncontrolledTooltip } from 'reactstrap'
import { isAlphanumeric, isIpValid } from '../../../../utils/validations'

const ConfigVM = ({ formState, setFormState, vm }) => {

    const handleChange = (e) => {
        var updatedFormState = { ...formState }
        updatedFormState["thunderADC"]["vmware"][vm] = { ...updatedFormState["thunderADC"]["vmware"][vm], [e.target.name]: e.target.value }
        setFormState(updatedFormState)
    }

    const handleOvfPathChange = (e) => {
        var updatedFormState = { ...formState }
        updatedFormState["thunderADC"]["vmware"] = { ...updatedFormState["thunderADC"]["vmware"], [e.target.name]: e.target.value }
        setFormState(updatedFormState)
    }

    return (<>
        <Card body color="light" className="pb-0">
            <FormGroup row >
                <Label bsSize="sm" for="vm_name" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    VM Name
                    <span
                        id={`vm_name-${vm}-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`vm_name-${vm}-t`}>
                        Specify name of virtual machine. Name must be unique inside a cluster.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="vm_name"
                        name="vm_name"
                        placeholder={vm === "vm1" ? "vth-inst-1" : vm === "vm2" ? "vth-inst-2" : "vth-inst-3"}
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"][vm]["vm_name"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"][vm]["vm_name"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"][vm]["vm_name"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="cpu" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    CPU (Core)
                    <span
                        id={`cpu-${vm}-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`cpu-${vm}-t`}>
                        {`Choose number of virtual CPU from available capacity. Check available capacity in vSphere vCenter -> Home.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="cpu"
                        name="cpu"
                        placeholder="4"
                        required={true}
                        type="number" min={0}
                        value={formState["thunderADC"]["vmware"][vm]["cpu"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"][vm]["cpu"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"][vm]["cpu"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="memory" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Memory (GiB)
                    <span
                        id={`memory-${vm}-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`memory-${vm}-t`}>
                        {`Choose memory in GB from available capacity. Check available capacity in vSphere vCenter -> Home.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="memory"
                        name="memory"
                        placeholder="8"
                        required={true}
                        type="number" min={0}
                        value={formState["thunderADC"]["vmware"][vm]["memory"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"][vm]["memory"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"][vm]["memory"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="disk_label" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Disk Name
                    <span
                        id={`disk_label-${vm}-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`disk_label-${vm}-t`}>
                        Specify name for storage disk.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="disk_label"
                        name="disk_label"
                        placeholder="thunder-disk"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"][vm]["disk_label"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"][vm]["disk_label"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"][vm]["disk_label"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="disk_size" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Disk Size(GiB)
                    <span
                        id={`disk_size-${vm}-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`disk_size-${vm}-t`}>
                        {`Choose storage size in GB from available capacity of selected data store. Check free storage in vSphere vCenter -> Shortcuts -> Storage -> <data store>.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="disk_size"
                        name="disk_size"
                        placeholder="32"
                        required={true}
                        type="number" min={0}
                        value={formState["thunderADC"]["vmware"][vm]["disk_size"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"][vm]["disk_size"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"][vm]["disk_size"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="disk_provisioning" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Provisioning Type
                    <span
                        id={`disk_provisioning-${vm}-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`disk_provisioning-${vm}-t`}>
                        Select disk provisioning type which is supported by selected data store.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="disk_provisioning"
                        name="disk_provisioning"
                        required={true}
                        type="select"
                        value={formState["thunderADC"]["vmware"][vm]["disk_provisioning"]}
                        onChange={handleChange}
                    >
                        <option selected>Thin</option>
                        <option >Thick</option>
                    </Input>
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="local_ovf_path" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Image
                    <span
                        id={`local_ovf_path-${vm}-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`local_ovf_path-${vm}-t`}>
                        {`Choose local path of Thunder OVA file. Download vThunder Installation -ADC/SLB -> X.X.X Release -> <file>.ova.gz file from https://support.a10networks.com/support/axseries`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="local_ovf_path"
                        name="local_ovf_path"
                        placeholder="/root/local/ACOS_vThunder_5_1_0-P6_38.ova"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"]["local_ovf_path"]}
                        valid={vm === "vm1" && isAlphanumeric(formState["thunderADC"]["vmware"]["local_ovf_path"])}
                        invalid={vm === "vm1" && !isAlphanumeric(formState["thunderADC"]["vmware"]["local_ovf_path"])}
                        onChange={handleOvfPathChange}
                        disabled={vm === "vm2" || vm === "vm3"}
                    />
                </Col>
            </FormGroup>
        </Card>
        <UncontrolledAccordion
            stayOpen
            className="mb-2"
        >
            <AccordionItem>
                <AccordionHeader targetId={`${vm}_1`}>
                    Configure Network Settings
                </AccordionHeader>
                <AccordionBody accordionId={`${vm}_1`}>
                    <Card body color="light" className="p-0">

                        {/* <------------- MANAGEMENT --------------> */}
                        <CardHeader>
                            Management
                        </CardHeader>
                        <CardBody>
                            <FormGroup row >
                                <Label bsSize="sm" for="eth0_ipAddress" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    IP
                                    <span
                                        id={`eth0_ipAddress-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`eth0_ipAddress-${vm}-t`}>
                                        Specify IP address for management interface of virtual machine.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="eth0_ipAddress"
                                        name="eth0_ipAddress"
                                        placeholder="10.0.1.2"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"][vm]["eth0_ipAddress"]}
                                        valid={isIpValid(formState["thunderADC"]["vmware"][vm]["eth0_ipAddress"])}
                                        invalid={!isIpValid(formState["thunderADC"]["vmware"][vm]["eth0_ipAddress"])}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="mgmt_subnet_mask" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Subnet Mask
                                    <span
                                        id={`mgmt_subnet_mask-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`mgmt_subnet_mask-${vm}-t`}>
                                        Specify subnet mask for management IP address.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="mgmt_subnet_mask"
                                        name="mgmt_subnet_mask"
                                        placeholder="255.255.255.0"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"][vm]["mgmt_subnet_mask"]}
                                        valid={isIpValid(formState["thunderADC"]["vmware"][vm]["mgmt_subnet_mask"])}
                                        invalid={!isIpValid(formState["thunderADC"]["vmware"][vm]["mgmt_subnet_mask"])}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="mgmt_subnet_gateway" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Gateway IP
                                    <span
                                        id={`mgmt_subnet_gateway-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`mgmt_subnet_gateway-${vm}-t`}>
                                        Specify gateway IP address for management subnet.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="mgmt_subnet_gateway"
                                        name="mgmt_subnet_gateway"
                                        placeholder="10.0.3.1"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"]["vm1"]["mgmt_subnet_gateway"]}
                                        valid={vm === "vm1" && isIpValid(formState["thunderADC"]["vmware"]["vm1"]["mgmt_subnet_gateway"])}
                                        invalid={vm === "vm1" && !isIpValid(formState["thunderADC"]["vmware"]["vm1"]["mgmt_subnet_gateway"])}
                                        onChange={handleChange}
                                        disabled={vm === "vm2" || vm === "vm3"}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="vsphere_mgmt_network" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    vSwitch Port Group Name
                                    <span
                                        id={`vsphere_mgmt_network-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`vsphere_mgmt_network-${vm}-t`}>
                                        {`Choose standard or distributed switch for management interface. Check available networks in vSphere vCenter -> Shortcuts -> Networking`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="vsphere_mgmt_network"
                                        name="vsphere_mgmt_network"
                                        placeholder="VM Network"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"]["vm1"]["vsphere_mgmt_network"]}
                                        valid={vm === "vm1" && formState["thunderADC"]["vmware"]["vm1"]["vsphere_mgmt_network"]}
                                        invalid={vm === "vm1" && !formState["thunderADC"]["vmware"]["vm1"]["vsphere_mgmt_network"]}
                                        onChange={handleChange}
                                        disabled={vm === "vm2" || vm === "vm3"}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="mgmt_adaptor" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Adaptor Type
                                    <span
                                        id={`mgmt_adaptor-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`mgmt_adaptor-${vm}-t`}>
                                        Choose vSphere vCenter network adaptor type, supported adaptor type for management network interface is e1000e.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="mgmt_adaptor"
                                        name="mgmt_adaptor"
                                        placeholder="e1000e"
                                        required={true}
                                        disabled
                                        type="select"
                                        value={formState["thunderADC"]["vmware"][vm]["mgmt_adaptor"]}
                                        onChange={handleChange}
                                    >
                                        <option>e1000</option>
                                        <option>e1000e</option>
                                        <option>vmxnet3</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                        </CardBody>

                        {/* <------------- DATA IN --------------> */}
                        <hr className='mb-0 pb-0' />
                        <CardHeader>
                            Data-In
                        </CardHeader>
                        <CardBody>
                            <FormGroup row >
                                <Label bsSize="sm" for="eth1_ipAddress" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    IP
                                    <span
                                        id={`eth1_ipAddress-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`eth1_ipAddress-${vm}-t`}>
                                        Specify IP address for of data-in interface (client side interface) of virtual machine.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="eth1_ipAddress"
                                        name="eth1_ipAddress"
                                        placeholder="10.0.2.2"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"][vm]["eth1_ipAddress"]}
                                        valid={isIpValid(formState["thunderADC"]["vmware"][vm]["eth1_ipAddress"])}
                                        invalid={!isIpValid(formState["thunderADC"]["vmware"][vm]["eth1_ipAddress"])}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="data_in_subnet_mask" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Subnet Mask
                                    <span
                                        id={`data_in_subnet_mask-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`data_in_subnet_mask-${vm}-t`}>
                                        Specify subnet mask for data-in IP address.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data_in_subnet_mask"
                                        name="data_in_subnet_mask"
                                        placeholder="255.255.255.0"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"][vm]["data_in_subnet_mask"]}
                                        valid={isIpValid(formState["thunderADC"]["vmware"][vm]["data_in_subnet_mask"])}
                                        invalid={!isIpValid(formState["thunderADC"]["vmware"][vm]["data_in_subnet_mask"])}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            {/* <FormGroup row >
                                <Label bsSize="sm" for="data1_subnet_gateway" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Gateway IP
                                    <span
                                        id={`data1_subnet_gateway-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`data1_subnet_gateway-${vm}-t`}>
                                        Specify gateway IP address for data-in subnet.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data1_subnet_gateway"
                                        name="data1_subnet_gateway"
                                        placeholder="10.0.2.1"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"]["vm1"]["data1_subnet_gateway"]}
                                        valid={vm === "vm1" && isIpValid(formState["thunderADC"]["vmware"]["vm1"]["data1_subnet_gateway"])}
                                        invalid={vm === "vm1" && !isIpValid(formState["thunderADC"]["vmware"]["vm1"]["data1_subnet_gateway"])}
                                        onChange={handleChange}
                                        disabled={vm === "vm2" || vm === "vm3"}
                                    />
                                </Col>
                            </FormGroup> */}

                            <FormGroup row className="center-label">
                                <Label bsSize="sm" for="vsphere_data1_network" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    vSwitch Port Group Name
                                    <span
                                        id={`vsphere_data1_network-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`vsphere_data1_network-${vm}-t`}>
                                        {`Choose standard or distributed switch for Data IN traffic. Check available networks in vSphere vCenter -> Shortcuts -> Networking`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="vsphere_data1_network"
                                        name="vsphere_data1_network"
                                        placeholder="VM Network"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"]["vm1"]["vsphere_data1_network"]}
                                        valid={vm === "vm1" && formState["thunderADC"]["vmware"]["vm1"]["vsphere_data1_network"]}
                                        invalid={vm === "vm1" && !formState["thunderADC"]["vmware"]["vm1"]["vsphere_data1_network"]}
                                        onChange={handleChange}
                                        disabled={vm === "vm2" || vm === "vm3"}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="data_adaptor_in" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Adaptor Type
                                    <span
                                        id={`data_adaptor_in-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`data_adaptor_in-${vm}-t`}>
                                        {`Choose network adaptor type for data network interface, Check available adaptor type https://docs.vmware.com/en/VMware-Cloud-on-AWS/services/vmc-aws-performance/GUID-838CD673-105A-4D5E-ACA9-CFC7366FB064.html.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data_adaptor_in"
                                        name="data_adaptor_in"
                                        placeholder="vmxnet3"
                                        required={true}
                                        type="select"
                                        value={formState["thunderADC"]["vmware"][vm]["data_adaptor_in"]}
                                        valid={isAlphanumeric(formState["thunderADC"]["vmware"][vm]["data_adaptor_in"])}
                                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"][vm]["data_adaptor_in"])}
                                        onChange={handleChange}
                                        >
                                        <option>e1000</option>
                                        <option>e1000e</option>
                                        <option>vmxnet3</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                        </CardBody>

                        {/* <------------- DATA OUT --------------> */}
                        <hr className='mb-0 pb-0' />
                        <CardHeader>
                            Data-Out
                        </CardHeader>
                        <CardBody>
                            <FormGroup row >
                                <Label bsSize="sm" for="eth2_ipAddress" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    IP
                                    <span
                                        id={`eth2_ipAddress-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`eth2_ipAddress-${vm}-t`}>
                                        Specify IP address for of data-out interface (server side interface) of virtual machine.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="eth2_ipAddress"
                                        name="eth2_ipAddress"
                                        placeholder="10.0.3.2"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"][vm]["eth2_ipAddress"]}
                                        valid={isIpValid(formState["thunderADC"]["vmware"][vm]["eth2_ipAddress"])}
                                        invalid={!isIpValid(formState["thunderADC"]["vmware"][vm]["eth2_ipAddress"])}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="data_out_subnet_mask" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Subnet Mask
                                    <span
                                        id={`data_out_subnet_mask-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`data_out_subnet_mask-${vm}-t`}>
                                        Specify subnet mask for data-out IP address.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data_out_subnet_mask"
                                        name="data_out_subnet_mask"
                                        placeholder="255.255.255.0"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"][vm]["data_out_subnet_mask"]}
                                        valid={isIpValid(formState["thunderADC"]["vmware"][vm]["data_out_subnet_mask"])}
                                        invalid={!isIpValid(formState["thunderADC"]["vmware"][vm]["data_out_subnet_mask"])}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            {/* <FormGroup row >
                                <Label bsSize="sm" for="data2_subnet_gateway" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Gateway IP
                                    <span
                                        id={`data2_subnet_gateway-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`data2_subnet_gateway-${vm}-t`}>
                                        Specify gateway IP address for data-out subnet.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data2_subnet_gateway"
                                        name="data2_subnet_gateway"
                                        placeholder="10.0.3.1"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"]['vm1']["data2_subnet_gateway"]}
                                        valid={vm === "vm1" && isIpValid(formState["thunderADC"]["vmware"]["vm1"]["data2_subnet_gateway"])}
                                        invalid={vm === "vm1" && !isIpValid(formState["thunderADC"]["vmware"]["vm1"]["data2_subnet_gateway"])}
                                        onChange={handleChange}
                                        disabled={vm === "vm2" || vm === "vm3"}
                                    />
                                </Col>
                            </FormGroup> */}

                            <FormGroup row >
                                <Label bsSize="sm" for="vsphere_data2_network" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    vSwitch Port Group Name
                                    <span
                                        id={`vsphere_data2_network-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`vsphere_data2_network-${vm}-t`}>
                                        {`Choose standard or distributed switch for Data Out traffic. Check available networks in vSphere vCenter -> Shortcuts -> Networking`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="vsphere_data2_network"
                                        name="vsphere_data2_network"
                                        placeholder="VM Network"
                                        required={true}
                                        type="text"
                                        value={formState["thunderADC"]["vmware"]["vm1"]["vsphere_data2_network"]}
                                        valid={vm === "vm1" && formState["thunderADC"]["vmware"]["vm1"]["vsphere_data2_network"]}
                                        invalid={vm === "vm1" && !formState["thunderADC"]["vmware"]["vm1"]["vsphere_data2_network"]}
                                        onChange={handleChange}
                                        disabled={vm === "vm2" || vm === "vm3"}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Label bsSize="sm" for="data_adaptor_out" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Adaptor Type
                                    <span
                                        id={`data_adaptor_out-${vm}-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`data_adaptor_out-${vm}-t`}>
                                        {`Choose network adaptor type for data network interface, Check available adaptor type https://docs.vmware.com/en/VMware-Cloud-on-AWS/services/vmc-aws-performance/GUID-838CD673-105A-4D5E-ACA9-CFC7366FB064.html.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data_adaptor_out"
                                        name="data_adaptor_out"
                                        placeholder="vmxnet3"
                                        required={true}
                                        disabled
                                        type="select"
                                        value={formState["thunderADC"]["vmware"][vm]["data_adaptor_in"]}
                                        // valid={isAlphanumeric(formState["thunderADC"]["vmware"][vm]["data_adaptor_out"])}
                                        // invalid={!isAlphanumeric(formState["thunderADC"]["vmware"][vm]["data_adaptor_out"])}
                                        onChange={handleChange}
                                        >
                                        <option>e1000</option>
                                        <option>e1000e</option>
                                        <option>vmxnet3</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    </>
    )
}

export default ConfigVM
