import React, { useState } from "react"
import { AccordionBody, AccordionHeader, AccordionItem, Button, CardColumns, Card, FormGroup, Label, Input, Col, UncontrolledTooltip } from "reactstrap"
import { FaTrashAlt } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";

const ConfigHttp = ({ formState, setFormState }) => {
  const [conditionOptions, setConditionOptions] = useState([
    { value: "contains", label: "contains" },
    { value: "starts-with", label: "starts-with" },
    { value: "ends-with", label: "ends-with" },
    { value: "equals", label: "equals" },
    { value: "regex-match", label: "regex-match" },
    { value: "host-hits-enable", label: "host-hits-enable" },
  ]);

  const [switchingOptions, setSwitchingOptions] = useState([
    { value: "url-switching", label: "url-switching" },
    { value: "host-switching", label: "host-switching" },
  ]);

  const resetVirtualServerHttpTemplate = (idx, newFormState) => {
    newFormState["slb"]["configVirtualServer"].forEach(element => {
      element["port_list"].forEach(member => {
        if (member.template_http === idx) {
          member.template_http = -1
        }
      });
    });
  }

  const handleAddConfigHttp = () => {
    let newFormState = { ...formState }
    let size = newFormState["slb"]["configHttp"].length
    newFormState["slb"]["configHttp"].push({
      name: `http-template-${size + 1}`, protocol: "http", switching: [{
        switchingType: "url-switching",
        conditionType: "contains",
        conditionValue: "service",
        "serviceGrpName": 0
      }]
    })
    setFormState(newFormState)
  }

  const handleChange = (e, idx) => {
    let newFormState = { ...formState }
    newFormState["slb"]["configHttp"][idx] = { ...newFormState["slb"]["configHttp"][idx], [e.target.name]: e.target.value }
    setFormState(newFormState)
  }

  const handleDeleteConfigHttp = (idx) => {
    let newFormState = { ...formState }
    resetVirtualServerHttpTemplate(idx, newFormState)
    newFormState["slb"]["configHttp"].splice(idx, 1)
    setFormState(newFormState)
  }


  const handleSwitchingTypeChange = (e, idx, sidx) => {

    const newFormState = { ...formState };
    const selectedSwitchingType = e ? e.value : "";
    newFormState["slb"]["configHttp"][idx]["switching"][sidx] = {
      ...newFormState["slb"]["configHttp"][idx]["switching"][sidx],
      switchingType: selectedSwitchingType,
    };
    if (!switchingOptions.some((option) => option.value === selectedSwitchingType)) {
      const newSwitchingOption = { value: selectedSwitchingType, label: selectedSwitchingType };
      setSwitchingOptions([...switchingOptions, newSwitchingOption]);
    }

    setFormState(newFormState);
  };

  const handleConditionTypeChange = (e, idx, sidx) => {

    const newFormState = { ...formState };
    const selectedConditionType = e ? e.value : "";
    newFormState["slb"]["configHttp"][idx]["switching"][sidx] = {
      ...newFormState["slb"]["configHttp"][idx]["switching"][sidx],
      conditionType: selectedConditionType,
    };
    if (!conditionOptions.some((option) => option.value === selectedConditionType)) {
      const newConditionOption = { value: selectedConditionType, label: selectedConditionType };
      setConditionOptions([...conditionOptions, newConditionOption]);
    }

    setFormState(newFormState);
  };

  const handleSwitchingListChange = (e, idx, pidx) => {
    let newFormState = { ...formState }
    if (e.target.name === "serviceGrpName") {
      newFormState["slb"]["configHttp"][idx]["switching"][pidx] = { ...newFormState["slb"]["configHttp"][idx]["switching"][pidx], [e.target.name]: parseInt(e.target.value) }
    } else {
      newFormState["slb"]["configHttp"][idx]["switching"][pidx] = { ...newFormState["slb"]["configHttp"][idx]["switching"][pidx], [e.target.name]: e.target.value }
    }
    setFormState(newFormState)
  }
  const handleDeleteSwitchingList = (idx, sidx) => {
    let newFormState = { ...formState }
    newFormState["slb"]["configHttp"][idx]["switching"].splice(sidx, 1)
    setFormState(newFormState)
  }
  const handleAddSwitchingList = (idx) => {
    let newFormState = { ...formState }
    newFormState["slb"]["configHttp"][idx]["switching"].push({ switchingType: "url-switching", conditionType: "contains", conditionValue: "service", serviceGrpName: 0 })
    setFormState(newFormState)
  }
  return (
    <div>
      <AccordionItem>
        <AccordionHeader targetId="4">
          Configure HTTP Template
        </AccordionHeader>
        <AccordionBody accordionId="4">
          <div className="d-flex justify-content-end">
            <Button className="mb-2" size="sm" onClick={handleAddConfigHttp}>Add Config Http</Button>
          </div>
          <CardColumns>
            {formState["slb"]["configHttp"].map((fields, idx) => {
              return (
                <Card
                  key={idx}
                  body
                  color="light"
                  className="mb-1"
                >
                  <div className="d-flex justify-content-end">
                    <Button className="mb-2" size="sm" onClick={() => handleDeleteConfigHttp(idx)}><FaTrashAlt /></Button>
                  </div>
                  <FormGroup row>
                    <Label bsSize="sm" style={{ fontSize: "13px", textAlign: "end" }} sm={4}
                      for={`Name-${idx + 1}`}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Name
                      <span
                        id={`configHttp-name-${idx + 1}`}
                        style={{ fontSize: "14px" }}
                      >
                        <strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`configHttp-name-${idx + 1}`}>
                        Specify unique HTTP template name.<br /> HTTP template is selectively redirect client requests based on URL strings.
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input
                        bsSize="sm"
                        id={`Name-${idx + 1}`}
                        name="name"
                        placeholder={`http-template-${idx + 1}`}
                        onChange={(e) => handleChange(e, idx)}
                        type="text"
                        value={fields["name"] || ""}
                        valid={fields["name"]}
                        invalid={!fields["name"]}
                      />
                    </Col>
                  </FormGroup>
                  {/* <FormGroup row>
                    <Label
                      bsSize="sm"
                      for={`Protocol-${idx + 1}`}
                      style={{ fontSize: "13px", textAlign: "end" }}
                      sm={4}
                    >
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Protocol
                      <span
                        id={`configHttp-protocol-${idx + 1}`}
                        style={{ fontSize: "14px" }}
                      >
                        <strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`configHttp-protocol-${idx + 1}`}>
                        HTTP template protocol.
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <Input
                        bsSize="sm"
                        id={`Protocol-${idx + 1}`}
                        name="protocol"
                        disabled
                        type="text"
                        value={fields["protocol"] || ""} />
                    </Col>
                  </FormGroup> */}
                  {fields["switching"].map((item, sidx) => {
                    return (<div key={`${idx}_${sidx}`}>
                      <div className="d-flex justify-content-end">
                        {sidx > 0 && <Button className="mb-1" size="sm" disabled={sidx === 0} onClick={() => handleDeleteSwitchingList(idx, sidx)}><FaTrashAlt /></Button>}
                      </div>
                      <FormGroup row>
                        <Label bsSize="sm" for={`SwitchingType-${sidx + 1}`} sm={4}
                          style={{ fontSize: "13px", textAlign: "end" }}>
                          <span style={{ color: "red", fontSize: "16px" }}>*</span>
                          {`Switching Type`}
                          <span
                            id={`configHttp-stype-${idx + 1}_${sidx}`}
                            style={{ fontSize: "14px" }}
                          >
                            <strong> ⓘ</strong>
                          </span>
                          <UncontrolledTooltip placement="top" target={`configHttp-stype-${idx + 1}_${sidx}`}>
                            Select the switching type.<br /> URL/host switching enables an ACOS device to select a service group based on the URL or domain name in a client’s GET request.
                            <br /> The selection overrides the service group configured on the virtual port.
                          </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                          <Input
                            bsSize="sm"
                            id={`SwitchingType-${sidx + 1}`}
                            name="switchingType"
                            type="select"
                            disabled={sidx > 0}
                            value={fields["switching"][0]["switchingType"]}
                            onChange={(e) => handleSwitchingListChange(e, idx, sidx)}
                          >
                            <option selected hidden>select</option>
                            <option value="url-switching" >url-switching</option>
                            <option value="host-switching" >host-switching</option>
                          </Input>

                          {/* <CreatableSelect size="sm"
                            
                           
                            value={switchingOptions.find((option) => option.value === item["switchingType"])}
                            onChange={(e) => handleSwitchingTypeChange(e, idx, sidx)}
                            onCreateOption={(inputValue) => handleSwitchingTypeChange({ value: inputValue, label: inputValue }, idx, sidx)}
                            options={switchingOptions}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                minHeight: "35px",
                                height: "30px",
                                fontSize: "14px",
                              }),
                            }} /> */}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label bsSize="sm" for={`ConditionType-${sidx + 1}`} sm={4}
                          style={{ fontSize: "13px", textAlign: "end" }}>
                          <span style={{ color: "red", fontSize: "16px" }}>*</span>
                          {`Condition Type`}
                          <span
                            id={`configHttp-ctype-${idx + 1}_${sidx}`}
                            style={{ fontSize: "14px" }}
                          >
                            <strong> ⓘ</strong>
                          </span>
                          <UncontrolledTooltip placement="top" target={`configHttp-ctype-${idx + 1}_${sidx}`}>
                            Select matching condition type of http template "{fields["name"]}".
                          </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                          <CreatableSelect size="sm"
                            id={`ConditionType-${sidx + 1}`}
                            name="conditionType"
                            placeholder=""
                            value={conditionOptions.find((option) => option.value === item["conditionType"])}
                            onChange={(e) => handleConditionTypeChange(e, idx, sidx)}
                            onCreateOption={(inputValue) => handleConditionTypeChange({ value: inputValue, label: inputValue }, idx, sidx)}
                            options={conditionOptions}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                minHeight: "35px",
                                height: "30px",
                                fontSize: "14px",
                              }),
                            }} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label bsSize="sm" for={`conditionValue-${sidx + 1}`}
                          style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                          <span style={{ color: "red", fontSize: "16px" }}>*</span>
                          {`Condition Value`}
                          <span
                            id={`configHttp-cvalue-${idx + 1}_${sidx}`}
                            style={{ fontSize: "14px" }}
                          >
                            <strong> ⓘ</strong>
                          </span>
                          <UncontrolledTooltip placement="top" target={`configHttp-cvalue-${idx + 1}_${sidx}`}>
                            Specify matching condition value as per condition type "{item["conditionType"]}".
                          </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                          <Input
                            bsSize="sm" id={`conditionValue-${sidx + 1}`} name="conditionValue" placeholder="service"
                            onChange={(e) => handleSwitchingListChange(e, idx, sidx)} type="text"
                            value={item["conditionValue"] || ""}
                            valid={item["conditionValue"]}
                            invalid={!item["conditionValue"]}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          bsSize="sm"
                          for={`serviceGrpName-${sidx + 1}`}
                          style={{ fontSize: "13px", textAlign: "end" }}
                          sm={4}
                        >
                          <span style={{ color: "red", fontSize: "16px" }}>*</span>
                          {`Service Group`}
                          <span
                            id={`configHttp-sgname-${idx + 1}_${sidx}`}
                            style={{ fontSize: "14px" }}
                          >
                            <strong> ⓘ</strong>
                          </span>
                          <UncontrolledTooltip placement="top" target={`configHttp-sgname-${idx + 1}_${sidx}`}>
                            Select the service group to send client requests.
                          </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                          <Input
                            bsSize="sm"
                            id={`serviceGrpName-${sidx + 1}`}
                            name="serviceGrpName"
                            type="select"
                            value={item["serviceGrpName"]}
                            valid={item["serviceGrpName"] !== -1}
                            invalid={item["serviceGrpName"] === -1}
                            onChange={(e) => handleSwitchingListChange(e, idx, sidx)}
                          >
                            <option selected hidden>select</option>
                            {formState["slb"]["configServiceGroup"].map((option, gidx) => {
                              return option["name"] && <option key={`${idx}_${gidx}`} value={gidx} >{option["name"]}</option>
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                      <div className="d-flex justify-content-end">
                        {sidx === fields["switching"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddSwitchingList(idx)}>Add Switching List</Button>}
                      </div>
                    </div>
                    )
                  })}
                </Card>
              )
            })}
          </CardColumns>
        </AccordionBody>
      </AccordionItem>
    </div>
  )
}

export default ConfigHttp
