import React, { useState } from "react";
import { Button } from "reactstrap";
import AzureCustomForm from "./AzureCustomForm";
import AzureStandaloneForm from "./AzureStandaloneForm";
import AzureHAForm from "./AzureHaForm";
import AzureGSLBForm from "./AzureGslbForm";

const AzureForm = ({ formState, setFormState, vThunderCount, version_thunder }) => {
  const [showMore, setShowMore] = useState(false)
  return (<>
    <ul className="note">
      {vThunderCount === "standalone" && (
        <li>
          This section will help to generate and download automated scripts to create one new virtual Thunder instance.
        </li>
      )}
      {vThunderCount === "ha" && (
        <li>
          This section will help to generate and download automated scripts to create two new virtual Thunder instances to configure high availability.
        </li>
      )}
      {vThunderCount === "gslb" && (
        <li>
          This section will help to generate and download automated scripts to create three new virtual Thunder instances to configure global server load balancer.
        </li>
      )}
      {vThunderCount === "custom" && (
        <li>
          This section will help to generate and download automated scripts to create multiple new virtual Thunder instances.
        </li>
      )}
      <li>Passwords need to be provided during execution, we do not store any password information.</li>
      {vThunderCount !== "standalone" && <li>Configurations provided for networks and security in Virtual Machine-1 will be applied same on other virtual machines.</li>}
      <li>Please test this out before applying to production.</li>
      {showMore && <>
        {vThunderCount === "standalone" && (
          <>
            <li>Scripts will only provision new Thunder instance without configuration, to initiate the configuration please see configuration section and generate them separately as required.	</li>
            <li>For more information on deployment architecture, please see <a href="/architecture/azure-standalone.png" target="_blank">here</a></li>
          </>
        )}
        {vThunderCount === "ha" && (
          <>
            <li>High availability can be configured using VRRP among set of two Thunder instances with priority considering both are in same region and same availability zone.</li>
            <ul>
              <li>First Thunder instance considering active mode with high priority.</li>
              <li>Second Thunder instance considering standby mode.</li>
            </ul>
            <li>Scripts will only provision new Thunder instance without configuration, to initiate the configuration please see configuration section and generate them separately as required.	</li>
            <li>For more information on deployment architecture, please see <a href="/architecture/azure-ha.png" target="_blank">here</a></li>
          </>
        )}
        {vThunderCount === "gslb" && (
          <>
            <li>Here, we are providing example for three thunders as below.</li>
            <ul>
              <li>Thunder acting as Controller.</li>
              <li>Thunder acting as Site 1.</li>
              <li>Thunder acting as Site 2.</li>
            </ul>
            <li>Scripts will only provision new Thunder instance without configuration, to initiate the configuration please see configuration section and generate them separately as required.	</li>
            <li>For more information on deployment architecture, please see <a href="/architecture/azure-gslb.png" target="_blank">here</a></li>
          </>
        )}
        <li>For more information on Thunder product documentation, please see <a href="https://documentation.a10networks.com/Install/Software/A10_ACOS_Install/html/vThunder-Azure-Install-book-Responsive_HTML5/Default.htm" target="_blank">here</a></li>
        <li>To install using Azure Resource Manager template click <a href="https://github.com/a10networks/A10-azure-arm-templates" target="_blank">here</a>.</li>
      </>}
      <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
    </ul >

    <hr />
    {vThunderCount === "custom" && <AzureCustomForm formState={formState} setFormState={setFormState} version_thunder={version_thunder} />}

    {vThunderCount === "standalone" && <AzureStandaloneForm formState={formState} setFormState={setFormState} version_thunder={version_thunder} />}
    {vThunderCount === "ha" && <AzureHAForm formState={formState} setFormState={setFormState} version_thunder={version_thunder} />}
    {vThunderCount === "gslb" && <AzureGSLBForm formState={formState} setFormState={setFormState} version_thunder={version_thunder} />}
  </>

  )
}
export default AzureForm;