import React from "react"
import ConfigCredentialsEditor from "./ConfigCredentialsEditor"

const SSLEditor = ({ formState }) => {
    return (
        <div>
            <ConfigCredentialsEditor formState={formState} />

            <code className="d-block" style={{ fontSize: "13px" }}>
                Upload_Certificate:
            </code>
            {formState["ssl"]["host"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\thost`}:<span style={{ color: "rgb(171, 227, 56)" }}>{formState["ssl"]["host"]}</span>
            </code>}
            {formState["ssl"]["username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tusername`}:<span style={{ color: "rgb(171, 227, 56)" }}>{formState["ssl"]["username"]}</span>
            </code>}
            {/* {formState["ssl"]["password"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tpassword`}: {formState["ssl"]["password"]}
            </code>} */}
            {formState["ssl"]["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tprotocol`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ssl"]["protocol"]}</span>
            </code>}
            {formState["ssl"]["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tname`}:<span style={{ color: "rgb(171, 227, 56)" }}>{formState["ssl"]["name"]}</span>
            </code>}
            {formState["ssl"]["path"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tpath`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ssl"]["path"]}</span>
            </code>}
            {formState["ssl"]["certificate_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tcertificate_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ssl"]["certificate_type"]}</span>
            </code>}
        </div>
    )
}

export default SSLEditor
