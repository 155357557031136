import React from 'react'

const OracleHaEditor = ({ formState }) => {
  return (
    <div>
      {/* <-----VM-1------> */}
      <>
        <code className="d-block" style={{ fontSize: "13px" }}>
          Configure_VM_1:
        </code>
        {/* {formState["thunderADC"]["oracle"]["ha"]["tenancy_ocid"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\ttenancy_ocid`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["tenancy_ocid"]}</span>
          </code>}
          {formState["thunderADC"]["oracle"]["ha"]["user_ocid"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tuser_ocid`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["user_ocid"]}</span>
          </code>}
          {formState["thunderADC"]["oracle"]["ha"]["fingerprint"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tfingerprint`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["fingerprint"]}</span>
        </code>} */}
        {formState["thunderADC"]["oracle"]["ha"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["region"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["compartment_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tcompartment_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["compartment_id"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["availability_domain"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tavailability_domain`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["availability_domain"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["thunder_name_1"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_name_1`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_name_1"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["thunder_image"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_image`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_image"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["thunder_shape"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_shape`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_shape"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["thunder_active_memory_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_active_memory_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_active_memory_in_gbs"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["thunder_active_ocpus"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_active_ocpus`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_active_ocpus"]}</span>
        </code>}
        {/* {formState["thunderADC"]["oracle"]["ha"]["size_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tsize_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["size_in_gbs"]}</span>
        </code>} */}
        {formState["thunderADC"]["oracle"]["ha"]["private_key_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tprivate_key_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["private_key_path"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["vm_key_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tvm_key_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["vm_key_path"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["pub_vip"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tpub_vip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["pub_vip"]}</span>
        </code>}
        {/* {formState["thunderADC"]["oracle"]["ha"]["block_vol_attachment_type"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tblock_vol_attachment_type`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["block_vol_attachment_type"]}</span>
        </code>} */}

        {/* <-------- Management --------->*/}
        <code className="d-block" style={{ fontSize: "13px" }}>
          {"\tConfigure_Network_Settings:"}
        </code>
        {formState["thunderADC"]["oracle"]["ha"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["mgmt_subnet_id"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["data_in_subnet_id"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["data_out_subnet_id"]}</span>
        </code>}

        {/* <-------- Security --------->*/}
        <code className="d-block" style={{ fontSize: "13px" }}>
          {"\tConfigure_Security:"}
        </code>
        {formState["thunderADC"]["oracle"]["ha"]["mgmt_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tmgmt_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
            {formState["thunderADC"]["oracle"]["ha"]["mgmt_nsg_ids"].map((ele, idx) => {
              return <>{idx > 0 && ", "}{ele}</>
            })}
            ]</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["data_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\t\tdata_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
            {formState["thunderADC"]["oracle"]["ha"]["data_nsg_ids"].map((ele, idx) => {
              return <>{idx > 0 && ", "}{ele}</>
            })}
            ]</span>
        </code>}
      </>

      <br />
      {/* <-----VM-2------> */}
      <>
        <code className="d-block" style={{ fontSize: "13px" }}>
          Configure_VM_2:
        </code>
        {formState["thunderADC"]["oracle"]["ha"]["thunder_name_2"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_name_2`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_name_2"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["thunder_standby_memory_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_standby_memory_in_gbs`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_standby_memory_in_gbs"]}</span>
        </code>}
        {formState["thunderADC"]["oracle"]["ha"]["thunder_standby_ocpus"] && <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tthunder_standby_ocpus`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["ha"]["thunder_standby_ocpus"]}</span>
        </code>}
      </>
    </div>
  )
}

export default OracleHaEditor