import axios from "axios";
import { baseUrl } from "../data/parameters";

const getChangepasswordFile = (data) => {
    return axios.post(`${baseUrl}/configurations/changePassword`, data, {
        responseType: 'blob',
    });
};
const getSlbFile = (data) => {
    return axios.post(`${baseUrl}/configurations/slb`, data, {
        responseType: 'blob',
    });
};
const getGslbFile = (data) => {
    return axios.post(`${baseUrl}/configurations/gslb`, data, {
        responseType: 'blob',
    });
};
const getHaFile = (data) => {
    return axios.post(`${baseUrl}/configurations/ha`, data, {
        responseType: 'blob',
    });
};
const getGlmFile = (data) => {
    return axios.post(`${baseUrl}/configurations/glm`, data, {
        responseType: 'blob',
    });
};
const getSslFile = (data) => {
    return axios.post(`${baseUrl}/configurations/ssl`, data, {
        responseType: 'blob',
    });
};

export { getChangepasswordFile, getGlmFile, getGslbFile, getHaFile, getSlbFile, getSslFile }