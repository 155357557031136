import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardColumns, Col, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import { isIpValid } from "../../../../utils/validations";

const ConfigServer = ({ formState, setFormState }) => {

    const resetServiceGrpServerName = (idx, newFormState) => {
        newFormState["slb"]["configServiceGroup"].forEach(element => {
            element["member_list"].forEach(member => {
                if (member.name === idx) {
                    member.name = -1
                }
            });
        });
    }

    const handleChange = (e, idx) => {
        let newFormState = { ...formState }
        if (e.target.name === "health_check_disable") {
            newFormState["slb"]["configServer"][idx] = { ...newFormState["slb"]["configServer"][idx], [e.target.name]: (e.target.value === "enable" ? 0 : 1) }
        }
        else {
            newFormState["slb"]["configServer"][idx] = { ...newFormState["slb"]["configServer"][idx], [e.target.name]: e.target.value }
        }
        setFormState(newFormState)
    }
    const handleChanges = (e, idx, pidx) => {
        let newFormState = { ...formState }
        if (e.target.name === "health_check_disable") {
            newFormState["slb"]["configServer"][idx]["port_list"][pidx] = { ...newFormState["slb"]["configServer"][idx]["port_list"][pidx], [e.target.name]: (e.target.value === "enable" ? 0 : 1) }
        } else {
            newFormState["slb"]["configServer"][idx]["port_list"][pidx] = { ...newFormState["slb"]["configServer"][idx]["port_list"][pidx], [e.target.name]: e.target.value }
        }

        setFormState(newFormState)
    }

    const handleAddConfigServer = () => {
        let newFormState = { ...formState }
        let size = newFormState["slb"]["configServer"].length
        newFormState["slb"]["configServer"].push({ "name": `server-${size + 1}`, "host": "127.0.0.1", "health_check_disable": 0, "port_list": [{ "port_number": "443", "protocol": "tcp", "health_check_disable": 0 },] })
        setFormState(newFormState)
    }

    const [protocolOptions, setProtocolOptions] = useState([
        { value: "tcp", label: "tcp" },
        { value: "udp", label: "udp" },
    ]);

    const handlePortProtocolChange = (selectedOption, idx, pidx) => {

        const newFormState = { ...formState };
        const selectedProtocol = selectedOption ? selectedOption.value : "";
        newFormState["slb"]["configServer"][idx]["port_list"][pidx] = {
            ...newFormState["slb"]["configServer"][idx]["port_list"][pidx],
            "protocol": selectedProtocol,
        };

        // Check if the selectedProtocol is not in protocolOptions, which means it's a newly created option
        if (!protocolOptions.some((option) => option.value === selectedProtocol)) {
            const newProtocolOption = { value: selectedProtocol, label: selectedProtocol };
            setProtocolOptions([...protocolOptions, newProtocolOption]);
        }

        setFormState(newFormState);
    };


    const handleAddPortList = (idx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configServer"][idx]["port_list"].push({ "port_number": "443", "protocol": "tcp", "health_check_disable": 0 })
        setFormState(newFormState)
    }

    const handleDeletePortList = (idx, pidx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configServer"][idx]["port_list"].splice(pidx, 1)
        setFormState(newFormState)
    }

    const handleDeleteConfigServer = (idx) => {
        let newFormState = { ...formState }
        resetServiceGrpServerName(idx, newFormState)
        newFormState["slb"]["configServer"].splice(idx, 1)
        setFormState(newFormState)
    }

    return (

        <AccordionItem>
            <AccordionHeader targetId="2">
                Configure Server
            </AccordionHeader>
            <AccordionBody accordionId="2">
                <div className="d-flex justify-content-end">
                    <Button className="mb-2" size="sm" onClick={handleAddConfigServer}>Add Config Server</Button>
                </div>
                <CardColumns>
                    {formState["slb"]["configServer"].map((fields, idx) => {
                        return <Card
                            key={idx}
                            body
                            color="light"
                            className="mb-1"
                        >
                            <div className="d-flex justify-content-end">
                                {idx > 0 && <Button className="mb-2" size="sm" disabled={idx === 0} onClick={() => handleDeleteConfigServer(idx)}><FaTrashAlt /></Button>}
                            </div>
                            <FormGroup row>
                                <Label
                                    bsSize="sm"
                                    for={`name-${idx + 1}`}
                                    style={{ fontSize: "13px", textAlign: "end" }}
                                    sm={4}
                                >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Name
                                    <span
                                        id={`configSer-SN-${idx + 1}`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`configSer-SN-${idx + 1}`}>
                                        Specify unique name of application server.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`name-${idx + 1}`}
                                        name="name"
                                        placeholder={`server-${idx + 1}`}
                                        type="text"
                                        value={fields["name"]}
                                        valid={fields["name"]}
                                        invalid={!fields["name"]}
                                        onChange={(e) => handleChange(e, idx)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    bsSize="sm"
                                    for={`host-${idx + 1}`}
                                    style={{ fontSize: "13px", textAlign: "end" }}
                                    sm={4}
                                >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Host
                                    <span
                                        id={`configSer-SH-${idx + 1}`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`configSer-SH-${idx + 1}`}>
                                        Specify host or private ip of application server.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`host-${idx + 1}`}
                                        name="host"
                                        placeholder="10.0.3.6"
                                        type="text"
                                        value={fields["host"]}
                                        valid={isIpValid(fields["host"])}
                                        invalid={!isIpValid(fields["host"])}
                                        onChange={(e) => handleChange(e, idx)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    bsSize="sm"
                                    for={`health_check_disable-${idx + 1}`}
                                    style={{ fontSize: "13px", textAlign: "end" }}
                                    sm={4}
                                >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Health Check
                                    <span
                                        id={`configSer-health-check-${idx + 1}`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`configSer-health-check-${idx + 1}`}>
                                        Select health check monitoring option enable or disable for "{fields["name"]}".
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`health_check_disable-${idx + 1}`}
                                        name="health_check_disable"
                                        type="select"
                                        value={fields["health_check_disable"] === 0 ? "enable" : "disable"}
                                        onChange={(e) => handleChange(e, idx)}
                                    >
                                        <option>enable</option>
                                        <option>disable</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            {fields["port_list"].map((item, pidx) => {
                                return <div key={`${idx}_${pidx}`}>
                                    <div className="d-flex justify-content-end">
                                        {pidx > 0 && <Button className="mb-1" size="sm" disabled={pidx === 0} onClick={() => handleDeletePortList(idx, pidx)}><FaTrashAlt /></Button>}
                                    </div>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`port_number-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Port`}
                                            <span
                                                id={`configSer-pl-pn-${idx + 1}_${pidx + 1}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`configSer-pl-pn-${idx + 1}_${pidx + 1}`}>
                                                Specify port number for "{fields["name"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`port_number-${pidx + 1}`}
                                                name="port_number"
                                                placeholder="443"
                                                type="number"
                                                min="0"
                                                value={item["port_number"]}
                                                valid={item["port_number"]}
                                                invalid={!item["port_number"]}
                                                onChange={(e) => handleChanges(e, idx, pidx)}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`protocol-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Protocol`}
                                            <span
                                                id={`configSer-pl-pr-${idx + 1}_${pidx + 1}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`configSer-pl-pr-${idx + 1}_${pidx + 1}`}>
                                                Select protocol for "{fields["name"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <CreatableSelect
                                                id={`protocol-${pidx + 1}`}
                                                name="protocol"
                                                placeholder="select"
                                                value={protocolOptions.find((option) => option.value === item["protocol"])}
                                                onChange={(selectedOption) => handlePortProtocolChange(selectedOption, idx, pidx)}
                                                onCreateOption={(inputValue) => handlePortProtocolChange({ value: inputValue, label: inputValue }, idx, pidx)}
                                                options={protocolOptions}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        minHeight: "35px",
                                                        height: "30px",
                                                        fontSize: "14px",
                                                    }),
                                                }} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`health_check_disable_p-${idx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Health Check
                                            <span
                                                id={`configSer-health-check_p-t-${idx + 1}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`configSer-health-check_p-t-${idx + 1}`}>
                                                Select health check monitoring option enable or disable for port "{item["port_number"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`health_check_disable_p-${idx + 1}`}
                                                name="health_check_disable"
                                                type="select"
                                                value={item["health_check_disable"] === 0 ? "enable" : "disable"}
                                                onChange={(e) => handleChanges(e, idx, pidx)}
                                            >
                                                <option>enable</option>
                                                <option>disable</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <div className="d-flex justify-content-end">
                                        {pidx === fields["port_list"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddPortList(idx)}>Add Port List</Button>}
                                    </div>
                                </div>

                            })}
                        </Card>
                    })}
                </CardColumns>
            </AccordionBody>
        </AccordionItem>
    )
}

export default ConfigServer
