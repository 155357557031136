import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardColumns, Col, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";

const ConfigServiceGroup = ({ formState, setFormState }) => {

    const resetHttpTemplateServiceGrpName = (idx, newFormState) => {
        newFormState["slb"]["configHttp"].forEach(element => {
            element["switching"].forEach(member => {
                if (member.serviceGrpName === idx) {
                    member.serviceGrpName = -1
                }
            });
        });
    }
    const resetVirtualServerServiceGrpName = (idx, newFormState) => {
        newFormState["slb"]["configVirtualServer"].forEach(element => {
            element["port_list"].forEach(member => {
                if (member.service_group === idx) {
                    member.service_group = -1
                }
            });
        });
    }

    const handleFieldChange = (e, idx) => {
        let updatedFormState = { ...formState }
        if (e.target.name === "health_check_disable") {
            updatedFormState["slb"]["configServiceGroup"][idx] = { ...updatedFormState["slb"]["configServiceGroup"][idx], [e.target.name]: (e.target.value === "enable" ? 0 : 1) }
        } else {
            updatedFormState["slb"]["configServiceGroup"][idx] = { ...updatedFormState["slb"]["configServiceGroup"][idx], [e.target.name]: e.target.value }
        }
        setFormState(updatedFormState)
    }
    const handleAddServiceGrp = () => {
        let updatedFormState = { ...formState }
        let size = updatedFormState["slb"]["configServiceGroup"].length
        updatedFormState["slb"]["configServiceGroup"].push({ "name": `service-group-${size + 1}`, "protocol": "tcp", "member_list": [{ "name": 0, "port": "443" }] })
        setFormState(updatedFormState)
    }

    const handleDeleteServiceGrp = (idx) => {
        let newFormState = { ...formState }
        resetHttpTemplateServiceGrpName(idx, newFormState)
        resetVirtualServerServiceGrpName(idx, newFormState)
        newFormState["slb"]["configServiceGroup"].splice(idx, 1)
        setFormState(newFormState)
    }

    const handleMemberListChange = (e, idx, pidx) => {
        let newFormState = { ...formState }
        if (e.target.name === "name") {
            newFormState["slb"]["configServiceGroup"][idx]["member_list"][pidx] = { ...newFormState["slb"]["configServiceGroup"][idx]["member_list"][pidx], [e.target.name]: parseInt(e.target.value) }
        }
        else {
            newFormState["slb"]["configServiceGroup"][idx]["member_list"][pidx] = { ...newFormState["slb"]["configServiceGroup"][idx]["member_list"][pidx], [e.target.name]: e.target.value }
        }

        setFormState(newFormState)
    }

    const handleAddMemberList = (idx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configServiceGroup"][idx]["member_list"].push({ "name": 0, "port": "443" })
        setFormState(newFormState)
    }

    const handleDeleteMemberList = (idx, pidx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configServiceGroup"][idx]["member_list"].splice(pidx, 1)
        setFormState(newFormState)
    }
    const [protocolOptions, setProtocolOptions] = useState([
        { value: "tcp", label: "tcp" },
        { value: "udp", label: "udp" },
    ]);
    const handlePortProtocolChange = (e, idx) => {

        const newFormState = { ...formState };
        const selectedProtocol = e ? e.value : "";
        newFormState["slb"]["configServiceGroup"][idx] = {
            ...newFormState["slb"]["configServiceGroup"][idx],
            "protocol": selectedProtocol,
        };

        // Check if the selectedProtocol is not in protocolOptions, which means it's a newly created option
        if (!protocolOptions.some((option) => option.value === selectedProtocol)) {
            const newProtocolOption = { value: selectedProtocol, label: selectedProtocol };
            setProtocolOptions([...protocolOptions, newProtocolOption]);
        }

        setFormState(newFormState);
    };

    return (
        <AccordionItem>
            <AccordionHeader targetId="3">
                Configure Service Group
            </AccordionHeader>
            <AccordionBody accordionId="3">
                <div className="d-flex justify-content-end">
                    <Button className="mb-2" size="sm" onClick={handleAddServiceGrp}>Add Service Group</Button>
                </div>
                <CardColumns>
                    {formState["slb"]["configServiceGroup"].map((fields, idx) => {
                        return (
                            <Card
                                key={idx}
                                color="light"
                                body
                                className="mb-1">
                                <div className="mb-2 d-flex justify-content-end">
                                    {idx > 0 && <Button size="sm" disabled={idx === 0} onClick={() => handleDeleteServiceGrp(idx)}><FaTrashAlt /></Button>}
                                </div>
                                <FormGroup row>
                                    <Label
                                        bsSize="sm"
                                        for="name"
                                        style={{ fontSize: "13px", textAlign: "end" }}
                                        sm={4}
                                    >
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Name
                                        <span
                                            id={`sg-SGN-${idx + 1}`}
                                            style={{ fontSize: "14px" }}
                                        >
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`sg-SGN-${idx + 1}`}>
                                            Specify unique name for service group.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id="name"
                                            name="name"
                                            placeholder={`service-group-${idx + 1}`}
                                            type="text"
                                            value={fields["name"]}
                                            valid={fields["name"]}
                                            invalid={!fields["name"]}
                                            onChange={(e) => handleFieldChange(e, idx)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        bsSize="sm"
                                        for="protocol"
                                        style={{ fontSize: "13px", textAlign: "end" }}
                                        sm={4}
                                    >
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Protocol
                                        <span
                                            id={`sg-SGP-${idx + 1}`}
                                            style={{ fontSize: "14px" }}
                                        >
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`sg-SGP-${idx + 1}`}>
                                            Select protocol of member server.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <CreatableSelect
                                            id="protocol"
                                            name="protocol"
                                            placeholder="Select"
                                            value={protocolOptions.find((option) => option.value === fields["protocol"])}
                                            onChange={(e) => handlePortProtocolChange(e, idx)}
                                            onCreateOption={(inputValue) => handlePortProtocolChange({ value: inputValue, label: inputValue }, idx)}
                                            options={protocolOptions}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    minHeight: "35px",
                                                    height: "30px",
                                                    fontSize: "14px",
                                                }),
                                            }} />
                                    </Col>
                                </FormGroup>
                                {fields["member_list"].map((item, pidx) => {
                                    return <div key={`${idx}_${pidx}`}>
                                        <div className="d-flex justify-content-end">
                                            {pidx > 0 && <Button className="mb-1" size="sm" disabled={pidx === 0} onClick={() => handleDeleteMemberList(idx, pidx)}><FaTrashAlt /></Button>}
                                        </div>
                                        <FormGroup row>
                                            <Label
                                                bsSize="sm"
                                                for={`name-${pidx + 1}`}
                                                style={{ fontSize: "13px", textAlign: "end" }}
                                                sm={4}
                                            >
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                {`Server`}
                                                <span
                                                    id={`sg-SN-${idx + 1}_${pidx + 1}`}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <strong> ⓘ</strong>
                                                </span>
                                                <UncontrolledTooltip placement="top" target={`sg-SN-${idx + 1}_${pidx + 1}`}>
                                                    Select server to be added as a member in service group "{fields["name"]}".
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input
                                                    bsSize="sm"
                                                    id={`name-${pidx + 1}`}
                                                    name="name"
                                                    type="select"
                                                    value={item["name"]}
                                                    valid={item["name"] !== -1}
                                                    invalid={item["name"] === -1}
                                                    onChange={(e) => handleMemberListChange(e, idx, pidx)}
                                                >
                                                    <option selected={true} hidden>select</option>
                                                    {formState["slb"]["configServer"].map((option, gidx) => {
                                                        return option["name"] && <option key={`${idx}_${gidx}`} value={gidx} >{option["name"]}</option>
                                                    })}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                bsSize="sm"
                                                for={`port-${pidx + 1}`}
                                                style={{ fontSize: "13px", textAlign: "end" }}
                                                sm={4}
                                            >
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                {`Port`}
                                                <span
                                                    id={`sg-PN-${idx + 1}_${pidx + 1}`}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <strong> ⓘ</strong>
                                                </span>
                                                <UncontrolledTooltip placement="top" target={`sg-PN-${idx + 1}_${pidx + 1}`}>
                                                    Specify port number of member server.
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input
                                                    bsSize="sm"
                                                    id={`port-${pidx + 1}`}
                                                    name="port"
                                                    placeholder="443"
                                                    type="number"
                                                    min="0"
                                                    value={item["port"]}
                                                    valid={item["port"]}
                                                    invalid={!item["port"]}
                                                    onChange={(e) => handleMemberListChange(e, idx, pidx)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <div className="d-flex justify-content-end">
                                            {pidx === fields["member_list"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddMemberList(idx)}>Add Member List</Button>}
                                        </div>
                                    </div>
                                })}
                            </Card>
                        )
                    })}
                </CardColumns>
            </AccordionBody >
        </AccordionItem>
    )
}

export default ConfigServiceGroup
