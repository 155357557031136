import React from "react"

const AzureCustomEditor = ({ formState, vThunderCount }) => {
    return (
        <>
            {/*Virtual Machine 1 */}
            <div>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM:
                </code>
                {formState["thunderADC"]["azure"]["custom"]["location"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["location"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["resource_group"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["existing_resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\texisting_resource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["existing_resource_group"]}</span>
                </code>}
                {<code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tinstance_count`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["vm_count"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["vm_names"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tinstance_names`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["azure"]["custom"]["vm_names"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["image"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tsize`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["size"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["admin_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["admin_username"]}</span>
                </code>}
                {/* <-------- Network --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["azure"]["custom"]["vnet_nsg_rg"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["vnet_nsg_rg"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["vnet_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvirtual_network_vnet`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["vnet_name"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["accelerated_networking"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\taccelerated_networking`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["accelerated_networking"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["ip_forwarding"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["subnet_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tsubnet_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["subnet_mgmt"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["subnet_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tsubnet_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["subnet_datain"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["subnet_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tsubnet_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["subnet_dataout"]}</span>
                </code>}
                {/* <-------- Security --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Security_Settings:"}
                </code>
                {formState["thunderADC"]["azure"]["custom"]["nsg_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["nsg_mgmt"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["nsg_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_datain`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["nsg_datain"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["custom"]["nsg_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_dataout`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["custom"]["nsg_dataout"]}</span>
                </code>}
            </div>
        </>
    )
}
export default AzureCustomEditor