import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Button, UncontrolledAccordion, UncontrolledAlert } from "reactstrap";
import ConfigureVM from "./ConfigureVM";

const GCPForm = ({ formState, setFormState, vThunderCount, version_thunder }) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <>
      <ul className='note'>
        {vThunderCount === "standalone" && (
          <li>
            This section will help to generate and download automated scripts to create one new virtual Thunder instance.
          </li>
        )}
        {vThunderCount === "ha" && (
          <li>
            This section will help to generate and download automated scripts to create two new virtual Thunder instances to configure high availability.
          </li>
        )}
        {vThunderCount === "gslb" && (
          <li>
            This section will help to generate and download automated scripts to create three new virtual Thunder instances to configure global server load balancer.
          </li>
        )}
        {vThunderCount === "custom" && (
          <li>
            This section will help to generate and download automated scripts to create custom number of new virtual Thunder instances.
          </li>
        )}
        <li>Passwords need to be provided during execution, we do not store any password information.</li>
        <li>Please test this out before applying to production.</li>
        {showMore && <>
          {vThunderCount === "standalone" && (
            <>
              <li>Scripts will only provision new Thunder instance without configuration, to initiate the configuration please see configuration section and generate them separately as required.	</li>
              {/* <li>For more information on deployment architecture, please see <a href="/architecture/gcp-standalone.jpg" target="_blank">here</a></li> */}
            </>
          )}
          {vThunderCount === "ha" && (
            <>
              <li>High availability can be configured using VRRP among set of two Thunder instances with priority considering both are in same region and same availability zone.</li>
              <ul>
                <li>First Thunder instance considering active mode with high priority.</li>
                <li>Second Thunder instance considering standby mode.</li>
              </ul>
              <li>Scripts will only provision new Thunder instance without configuration, to initiate the configuration please see configuration section and generate them separately as required.	</li>
              {/* <li>For more information on deployment architecture, please see <a href="/architecture/gcp-ha.jpg" target="_blank">here</a></li> */}
            </>
          )}
          {vThunderCount === "gslb" && (
            <>
              <li>Here, we are providing example for three thunders as below.</li>
              <ul>
                <li>Thunder acting as Controller.</li>
                <li>Thunder acting as Site 1.</li>
                <li>Thunder acting as Site 2.</li>
              </ul>
              <li>Scripts will only provision new Thunder instance without configuration, to initiate the configuration please see configuration section and generate them separately as required.	</li>
              {/* <li>For more information on deployment architecture, please see <a href="/architecture/gcp-gslb.jpg" target="_blank">here</a></li> */}
            </>
          )}
          <li>For more information on Thunder product documentation, please see <a href="https://documentation.a10networks.com/Install/Software/A10_ACOS_Install/html/vThunder-GCP-Install-Guide-Responsive_HTML5/Default.htm" target="_blank">here</a></li>
          <li>For more information on deployment architecture, please see <a href={`/architecture/gcp-${vThunderCount}.jpg`} target="_blank">here</a></li>
        </>}
        <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
      </ul >

      <hr />

      {version_thunder === "v5.x" && <UncontrolledAlert className="p-2 mb-0" color="danger">
        GCP image for version 5 is not available. Please choose the latest version.
      </UncontrolledAlert>}

      <UncontrolledAccordion stayOpen className="mb-2 pb-2">
        {vThunderCount === "custom" ? <>Coming Soon...</> : <>
          <AccordionItem>
            <AccordionHeader targetId="2">
              {vThunderCount !== "standalone" ? "Configure Virtual Machine-1" : "Configure Virtual Machine"}
            </AccordionHeader>
            <AccordionBody accordionId="2">
              <ConfigureVM formState={formState} setFormState={setFormState} vm={"vm1"} version_thunder={version_thunder} />
            </AccordionBody>
          </AccordionItem>
          {vThunderCount !== "standalone" && <AccordionItem>
            <AccordionHeader targetId="3">
              Configure Virtual Machine-2
            </AccordionHeader>
            <AccordionBody accordionId="3">
              <ConfigureVM formState={formState} setFormState={setFormState} vm={"vm2"} version_thunder={version_thunder} />
            </AccordionBody>
          </AccordionItem>}

          {vThunderCount === "gslb" && <AccordionItem>
            <AccordionHeader targetId="4">
              Configure Virtual Machine-3
            </AccordionHeader>
            <AccordionBody accordionId="4">
              <ConfigureVM formState={formState} setFormState={setFormState} vm={"vm3"} version_thunder={version_thunder} />
            </AccordionBody>
          </AccordionItem>}
        </>}
      </UncontrolledAccordion>
    </>
  )
}

export default GCPForm
