import React from 'react'

const ConfigCredentialsEditor = ({ formState }) => {
    return (
        <div>
            <code className="d-block" style={{ fontSize: "13x" }}>
                Configure_Credentials:
            </code>
            {
                formState['configCredentials']['address'] && <code className="d-block" style={{ fontSize: "13x" }}>
                    
                    {`\taddress`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState['configCredentials']['address']}</span>
                </code>
            }
            {formState['configCredentials']['username'] &&
                <code className="d-block" style={{ fontSize: "13x" }}>
                    {`\tusername`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState['configCredentials']['username']}</span>
                </code>
            }
            {/* <code className="d-block" style={{ fontSize: "13x" }}>
                {`\tpassword: ${formState['configCredentials']['password']}`}
            </code> */}
            <br />
        </div>
    )
}

export default ConfigCredentialsEditor
