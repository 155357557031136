import React from 'react'
import ConfigCredentialsEditor from './ConfigCredentialsEditor'

const GLMEditor = ({ formState }) => {
  return (
    <div>
      <ConfigCredentialsEditor formState={formState} />

      <code className="d-block" style={{ fontSize: "13px" }}>
        Configure_License:
      </code>
      {formState['glm']['token'] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\ttoken`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState['glm']['token']}</span>
      </code>}
      {formState['glm']['dns_ip'] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tdns_address`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState['glm']['dns_ip']}</span>
      </code>}
    </div>
  )
}

export default GLMEditor