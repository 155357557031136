import React from "react"
import { FaTrashAlt } from "react-icons/fa"
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardColumns, Col, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap"
import { isIpValid } from "../../../../utils/validations"

const ConfigVirtualServer = ({ formState, setFormState }) => {

    const handleAddVirtualServer = () => {
        let newFormState = { ...formState }
        let size = newFormState["slb"]["configVirtualServer"].length
        newFormState["slb"]["configVirtualServer"].push({
            "name": `virtual-server-${size + 1}`,
            "ip_address": "127.0.0.1",
            "port_list": [{
                "port_number": "443",
                "protocol": "tcp",
                "ha_conn_mirror": 0,
                "auto": 1,
                "service_group": 0,
                "template_http": -1,
                "support_http2": 0
            }]
        })
        setFormState(newFormState)
    }

    const handleDeleteVirtualServer = (idx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configVirtualServer"].splice(idx, 1)
        setFormState(newFormState)
    }

    const handleChange = (e, idx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configVirtualServer"][idx] = { ...newFormState["slb"]["configVirtualServer"][idx], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }

    const handleAddVirtualPort = (idx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configVirtualServer"][idx]["port_list"].push({
            "port_number": "443",
            "protocol": "tcp",
            "ha_conn_mirror": 0,
            "auto": 1,
            "service_group": 0,
            "template_http": -1,
            "support_http2": 0
        })
        setFormState(newFormState)
    }

    const handleDeleteVirtualPort = (idx, pidx) => {
        let newFormState = { ...formState }
        newFormState["slb"]["configVirtualServer"][idx]["port_list"].splice(pidx, 1)
        setFormState(newFormState)
    }

    const handleVirtualPortChange = (e, idx, pidx) => {
        let newFormState = { ...formState }
        if (e.target.name === "auto" || e.target.name === "ha_conn_mirror") {
            newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx] = { ...newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx], [e.target.name]: (e.target.value === "enable" ? 1 : 0) }
        }
        else if (e.target.name === 'protocol') {
            if (e.target.value !== "udp" && e.target.value !== "tcp") {
                newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx]["ha_conn_mirror"] = 0
            } else if (e.target.value !== "http" && e.target.value !== "https") {
                newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx]["support_http2"] = 0
                newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx]["template_http"] = -1
            }
            newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx] = { ...newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx], [e.target.name]: e.target.value }
        } else if (e.target.name === "service_group" || e.target.name === "template_http") {
            newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx] = { ...newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx], [e.target.name]: parseInt(e.target.value) }
        }
        else {
            newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx] = { ...newFormState["slb"]["configVirtualServer"][idx]["port_list"][pidx], [e.target.name]: e.target.value }
        }
        setFormState(newFormState)
    }

    return (
        <AccordionItem>
            <AccordionHeader targetId="5">
                Configure Virtual Server
            </AccordionHeader>
            <AccordionBody accordionId="5">
                <div className="d-flex justify-content-end">
                    <Button className="mb-2" size="sm" onClick={handleAddVirtualServer}>Add Virtual Server</Button>
                </div>
                <CardColumns>
                    {formState["slb"]["configVirtualServer"].map((fields, idx) => {
                        return <Card
                            key={idx}
                            color="light"
                            body
                            className="mb-1">
                            <div className="d-flex justify-content-end">
                                {idx > 0 && <Button className="mb-2" size="sm" disabled={idx === 0} onClick={() => handleDeleteVirtualServer(idx)}><FaTrashAlt /></Button>}
                            </div>
                            <FormGroup row>
                                <Label
                                    bsSize="sm"
                                    for="name"
                                    style={{ fontSize: "13px", textAlign: "end" }}
                                    sm={4}
                                >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Name
                                    <span
                                        id={`vserver-SN-${idx + 1}`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`vserver-SN-${idx + 1}`}>
                                        Specify unique name of vitual server.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id="name"
                                        name="name"
                                        placeholder={`virtual-server-${idx + 1}`}
                                        type="text"
                                        value={fields["name"]}
                                        valid={fields["name"]}
                                        invalid={!fields["name"]}
                                        onChange={(e) => handleChange(e, idx)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    bsSize="sm"
                                    for="ip_address"
                                    style={{ fontSize: "13px", textAlign: "end" }}
                                    sm={4}
                                >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    IP
                                    <span
                                        id={`vserver-ip-${idx + 1}`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`vserver-ip-${idx + 1}`}>
                                        Specify private ip address to be used as virtual server ip (vip). Specify data interface primary private ip address for single Thunder deployment and seconday private ip address of data-in interface for multiple Thunder deployment.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id="ip_address"
                                        name="ip_address"
                                        placeholder="10.0.2.11"
                                        type="text"
                                        value={fields["ip_address"]}
                                        valid={isIpValid(fields["ip_address"])}
                                        invalid={!isIpValid(fields["ip_address"])}
                                        onChange={(e) => handleChange(e, idx)}
                                    />
                                </Col>
                            </FormGroup>
                            {fields["port_list"].map((item, pidx) => {
                                return <div key={`${idx}_${pidx}`}>
                                    <div className="d-flex justify-content-end">
                                        {pidx > 0 && <Button className="mb-1" size="sm" disabled={pidx === 0} onClick={() => handleDeleteVirtualPort(idx, pidx)}><FaTrashAlt /></Button>}
                                    </div>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`port_number-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Port`}
                                            <span
                                                id={`vserver-pn-${idx}_${pidx}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`vserver-pn-${idx}_${pidx}`}>
                                                Specify vitual port number for virtual server "{fields["name"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`port_number-${pidx + 1}`}
                                                name="port_number"
                                                placeholder="443"
                                                type="number"
                                                min="0"
                                                value={item["port_number"]}
                                                valid={item["port_number"]}
                                                invalid={!item["port_number"]}
                                                onChange={(e) => handleVirtualPortChange(e, idx, pidx)}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`protocol-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Protocol`}
                                            <span
                                                id={`vserver-protocol-${idx}_${pidx}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`vserver-protocol-${idx}_${pidx}`}>
                                                Select protocol for virtual port "{item["port_number"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`protocol-${pidx + 1}`}
                                                name="protocol"
                                                type="select"
                                                value={item["protocol"]}
                                                onChange={(e) => handleVirtualPortChange(e, idx, pidx)}
                                            >
                                                <option >tcp</option>
                                                <option >udp</option>
                                                <option >http</option>
                                                <option >https</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`ha_conn_mirror-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`HA-Conn-Mirror`}
                                            <span
                                                id={`vserver-ha-conn-${idx}_${pidx}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`vserver-ha-conn-${idx}_${pidx}`}>
                                                Select enable or disable for Thunders in HA synchronization.<br /> Default value is disable.<br />It can be enable for "tcp" and "udp" protocol.
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`ha_conn_mirror-${pidx + 1}`}
                                                name="ha_conn_mirror"
                                                type="select"
                                                disabled={item["protocol"] !== "udp" && item["protocol"] !== "tcp"}
                                                value={item["ha_conn_mirror"] === 1 ? "enable" : "disable"}
                                                onChange={(e) => handleVirtualPortChange(e, idx, pidx)}
                                            >
                                                <option >enable</option>
                                                <option >disable</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`auto-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Auto NAT (vPort)`}
                                            <span
                                                id={`vserver-auto-conn-${idx}_${pidx}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`vserver-auto-conn-${idx}_${pidx}`}>
                                                Select enable/disable for auto NAT configuration for virtual port "{item["port_number"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`auto-${pidx + 1}`}
                                                name="auto"
                                                type="select"
                                                value={item["auto"] === 1 ? "enable" : "disable"}
                                                onChange={(e) => handleVirtualPortChange(e, idx, pidx)}
                                            >
                                                <option >enable</option>
                                                <option >disable</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`service_group-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Service Group`}
                                            <span
                                                id={`vserver-sg-conn-${idx}_${pidx}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`vserver-sg-conn-${idx}_${pidx}`}>
                                                Select service group name to bind with virtual port "{item["port_number"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`service_group-${pidx + 1}`}
                                                name="service_group"
                                                type="select"
                                                value={item["service_group"]}
                                                valid={item["service_group"] !== -1}
                                                invalid={item["service_group"] === -1}
                                                onChange={(e) => handleVirtualPortChange(e, idx, pidx)}
                                            >
                                                <option selected hidden>Select</option>
                                                {formState["slb"]["configServiceGroup"].map((option, gidx) => {
                                                    return option["name"] && <option value={gidx} key={`${idx}_${gidx}`} >{option["name"]}</option>
                                                })}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`template_http-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Http Template`}
                                            <span
                                                id={`vserver-ht-${idx}_${pidx}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`vserver-ht-${idx}_${pidx}`}>
                                                Select HTTP template to be applied on virtual port "{item["port_number"]}". It can be selected only for "http" & "https" protocol.
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`template_http-${pidx + 1}`}
                                                name="template_http"
                                                type="select"
                                                disabled={item["protocol"] !== "http" && item["protocol"] !== "https"}
                                                value={item["template_http"]}
                                                onChange={(e) => handleVirtualPortChange(e, idx, pidx)}
                                            >
                                                <option selected hidden>none</option>
                                                {formState["slb"]["configHttp"].map((option, gidx) => {
                                                    return option["name"] && <option value={gidx} key={`${idx}_${gidx}`} >{option["name"]}</option>
                                                })}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label
                                            bsSize="sm"
                                            for={`support_http2-${pidx + 1}`}
                                            style={{ fontSize: "13px", textAlign: "end" }}
                                            sm={4}
                                        >
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Support HTTP 2`}
                                            <span
                                                id={`support_http2-${idx}_${pidx}`}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`support_http2-${idx}_${pidx}`}>
                                                SLB virtual server http2 support. It can be enabled only for "http" & "https" protocol.
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`support_http2-${pidx + 1}`}
                                                name="support_http2"
                                                type="select"
                                                value={item["support_http2"]}
                                                disabled={!(item["protocol"] === "http" || item["protocol"] === "https")}
                                                onChange={(e) => handleVirtualPortChange(e, idx, pidx)}
                                            >
                                                <option value={1}>enable</option>
                                                <option value={0} >disable</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <div className="d-flex justify-content-end">
                                        {pidx === formState["slb"]["configVirtualServer"][idx]["port_list"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddVirtualPort(idx)}>Add Virtual Port</Button>}
                                    </div>
                                </div>
                            })
                            }
                        </Card>
                    })}
                </CardColumns>
            </AccordionBody >
        </AccordionItem>
    )
}

export default ConfigVirtualServer
