import React from 'react';
import { Card, Col, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric, isIpValid } from "../../../../utils/validations";

const ConfigvSvC = ({ formState, setFormState }) => {

    const handleChange = (e) => {
        var updatedFormState = { ...formState }
        updatedFormState["thunderADC"]["vmware"] = { ...updatedFormState["thunderADC"]["vmware"], [e.target.name]: e.target.value }
        setFormState(updatedFormState)
    }
    return (
        <Card body color="light" className="mt-1">
            <FormGroup row >
                <Label bsSize="sm" for="esxi_host" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    ESXi Host
                    <span
                        id={`esxi_host-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`esxi_host-t`}>
                        {`Choose ESXi IP address or FQDN connected to vSphere. Check connected ESXi VMs in vSphere vCenter -> Shortcuts -> Host and Clusters.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="esxi_host"
                        name="esxi_host"
                        placeholder="127.0.0.1"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"]["esxi_host"]}
                        valid={isIpValid(formState["thunderADC"]["vmware"]["esxi_host"])}
                        invalid={!isIpValid(formState["thunderADC"]["vmware"]["esxi_host"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="vsphere_vcenter_server" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    vCenter Host
                    <span
                        id={`vsphere_vcenter_server-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`vsphere_vcenter_server-t`}>
                        Specify vSphere vCenter IP address or FQDN connected to vSphere.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="vsphere_vcenter_server"
                        name="vsphere_vcenter_server"
                        placeholder="127.0.0.1"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"]["vsphere_vcenter_server"]}
                        valid={isIpValid(formState["thunderADC"]["vmware"]["vsphere_vcenter_server"])}
                        invalid={!isIpValid(formState["thunderADC"]["vmware"]["vsphere_vcenter_server"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="vsphere_user" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    vCenter Username
                    <span
                        id={`vsphere_user-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`vsphere_user-t`}>
                        Specify login username of vSphere vCenter.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="vsphere_user"
                        name="vsphere_user"
                        placeholder="administrator@vsphere.local"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"]["vsphere_user"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_user"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_user"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            {/* <FormGroup row >
                <Label bsSize="sm" for="password" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    vCenter Password
                    <span
                        id={`password-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`password-t`}>
                        Specify login password of vSphere vCenter.
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="password"
                        name="password"
                        placeholder="<password>"
                        disabled
                        type="text"
                        value={formState["thunderADC"]["vmware"]["password"]}
                    />
                </Col>
            </FormGroup> */}

            <FormGroup row >
                <Label bsSize="sm" for="vsphere_datacenter" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    vCenter DataCenter
                    <span
                        id={`vsphere_datacenter-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`vsphere_datacenter-t`}>
                        {`Choose data center. Check available data center in vSphere vCenter -> Shortcuts -> Hosts and Clusters`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="vsphere_datacenter"
                        name="vsphere_datacenter"
                        placeholder="us-east-1-dc1"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"]["vsphere_datacenter"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_datacenter"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_datacenter"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="vsphere_datastore" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    vCenter DataStore
                    <span
                        id={`vsphere_datastore-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`vsphere_datastore-t`}>
                        {`Choose datastore for creating storage. Check available data stores in vSphere vCenter -> Shortcuts -> Storage`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="vsphere_datastore"
                        name="vsphere_datastore"
                        placeholder="us-east-1-ds1"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"]["vsphere_datastore"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_datastore"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_datastore"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>

            <FormGroup row >
                <Label bsSize="sm" for="vsphere_cluster" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    vCenter Cluster
                    <span
                        id={`vsphere_cluster-t`}
                        style={{ fontSize: "14px" }}
                    >
                        <strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`vsphere_cluster-t`}>
                        {`Choose cluster in which VMs will get created. Check available clusters in vSphere vCenter -> Shortcuts -> Hosts and Clusters`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="vsphere_cluster"
                        name="vsphere_cluster"
                        placeholder="us-east-1-c1"
                        required={true}
                        type="text"
                        value={formState["thunderADC"]["vmware"]["vsphere_cluster"]}
                        valid={isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_cluster"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["vmware"]["vsphere_cluster"])}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
        </Card>
    )
}

export default ConfigvSvC
