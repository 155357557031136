import React from "react";
import { Col, Input, AccordionItem, AccordionHeader, AccordionBody, FormGroup, Card, Label, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric } from "../../../../utils/validations";
import Multiselect from 'multiselect-react-dropdown';


const GSLBPolicy = ({ formState, setFormState }) => {
    const handleChange = (e,) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"]["gslbpolicy"] = { ...newFormState["gslb"]["gslbSetting"]["gslbpolicy"], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }
    const handleMetricTypeSelect = (selectedList, selectedItem) => {
        let newFormState = { ...formState };
        newFormState["gslb"]["gslbSetting"]["gslbpolicy"]["metric_type"] = selectedList;
        setFormState(newFormState);
    };

    return (
        <AccordionItem>
        <AccordionHeader targetId="1_2_1">
            Configure GSLB Policy
        </AccordionHeader>
        <AccordionBody accordionId="1_2_1">
            <Card body color="light">
                <FormGroup row>
                    <Label bsSize="sm" for="name" sm={4}
                        style={{ fontSize: "13px", textAlign: "end" }}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Name
                        <span id="gslbzonename-t" style={{ fontSize: "14px" }}>
                            <strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target="gslbzonename-t">
                        Specify the unique name for GSLB policy. Default value is "a10".
                        </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                        <Input
                            bsSize="sm"
                            id="name"
                            name="name"
                            type="text"
                            placeholder="a10"
                            value={formState["gslb"]["gslbSetting"]["gslbpolicy"]["name"]}
                            valid={isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbpolicy"]["name"])}
                            invalid={!isAlphanumeric(formState["gslb"]["gslbSetting"]["gslbpolicy"]["name"])}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label bsSize="sm" for="metric_type" sm={4}
                        style={{ fontSize: "13px", textAlign: "end" }}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Metric Type
                        <span id="metric_type-t" style={{ fontSize: "14px" }}>
                            <strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target="metric_type-t">
                        Select metrics for GSLB policy.<br/>Metric order for choosing sites is left to right.
                        </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                        <Multiselect
                            isObject={false}
                            options={[
                                'health-check', 'weighted-ip', 'weighted-site', 'capacity', 
                                'active-servers', 'active-rdt', 'geographic', 'connection-load', 
                                'num-session', 'admin-preference', 'bw-cost', 'least-response', 'admin-ip'
                            ]}
                            onSelect={handleMetricTypeSelect}
                            onRemove={handleMetricTypeSelect}
                            selectedValues={formState["gslb"]["gslbSetting"]["gslbpolicy"]["metric_type"]}
                        />
                    </Col>
                </FormGroup>
            </Card>
        </AccordionBody>
    </AccordionItem>
    )

}
export default GSLBPolicy