import React from "react";
import { Col, Container, Row } from "reactstrap";
import CloudNav from "./CloudNav";
import CustomForm from "./CustomForms/CustomForm";
import Editor from "./Editor/Editor";
import HelmPanel from "./HelmPanel";

const RightPanel = ({
  config,
  cloud,
  setCloud,
  product,
  version_thunder,
  version_TOA,
  formState,
  setFormState,
  vThunderCount
}) => {

  return (
    <Container className="pt-0 ps-1 pe-0 mt-0" fluid>
      <Row className="m-0 pt-0">
        <CloudNav config={config} cloud={cloud} setCloud={setCloud} />
      </Row>
      {/* <Row>
        <Prerequisite cloud={cloud} product={product} />
      </Row> */}
      <Row className="me-1">
        <Col xs={12} sm={7}>
          <CustomForm
            formState={formState}
            setFormState={setFormState}
            config={config}
            product={product}
            version_thunder={version_thunder}
            version_TOA={version_TOA}
            cloud={cloud}
            vThunderCount={vThunderCount}
          />
        </Col>
        <Col xs={12} sm={5} className="">
          <Editor formState={formState} cloud={cloud} product={product} version_thunder={version_thunder} version_TOA={version_TOA} config={config} vThunderCount={vThunderCount} />
          <HelmPanel config={config} />
        </Col>
      </Row>
    </Container>
  );
};

export default RightPanel;
