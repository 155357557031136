import React, { useState } from "react"
import { AccordionBody, AccordionHeader, AccordionItem, Table, Input, UncontrolledTooltip, Button, UncontrolledAccordion } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa"
import { isIpValid, isAlphanumeric, isSubnetValid, isNotEmpty } from "../../../../utils/validations";
import GSLBSetting from "./GSLBSetting";
import SiteSettings from "./SiteSettings";
import SLBServer from "./ServerSLB";
import ServiceGroupSLB from "./ServiceGroupSLB";
import VirtualServerSLB from "./VirtualServerSLB";
import CreatableSelect from 'react-select/creatable';

const Master = ({ formState, setFormState, siteCount, setSiteCount }) => {
  const [multiValFields, setMultiValFields] = useState({
    "master_next_hop_ip": [...formState["gslb"]["master"]["masterController"]["master_next_hop_ip"]].map((value) => ({ value, label: value })),
    "site_next_hop_ip_0": [...formState["gslb"]["master"]["sites"][0]["site_next_hop_ip"]].map((value) => ({ value, label: value })),
    "site_next_hop_ip_1": [...formState["gslb"]["master"]["sites"][1]["site_next_hop_ip"]].map((value) => ({ value, label: value })),
  });
  const handleChange = (e, thunder) => {
    let newFormState = { ...formState }
    newFormState["gslb"]["master"][thunder] = { ...newFormState["gslb"]["master"][thunder], [e.target.name]: e.target.value }
    setFormState(newFormState)
  };
  const handleMultiValFieldChange = (selectedList, fieldName, thunder) => {
    const valuesArray = selectedList.map(item => item.value);

    let newMultiValFields = { ...multiValFields }
    newMultiValFields[fieldName] = selectedList
    console.log(fieldName, selectedList)
    setMultiValFields(newMultiValFields)

    let newFormState = { ...formState };
    newFormState["gslb"]["master"][thunder][fieldName] = valuesArray;
    setFormState(newFormState);
  };
  const handleMultiValFieldChangeSite = (selectedList, fieldName, index) => {
    const valuesArray = selectedList.map(item => item.value);

    let newMultiValFields = { ...multiValFields };
    newMultiValFields[fieldName + "_" + index] = selectedList;
    console.log(fieldName, selectedList);
    setMultiValFields(newMultiValFields);

    let newFormState = { ...formState };
    newFormState["gslb"]["master"]["sites"][index][fieldName] = valuesArray;
    setFormState(newFormState);
  };
  const handleAddSite = (index) => {
    setSiteCount(siteCount + 1);
    let newFormState = { ...formState };
    newFormState["gslb"]["master"]["sites"][index] = {
      "site_publicIPMgmt": "101.188.67.144",
      "site_username": `admin-${index + 1}`,
      "site_password": "<password>",
      "site_subnetMgmt": "10.0.1.0/24",
      "site_publicIPdata": "101.188.70.144",
      "site_subnetdata": "10.0.2.0/24",
      "site_privateIP": "10.0.2.6",
      "site_name": `site-${index + 1}`,
      "site_geolocation": "us-east",
      "site_destination_ip": "0.0.0.0",
      "site_destination_ip_mask": "/0",
      "site_next_hop_ip": "10.0.1.1"
    };
    newFormState["gslb"]["member"]["sites"][index] = {
      "site_publicIPMgmt": "101.188.67.144",
      "site_username": `admin-${index + 1}`,
      "site_password": "<password>",
      "site_subnetMgmt": "10.0.1.0/24",
      "site_publicIPdata": "101.188.70.144",
      "site_subnetdata": "10.0.2.0/24",
      "site_privateIP": "10.0.2.6",
      "site_name": `site-${index + 1}`,
      "site_geolocation": "us-east",
      "site_destination_ip": "0.0.0.0",
      "site_destination_ip_mask": "/0",
      "site_next_hop_ip": "10.0.1.1"
    };
    newFormState["gslb"]["gslbSetting"]["serviceIP_master"][index] = {
      "name": `serviceIP-${index + 1}`,
      "site_name": "site1",
      portList: [{ "portNumber": "80", "protocol": "tcp" }]
    }
    newFormState["gslb"]["gslbSetting"]["serviceIP_member"][index] = {
      "name": `serviceIP-${index + 1}`,
      "site_name": "site1",
      portList: [{ "portNumber": "80", "protocol": "tcp" }]
    }
    newFormState["gslb"]["master_site_setting"][index] = {
      server: [{
        "name": "server1",
        "host": "10.0.2.6",
        "health_check_disable": 0,
        port_list: [{
          "port_number": "80",
          "protocol": "tcp",
          "health_check_disable": 0,
        }]
      }],
      service_group: [{
        "name": "serviceGroup1",
        "protocol": "tcp",
        member_list: [{
          "server_name": "server1",
          "port": "80"
        }]
      }, {
        "name": "serviceGroup1",
        "protocol": "udp",
        member_list: [{
          "server_name": "server1",
          "port": "80"
        }]
      }],
      virtualServer: {
        "name": "virtualServer",
        "host": "10.0.2.5",
        port_list: [{
          "port_number": "80",
          "protocol": "tcp",
          "auto": 1,
          "service_group": "serviceGroup1"
        }]
      }
    }
    setFormState(newFormState);
  };
  const handleSiteChange = (e, index) => {
    let newFormState = { ...formState }
    newFormState["gslb"]["master"]["sites"][index] = { ...newFormState["gslb"]["master"]["sites"][index], [e.target.name]: e.target.value }
    setFormState(newFormState)
  };
  const handleDeleteSite = (siteIndex) => {
    setSiteCount((prevCount) => prevCount - 1);
    let newFormState = { ...formState };
    const newSites = [...newFormState["gslb"]["master"]["sites"]];
    newSites.splice(siteIndex, 1);
    newFormState["gslb"]["member"]["sites"].splice(siteIndex, 1)
    newFormState["gslb"]["gslbSetting"]["serviceIP_master"].splice(siteIndex, 1)
    newFormState["gslb"]["gslbSetting"]["serviceIP_member"].splice(siteIndex, 1)
    newFormState["gslb"]["master_site_setting"].splice(siteIndex, 1)
    newFormState["gslb"]["master"]["sites"] = newSites;
    setFormState(newFormState);
  };

  return (
    <AccordionItem>
      <AccordionHeader targetId="1">
        Configure Region1 (Active-Thunders)
      </AccordionHeader>
      <AccordionBody accordionId="1">

        <AccordionItem>
          <AccordionHeader targetId="1_1">
            Master Controller (Thunder-1)
          </AccordionHeader>
          <AccordionBody accordionId="1_1">
            {/* <h5>Master Controller </h5> */}
            <Table responsive bordered hover striped>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th style={{ fontSize: "14px", width: "40%" }}>Field Name</th>
                  <th style={{ fontSize: "14px", width: "60%" }}>Value</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr key="masterGeoRegion" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Region
                    <span id={`masterGeoRegion-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterGeoRegion-t`} placement="top">
                      Specify the geolocation region for the master controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="masterGeoRegion"
                      name="master_geo_region"
                      placeholder="us"
                      type="text"
                      value={formState['gslb']['master']['masterController']['master_geo_region']}
                      valid={isAlphanumeric(formState['gslb']['master']['masterController']['master_geo_region'])}
                      invalid={!isAlphanumeric(formState['gslb']['master']['masterController']['master_geo_region'])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                <tr key="masterGeoCity" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    City
                    <span id={`masterGeoCity-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterGeoCity-t`} placement="top">
                      Specify the geolocation city for the master controller in the region "{formState['gslb']['master']['masterController']['master_geo_region']}".
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="masterGeoCity"
                      name="master_geo_city"
                      placeholder="california"
                      type="text"
                      value={formState['gslb']['master']['masterController']['master_geo_city']}
                      valid={isAlphanumeric(formState['gslb']['master']['masterController']['master_geo_city'])}
                      invalid={!isAlphanumeric(formState['gslb']['master']['masterController']['master_geo_city'])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr> */}
                <tr key="masterPublicIPMgmt" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Public IP
                    <span id={`masterPublicIPMgmt-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`masterPublicIPMgmt-t`}>
                      Specify public IP of master controller Thunder to connect and apply configuration. IP should belong from management interface.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="masterPublicIPMgmt"
                      name="master_publicIPMgmt"
                      placeholder="53.0.0.1"
                      type="text"
                      value={formState["gslb"]["master"]["masterController"]["master_publicIPMgmt"]}
                      valid={isIpValid(formState["gslb"]["master"]["masterController"]["master_publicIPMgmt"])}
                      invalid={!isIpValid(formState["gslb"]["master"]["masterController"]["master_publicIPMgmt"])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                <tr key="masterUsername" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Username
                    <span id={`masterUsername-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`masterUsername-t`}>
                      Specify username of the Thunder to connect and apply configuration. Default value is 'admin'.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="masterUsername"
                      name="master_username"
                      placeholder="admin"
                      type="text"
                      value={formState["gslb"]["master"]["masterController"]["master_username"]}
                      valid={isAlphanumeric(formState["gslb"]["master"]["masterController"]["master_username"])}
                      invalid={!isAlphanumeric(formState["gslb"]["master"]["masterController"]["master_username"])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                {/* <tr key="masterPassword" style={{ height: "30px" }}>
              <td style={{ fontSize: "12px", padding: "2px" }}>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                Password
                <span id={`masterPassword-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                </span>
                <UncontrolledTooltip placement="top" target={`masterPassword-t`}>
                  Specify password to login.
                </UncontrolledTooltip>
              </td>
              <td style={{ padding: "2px" }}>
                <Input
                  bsSize="sm"
                  id="masterPassword"
                  name="master_password"
                  placeholder="<password>"
                  disabled
                  value={formState["gslb"]["master"]["masterController"]["master_password"]}
                />
              </td>
            </tr> */}
                {/* <tr key="masterSubnetMgmt" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Subnet-1 <span id={`masterSubnetMgmt-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`masterSubnetMgmt-t`}>
                      Specify CIDR block of management subnet in format a.b.c.d/n.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="masterSubnetMgmt"
                      name="master_subnetMgmt"
                      placeholder="10.0.0.1/24"
                      type="text"
                      value={formState["gslb"]["master"]["masterController"]["master_subnetMgmt"]}
                      valid={isSubnetValid(formState["gslb"]["master"]["masterController"]["master_subnetMgmt"])}
                      invalid={!isSubnetValid(formState["gslb"]["master"]["masterController"]["master_subnetMgmt"])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr> */}
                <tr key="masterPublicIPdata" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Public IP - Data In
                    <span id={`masterPublicIPdata-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`masterPublicIPdata-t`}>
                      Specify the secondary public IP address for data in interface.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="masterPublicIPdata"
                      name="master_publicIPdata"
                      placeholder="53.0.0.5"
                      type="text"
                      value={formState["gslb"]["master"]["masterController"]["master_publicIPdata"]}
                      valid={isIpValid(formState["gslb"]["master"]["masterController"]["master_publicIPdata"])}
                      invalid={!isIpValid(formState["gslb"]["master"]["masterController"]["master_publicIPdata"])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                {/* <tr key="masterSubnetdata" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Subnet-2 <span id={`masterSubnetdata-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`masterSubnetdata-t`}>
                      Specify CIDR block of data-in subnet in format a.b.c.d/n.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="masterSubnetdata"
                      name="master_subnetdata"
                      placeholder="10.0.1.0/24"
                      type="text"
                      value={formState["gslb"]["master"]["masterController"]["master_subnetdata"]}
                      valid={isSubnetValid(formState["gslb"]["master"]["masterController"]["master_subnetdata"])}
                      invalid={!isSubnetValid(formState["gslb"]["master"]["masterController"]["master_subnetdata"])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr> */}
                <tr key="masterPrivateIP" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Private IP
                    <span id={`masterPrivateIP-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`masterPrivateIP-t`}>
                      Specify the secondary private IP address of data-in subnet. This IP should be unique and is not assigned to any other member within the GSLB group.                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="masterPrivateIP"
                      name="master_privateIP"
                      placeholder="10.0.0.1"
                      type="text"
                      value={formState["gslb"]["master"]["masterController"]["master_privateIP"]}
                      valid={isIpValid(formState["gslb"]["master"]["masterController"]["master_privateIP"])}
                      invalid={!isIpValid(formState["gslb"]["master"]["masterController"]["master_privateIP"])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                <tr key="masterDns" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    DNS
                    <span id={`masterDns-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterDns-t`} placement="top">
                      Specify the primary DNS address for the master controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="masterDns"
                      name="master_dns"
                      placeholder="8.8.8.8"
                      type="text"
                      value={formState['gslb']['master']['masterController']['master_dns']}
                      valid={isIpValid(formState['gslb']['master']['masterController']['master_dns'])}
                      invalid={!isIpValid(formState['gslb']['master']['masterController']['master_dns'])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                <tr key="masterGroupName" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Group Name
                    <span id={`masterGroupName-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterGroupName-t`} placement="top">
                      Specify the GSLB group name for the master controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="masterGroupName"
                      name="master_group_name"
                      placeholder="group-1"
                      type="text"
                      value={formState['gslb']['master']['masterController']['master_group_name']}
                      valid={isAlphanumeric(formState['gslb']['master']['masterController']['master_group_name'])}
                      invalid={!isAlphanumeric(formState['gslb']['master']['masterController']['master_group_name'])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                <tr key="masterPriority" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Priority
                    <span id={`masterPriority-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterPriority-t`} placement="top">
                      {` Specify priority for the master controller.The priority ranges between 1 to 255. The priority of the master(active) controller is less than the member(standby) controller`}
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="masterPriority"
                      name="master_priority"
                      placeholder="255"
                      type="number"
                      disabled
                      value={formState['gslb']['master']['masterController']['master_priority']}
                    />
                  </td>
                </tr>
                <tr key="masterDestIP" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Destination IP
                    <span id={`masterDestIP-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterDestIP-t`} placement="top">
                      Specify the destination IP address for the master controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="masterDestIP"
                      name="master_destination_ip"
                      placeholder="0.0.0.0"
                      type="text"
                      value={formState['gslb']['master']['masterController']['master_destination_ip']}
                      valid={isIpValid(formState['gslb']['master']['masterController']['master_destination_ip'])}
                      invalid={!isIpValid(formState['gslb']['master']['masterController']['master_destination_ip'])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                <tr key="masterDestIPMask" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Destination IP Mask
                    <span id={`masterDestIPMask-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterDestIPMask-t`} placement="top">
                      Specify the destination IP address mask for the master controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="masterDestIPMask"
                      name="master_destination_ip_mask"
                      placeholder="/0"
                      type="text"
                      value={formState['gslb']['master']['masterController']['master_destination_ip_mask']}
                      valid={isAlphanumeric(formState['gslb']['master']['masterController']['master_destination_ip_mask'])}
                      invalid={!isAlphanumeric(formState['gslb']['master']['masterController']['master_destination_ip_mask'])}
                      onChange={(e) => handleChange(e, "masterController")}
                    />
                  </td>
                </tr>
                <tr key="masterNextHopIP" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Next Hop IP
                    <span id={`masterNextHopIP-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`masterNextHopIP-t`} placement="top">
                      Specify next hop IP address of management and data-in subnet. Next hop IP address is the first IP address of respective subnet.                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    {/* <Input bsSize="sm"
                      id="masterNextHopIP"
                      name="master_next_hop_ip"
                      placeholder="10.0.1.1"
                      type="text"
                      value={formState['gslb']['master']['masterController']['master_next_hop_ip']}
                      valid={isIpValid(formState['gslb']['master']['masterController']['master_next_hop_ip'])}
                      invalid={!isIpValid(formState['gslb']['master']['masterController']['master_next_hop_ip'])}
                      onChange={(e) => handleChange(e, "masterController")}
                    /> */}
                    <CreatableSelect
                      isMulti
                      isValidNewOption={(inputValue, selectValue, selectOptions) => {
                        return isIpValid(inputValue)
                      }}
                      placeholder="Provide multiple IP and press enter.."
                      value={multiValFields["master_next_hop_ip"]}
                      onChange={(selectedOption) => handleMultiValFieldChange(selectedOption, "master_next_hop_ip", "masterController")}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          minHeight: "35px",
                          fontSize: "14px",
                          borderColor: multiValFields["master_next_hop_ip"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                        }),
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <GSLBSetting formState={formState} setFormState={setFormState} siteCount={siteCount} />
          </AccordionBody>
        </AccordionItem>


        {[...Array(siteCount)].map((item, index) => (
          <AccordionItem key={index}>
            <AccordionHeader targetId={`1_${index + 3}`}>
              Site-{index + 1} (Thunder-{index + 2})
            </AccordionHeader>
            <AccordionBody accordionId={`1_${index + 3}`}>
              {/* <h5>Site-{index + 1}</h5> */}
              {/* <div className="d-flex justify-content-end">
                {index > 0 && <Button className='mb-1 ms-0' size="sm" disabled={index === 0} onClick={() => handleDeleteSite(index)}>
                  <FaTrashAlt />
                </Button>}
              </div> */}
              <Table responsive bordered hover striped>
                <thead>
                  <tr style={{ height: "30px" }}>
                    <th style={{ fontSize: "14px", width: "40%" }}>Field Name</th>
                    <th style={{ fontSize: "14px", width: "60%" }}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={`siteName${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Name
                      <span id={`siteName${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip target={`siteName${index}-t`} placement="top">
                        Specify the name for "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input bsSize="sm"
                        id={`siteName${index}`}
                        name="site_name"
                        placeholder="site"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_name"]}
                        valid={isAlphanumeric(formState["gslb"]["master"]["sites"][index]["site_name"])}
                        invalid={!isAlphanumeric(formState["gslb"]["master"]["sites"][index]["site_name"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  <tr key={`siteGeolocation${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Region
                      <span id={`siteGeolocation${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip target={`siteGeolocation${index}-t`} placement="top">
                        Specify the geolocation region for the "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input bsSize="sm"
                        id={`siteGeolocation${index}`}
                        name="site_geolocation"
                        placeholder="us-east"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_geolocation"]}
                        valid={isNotEmpty(formState["gslb"]["master"]["sites"][index]["site_geolocation"])}
                        invalid={!isNotEmpty(formState["gslb"]["master"]["sites"][index]["site_geolocation"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  <tr key={`sitePublicIPMgmt${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Public IP
                      <span id={`sitePublicIPMgmt${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`sitePublicIPMgmt${index}-t`}>
                        Specify the public managemet IP address for "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input
                        bsSize="sm"
                        id={`sitePublicIPMgmt${index}`}
                        name="site_publicIPMgmt"
                        placeholder="53.0.0.1"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_publicIPMgmt"]}
                        valid={isIpValid(formState["gslb"]["master"]["sites"][index]["site_publicIPMgmt"])}
                        invalid={!isIpValid(formState["gslb"]["master"]["sites"][index]["site_publicIPMgmt"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  <tr key={`siteUsername${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Username
                      <span id={`siteUsername${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`siteUsername${index}-t`}>
                        Specify the Thunder username for "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input
                        bsSize="sm"
                        id={`siteUsername${index}`}
                        name="site_username"
                        placeholder="admin"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_username"]}
                        valid={isAlphanumeric(formState["gslb"]["master"]["sites"][index]["site_username"])}
                        invalid={!isAlphanumeric(formState["gslb"]["master"]["sites"][index]["site_username"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  {/* <tr key={`sitePassword${index}`} style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Password
                    <span id={`sitePassword${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`sitePassword${index}-t`}>
                      Specify the Thunder password for "site {index + 1}".
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id={`sitePassword${index}`}
                      name="site_password"
                      placeholder="<password>"
                      type="text"
                      disabled
                      value={formState["gslb"]["master"]["sites"][index]["site_password"]}
                    />
                  </td>
                </tr> */}
                  {/* <tr key={`siteSubnetMgmt${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Subnet-1
                      <span id={`siteSubnetMgmt${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`siteSubnetMgmt${index}-t`}>
                        Specify CIDR block of management subnet in format a.b.c.d/n for "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input
                        bsSize="sm"
                        id={`siteSubnetMgmt${index}`}
                        name="site_subnetMgmt"
                        placeholder="10.0.0.1/24"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_subnetMgmt"]}
                        valid={isSubnetValid(formState["gslb"]["master"]["sites"][index]["site_subnetMgmt"])}
                        invalid={!isSubnetValid(formState["gslb"]["master"]["sites"][index]["site_subnetMgmt"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr> */}
                  <tr key={`sitePublicIPdata${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Public IP - Data In
                      <span id={`sitePublicIPdata${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`sitePublicIPdata${index}-t`}>
                        Specify the secondary public IP address for data in interface for "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input
                        bsSize="sm"
                        id={`sitePublicIPdata${index}`}
                        name="site_publicIPdata"
                        placeholder="53.0.0.5"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_publicIPdata"]}
                        valid={isIpValid(formState["gslb"]["master"]["sites"][index]["site_publicIPdata"])}
                        invalid={!isIpValid(formState["gslb"]["master"]["sites"][index]["site_publicIPdata"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  {/* <tr key={`siteSubnetdata${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Subnet-2
                      <span id={`siteSubnetdata${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`siteSubnetdata${index}-t`}>
                        Specify CIDR block of data-in subnet in format a.b.c.d/n for "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input
                        bsSize="sm"
                        id={`siteSubnetdata${index}`}
                        name="site_subnetdata"
                        placeholder="10.0.1.0/24"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_subnetdata"]}
                        valid={isSubnetValid(formState["gslb"]["master"]["sites"][index]["site_subnetdata"])}
                        invalid={!isSubnetValid(formState["gslb"]["master"]["sites"][index]["site_subnetdata"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr> */}
                  <tr key={`sitePrivateIP${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Private IP
                      <span id={`sitePrivateIP${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                      </span>
                      <UncontrolledTooltip placement="top" target={`sitePrivateIP${index}-t`}>
                        Specify the secondary private IP address of data-in subnet. This IP should be unique and is not assigned to any other member within the GSLB group.
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input
                        bsSize="sm"
                        id={`sitePrivateIP${index}`}
                        name="site_privateIP"
                        placeholder="10.0.0.1"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_privateIP"]}
                        valid={isIpValid(formState["gslb"]["master"]["sites"][index]["site_privateIP"])}
                        invalid={!isIpValid(formState["gslb"]["master"]["sites"][index]["site_privateIP"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  <tr key={`siteDestIP${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Destination IP
                      <span id={`siteDestIP${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip target={`siteDestIP${index}-t`} placement="top">
                        Specify the destination IP address for the "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input bsSize="sm"
                        id={`siteDestIP${index}`}
                        name="site_destination_ip"
                        placeholder="0.0.0.0"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_destination_ip"]}
                        valid={isIpValid(formState["gslb"]["master"]["sites"][index]["site_destination_ip"])}
                        invalid={!isIpValid(formState["gslb"]["master"]["sites"][index]["site_destination_ip"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  <tr key={`siteDestIPMask${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Destination IP Mask
                      <span id={`siteDestIPMask${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip target={`siteDestIPMask${index}-t`} placement="top">
                        Specify the destination IP address mask for the "site {index + 1}".
                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      <Input bsSize="sm"
                        id={`siteDestIPMask${index}`}
                        name="site_destination_ip_mask"
                        placeholder="/0"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_destination_ip_mask"]}
                        valid={isAlphanumeric(formState["gslb"]["master"]["sites"][index]["site_destination_ip_mask"])}
                        invalid={!isAlphanumeric(formState["gslb"]["master"]["sites"][index]["site_destination_ip_mask"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      />
                    </td>
                  </tr>
                  <tr key={`siteNextHopIP${index}`} style={{ height: "30px" }}>
                    <td style={{ fontSize: "12px", padding: "2px" }}>
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      Next Hop IP
                      <span id={`siteNextHopIP${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip target={`siteNextHopIP${index}-t`} placement="top">
                        Specify next hop IP address of management and data-in subnet. Next hop IP address is the first IP address of respective subnet.                      </UncontrolledTooltip>
                    </td>
                    <td style={{ padding: "2px" }}>
                      {/* <Input bsSize="sm"
                        id={`siteNextHopIP${index}`}
                        name="site_next_hop_ip"
                        placeholder="10.0.1.1"
                        type="text"
                        value={formState["gslb"]["master"]["sites"][index]["site_next_hop_ip"]}
                        valid={isIpValid(formState["gslb"]["master"]["sites"][index]["site_next_hop_ip"])}
                        invalid={!isIpValid(formState["gslb"]["master"]["sites"][index]["site_next_hop_ip"])}
                        onChange={(e) => handleSiteChange(e, index)}
                      /> */}
                      <CreatableSelect
                        isMulti
                        isValidNewOption={(inputValue, selectValue, selectOptions) => {
                          return isIpValid(inputValue)
                        }}
                        placeholder="Provide multiple IP and press enter.."
                        value={multiValFields[`site_next_hop_ip_${index}`]}
                        onChange={(selectedOption) => handleMultiValFieldChangeSite(selectedOption, "site_next_hop_ip", index)}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            minHeight: "35px",
                            fontSize: "14px",
                            borderColor: multiValFields[`site_next_hop_ip_${index}`].length > 0 ? "#198754 !important" : "#dc3545 !important"
                          }),
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>

              <UncontrolledAccordion stayOpen>
                <AccordionItem>
                  <AccordionHeader targetId={`1_${index + 3}_1`}>
                    Configure Site Settings
                  </AccordionHeader>
                  <AccordionBody accordionId={`1_${index + 3}_1`}>
                    <SLBServer formState={formState} setFormState={setFormState} siteIndex={index} region={"master_site_setting"} />
                    <ServiceGroupSLB formState={formState} setFormState={setFormState} siteIndex={index} region={"master_site_setting"} />
                    <VirtualServerSLB formState={formState} setFormState={setFormState} siteIndex={index} region={"master_site_setting"} />
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>

            </AccordionBody>
          </AccordionItem>
        ))}
        {/* <div className="d-flex justify-content-end">
          <Button className="m-1" size="sm" onClick={() => handleAddSite(siteCount)}>Add Site</Button>
        </div> */}
      </AccordionBody>
    </AccordionItem>
  )
}
export default Master