import React from 'react'

const GSLBEditor = ({ formState }) => {
    return (
        <div >
            <code className="d-block" style={{ fontSize: "13px" }}>Configure_Region_1:</code>
            <code className="d-block" style={{ fontSize: "13px" }}>&nbsp; &nbsp;Master_Controller:</code>
            <div key="master_controller">
                {/* {formState["gslb"]["master"]["masterController"]["master_geo_region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_geo_region"]}</span>
                </code>}
                {formState["gslb"]["master"]["masterController"]["master_geo_city"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tcity: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_geo_city"]}</span>
                </code>} */}
                {formState["gslb"]["master"]["masterController"]["master_publicIPMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpublic_ip_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_publicIPMgmt"]}</span>
                </code>}
                {formState["gslb"]["master"]["masterController"]["master_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tusername: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_username"]}</span>
                </code>}
                {/* {formState["gslb"]["master"]["masterController"]["master_password"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpassword: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_password"]}</span>
                </code>} */}
                {/* {formState["gslb"]["master"]["masterController"]["master_subnetMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tsubnet_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_subnetMgmt"]}</span>
                </code>} */}
                {formState["gslb"]["master"]["masterController"]["master_publicIPdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpublic_ip_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_publicIPdata"]}</span>
                </code>}
                {/* {formState["gslb"]["master"]["masterController"]["master_subnetdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tsubnet_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_subnetdata"]}</span>
                </code>} */}
                {formState["gslb"]["master"]["masterController"]["master_privateIP"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tprivate_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_privateIP"]}</span>
                </code>}
                {formState["gslb"]["master"]["masterController"]["master_dns"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdns: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_dns"]}</span>
                </code>}
                {formState["gslb"]["master"]["masterController"]["master_group_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tgroup_name: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_group_name"]}</span>
                </code>}
                {formState["gslb"]["master"]["masterController"]["master_priority"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpriority: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_priority"]}</span>
                </code>}
                {formState["gslb"]["master"]["masterController"]["master_destination_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdestination_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_destination_ip"]}</span>
                </code>}
                {formState["gslb"]["master"]["masterController"]["master_destination_ip_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdestination_ip_mask: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_destination_ip_mask"]}</span>
                </code>}
                {/* {formState["gslb"]["master"]["masterController"]["master_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tnext_hop_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["masterController"]["master_next_hop_ip"]}</span>
                </code>} */}
                {formState["gslb"]["master"]["masterController"]["master_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_next_hop`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                {formState["gslb"]["master"]["masterController"]["master_next_hop_ip"].map((ele, idx) => {
                  return <>{idx > 0 && ", "}{ele}</>
                })}
                ]</span>
            </code>}
            </div>
            <br />
            <div className="gslbSetting">
                <code className="d-block" style={{ fontSize: "13px" }}>&nbsp; &nbsp;GSLB_Settings:</code>
                <div className='GSLBPolicy'>
                    {(formState["gslb"]["gslbSetting"]["gslbpolicy"]["name"] || formState["gslb"]["gslbSetting"]["gslbpolicy"]['metric_type']) &&
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tGSLB_Policy:`}
                        </code>}
                    {formState["gslb"]["gslbSetting"]["gslbpolicy"]['name'] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["gslbSetting"]["gslbpolicy"]["name"]}</span>
                    </code>}
                    {formState["gslb"]["gslbSetting"]["gslbpolicy"]['metric_type'] && (
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tmetric_type`}:{" "}
                        <span style={{ color: "rgb(171, 227, 56)" }}>
                            {formState["gslb"]["gslbSetting"]["gslbpolicy"]['metric_type'].map((tag, index) => (
                                <span key={index}>
                                    {tag}
                                    {index < formState["gslb"]["gslbSetting"]["gslbpolicy"]['metric_type'].length - 1 ? ", " : ""}
                                </span>
                            ))}
                        </span>
                    </code>
                )}
                </div>
                <br />
                <div className='GSLBZone'>
                    {(formState["gslb"]["gslbSetting"]["gslbzone"]["name"] || formState["gslb"]["gslbSetting"]["gslbzone"]['servicePort'] || formState["gslb"]["gslbSetting"]["gslbzone"]['serviceName']) &&
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tGSLB_Zone:`}
                        </code>}
                    {formState["gslb"]["gslbSetting"]["gslbzone"]['name'] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["gslbSetting"]["gslbzone"]["name"]}</span>
                    </code>}
                    {formState["gslb"]["gslbSetting"]["gslbpolicy"]['name'] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tpolicy: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["gslbSetting"]["gslbpolicy"]["name"]}</span>
                    </code>}
                    {formState["gslb"]["gslbSetting"]["gslbzone"]['servicePort'] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tservice_port: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["gslbSetting"]["gslbzone"]["servicePort"]}</span>
                    </code>}
                    {formState["gslb"]["gslbSetting"]["gslbzone"]['serviceName'] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tservice_name: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["gslbSetting"]["gslbzone"]["serviceName"]}</span>
                    </code>}
                </div>
                <br />
                <div className='virtualServer'>
                    {(formState["gslb"]["gslbSetting"]["virtualServer"]["name"] || formState["gslb"]["gslbSetting"]["virtualServer"]['portList'][0]["portNumber"] || formState["gslb"]["gslbSetting"]["virtualServer"]['portList'][0]["protocol"]) &&
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tVirtual_Server:`}
                        </code>}
                    {formState["gslb"]["gslbSetting"]["virtualServer"]["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["gslbSetting"]["virtualServer"]["name"]}</span>
                    </code>}
                    {formState["gslb"]["gslbSetting"]["virtualServer"]["mConPrivateIPData"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tmaster_controller_private_ip_data: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState['gslb']['master']['masterController']['master_privateIP']}</span>
                    </code>}
                    {(formState["gslb"]["gslbSetting"]["virtualServer"]["portList"][0]["portNumber"] || formState["gslb"]["gslbSetting"]["virtualServer"]["portList"][0]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tportList:`}
                    </code>}
                    {formState["gslb"]["gslbSetting"]["virtualServer"]["portList"].map((portfield, pidx) => {
                        return (
                            <div key={`${pidx}`}>
                                {portfield["portNumber"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["portNumber"]}</span>
                                </code>}
                                {portfield["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["protocol"]}</span>
                                </code>}
                            </div>
                        )
                    })}
                </div>
                <br />
                <div className="serviceIP">
                    {formState["gslb"]["gslbSetting"]["serviceIP_master"].map((item, idx) => {
                        return (
                            <div key={idx}>
                                {(item["name"] || item["site_name"] || item["portList"]["portNumber"] || item["portList"]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\tService_IP_${idx + 1}:`}
                                </code>}
                                {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                                </code>}
                                {item["site_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tsite: `}<span style={{ color: "rgb(171, 227, 56)" }}>{`${formState["gslb"]["master"]["sites"][idx]["site_name"]}_${formState["gslb"]["master"]["sites"][idx]["site_privateIP"]}`}</span>
                                </code>}
                                {(item["portList"][0]["portNumber"] || item["portList"][0]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tportList:`}
                                </code>}
                                {item["portList"].map((portfield, pidx) => {
                                    return (
                                        <div key={`${idx}_${pidx}`}>
                                            {portfield["portNumber"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["portNumber"]}</span>
                                            </code>}
                                            {portfield["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["protocol"]}</span>
                                            </code>}
                                        </div>
                                    )
                                })}
                                <br />
                            </div>
                        )
                    })}
                </div>
            </div>
            <br />
            {formState["gslb"]["master"]["sites"].map((item, idx) => {
                return (
                    <div key={idx}>
                        {<code className="d-block" style={{ fontSize: "13px" }}>
                            &nbsp; &nbsp;Site_{idx + 1}:
                        </code>}
                        {item["site_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_name"]}</span>
                        </code>}
                        {item["site_geolocation"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tgeolocation: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_geolocation"]}</span>
                        </code>}
                        {item["site_publicIPMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tpublic_ip_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_publicIPMgmt"]}</span>
                        </code>}
                        {item["site_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tusername: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_username"]}</span>
                        </code>}
                        {/* {item["site_password"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tpassword: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_password"]}</span>
                        </code>} */}
                        {/* {item["site_subnetMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tsubnet_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_subnetMgmt"]}</span>
                        </code>} */}
                        {item["site_publicIPdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tpublic_ip_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_publicIPdata"]}</span>
                        </code>}
                        {/* {item["site_subnetdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tsubnet_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_subnetdata"]}</span>
                        </code>} */}
                        {item["site_privateIP"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tprivate_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_privateIP"]}</span>
                        </code>}
                        {item["site_destination_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tdestination_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_destination_ip"]}</span>
                        </code>}
                        {item["site_destination_ip_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tdestination_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_destination_ip_mask"]}</span>
                        </code>}
                        {/* {item["site_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tnext_hop_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_next_hop_ip"]}</span>
                        </code>} */}
                        {item["site_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_next_hop`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                {item["site_next_hop_ip"].map((ele, idx) => {
                  return <>{idx > 0 && ", "}{ele}</>
                })}
                ]</span>
            </code>}
                        <br />
                        <div className="sitesSetting">
                            <code className="d-block" style={{ fontSize: "13px" }}>{`\tSite_Settings:`}</code>
                            <div className='slbServer'>
                                {formState['gslb']['master_site_setting'][idx]['server'].map((item, sidx) => {
                                    return (
                                        <div key={`${sidx}`}>
                                            {(item["name"] || item["host"] || item["port_list"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                                <code className="d-block" style={{ fontSize: "13px" }}>{`\t`}&nbsp;&nbsp;SLB_Server_{sidx + 1}:</code>
                                            </code>}
                                            {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                                            </code>}
                                            {item["host"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\thost: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["host"]}</span>
                                            </code>}
                                            <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\thealth_check_disable: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["health_check_disable"]}</span>
                                            </code>
                                            {(item["port_list"][0]["port_number"] || item["port_list"][0]["protocol"] || item["port_list"][0]["health_check_disable"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tport_list:`}
                                            </code>}
                                            {item["port_list"].map((serverPort, pidx) => {
                                                return (
                                                    <div key={`${sidx}_${pidx}`}>
                                                        {serverPort["port_number"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{serverPort["port_number"]}</span>
                                                        </code>}
                                                        {serverPort["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{serverPort["protocol"]}</span>
                                                        </code>}
                                                        {serverPort["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\thealth_check_disable: `}<span style={{ color: "rgb(171, 227, 56)" }}>{serverPort["health_check_disable"]}</span>
                                                        </code>}
                                                    </div>
                                                )
                                            })}</div>
                                    )
                                })}
                                <br />
                            </div>
                            <div className='serviceGroup'>
                                {formState['gslb']['master_site_setting'][idx]['service_group'].map((item, sidx) => {
                                    return (
                                        <div key={`${sidx}`}>
                                            {(item["name"] || item["protocol"] || item["member_list"][0]["port"]) &&
                                                <code className="d-block" style={{ fontSize: "13px" }}>{`\t`}&nbsp;&nbsp;Service_Group_{sidx + 1}:</code>
                                            }
                                            {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                                            </code>}
                                            {item["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["protocol"]}</span>
                                            </code>}
                                            <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tmember_list:`}
                                            </code>
                                            {item["member_list"].map((memberField, pidx) => {
                                                return (
                                                    <div key={`${sidx}_${pidx}`}>
                                                        {memberField["server_name"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tserver_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master_site_setting"][idx]["server"][memberField["server_name"]]["name"]}</span>
                                                        </code>}
                                                        {memberField["port"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tport`}: <span style={{ color: "rgb(171, 227, 56)" }}>{memberField["port"]}</span>
                                                        </code>}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                                <br />
                            </div>
                            <div className='virtualServer'>
                                {<code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t`} &nbsp;&nbsp;{`Virtual_Server`}:
                                </code>}
                                {formState["gslb"]["master_site_setting"][idx]["virtualServer"]["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master_site_setting"][idx]["virtualServer"]["name"]}</span>
                                </code>}
                                {formState["gslb"]["master_site_setting"][idx]["virtualServer"]["host"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\thost: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["sites"][idx]["site_privateIP"]}</span>
                                </code>}
                                {<code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tport_list:`}
                                </code>}
                                {formState["gslb"]["master_site_setting"][idx]["virtualServer"]["port_list"].map((portfield, pidx) => {
                                    return (
                                        <div key={pidx}>
                                            {portfield["port_number"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["port_number"]}</span>
                                            </code>}
                                            {portfield["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["protocol"]}</span>
                                            </code>}
                                            { <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tauto_nat: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["auto"]}</span>
                                            </code>}
                                            {portfield["service_group"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tservice_group: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master_site_setting"][idx]["service_group"][portfield["service_group"]]["name"]}</span>
                                            </code>}
                                        </div>
                                    )
                                })}
                                <br />
                            </div>
                        </div>
                    </div>
                )
            })}
            <code className="d-block" style={{ fontSize: "13px" }}>&nbsp; &nbsp;Member_Controller</code>
            <div key="member_controller">
                {/* {formState["gslb"]["member"]["memberController"]["member_geo_region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_geo_region"]}</span>
                </code>}
                {formState["gslb"]["member"]["memberController"]["member_geo_city"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tcity: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_geo_city"]}</span>
                </code>} */}
                {formState["gslb"]["member"]["memberController"]["member_publicIPMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpublic_ip_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_publicIPMgmt"]}</span>
                </code>}
                {formState["gslb"]["member"]["memberController"]["member_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tusername: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_username"]}</span>
                </code>}
                {/* {formState["gslb"]["member"]["memberController"]["member_password"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpassword: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_password"]}</span>
                </code>} */}
                {/* {formState["gslb"]["member"]["memberController"]["member_subnetMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tsubnet_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_subnetMgmt"]}</span>
                </code>} */}
                {formState["gslb"]["member"]["memberController"]["member_publicIPdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpublic_ip_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_publicIPdata"]}</span>
                </code>}
                {/* {formState["gslb"]["member"]["memberController"]["member_subnetdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tsubnet_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_subnetdata"]}</span>
                </code>} */}
                {formState["gslb"]["member"]["memberController"]["member_privateIP"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tprivate_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_privateIP"]}</span>
                </code>}
                {formState["gslb"]["member"]["memberController"]["member_dns"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdns: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_dns"]}</span>
                </code>}
                {formState["gslb"]["member"]["memberController"]["member_group_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tgroup_name: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_group_name"]}</span>
                </code>}
                {formState["gslb"]["member"]["memberController"]["member_priority"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tpriority: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_priority"]}</span>
                </code>}
                {formState["gslb"]["member"]["memberController"]["member_destination_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdestination_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_destination_ip"]}</span>
                </code>}
                {formState["gslb"]["member"]["memberController"]["member_destination_ip_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdestination_ip_mask: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_destination_ip_mask"]}</span>
                </code>}
                {/* {formState["gslb"]["member"]["memberController"]["member_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tnext_hop_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member"]["memberController"]["member_next_hop_ip"]}</span>
                </code>} */}
                {formState["gslb"]["member"]["memberController"]["member_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_next_hop`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                {formState["gslb"]["member"]["memberController"]["member_next_hop_ip"].map((ele, idx) => {
                  return <>{idx > 0 && ", "}{ele}</>
                })}
                ]</span>
            </code>}
            </div>
            <br />
            <div className="gslbSetting">
                <code className="d-block" style={{ fontSize: "13px" }}>&nbsp; &nbsp;GSLB_Settings:</code>
                <div className='virtualServer_member'>
                    {(formState["gslb"]["gslbSetting"]["virtualServer_member"]["name"] || formState["gslb"]["gslbSetting"]["virtualServer_member"]['portList'][0]["portNumber"] || formState["gslb"]["gslbSetting"]["virtualServer_member"]['portList'][0]["protocol"]) &&
                        <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tVirtual_Server:`}
                        </code>}
                    {formState["gslb"]["gslbSetting"]["virtualServer_member"]["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["gslbSetting"]["virtualServer_member"]["name"]}</span>
                    </code>}
                    {formState["gslb"]["gslbSetting"]["virtualServer_member"]["mConPrivateIPData"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tmember_controller_private_ip_data: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState['gslb']['member']['memberController']['member_privateIP']}</span>
                    </code>}
                    {(formState["gslb"]["gslbSetting"]["virtualServer_member"]["portList"][0]["portNumber"] || formState["gslb"]["gslbSetting"]["virtualServer_member"]["portList"][0]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tportList:`}
                    </code>}
                    {formState["gslb"]["gslbSetting"]["virtualServer_member"]["portList"].map((portfield, pidx) => {
                        return (
                            <div key={`${pidx}`}>
                                {portfield["portNumber"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["portNumber"]}</span>
                                </code>}
                                {portfield["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["protocol"]}</span>
                                </code>}
                            </div>
                        )
                    })}
                </div>
                <br />
                <div className="serviceIP">
                    {formState["gslb"]["gslbSetting"]["serviceIP_member"].map((item, idx) => {
                        return (
                            <div key={idx}>
                                {(item["name"] || item["site_name"] || item["portList"]["portNumber"] || item["portList"]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\tService_IP_${idx + 1}:`}
                                </code>}
                                {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                                </code>}
                                {item["site_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tsite: `}<span style={{ color: "rgb(171, 227, 56)" }}>{`${formState["gslb"]["member"]["sites"][idx]["site_name"]}_${formState["gslb"]["member"]["sites"][idx]["site_privateIP"]}`}</span>
                                </code>}
                                {(item["portList"][0]["portNumber"] || item["portList"][0]["protocol"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tportList:`}
                                </code>}
                                {item["portList"].map((portfield, pidx) => {
                                    return (
                                        <div key={`${idx}_${pidx}`}>
                                            {portfield["portNumber"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["portNumber"]}</span>
                                            </code>}
                                            {portfield["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["protocol"]}</span>
                                            </code>}
                                        </div>
                                    )
                                })}
                                <br />
                            </div>
                        )
                    })}
                </div>
            </div>
            {formState["gslb"]["member"]["sites"].map((item, idx) => {
                return (
                    <div key={idx}>
                        {(item["site_name"] || item["site_geolocation"] || item["site_publicIPMgmt"] || item["site_username"] || item["site_password"]
                            || item["site_subnetMgmt"] || item["site_publicIPdata"] || item["site_subnetdata"] || item["site_privateIP"]
                            || item["site_destination_ip"] || item["site_destination_ip_mask"] || item["site_next_hop_ip"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                &nbsp; &nbsp;Site_{idx + 1}:
                            </code>}
                        {item["site_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_name"]}</span>
                        </code>}
                        {item["site_geolocation"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tgeolocation: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_geolocation"]}</span>
                        </code>}
                        {item["site_publicIPMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tpublic_ip_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_publicIPMgmt"]}</span>
                        </code>}
                        {item["site_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tusername: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_username"]}</span>
                        </code>}
                        {/* {item["site_password"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tpassword: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_password"]}</span>
                        </code>} */}
                        {item["site_subnetMgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tsubnet_management: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_subnetMgmt"]}</span>
                        </code>}
                        {item["site_publicIPdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tpublic_ip_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_publicIPdata"]}</span>
                        </code>}
                        {item["site_subnetdata"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tsubnet_data_in: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_subnetdata"]}</span>
                        </code>}
                        {item["site_privateIP"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tprivate_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_privateIP"]}</span>
                        </code>}
                        {item["site_destination_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tdestination_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_destination_ip"]}</span>
                        </code>}
                        {item["site_destination_ip_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tdestination_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_destination_ip_mask"]}</span>
                        </code>}
                        {/* {item["site_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                            {`\tnext_hop_ip: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["site_next_hop_ip"]}</span>
                        </code>} */}
                        {item["site_next_hop_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_next_hop`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                {item["site_next_hop_ip"].map((ele, idx) => {
                  return <>{idx > 0 && ", "}{ele}</>
                })}
                ]</span>
            </code>}
                        <br />
                        <div className="sitesSetting">
                            <code className="d-block" style={{ fontSize: "13px" }}>{`\tSite_Settings:`}</code>
                            <div className='slbServer'>
                                {formState['gslb']['member_site_setting'][idx]['server'].map((item, sidx) => {
                                    return (
                                        <div key={`${sidx}`}>
                                            {(item["name"] || item["host"] || item["port_list"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                                <code className="d-block" style={{ fontSize: "13px" }}>{`\t`}&nbsp;&nbsp;SLB_Server_{sidx + 1}:</code>
                                            </code>}
                                            {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                                            </code>}
                                            {item["host"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\thost: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["host"]}</span>
                                            </code>}
                                            <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\thealth_check_disable: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["health_check_disable"]}</span>
                                            </code>
                                            {(item["port_list"][0]["port_number"] || item["port_list"][0]["protocol"] || item["port_list"][0]["health_check_disable"]) && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tport_list:`}
                                            </code>}
                                            {item["port_list"].map((serverPort, pidx) => {
                                                return (
                                                    <div key={`${sidx}_${pidx}`}>
                                                        {serverPort["port_number"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{serverPort["port_number"]}</span>
                                                        </code>}
                                                        {serverPort["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{serverPort["protocol"]}</span>
                                                        </code>}
                                                        {serverPort["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\thealth_check_disable: `}<span style={{ color: "rgb(171, 227, 56)" }}>{serverPort["health_check_disable"]}</span>
                                                        </code>}
                                                    </div>
                                                )
                                            })}</div>
                                    )
                                })}
                                <br />
                            </div>
                            <div className='serviceGroup'>
                                {formState['gslb']['member_site_setting'][idx]['service_group'].map((item, sidx) => {
                                    return (
                                        <div key={`${sidx}`}>
                                            {(item["name"] || item["protocol"] || item["member_list"][0]["port"]) &&
                                                <code className="d-block" style={{ fontSize: "13px" }}>{`\t`}&nbsp;&nbsp;Service_Group_{sidx + 1}:</code>
                                            }
                                            {item["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["name"]}</span>
                                            </code>}
                                            {item["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{item["protocol"]}</span>
                                            </code>}
                                            <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\tmember_list:`}
                                            </code>
                                            {item["member_list"].map((memberField, pidx) => {
                                                return (
                                                    <div key={`${sidx}_${pidx}`}>
                                                        {memberField["server_name"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tserver_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member_site_setting"][idx]["server"][memberField["server_name"]]["name"]}</span>
                                                        </code>}
                                                        {memberField["port"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                            {`\t\t\tport`}: <span style={{ color: "rgb(171, 227, 56)" }}>{memberField["port"]}</span>
                                                        </code>}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                                <br />
                            </div>
                            <div className='virtualServer'>
                                {<code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t`} &nbsp;&nbsp;{`Virtual_Server`}:
                                </code>}
                                {formState["gslb"]["member_site_setting"][idx]["virtualServer"]["name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tname: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member_site_setting"][idx]["virtualServer"]["name"]}</span>
                                </code>}
                                {formState["gslb"]["member_site_setting"][idx]["virtualServer"]["host"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\thost: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["master"]["sites"][idx]["site_privateIP"]}</span>
                                </code>}
                                {<code className="d-block" style={{ fontSize: "13px" }}>
                                    {`\t\tport_list:`}
                                </code>}
                                {formState["gslb"]["member_site_setting"][idx]["virtualServer"]["port_list"].map((portfield, pidx) => {
                                    return (
                                        <div key={pidx}>
                                            {portfield["port_number"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tport: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["port_number"]}</span>
                                            </code>}
                                            {portfield["protocol"] && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tprotocol: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["protocol"]}</span>
                                            </code>}
                                            {<code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tauto_nat: `}<span style={{ color: "rgb(171, 227, 56)" }}>{portfield["auto"]}</span>
                                            </code>}
                                            {portfield["service_group"] !== -1 && <code className="d-block" style={{ fontSize: "13px" }}>
                                                {`\t\t\tservice_group: `}<span style={{ color: "rgb(171, 227, 56)" }}>{formState["gslb"]["member_site_setting"][idx]["service_group"][portfield["service_group"]]["name"]}</span>
                                            </code>}
                                        </div>
                                    )
                                })}
                                <br />
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    )
}

export default GSLBEditor