import React, { useState } from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, UncontrolledAccordion, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric } from "../../../../utils/validations";
import { oracleImages, oracleRegion, oracleSize } from "../../../../data/parameters";

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} className='ms-1 mb-2'>
    <div style={{ fontSize: '14px' }}>{label}</div>
    {(data.version && data.link) && <Row className="m-0" style={{ fontSize: '12px', color: '#888' }}>
      <Col>version-{data.version}</Col>
      <Col className='text-end me-2'><a target="_blank" href={data.link}>more details</a></Col>
    </Row>}
  </div>
);
const FormatOptionLabel = ({ label, version, link }) => {
  return (
    <div className='m-0 p-0'>
      <div style={{ fontSize: '14px' }}>{label}</div>
      {(version && link) &&
        <Row style={{ fontSize: '12px', color: '#888' }}>
          <Col>version-{version}</Col>
          <Col className='text-end me-2'><a target="_blank" href={link} style={{ "textDecoration": "none" }}>More Details</a></Col>
        </Row>
      }
    </div>
  );
}

const OracleGslbForm = ({ formState, setFormState, version_thunder }) => {
  const [securityGrpFields, setSecurityGrpFields] = useState({
    "mgmt_nsg_ids": [...formState["thunderADC"]["oracle"]["gslb"]["mgmt_nsg_ids"]].map((value) => ({ value, label: value })),
    "data_nsg_ids": [...formState["thunderADC"]["oracle"]["gslb"]["data_nsg_ids"]].map((value) => ({ value, label: value })),
  });

  const handleChange = (e) => {
    const newFormState = { ...formState };
    // if (e.target.name === "region") {
    //   newFormState["thunderADC"]["oracle"]["gslb"]["thunder_ami_id"] = awsRegionImages[e.target.value]["ami"][0]["value"]
    // }
    newFormState["thunderADC"]["oracle"]["gslb"] = { ...newFormState["thunderADC"]["oracle"]["gslb"], [e.target.name]: e.target.value }
    setFormState(newFormState)
  }
  const handleArrayChange = (e, idx) => {
    const newFormState = { ...formState };
    newFormState["thunderADC"]["oracle"]["gslb"][e.target.name][idx] = e.target.value
    setFormState(newFormState)
  }
  const handleSecurityChange = (selectedList, fieldName) => {
    // if (selectedList.length > 2 && fieldName !== "mgmt_nsg_ids" && fieldName !== "data_nsg_ids") {
    //   return
    // }
    const valuesArray = selectedList.map(item => item.value);

    let newSecurityGrpFields = { ...securityGrpFields }
    newSecurityGrpFields[fieldName] = selectedList
    setSecurityGrpFields(newSecurityGrpFields)

    let newFormState = { ...formState };
    newFormState["thunderADC"]["oracle"]["gslb"][fieldName] = valuesArray;
    setFormState(newFormState);
  };

  return (
    <UncontrolledAccordion stayOpen className="pb-2">
      <AccordionItem>
        <AccordionHeader targetId="1">
          Configure Virtual Machine-1
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <Card body color="light" className="pb-0">
            <FormGroup row>
              <Label bsSize="sm" for="region" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Region
                <span id="oci-3vm-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-region_t">
                  {`Specify region identifier where resources will be deployed. Default value is 'us-phoenix-1'.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Select
                  inputId="region"
                  name="region"
                  placeholder="Select a region"
                  isSearchable
                  value={oracleRegion.find((region) => region.value === formState["thunderADC"]["oracle"]["gslb"]["region"])}
                  onChange={(selectedOption) => handleChange({ target: { name: 'region', value: selectedOption.value } })}
                  options={oracleRegion}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px', // Adjust the font size
                      lineHeight: '1', // Adjust the line height
                      padding: '4px', // Adjust the padding
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      fontSize: "14px",
                    })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="compartment_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Compartment ID
                <span id="oci-3vm-compartment_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-compartment_id_t">
                  {`Specify the compartment OCID inside which resources will be deployed. Go to the 'Identity & Security' section in the OCI Console -> click 'Compartments' under Identity and select the desired compartment -> copy OCID under Compartment Information.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="compartment_id"
                  name="compartment_id"
                  placeholder="ocid1.compartment.oc1..xxxx"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["compartment_id"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["compartment_id"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["compartment_id"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label bsSize="sm" for="availability_domain" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Domain
                <span id="oci-3vm-availability_domain_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-availability_domain_t">
                  {`Specify availability domain name. Default value is 'LjsV:PHX-AD-1'.	Check availability domain in Placement section while creating an instance.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="availability_domain"
                  name="availability_domain"
                  placeholder="LjsV:PHX-AD-1"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["availability_domain"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["availability_domain"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["availability_domain"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_name_1" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                VM Name
                <span id="oci-3vm-thunder_name_1_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_name_1_t">
                  {`Specify the Thunder instance name.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_name_1"
                  name="thunder_name_1"
                  placeholder="vth-inst-1"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_name_1"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_name_1"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_name_1"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_image" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Image
                <span id="oci-3vm-thunder_image_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_image_t">
                  {`Specify the Thunder ACOS image OCID. To find custom Images, Go to the 'Compute' section in the OCI Console -> select 'Custom Images' -> copy the OCID of the desired image.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <CreatableSelect
                  inputId="thunder_image"
                  name="thunder_image"
                  placeholder="Select an image"
                  isSearchable
                  isValidNewOption={(inputValue, selectValue, selectOptions) => {
                    return isAlphanumeric(inputValue)
                  }}
                  components={{ Option: CustomOption }}
                  value={oracleImages[version_thunder].find((image) => image.value === formState["thunderADC"]["oracle"]["gslb"]["thunder_image"]) || { "label": formState["thunderADC"]["oracle"]["gslb"]["thunder_image"], "value": formState["thunderADC"]["oracle"]["gslb"]["thunder_image"] }}
                  onChange={(selectedOption) => handleChange({ target: { name: 'thunder_image', value: selectedOption.value } })}
                  options={oracleImages[version_thunder]}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px', // Adjust the font size
                      lineHeight: '1', // Adjust the line height
                      padding: '8px', // Adjust the padding
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      fontSize: "14px",
                    })
                  }}
                  formatOptionLabel={FormatOptionLabel}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label bsSize="sm" for="thunder_shape" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Shape
                <span id="oci-3vm-thunder_shape_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_shape_t">
                  {`Specify shape of Thunder instance. Default value is 'VM.Standard.E4.Flex'.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                {/* <Input bsSize="sm"
                  id="thunder_shape"
                  name="thunder_shape"
                  placeholder="VM.Standard.E4.Flex"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_shape"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_shape"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_shape"]}
                  onChange={handleChange}
                /> */}
                <CreatableSelect
                  inputId="thunder_shape"
                  name="thunder_shape"
                  placeholder="Select a shape"
                  isSearchable
                  isValidNewOption={(inputValue, selectValue, selectOptions) => {
                    return isAlphanumeric(inputValue)
                  }}
                  value={oracleSize.find((shape) => shape.value === formState["thunderADC"]["oracle"]["gslb"]["thunder_shape"])}
                  onChange={(selectedOption) => handleChange({ target: { name: 'thunder_shape', value: selectedOption.value } })}
                  options={oracleSize}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '14px', // Adjust the font size
                      lineHeight: '1', // Adjust the line height
                      padding: '4px', // Adjust the padding
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      fontSize: "14px",
                    })
                  }}
                />
                <a href="https://docs.oracle.com/en-us/iaas/Content/Compute/References/computeshapes.htm" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
              </Col>
            </FormGroup>



            {/* <FormGroup row>
              <Label bsSize="sm" for="tenancy_ocid" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Tenancy OCID
                <span id="oci-3vm-tenancy_ocid_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-tenancy_ocid_t">
                  {`Specify OCI account tenant OCID. Go to Administrator->Tenancy details->OCID.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="tenancy_ocid"
                  name="tenancy_ocid"
                  placeholder="ocid1.tenancy.oc1..xxxx"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["tenancy_ocid"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["tenancy_ocid"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["tenancy_ocid"]}
                  disabled
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="user_ocid" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                User OCID
                <span id="oci-3vm-user_ocid_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-user_ocid_t">
                  {`Specify OCI user OCID. Go to Identity->Users->User Details->OCID.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="user_ocid"
                  name="user_ocid"
                  placeholder="ocid1.user.oc1..xxxx"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["user_ocid"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["user_ocid"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["user_ocid"]}
                  disabled
                // onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="fingerprint" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Fingerprint
                <span id="oci-3vm-fingerprint_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-fingerprint_t">
                  {`Specify OCI user API Key fingerprint. Go to	Identity->Users->User Details->API Keys->Fingerprint.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="fingerprint"
                  name="fingerprint"
                  placeholder="xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["fingerprint"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["fingerprint"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["fingerprint"]}
                  disabled
                // onChange={handleChange}
                />
              </Col>
            </FormGroup> */}



            {/* <FormGroup row>
              <Label bsSize="sm" for="region" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Region
                <span id="oci-3vm-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-region_t">
                  {`Specify region identifier where resources will be deployed. Default value is 'us-phoenix-1'.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="region"
                  name="region"
                  placeholder="us-phoenix-1"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["region"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["region"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["region"]}
                  onChange={handleChange}
                />
                <a href="https://docs.oracle.com/en-us/iaas/Content/General/Concepts/regions.htm" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
              </Col>
            </FormGroup> */}




            {/* <FormGroup row>
              <Label bsSize="sm" for="thunder_image" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Thunder Image
                <span id="oci-3vm-thunder_image_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_image_t">
                  {`Choose from available AMI ID for a10 thunder instance. A10 Thunder ADC for Advanced Load Balancing - BYOL is set as a default AMI ID for us-east-1 region. Find more type of thunder images available with different capacity under respective regions. Go to aws -> ec2 -> launch instance -> Application and OS Images (Amazon Machine Image) -> search for acos vthunder -> AWS marketplace amis -> select the relevant thunder image -> click on continue -> copy ami id.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input
                  bsSize="sm"
                  id="thunder_image"
                  name="thunder_image"
                  required
                  type="select"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_image"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_image"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_image"]}
                  onChange={handleChange}
                >
                  {awsRegionImages[formState["thunderADC"]["oracle"]["gslb"]["region"]]["ami"].map((ami, idx) => {
                    return (
                      <option key={idx} value={ami["value"]} >{ami["label"]}</option>
                    )
                  })}
                </Input>
              </Col>
            </FormGroup> */}

            <FormGroup row>
              <Label bsSize="sm" for="thunder_active_memory_in_gbs" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Memory (GiB)
                <span id="oci-3vm-thunder_active_memory_in_gbs_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_active_memory_in_gbs_t">
                  {`Specify memory for Thunder instance in GB, minimum requiremt is 8GB.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_active_memory_in_gbs"
                  name="thunder_active_memory_in_gbs"
                  placeholder="16"
                  required="true"
                  type="number"
                  min={1}
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_memory_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_memory_in_gbs"]) > 0}
                  invalid={!(isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_memory_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_memory_in_gbs"]) > 0)}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_active_memory_in_gbs"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label bsSize="sm" for="thunder_active_ocpus" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                OCPUs (Core)
                <span id="oci-3vm-thunder_active_ocpus_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_active_ocpus_t">
                  {`Specify number of OCPUs, minimum requirement is 3 OCPUs.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_active_ocpus"
                  name="thunder_active_ocpus"
                  placeholder="3"
                  required="true"
                  type="number"
                  min={1}
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_ocpus"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_ocpus"]) > 0}
                  invalid={!(isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_ocpus"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_active_ocpus"]) > 0)}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_active_ocpus"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            {/* <FormGroup row>
              <Label bsSize="sm" for="size_in_gbs" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Size (GiB)
                <span id="oci-3vm-size_in_gbs_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-size_in_gbs_t">
                  {`Specify boot storage size in GB, minimum requirement is 20GB.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="size_in_gbs"
                  name="size_in_gbs"
                  placeholder="50"
                  required="true"
                  type="number"
                  min={1}
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["size_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["size_in_gbs"]) > 0}
                  invalid={!(isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["size_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["size_in_gbs"]) > 0)}
                  value={formState["thunderADC"]["oracle"]["gslb"]["size_in_gbs"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup> */}
            <FormGroup row>
              <Label bsSize="sm" for="private_key_path" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                OCI Key Path
                <span id="oci-3vm-private_key_path_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-private_key_path_t">
                  {`Specify existing or create a new OCI user API Key. Go to Identity->Users->User Details->API Keys.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="private_key_path"
                  name="private_key_path"
                  placeholder="/mnt/c/oci_api_key.pem"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["private_key_path"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["private_key_path"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["private_key_path"]}
                  // disabled
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="vm_key_path" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                VM Key Path
                <span id="oci-3vm-vm_key_path_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-vm_key_path_t">
                  {`Specify existing or create a new OCI user API Key. Go to Identity->Users->User Details->API Keys.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="vm_key_path"
                  name="vm_key_path"
                  placeholder="/mnt/c/vm_key.pem"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["vm_key_path"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["vm_key_path"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["vm_key_path"]}
                  // disabled
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            {/* <FormGroup row>
              <Label bsSize="sm" for="block_vol_attachment_type" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Block Volume Attachment Type
                <span id="oci-3vm-block_vol_attachment_type_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-block_vol_attachment_type_t">
                  {``}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="block_vol_attachment_type"
                  name="block_vol_attachment_type"
                  placeholder="iscsi"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["block_vol_attachment_type"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["block_vol_attachment_type"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["block_vol_attachment_type"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup> */}

          </Card>
          <UncontrolledAccordion stayOpen className="mb-2">
            <AccordionItem>
              <AccordionHeader targetId={`1`}>
                Configure Network Settings
              </AccordionHeader>
              <AccordionBody accordionId={`1`}>
                <Card body color="light"
                  className="p-0"
                >

                  {/* <------------- MANAGEMENT --------------> */}
                  <CardHeader>
                    Management
                  </CardHeader>
                  <CardBody className='pb-0'>
                    <FormGroup row>
                      <Label bsSize="sm" for="mgmt_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Subnet ID
                        <span id="oci-3vm-mgmt_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="oci-3vm-mgmt_subnet_id_t">
                          {`Specify the management subnet OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired subnet under Subnets -> copy OCID under Subnet Information.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="mgmt_subnet_id"
                          name="mgmt_subnet_id"
                          placeholder="ocid1.subnet.oc1.phx.xxxx"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["mgmt_subnet_id"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["mgmt_subnet_id"])}
                          value={formState["thunderADC"]["oracle"]["gslb"]["mgmt_subnet_id"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>

                  </CardBody>
                  {/* <------------- Data IN --------------> */}
                  <hr className='mb-0 pb-0' />
                  <CardHeader>
                    Data (IN)
                  </CardHeader>
                  <CardBody className="pb-0">
                    <FormGroup row>
                      <Label bsSize="sm" for="data_in_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Subnet ID (Data-In)
                        <span id="oci-3vm-data_in_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="oci-3vm-data_in_subnet_id_t">
                          {`Specify the data-in subnet OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired subnet under Subnets -> copy OCID under Subnet Information.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_in_subnet_id"
                          name="data_in_subnet_id"
                          placeholder="ocid1.subnet.oc1.phx.xxxx"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["data_in_subnet_id"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["data_in_subnet_id"])}
                          value={formState["thunderADC"]["oracle"]["gslb"]["data_in_subnet_id"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                  {/* <------------- Data OUT --------------> */}
                  <hr className='mb-0 pb-0' />
                  <CardHeader>
                    Data (OUT)
                  </CardHeader>
                  <CardBody>
                    <FormGroup row>
                      <Label bsSize="sm" for="data_out_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                        Subnet ID (Data-Out)
                        <span id="oci-3vm-data_out_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip placement="top" target="oci-3vm-data_out_subnet_id_t">
                          {`Specify the data-out subnet OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired subnet under Subnets -> copy OCID under Subnet Information.`}
                        </UncontrolledTooltip>
                      </Label>
                      <Col sm={7}>
                        <Input bsSize="sm"
                          id="data_out_subnet_id"
                          name="data_out_subnet_id"
                          placeholder="ocid1.subnet.oc1.phx.xxxx"
                          required="true"
                          type="text"
                          valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["data_out_subnet_id"])}
                          invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["data_out_subnet_id"])}
                          value={formState["thunderADC"]["oracle"]["gslb"]["data_out_subnet_id"]}
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId={`2`}>
                Configure Security Settings
              </AccordionHeader>
              <AccordionBody accordionId={`2`}>
                <Card body color="light">
                  <FormGroup row>
                    <Label bsSize="sm" for={`mgmt_nsg_ids`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Network Security Group ID (Management)
                      <span id={`oci-3vm-mgmt_nsg_ids_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`oci-3vm-mgmt_nsg_ids_t`}>
                        {`Specify the management network security group OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired network security group under 'Network Security Groups' -> copy OCID under Network Security Group Information.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <CreatableSelect
                        isMulti
                        isValidNewOption={(inputValue, selectValue, selectOptions) => {
                          return isAlphanumeric(inputValue)
                        }}
                        value={securityGrpFields["mgmt_nsg_ids"]}
                        onChange={(selectedOption) => handleSecurityChange(selectedOption, "mgmt_nsg_ids")}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            minHeight: "35px",
                            fontSize: "14px",
                            borderColor: securityGrpFields["mgmt_nsg_ids"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                          }),
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label bsSize="sm" for={`data_nsg_ids`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                      <span style={{ color: "red", fontSize: "16px" }}>*</span>
                      Network Security Group ID (Data-In/Out)
                      <span id={`oci-3vm-data_nsg_ids_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                      <UncontrolledTooltip placement="top" target={`oci-3vm-data_nsg_ids_t`}>
                        {`Specify the Data-in/out network security group OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired network security group under 'Network Security Groups' -> Copy OCID under Network Security Group Information.`}
                      </UncontrolledTooltip>
                    </Label>
                    <Col sm={7}>
                      <CreatableSelect
                        isMulti
                        isValidNewOption={(inputValue, selectValue, selectOptions) => {
                          return isAlphanumeric(inputValue)
                        }}
                        value={securityGrpFields["data_nsg_ids"]}
                        onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_nsg_ids")}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            minHeight: "35px",
                            fontSize: "14px",
                            borderColor: securityGrpFields["data_nsg_ids"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                          }),
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Card>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        </AccordionBody>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader targetId="2">
          Configure Virtual Machine-2
        </AccordionHeader>
        <AccordionBody accordionId="2">
          <Card body color="light" className="pb-0">
            <FormGroup row>
              <Label bsSize="sm" for="thunder_name_2" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                VM Name
                <span id="oci-3vm-thunder_name_2_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_name_2_t">
                  {`Specify the Thunder instance name.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_name_2"
                  name="thunder_name_2"
                  placeholder="vth-inst-2"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_name_2"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_name_2"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_name_2"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="thunder_standby_memory_in_gbs" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Memory (GiB)
                <span id="oci-3vm-thunder_standby_memory_in_gbs_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_standby_memory_in_gbs_t">
                  {`Specify memory for Thunder instance in GB, minimum requiremt is 8GB.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_standby_memory_in_gbs"
                  name="thunder_standby_memory_in_gbs"
                  placeholder="16"
                  required="true"
                  type="number"
                  min={1}
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_memory_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_memory_in_gbs"]) > 0}
                  invalid={!(isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_memory_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_memory_in_gbs"]) > 0)}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_memory_in_gbs"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label bsSize="sm" for="thunder_standby_ocpus" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                OCPUs (Core)
                <span id="oci-3vm-thunder_standby_ocpus_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_standby_ocpus_t">
                  {`Specify number of OCPUs, minimum requirement is 3 OCPUs.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_standby_ocpus"
                  name="thunder_standby_ocpus"
                  placeholder="3"
                  required="true"
                  type="number"
                  min={1}
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_ocpus"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_ocpus"]) > 0}
                  invalid={!(isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_ocpus"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_ocpus"]) > 0)}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_standby_ocpus"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
          </Card>
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="3">
          Configure Virtual Machine-3
        </AccordionHeader>
        <AccordionBody accordionId="3">
          <Card body color="light" className="pb-0">
            <FormGroup row>
              <Label bsSize="sm" for="thunder_name_3" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                VM Name
                <span id="oci-3vm-thunder_name_3_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-thunder_name_3_t">
                  {`Specify the Thunder instance name.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="thunder_name_3"
                  name="thunder_name_3"
                  placeholder="vth-inst-3"
                  required="true"
                  type="text"
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_name_3"])}
                  invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["thunder_name_3"])}
                  value={formState["thunderADC"]["oracle"]["gslb"]["thunder_name_3"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label bsSize="sm" for="instance_2_thunder_memory_in_gbs" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                Memory (GiB)
                <span id="oci-3vm-instance_2_thunder_memory_in_gbs_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-instance_2_thunder_memory_in_gbs_t">
                  {`Specify memory for Thunder instance in GB, minimum requiremt is 8GB.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="instance_2_thunder_memory_in_gbs"
                  name="instance_2_thunder_memory_in_gbs"
                  placeholder="16"
                  required="true"
                  type="number"
                  min={1}
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_memory_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_memory_in_gbs"]) > 0}
                  invalid={!(isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_memory_in_gbs"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_memory_in_gbs"]) > 0)}
                  value={formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_memory_in_gbs"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label bsSize="sm" for="instance_2_thunder_ocpus" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                OCPUs (Core)
                <span id="oci-3vm-instance_2_thunder_ocpus_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                <UncontrolledTooltip placement="top" target="oci-3vm-instance_2_thunder_ocpus_t">
                  {`Specify number of OCPUs, minimum requirement is 3 OCPUs.`}
                </UncontrolledTooltip>
              </Label>
              <Col sm={7}>
                <Input bsSize="sm"
                  id="instance_2_thunder_ocpus"
                  name="instance_2_thunder_ocpus"
                  placeholder="3"
                  required="true"
                  type="number"
                  min={1}
                  valid={isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_ocpus"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_ocpus"]) > 0}
                  invalid={!(isAlphanumeric(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_ocpus"]) && parseInt(formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_ocpus"]) > 0)}
                  value={formState["thunderADC"]["oracle"]["gslb"]["instance_2_thunder_ocpus"]}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
          </Card>
        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  )
}

export default OracleGslbForm