import React, { useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { Button, Input, Table, UncontrolledTooltip } from 'reactstrap'
import { isAlphanumeric, isIpValid } from '../../../../utils/validations'
import CreatableSelect from 'react-select/creatable';

const IPRoute = ({ formState, setFormState, region }) => {

  const [multiValFields, setMultiValFields] = useState({
    "ip_next_hop": [...formState["ha"]["iPRoute"][0]["ip_next_hop"]].map((value) => ({ value, label: value })),
  });

  const handleChange = (e, idx) => {
    let newFormState = { ...formState }
    newFormState["ha"]["iPRoute"][idx] = { ...newFormState["ha"]["iPRoute"][idx], [e.target.name]: e.target.value }
    setFormState(newFormState)
  }

  const handleMultiValFieldChange = (selectedList, fieldName) => {
    const valuesArray = selectedList.map(item => item.value);

    let newMultiValFields = { ...multiValFields }
    newMultiValFields[fieldName] = selectedList
    console.log(fieldName, selectedList)
    setMultiValFields(newMultiValFields)

    let newFormState = { ...formState };
    newFormState["ha"]["iPRoute"][0][fieldName] = valuesArray;
    setFormState(newFormState);
  };

  const handleAddIPRoute = () => {
    let newFormState = { ...formState }
    newFormState["ha"]["iPRoute"].push({
      "ip_dest_addr": "0.0.0.0",
      "ip_mask": "/0",
      "ip_next_hop": "127.0.0.1"
    })
    setFormState(newFormState)
  }

  const handleDeleteIPRoute = (idx) => {
    let newFormState = { ...formState }
    newFormState["ha"]["iPRoute"].splice(idx, 1)
    setFormState(newFormState)
  }
  return (
    <>
      {/* <div className="d-flex justify-content-end">
        <Button className="mt-1" size="sm" onClick={handleAddIPRoute}>Add IP Route</Button>
      </div> */}
      <h5 className='mt-0 pt-0'>IP Route </h5>
      {formState["ha"]["iPRoute"].map((item, idx) => {
        return <>
          {/* <div className="d-flex justify-content-end">
            {idx > 0 && <Button className='mb-2' size="sm" disabled={idx === 0} onClick={() => handleDeleteIPRoute(idx)}><FaTrashAlt /></Button>}
          </div> */}
          <Table responsive bordered hover striped>
            <thead>
              <tr style={{ height: "30px" }}>
                <th style={{ fontSize: "14px", width: "35%" }}>Field Name</th>
                <th style={{ fontSize: "14px", width: "65%" }}>Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr key={`ip_dest_addr${idx + 1}`} style={{ height: "30px" }}>
                <td style={{ fontSize: "12px", padding: "2px" }}>
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  Destination IP
                  <span id={`ip_dest_addr-t${idx + 1}`} style={{ fontSize: "14px" }}>
                    <strong> ⓘ</strong>
                  </span>
                  <UncontrolledTooltip placement="top" target={`ip_dest_addr-t${idx + 1}`}>
                    Specify destination ip address.
                  </UncontrolledTooltip>
                </td>
                <td style={{ padding: "2px" }}>
                  <Input bsSize="sm"
                    id={`ip_dest_addr${idx + 1}`}
                    name="ip_dest_addr"
                    placeholder="0.0.0.0"
                    type="text"
                    value={item["ip_dest_addr"]}
                    valid={isIpValid(item["ip_dest_addr"])}
                    invalid={!isIpValid(item["ip_dest_addr"])}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </td>
              </tr>
              <tr key={`ip_mask${idx + 1}`} style={{ height: "30px" }}>
                <td style={{ fontSize: "12px", padding: "2px" }}>
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                  IP Mask
                  <span id={`ip_mask-t${idx + 1}`} style={{ fontSize: "14px" }}>
                    <strong> ⓘ</strong>
                  </span>
                  <UncontrolledTooltip placement="top" target={`ip_mask-t${idx + 1}`}>
                    Specify destination ip address mask.
                  </UncontrolledTooltip>
                </td>
                <td style={{ padding: "2px" }}>
                  <Input bsSize="sm"
                    id={`ip_mask${idx + 1}`}
                    name="ip_mask"
                    placeholder="/0"
                    type="text"
                    value={item["ip_mask"]}
                    valid={isAlphanumeric(item["ip_mask"])}
                    invalid={!isAlphanumeric(item["ip_mask"])}
                    onChange={(e) => handleChange(e, idx)}
                  />
                </td>
              </tr>
              <tr key={`ip_next_hop${idx + 1}`} style={{ height: "30px" }}>
                <td style={{ fontSize: "12px", padding: "2px" }}>
                  <span style={{ color: "red", fontSize: "16px" }}>*</span>
                  IP Next Hop
                  <span id={`ip_next_hop-t${idx + 1}`} style={{ fontSize: "14px" }}>
                    <strong> ⓘ</strong>
                  </span>
                  <UncontrolledTooltip placement="top" target={`ip_next_hop-t${idx + 1}`}>
                  Specify next hop IP address of management and data-in subnet. Next hop IP address is the first IP address of respective subnet.
                  </UncontrolledTooltip>
                </td>
                <td style={{ padding: "2px" }}>
                  {/* <Input bsSize="sm"
                    id={`ip_next_hop${idx + 1}`}
                    name="ip_next_hop"
                    placeholder="127.0.0.1"
                    type="text"
                    value={item["ip_next_hop"]}
                    valid={isIpValid(item["ip_next_hop"])}
                    invalid={!isIpValid(item["ip_next_hop"])}
                    onChange={(e) => handleChange(e, idx)}
                  /> */}
                  <CreatableSelect
                    isMulti
                    isValidNewOption={(inputValue, selectValue, selectOptions) => {
                      return isIpValid(inputValue)
                    }}
                    placeholder="Provide multiple IP and press enter.."
                    value={multiValFields["ip_next_hop"]}
                    onChange={(selectedOption) => handleMultiValFieldChange(selectedOption, "ip_next_hop")}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        minHeight: "35px",
                        fontSize: "14px",
                        borderColor: multiValFields["ip_next_hop"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                      }),
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>

        </>
      })}

    </>
  )
}

export default IPRoute
