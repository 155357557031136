import React from "react"
import AzureCustomEditor from "./AzureCustomEditor"
import AzureHAEditor from "./AzureHAEditor"
import AzureGslbEditor from "./AzureGslbEditor"

const AzureEditor = ({ formState, vThunderCount }) => {

    if (vThunderCount === "custom") {
        return <AzureCustomEditor formState={formState} vThunderCount={vThunderCount} />
    }
    else if (vThunderCount === "ha") {
        return <AzureHAEditor formState={formState} vThunderCount={vThunderCount} />
    }
    else if (vThunderCount === "gslb") {
        return <AzureGslbEditor formState={formState} vThunderCount={vThunderCount} />
    }
    else {
        return (
            <>
                {/*Virtual Machine 1 */}
                <div>
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        Configure_VM_1:
                    </code>
                    {formState["thunderADC"]["azure"]["standalone"]["location"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["location"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["resource_group"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["existing_resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\texisting_resource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["existing_resource_group"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["instance_name1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["instance_name1"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["image"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tsize`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["size"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["admin_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["admin_username"]}</span>
                    </code>}
                    {/* <-------- Network --------->*/}
                    <code className="d-block" style={{ fontSize: "13px" }}>
                        {"\tConfigure_Network_Settings:"}
                    </code>
                    {formState["thunderADC"]["azure"]["standalone"]["vnet_nsg_rg"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["vnet_nsg_rg"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["vnet_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tvirtual_network_vnet`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["vnet_name"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["publicIP_name1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tpublic_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["publicIP_name1"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["accelerated_networking"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\taccelerated_networking`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["accelerated_networking"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["ip_forwarding"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["subnet_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tsubnet_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["subnet_mgmt"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["nic_mgmt1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tnic_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["nic_mgmt1"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["subnet_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tsubnet_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["subnet_datain"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["nic_datain1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tnic_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["nic_datain1"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["subnet_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tsubnet_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["subnet_dataout"]}</span>
                    </code>}
                    {formState["thunderADC"]["azure"]["standalone"]["nic_dataout1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                        {`\t\tnic_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["nic_dataout1"]}</span>
                    </code>}
                    {/* <-------- Security --------->*/}
                    {vThunderCount !== "gslb" && <><code className="d-block" style={{ fontSize: "13px" }}>
                        {"\tConfigure_Security_Settings:"}
                    </code>
                    {formState["thunderADC"]["azure"]["standalone"]["nsg_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["nsg_mgmt"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["standalone"]["nsg_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["nsg_datain"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["standalone"]["nsg_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["standalone"]["nsg_dataout"]}</span>
                </code>}
                        </>}
                </div>
            </>
        )
    }
}
export default AzureEditor