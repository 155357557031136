import React from 'react'

const ChangePasswordEditor = ({ formState }) => {
  return (
    <div>
      <code className="d-block" style={{ fontSize: "13px" }}>
        Change_Password:
      </code>
      {
        formState['configCredentials']['address'] && <code className="d-block" style={{ fontSize: "13px" }}>

          {`\taddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState['configCredentials']['address']}</span>
        </code>
      }
      {formState['configCredentials']['username'] &&
        <code className="d-block" style={{ fontSize: "13px" }}>
          {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState['configCredentials']['username']}</span>
        </code>
      }
      {/* <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tpassword: ${formState['configCredentials']['password']}`}
            </code> */}
      <br />
    </div>
  )
}

export default ChangePasswordEditor