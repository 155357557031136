import React, { useState } from "react";
import { Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";
import { clouds, description_cloud } from "../../data/parameters";

const CloudNav = ({ config, cloud, setCloud }) => {
  const [isHardware, setIsHardware] = useState(true);

  return (
    <div className="mt-0 mb-1 ps-0">
      {config === undefined ? (
        <ButtonGroup>
          {Object.keys(clouds).map((cld, idx) => (
            <Button
              size="sm"
              key={idx}
              color="primary"
              outline
              onClick={() => setCloud(cld)}
              className="fw-bold"
              active={cloud === cld && config === undefined}
              style={{ fontSize: "14px", pointerEvents: "auto" }}
              disabled={
                config !== undefined
                // || cld === "aws"
                // || cld === "azure"
                // || cld === "oracle"
              }
            >
              <span id={`${cld}_t`}>{clouds[cld]}</span>
              <UncontrolledTooltip placement="bottom" target={`${cld}_t`}>
                {description_cloud[cld]}
              </UncontrolledTooltip>
            </Button>
          ))}
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          <Button
            color="primary"
            size="sm"
            className="fw-bold"
            outline
            onClick={() => setIsHardware(true)}
            active={isHardware}
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            HARDWARE
          </Button>
          <Button
            color="primary"
            size="sm"
            outline
            onClick={() => setIsHardware(false)}
            active={!isHardware}
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            &nbsp; CLOUD &nbsp;
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default CloudNav;
