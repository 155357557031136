import React from "react";

const OracleStandaloneEditor = ({ formState }) => {
return (
    <div>
        <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_VM:
            </code>
            {formState["thunderADC"]["oracle"]["standalone"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["region"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["compartment_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tcompartment_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["compartment_id"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["availability_domain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tavailability_domain`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["availability_domain"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["thunder_name_1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["thunder_name_1"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["thunder_image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["thunder_image"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["thunder_shape"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tshape`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["thunder_shape"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["thunder_active_memory_in_gbs"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tmemory`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["thunder_active_memory_in_gbs"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["thunder_active_ocpus"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tocpus`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["thunder_active_ocpus"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["private_key_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\toci_key_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["private_key_path"]}</span>
            </code>}
            {/* <-------- Network --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network:"}
            </code>
            {formState["thunderADC"]["oracle"]["standalone"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tmanagement_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["mgmt_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["data_in_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["oracle"]["standalone"]["data_out_subnet_id"]}</span>
            </code>}
            {/* <-------- Security --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Security:"}
            </code>
            {formState["thunderADC"]["oracle"]["standalone"]["mgmt_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tmanagement_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["oracle"]["standalone"]["mgmt_nsg_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
            {formState["thunderADC"]["oracle"]["standalone"]["data_nsg_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_nsg_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["oracle"]["standalone"]["data_nsg_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
    </div>
)
}
export default OracleStandaloneEditor