import React from 'react'

const HAEditor = ({ formState }) => {
  return (
    <>
      {/* <ConfigCredentialsEditor formState={formState} /> */}

      {/* Thunder-1 */}
      {<code className="d-block" style={{ fontSize: "13px" }}>
        Thunder_1:
      </code>}
      {formState["ha"]["prerequisite"]["thunder1"]["address"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\taddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder1"]["address"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["thunder1"]["username"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder1"]["username"]}</span>
      </code>}
      {/* {formState["ha"]["prerequisite"]["thunder1"]["password"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tpassword: ${formState["ha"]["prerequisite"]["thunder1"]["password"]}`}
      </code>} */}
      {formState["ha"]["prerequisite"]["thunder1"]["set_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tset_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder1"]["set_id"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["thunder1"]["device_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tdevice_id: ${formState["ha"]["prerequisite"]["thunder1"]["device_id"]}`}
      </code>}
      {formState["ha"]["prerequisite"]["thunder1"]["primaryIP"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tprimary_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder1"]["primaryIP"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["thunder1"]["priority"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tpriority`}: {formState["ha"]["prerequisite"]["thunder1"]["priority"]}
      </code>}
      <br />

      {<code className="d-block" style={{ fontSize: "13px" }}>
        Thunder_2:
      </code>}
      {formState["ha"]["prerequisite"]["thunder2"]["address"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\taddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder2"]["address"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["thunder2"]["username"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder2"]["username"]}</span>
      </code>}
      {/* {formState["ha"]["prerequisite"]["thunder2"]["password"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tpassword: ${formState["ha"]["prerequisite"]["thunder2"]["password"]}`}
      </code>} */}
      {formState["ha"]["prerequisite"]["thunder1"]["set_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tset_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder1"]["set_id"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["thunder2"]["device_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tdevice_id: ${formState["ha"]["prerequisite"]["thunder2"]["device_id"]}`}
      </code>}
      {formState["ha"]["prerequisite"]["thunder2"]["primaryIP"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tprimary_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["thunder2"]["primaryIP"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["thunder2"]["priority"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tpriority: ${formState["ha"]["prerequisite"]["thunder2"]["priority"]}`}
      </code>}
      <br />

      {<code className="d-block" style={{ fontSize: "13px" }}>
        Common:
      </code>}
      {formState["ha"]["prerequisite"]["common"]["ip_v4_addr"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tip_v4_addr`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["common"]["ip_v4_addr"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["common"]["ip_address"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tip_address`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["common"]["ip_address"]}</span>
      </code>}
      {formState["ha"]["prerequisite"]["common"]["idle_timeout"] && <code className="d-block" style={{ fontSize: "13px" }}>
        {`\tidle_timeout`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["ha"]["prerequisite"]["common"]["idle_timeout"]}</span>
      </code>}
      <br />

      {<code className="d-block" style={{ fontSize: "13px" }}>
        IP_Route:
      </code>}
      {formState["ha"]["iPRoute"].map((item, idx) => {
        return (
          <div key={idx}>
            {item["ip_dest_addr"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_dest_addr`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["ip_dest_addr"]}</span>
            </code>}
            {item["ip_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["ip_mask"]}</span>
            </code>}
            {/* {item["ip_next_hop"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_next_hop`}: <span style={{ color: "rgb(171, 227, 56)" }}>{item["ip_next_hop"]}</span>
            </code>} */}
            {item["ip_next_hop"] && <code className="d-block" style={{ fontSize: "13px" }}>
              {`\tip_next_hop`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                {item["ip_next_hop"].map((ele, idx) => {
                  return <>{idx > 0 && ", "}{ele}</>
                })}
                ]</span>
            </code>}
          </div>
        )
      })}
      <br />

    </>
  )
  // return (
  //   <>
  //     <ConfigCredentialsEditor formState={formState} />

  //     {/* Primary DNS */}
  //     {<code className="d-block" style={{ fontSize: "13px" }}>
  //       Primary DNS:
  //     </code>}
  //     {formState["ha"]["primaryDNS"]["ip-add-type"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //       {`\t-ip-address-type: ${formState["ha"]["primaryDNS"]["ip-add-type"]}`}
  //     </code>}
  //     {formState["ha"]["primaryDNS"]["ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //       {`\t-ip: ${formState["ha"]["primaryDNS"]["ip"]}`}
  //     </code>}
  //     <br />

  //     {/* IP Route */}
  //     {formState["ha"]["ipRoute"].map((item, idx) => {
  //       return (
  //         <div key={idx}>
  //           {<code className="d-block" style={{ fontSize: "13px" }}>
  //             IP Route-{idx + 1}:
  //           </code>}
  //           {item["ip-dest-address"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //             {`\t-ip-dest-address: ${item["ip-dest-address"]}`}
  //           </code>}
  //           {item["ip-mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //             {`\t-ip-mask: ${item["ip-mask"]}`}
  //           </code>}
  //           {item["ip-nexthop-ipv4-address"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //             {`\t-ip-nexthop-ipv4-address: ${item["ip-nexthop-ipv4-address"]}`}
  //           </code>}
  //         </div>
  //       )
  //     })}
  //     <br />

  //     {/* Vrrp-A-Common */}
  //     {<code className="d-block" style={{ fontSize: "13px" }}>
  //       Vrrp-A-Common:
  //     </code>}
  //     {formState["ha"]["Vrrp-A-Common"]["device-id"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //       {`\t-device-id: ${formState["ha"]["Vrrp-A-Common"]["device-id"]}`}
  //     </code>}
  //     {formState["ha"]["Vrrp-A-Common"]["set-id"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //       {`\t-set-id: ${formState["ha"]["Vrrp-A-Common"]["set-id"]}`}
  //     </code>}
  //     {formState["ha"]["Vrrp-A-Common"]["action"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //       {`\t-action: ${formState["ha"]["Vrrp-A-Common"]["action"]}`}
  //     </code>}
  //     <br />

  //     {/* Terminal Timeout */}
  //     {<code className="d-block" style={{ fontSize: "13px" }}>
  //       Terminal Timeout:
  //     </code>}
  //     {formState["ha"]["terminalTimeout"]["idleTimeout"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //       {`\t-idle-timeout: ${formState["ha"]["terminalTimeout"]["idleTimeout"]}`}
  //     </code>}
  //     <br />

  //     {/* Vrrp-A-Vrid */}
  //     {formState['ha']['Vrrp-A-Vrid'].map((item, idx) => {
  //       return (
  //         <div key={idx}>
  //           {<code className="d-block" style={{ fontSize: "13px" }}>
  //             Vrrp-A-Vrid-{idx + 1}:
  //           </code>}
  //           {item["vrid-val"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //             {`\t-vrid-val: ${item["vrid-val"]}`}
  //           </code>}
  //           {item["priority"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //             {`\t-priority: ${item["priority"]}`}
  //           </code>}
  //           {item["floating-ip"].map((memberField, pidx) => {
  //             return (
  //               <div key={`${idx}_${pidx}`}>
  //                 {memberField["ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //                   {`\t-floating-ip: ${memberField["ip"]}`}
  //                 </code>}
  //               </div>
  //             )
  //           })}
  //         </div>
  //       )
  //     })}
  //     <br />

  //     {/* Peer Group */}
  //     {<code className="d-block" style={{ fontSize: "13px" }}>
  //       Peer Group:
  //     </code>}
  //     {formState["ha"]["peerGroup"]["ip-peer-address"].map((item, idx) => {
  //       return (
  //         <div key={idx}>
  //           {item["ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
  //             {`\t-ip-peer-address: ${item["ip"]}`}
  //           </code>}
  //         </div>
  //       )
  //     })}


  //   </>
  // )
}

export default HAEditor
