import React, { useEffect, useState } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Card, Row, CardBody, CardHeader, Col, FormGroup, Input, Label, UncontrolledAccordion, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric, isNotEmpty } from '../../../../utils/validations';
import { azureRegionImages, azureRegion, azureSize } from "../../../../data/parameters";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
const AzureCustomForm = ({ formState, setFormState, version_thunder }) => {

    useEffect(() => {
        let newFormState = { ...formState }
        let index = -1;
        Object.keys(azureRegionImages).forEach((version) => {
            if (index === -1) {
                index = azureRegionImages[version].findIndex((image) => {
                    return image.value === formState["thunderADC"]["azure"]["custom"]["image"]
                })
            }
        })
        if (index !== -1) {
            newFormState["thunderADC"]["azure"]["custom"]["image"] = azureRegionImages[version_thunder][index].value
            newFormState["thunderADC"]["azure"]["custom"]["product"] = azureRegionImages[version_thunder][index].product
            setFormState(newFormState)
        }
    }, [version_thunder])

    const [multiValueFields, setMultiValueFields] = useState({
        "vm_names": [...formState["thunderADC"]["azure"]["custom"]["vm_names"]].map((value) => ({ value, label: value })),
    });

    const handleSecurityChange = (selectedList, fieldName) => {
        const valuesArray = selectedList.map(item => item.value);

        let newSecurityGrpFields = { ...multiValueFields }
        newSecurityGrpFields[fieldName] = selectedList
        setMultiValueFields(newSecurityGrpFields)

        let newFormState = { ...formState };
        newFormState["thunderADC"]["azure"]["custom"][fieldName] = valuesArray;
        newFormState["thunderADC"]["azure"]["custom"]["vm_count"] = valuesArray.length;
        setFormState(newFormState);
    };

    const handleChange = (e) => {
        const newFormState = { ...formState };
        newFormState["thunderADC"]["azure"]["custom"] = { ...newFormState["thunderADC"]["azure"]["custom"], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }
    const updateProductBasedOnImage = (selectedImage) => {
        const correspondingProduct = azureRegionImages[version_thunder].find((image) => image.value === selectedImage);
        if (correspondingProduct) {
            const updatedFormState = { ...formState };
            updatedFormState["thunderADC"]["azure"]["custom"]["product"] = correspondingProduct.product;
            setFormState(updatedFormState);
        }
    };
    const CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps} className='ms-1 mb-2'>
            <div style={{ fontSize: '14px' }}>{label}</div>
            {(data.version && data.link) && <Row className="m-0" style={{ fontSize: '12px', color: '#888' }}>
                <Col>version-{data.version}</Col>
                <Col className='text-end me-2'><a target="_blank" href={data.link} style={{ "textDecoration": "none" }}>More Details</a></Col>
            </Row>}
        </div>
    )
    const FormatOptionLabel = ({ label, version, link }) => {
        return (
            <div className='m-0 p-0'>
                <div style={{ fontSize: '14px' }}>{label}</div>
                {(version && link) &&
                    <Row style={{ fontSize: '12px', color: '#888' }}>
                        <Col>version-{version}</Col>
                        <Col className='text-end me-2'><a target="_blank" href={link} style={{ "textDecoration": "none" }}>More Details</a></Col>
                    </Row>
                }
            </div>
        );
    }
    const isAcceleratedNetworkingSupported = (selectedSize) => {
        const isPresent = azureSize.some((size) => size.value === selectedSize)
        if (!isPresent)
            return true;
        const sizeInfo = azureSize.find((size) => size.value === selectedSize);
        return sizeInfo && sizeInfo.acceleratedNetworking === "Supported";
    };

    return (
        <UncontrolledAccordion stayOpen className="pb-2">
            <AccordionItem>
                <AccordionHeader targetId="1">
                    Configure Virtual Machines
                </AccordionHeader>
                <AccordionBody accordionId="1">
                    <Card body color="light" className="pb-0">
                        <FormGroup row>
                            <Label bsSize="sm" for="location"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Region
                                <span id="location_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="location_t">
                                    {`Choose the region resources will be deployed.`}<br />{`The default value is "East US".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    inputId="location"
                                    name="location"
                                    placeholder="Select a region"
                                    isSearchable
                                    value={azureRegion.find((region) => region.value === formState["thunderADC"]["azure"]["custom"]["location"])}
                                    onChange={(selectedOption) => handleChange({ target: { name: 'location', value: selectedOption.value } })}
                                    options={azureRegion}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '4px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="resource_group"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Resource Group
                                <span id="resource_group_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="resource_group_t">
                                    {`Specify the resource group name where resources will be deployed.`}<br />{`A resource group is a collection of resources that share the same lifecycle, permissions, and policies.`}<br />{`The default value is "vth-rg2".`}<br />{`Go to the Azure portal -> Resource groups.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="resource_group"
                                    name="resource_group"
                                    placeholder="vth-rg2"
                                    required="true"
                                    type="text"
                                    valid={isNotEmpty(formState["thunderADC"]["azure"]["custom"]["resource_group"])}
                                    invalid={!isNotEmpty(formState["thunderADC"]["azure"]["custom"]["resource_group"])}
                                    value={formState["thunderADC"]["azure"]["custom"]["resource_group"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="existing_resource_group"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Existing Resource Group
                                <span id={`azure_existing_resource_group_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_existing_resource_group_t`}>
                                    {`Select 'Yes' to enable the use of an existing resource group for deployment.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="existing_resource_group"
                                    name="existing_resource_group"
                                    placeholder="true"
                                    required="true"
                                    type="select"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["existing_resource_group"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["existing_resource_group"])}
                                    value={formState["thunderADC"]["azure"]["custom"]["existing_resource_group"]}
                                    onChange={handleChange}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="vm_count"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                VM Count
                                <span id="vm_count_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="vm_count_t">
                                    {`The number of Thunder instances is set to be equal to the number of VM names provided.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="vm_count"
                                    name="vm_count"
                                    placeholder="1"
                                    required="true"
                                    min={1}
                                    type="number"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["vm_count"]) && parseInt(formState["thunderADC"]["azure"]["custom"]["vm_count"]) > 0}
                                    invalid={!(isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["vm_count"]) && parseInt(formState["thunderADC"]["azure"]["custom"]["vm_count"]) > 0)}
                                    value={formState["thunderADC"]["azure"]["custom"]["vm_count"]}
                                    disabled
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for={`vm_names`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                VM Names
                                <span id={`azure-custom-vm_names_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure-custom-vm_names_t`}>
                                    {`Specify the virtual machine name for vThunder.`}<br />{`The ARM template will create a new VM with the provided name.`}<br />{`For example, "vth-inst-1".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <CreatableSelect
                                    isMulti
                                    value={multiValueFields["vm_names"]}
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                        return isAlphanumeric(inputValue)
                                    }}
                                    onChange={(selectedOption) => handleSecurityChange(selectedOption, "vm_names")}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            minHeight: "35px",
                                            fontSize: "14px",
                                            borderColor: multiValueFields["vm_names"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                                        }),
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="image"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Image
                                <span id="image_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="bottom" target="image_t">
                                    {`Choose the A10 vThunder image that is right for you and your customers.`}<br />{`A10 Thunder ADC for Advanced Load Balancing - BYOL is set as a default Image ID.`}<br />{`Find more types of thunder images available in different capacities.`}<br />{`For more information, Go to Azure Portal -> Marketplace -> Search for 'vThunder' -> Select Image -> Usage Information + Support -> Plan ID is the Image ID.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    name="image"
                                    isSearchable
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                        return isAlphanumeric(inputValue)
                                    }}
                                    value={azureRegionImages[version_thunder].find(option => option.value === formState["thunderADC"]["azure"]["custom"]["image"]) || { "label": formState["thunderADC"]["azure"]["custom"]["image"], "value": formState["thunderADC"]["azure"]["custom"]["image"] }}
                                    options={azureRegionImages[version_thunder]}
                                    components={{ Option: CustomOption }}
                                    onChange={(selectedOption) => {
                                        handleChange({ target: { name: 'image', value: selectedOption.value } });
                                        updateProductBasedOnImage(selectedOption.value);
                                    }}
                                    formatOptionLabel={FormatOptionLabel}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '8px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '8px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="size"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Size
                                <span id="size_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="size_t">
                                    {`Specify/Choose the available instance or compute type supported for vThunder.`}<br />{`The default instance type is Standard A4mv2, which contains 8 vCPU and 32 GiB of memory.`}<br />{`vThunder requires a minimum of 4 vCPUs , 16 GiB of memory and support of 3 NICs attachment.`}<br />{`Go to the Azure portal -> Virtual machines -> Create a virtual machine -> Size.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    name="size"
                                    isSearchable
                                    value={azureSize.find(option => option.value === formState["thunderADC"]["azure"]["custom"]["size"])}
                                    options={azureSize}
                                    onChange={(selectedOption) => {
                                        handleChange({ target: { name: 'size', value: selectedOption.value } });
                                    }}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '8px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                                <a href="https://azure.microsoft.com/en-in/pricing/details/virtual-machines/series/" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="admin_username"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Username
                                <span id="admin_username_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="admin_username_t">
                                    {`Specify the username. The default value is "vth-user".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="admin_username"
                                    name="admin_username"
                                    placeholder="vth-user"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["admin_username"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["admin_username"])}
                                    value={formState["thunderADC"]["azure"]["custom"]["admin_username"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                    </Card>
                    <UncontrolledAccordion stayOpen className="mb-2">
                        <AccordionItem>
                            <AccordionHeader targetId="2">
                                Configure Network Settings
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                                <Card body color="light" className="p-0">
                                    <div className="p-3">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="vnet_nsg_rg"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Resource Group
                                                <span id="vnet_nsg_rg_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="vnet_nsg_rg_t">
                                                    {`Specify the resource group name where virtual network, security group and public IP address are already created. The default value is "vth-rg1".`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="vnet_nsg_rg"
                                                    name="vnet_nsg_rg"
                                                    placeholder="vth-rg1"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["vnet_nsg_rg"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["vnet_nsg_rg"])}
                                                    value={formState["thunderADC"]["azure"]["custom"]["vnet_nsg_rg"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="vnet_name"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Virtual Network (VNET)
                                                <span id="vnet_name_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="vnet_name_t">
                                                    {`Specify an existing virtual network name for virtual machines. This should be available within the specified resource group where public IP addresses and security groups are being created. The default value is "vth-vnet". Go to Azure Protal -> Virtual networks -> Virtual_networks_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="vnet_name"
                                                    name="vnet_name"
                                                    placeholder="vth-vnet"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["vnet_name"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["vnet_name"])}
                                                    value={formState["thunderADC"]["azure"]["custom"]["vnet_name"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="accelerated_networking"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Accelerated Networking
                                                <span id="accelerated_networking_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="accelerated_networking_t">
                                                    {isAcceleratedNetworkingSupported(formState["thunderADC"]["azure"]["custom"]["size"]) ? (
                                                        <>
                                                        {`Select "Yes" to enable accelerated networking to enable low latency and high throughput on the network interface.`}<br/>
                                                        {`Please ensure that the specified/selected size supports accelerated networking.`}
                                                    </>
                                                    ) : (
                                                        <>
                                                            {`Accelerated networking is not supported for the selected VM instance size.`}<br />
                                                            {`Please choose a different size that supports accelerated networking.`}
                                                        </>
                                                    )}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="accelerated_networking"
                                                    name="accelerated_networking"
                                                    placeholder="true"
                                                    required="true"
                                                    type="select"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["accelerated_networking"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["accelerated_networking"])}
                                                    value={formState["thunderADC"]["azure"]["custom"]["accelerated_networking"]}
                                                    onChange={handleChange}
                                                    disabled={!isAcceleratedNetworkingSupported(formState["thunderADC"]["azure"]["custom"]["size"])}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                                <a href="https://learn.microsoft.com/en-us/azure/virtual-network/create-vm-accelerated-networking-cli?tabs=windows" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="ip_forwarding"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                IP Forwarding
                                                <span id="ip_forwarding_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="ip_forwarding_t">
                                                    {`Select "Yes" to enabling IP forwarding to allow the virtual machine to forward network traffic between networks, greatly improving its networking performance.`}<br />{`This high-performance path bypasses the host from the datapath, reducing latency, jitter, and CPU utilization, for use with the most demanding network workloads on supported VM types.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="ip_forwarding"
                                                    name="ip_forwarding"
                                                    placeholder="true"
                                                    required="true"
                                                    type="select"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["ip_forwarding"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["ip_forwarding"])}
                                                    value={formState["thunderADC"]["azure"]["custom"]["ip_forwarding"]}
                                                    onChange={handleChange}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                                <a href="https://learn.microsoft.com/en-us/azure/virtual-network/virtual-network-network-interface?tabs=azure-portal#enable-or-disable-ip-forwarding-for-a-network-interface" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    {/*<!---Management--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Management
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_mgmt"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id="subnet_mgmt_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="subnet_mgmt_t">
                                                    {`Specify an existing subnet name that is available within a selected virtual network for management traffic inward to vThunder. This will not create a new subnet. The default value is "subnet-mgmt". Go to Azure Protal -> Virtual networks -> Virtual_networks_name -> subnets`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_mgmt"
                                                    name="subnet_mgmt"
                                                    placeholder="subnet-mgmt"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["subnet_mgmt"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["subnet_mgmt"])}
                                                    value={formState["thunderADC"]["azure"]["custom"]["subnet_mgmt"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/*<!---Data In--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Data IN
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_datain"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id="subnet_datain_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="subnet_datain_t">
                                                    {`Specify an existing subnet name that is available within a selected virtual network for data traffic inward to vThunder. This will not create a new subnet. The default value is "subnet-data-in". Go to Azure Protal -> Virtual networks -> Virtual_networks_name -> subnets`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_datain"
                                                    name="subnet_datain"
                                                    placeholder="subnet-data-in"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["subnet_datain"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["subnet_datain"])}
                                                    value={formState["thunderADC"]["azure"]["custom"]["subnet_datain"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/*<!---Data OUT--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Data OUT
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_dataout"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id="subnet_dataout_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="subnet_dataout_t">
                                                    {`Specify an existing subnet name that is available within a selected virtual network for data traffic outward to vThunder. This will not create a new subnet. The default value is "subnet-data-out". Go to Azure Protal -> Virtual networks -> Virtual_networks_name -> subnets`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_dataout"
                                                    name="subnet_dataout"
                                                    placeholder="subnet-data-out"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["subnet_dataout"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["subnet_dataout"])}
                                                    value={formState["thunderADC"]["azure"]["custom"]["subnet_dataout"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    <hr className='mb-0 pb-0 pt-3' />

                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                    <UncontrolledAccordion stayOpen className="mb-2">
                        <AccordionItem>
                            <AccordionHeader targetId="2">
                                Configure Security Settings
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                                <Card body color="light">
                                    <FormGroup row>
                                        <Label bsSize="sm" for="nsg_mgmt"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Management)
                                            <span id="nsg_mgmt_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target="nsg_mgmt_t">
                                                {`Specify an existing network security group name for all the network interface card. This network security group should be available within the specified resource group where virtual network and public IP addresses are being created. This will not create a new network security group for a virtual machine. The default value is "vth-mgmt-nsg". Go to Azure Protal -> Network security groups -> Network_security_group_name`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_mgmt"
                                                name="nsg_mgmt"
                                                placeholder="vth-mgmt-nsg"
                                                required="true"
                                                type="text"
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["nsg_mgmt"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["nsg_mgmt"])}
                                                value={formState["thunderADC"]["azure"]["custom"]["nsg_mgmt"]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for="nsg_datain"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Data-IN)
                                            <span id="nsg_datain_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target="nsg_datain_t">
                                                {`Specify an existing network security group name for all the network interface card. This network security group should be available within the specified resource group where virtual network and public IP addresses are being created. This will not create a new network security group for a virtual machine. The default value is "vth-datain-nsg". Go to Azure Protal -> Network security groups -> Network_security_group_name`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_datain"
                                                name="nsg_datain"
                                                placeholder="vth-datain-nsg"
                                                required="true"
                                                type="text"
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["nsg_datain"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["nsg_datain"])}
                                                value={formState["thunderADC"]["azure"]["custom"]["nsg_datain"]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for="nsg_dataout"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Data-OUT)
                                            <span id="nsg_dataout_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target="nsg_dataout_t">
                                                {`Specify an existing network security group name for all the network interface card. This network security group should be available within the specified resource group where virtual network and public IP addresses are being created. This will not create a new network security group for a virtual machine. The default value is "vth-dataout-nsg". Go to Azure Protal -> Network security groups -> Network_security_group_name`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_dataout"
                                                name="nsg_dataout"
                                                placeholder="vth-dataout-nsg"
                                                required="true"
                                                type="text"
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["nsg_dataout"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["custom"]["nsg_dataout"])}
                                                value={formState["thunderADC"]["azure"]["custom"]["nsg_dataout"]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    )
}

export default AzureCustomForm