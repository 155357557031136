import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardColumns,Col, FormGroup, Button,Label,UncontrolledTooltip, Input } from "reactstrap";
import { FaTrashAlt } from 'react-icons/fa';
import { isAlphanumeric, isIpValid } from "../../../../utils/validations";
const SLBServer = ({formState, setFormState, siteIndex, region}) =>{

    const resetServiceGrpServer = (idx, newFormState) => {
        newFormState["gslb"][region][siteIndex]["service_group"].forEach(ele => {
            ele["member_list"].forEach(member => {
                if(member.server_name === idx){
                    member.server_name = -1
                }
            })
        });
    }

    const addSLBServer =()=>{
        let newFormState = { ...formState }
        let index = newFormState["gslb"][region][siteIndex]["server"].length
        newFormState["gslb"][region][siteIndex]["server"].push({ name: `server${index+1}`,host:"10.0.3.7",health_check_disable:"1", port_list: [{ port_number: "80", protocol: "tcp", health_check_disable: "1" }] })
        setFormState(newFormState)
    }
    const deleteSLBServer = (idx) =>{
        let newFormState = { ...formState }
        resetServiceGrpServer(idx, newFormState);
        newFormState["gslb"][region][siteIndex]["server"].splice(idx, 1)
        setFormState(newFormState)
    }
    const handleChanges = (e,idx) =>{
        let newFormState = {...formState}
        if (e.target.name === "health_check_disable") {
            newFormState["gslb"][region][siteIndex]["server"][idx] = { ...newFormState["gslb"][region][siteIndex]["server"][idx], [e.target.name]: (e.target.value === "enable" ? 0 : 1) }
        }
        else{
        newFormState["gslb"][region][siteIndex]["server"][idx] = { ...newFormState["gslb"][region][siteIndex]["server"][idx], [e.target.name]: e.target.value }
        }
        setFormState(newFormState)
    }
    const deletePortList =(idx,pidx) =>{
        let newFormState = { ...formState }
        resetServiceGrpServer(idx, newFormState)
        newFormState["gslb"][region][siteIndex]["server"][idx]["port_list"].splice(pidx, 1)
        setFormState(newFormState)
    }
    const handlePortChange = (e, idx, pidx) => {
        let newFormState = { ...formState }
        if (e.target.name === "health_check_disable") {
            newFormState["gslb"][region][siteIndex]["server"][idx]["port_list"][pidx] = { ...newFormState["gslb"][region][siteIndex]["server"][idx]["port_list"][pidx], [e.target.name]: (e.target.value === "enable" ? 0 : 1) }
        } 
        else{
            if(e.target.name === "protocol"){
            resetServiceGrpServer(idx, newFormState)
            }
        newFormState["gslb"][region][siteIndex]["server"][idx]["port_list"][pidx] = { ...newFormState["gslb"][region][siteIndex]["server"][idx]["port_list"][pidx], [e.target.name]: e.target.value }
        }
        setFormState(newFormState)
    }
    const handleAddPort = (idx) => {
        let newFormState = { ...formState }
        newFormState["gslb"][region][siteIndex]["server"][idx]["port_list"].push({ port_number: "80", protocol: "tcp", health_check_disable: 0 })
        setFormState(newFormState)
    }
    return (
        <AccordionItem>
            <AccordionHeader targetId={`${siteIndex}_1`}>
                Configure SLB Server
            </AccordionHeader>
            <AccordionBody accordionId={`${siteIndex}_1`}>
            <div className="d-flex justify-content-end">
                    <Button className="mb-2" size="sm" onClick={addSLBServer}>Add SLB Server</Button>
                </div>
                <CardColumns>
                {formState["gslb"][region][siteIndex]["server"].map((fields,idx)=>{
                    return(
                        <Card key={idx} body color="light" className='mb-1'>
                            <div className="d-flex justify-content-end">
                                {idx > 0 && <Button className='mb-2' size="sm" disabled={idx === 0} onClick={() => deleteSLBServer(idx)}><FaTrashAlt /></Button>}
                            </div>
                            <FormGroup row>
                            <Label bsSize="sm" for={`name-${idx + 1}-${siteIndex+1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Name
                                    <span id={`slbservername-${region}-t-${idx + 1}_${siteIndex+1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`slbservername-${region}-t-${idx + 1}_${siteIndex+1}`}>
                                    Specify unique name of application server.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                <Input
                                        bsSize="sm"
                                        id={`name-${idx + 1}-${siteIndex+1}`}
                                        name="name"
                                        placeholder="server1"
                                        type="text"
                                        value={fields["name"]}
                                        valid={isAlphanumeric(fields["name"])}
                                        invalid={!isAlphanumeric(fields["name"])}
                                        onChange={(e) => handleChanges(e, idx)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label bsSize="sm" for={`host-${idx + 1}-${siteIndex+1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Host
                                    <span id={`slbserverhost-${region}-t-${idx + 1}_${siteIndex+1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`slbserverhost-${region}-t-${idx + 1}_${siteIndex+1}`}>
                                    Specify host of application server "{fields["name"]}".
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`host-${idx + 1}-${siteIndex+1}`}
                                        name="host"
                                        placeholder="101.188.67.134"
                                        type="text"
                                        value={fields["host"]}
                                        valid={isIpValid(fields["host"])}
                                        invalid={!isIpValid(fields["host"])}
                                        onChange={(e) => handleChanges(e, idx)} />
                                        </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label bsSize="sm" for={`health_check_disable-${idx + 1}-${siteIndex+1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Health Check 
                                    <span id={`slbserverhealthCheckDisable_s-${region}-t${idx + 1}_${siteIndex+1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`slbserverhealthCheckDisable_s-${region}-t${idx + 1}_${siteIndex+1}`}>
                                    Select health check monitoring option enable or disable on server "{fields["name"]}".
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`health_check_disable-${idx + 1}-${siteIndex+1}`}
                                        name="health_check_disable"
                                        type="select"
                                        value={fields["health_check_disable"] === 0 ? "enable" : "disable"}
                                        onChange={(e) => handleChanges(e, idx)} >
                                            <option>enable</option>
                                                <option>disable</option>
                                            </Input>
                                </Col>
                            </FormGroup>
                            {fields["port_list"].map((port,pidx)=>{
                                return(
                                    <div key={`${idx}_${pidx}`}>
                                        <div className="d-flex justify-content-end">
                                        {pidx > 0 && <Button className='mb-1' size="sm" disabled={pidx === 0} onClick={() => deletePortList(idx, pidx)}><FaTrashAlt /></Button>}
                                    </div>
                                    <FormGroup row>
                                        <Label bsSize="sm" for={`port_number-${pidx + 1}-${siteIndex+1}`} sm={4}
                                            style={{ fontSize: "13px", textAlign: "end" }}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Port
                                            <span id={`slbserverpportNumber-${region}-t${pidx + 1}_${siteIndex+1}`} style={{ fontSize: "14px" }}>
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`slbserverpportNumber-${region}-t${pidx + 1}_${siteIndex+1}`}>
                                            Specify port number of application server "{fields["name"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`port_number-${pidx + 1}-${siteIndex+1}`}
                                                name="port_number"
                                                placeholder="80"
                                                type="number"
                                                value={port["port_number"]}
                                                valid={isAlphanumeric(port["port_number"])}
                                                invalid={!isAlphanumeric(port["port_number"])}
                                                onChange={(e) => handlePortChange(e, idx, pidx)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for={`protocol-${pidx + 1}-${siteIndex+1}`} sm={4}
                                            style={{ fontSize: "13px", textAlign: "end" }}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Protocol
                                            <span id={`slbserverpprotocol-${region}-t${pidx + 1}_${siteIndex+1}`} style={{ fontSize: "14px" }}>
                                                <strong> ⓘ</strong>
                                            </span>
                                            <UncontrolledTooltip placement="top" target={`slbserverpprotocol-${region}-t${pidx + 1}_${siteIndex+1}`}>
                                            Select protocol for port "{port["port_number"]}". 
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input
                                                bsSize="sm"
                                                id={`protocol-${pidx + 1}-${siteIndex+1}`}
                                                name="protocol"
                                                placeholder="Select"
                                                type="select"
                                                value={port["protocol"]}
                                                onChange={(e) => handlePortChange(e, idx, pidx)}>
                                                <option>tcp</option>
                                                <option>udp</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                <Label bsSize="sm" for={`health_check_disable-${idx + 1}-${siteIndex+1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Health Check 
                                    <span id={`healthCheckDisable_port-${region}-t${idx + 1}_${siteIndex+1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`healthCheckDisable_port-${region}-t${idx + 1}_${siteIndex+1}`}>
                                    Select health check monitoring option enable or disable on port "{port["port_number"]}".
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`health_check_disable-${idx + 1}-${siteIndex+1}`}
                                        name="health_check_disable"
                                        type="select"
                                        value={port["health_check_disable"] === 0 ? "enable" : "disable"}
                                        onChange={(e) => handlePortChange(e, idx,pidx)} >
                                            <option>enable</option>
                                                <option>disable</option>
                                            </Input>
                                </Col>
                            </FormGroup>
                            <div className="d-flex justify-content-end">
                                        {pidx === fields["port_list"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddPort(idx)}>Add Port List</Button>}
                                    </div>
                                    </div>
                                )
                            })}
                        </Card>
                    )
                })}
                </CardColumns>
            </AccordionBody>
        </AccordionItem>

    )
}
export default SLBServer;