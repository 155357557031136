import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap";
import VirtualServer from "./VirtualServer";
import GSLBPolicy from "./GSLBPolicy";
import GSLBZone from "./GSLBZone";
import ServiceIP from "./ServiceIP";

const GSLBSetting = ({ formState, setFormState, siteCount }) => {
    return (
        <UncontrolledAccordion stayOpen className='mb-2'>
            <AccordionItem>
                <AccordionHeader targetId="1_2">
                    Configure GSLB Settings
                </AccordionHeader>
                <AccordionBody accordionId="1_2">
                    {/* <p className="note">Settings will be applied to all the thunders.</p> */}
                    <GSLBPolicy formState={formState} setFormState={setFormState} />
                    <GSLBZone formState={formState} setFormState={setFormState} />
                    <VirtualServer formState={formState} setFormState={setFormState} />
                    <ServiceIP formState={formState} setFormState={setFormState} siteCount={siteCount} />
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    )

}
export default GSLBSetting