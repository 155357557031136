import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardColumns, Col, FormGroup, Button, Label, UncontrolledTooltip, Input } from "reactstrap";
import { FaTrashAlt } from 'react-icons/fa';
import { isAlphanumeric, isIpValid } from "../../../../utils/validations";

const VirtualServerSLB = ({ formState, setFormState, siteIndex, region }) => {
    const handleChange = (e) => {
        let newFormState = { ...formState }
        newFormState["gslb"][region][siteIndex]["virtualServer"] = {
            ...newFormState["gslb"][region][siteIndex]["virtualServer"],
            [e.target.name]: e.target.value,
        };
        setFormState(newFormState);
    };
    const handleDeletePortList = (pidx) => {
        let newFormState = { ...formState }
        newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"].splice(pidx, 1)
        setFormState(newFormState);
    }
    const handlePortChange = (e, pidx) => {
        let newFormState = { ...formState };
        if(e.target.name === "auto"){
            newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"][pidx] ={
                ...newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"][pidx],[e.target.name]: (e.target.value === "enable" ? 1 : 0) 
            }
        }
        else if (e.target.name === "service_group") {
            newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"][pidx] = {
                ...newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"][pidx],
                [e.target.name]: parseInt(e.target.value)
            }
        } else {
            newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"][pidx] = {
                ...newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"][pidx],
                [e.target.name]: e.target.value
            }
        }
        setFormState(newFormState)
    }
    const handleAddPortList = () => {
        let newFormState = { ...formState }
        newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"].push({ "port_number": "80", "protocol": "tcp", "auto": 1, "service_group": -1 })
        setFormState(newFormState);
    }
    return (
        <AccordionItem>
            <AccordionHeader targetId={`${siteIndex}_3`}>
                Configure SLB Virtual Server
            </AccordionHeader>
            <AccordionBody accordionId={`${siteIndex}_3`}>
                <Card key={`${siteIndex + 1}`} body color="light" className='mb-1'>
                    <FormGroup row>
                        <Label bsSize="sm" for={`name-${siteIndex + 1}`} sm={4} style={{ fontSize: "13px", textAlign: "end" }}>
                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                            Name
                            <span id={`slbvirtualServer_mastername-${region}-t-${siteIndex + 1}`} style={{ fontSize: "14px" }}>
                                <strong> ⓘ</strong>
                            </span>
                            <UncontrolledTooltip placement="top" target={`slbvirtualServer_mastername-${region}-t-${siteIndex + 1}`}>
                                {region === "master_site_setting" && `The name is same as the name defined in Configure Region1 -> Configure GSLB Settings -> Configure Service IP - Site -> Card-${siteIndex + 1} -> Name.`}
                                {region === "member_site_setting" && `The name is same as the name defined in Configure Region2 -> Configure GSLB Settings -> Configure Service IP - Site -> Card-${siteIndex + 1} -> Name.`}
                            </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                            <Input
                                bsSize="sm"
                                id={`name-${siteIndex + 1}`}
                                name="name"
                                type="text"
                                disabled
                                placeholder="site1"
                                value={region === "master_site_setting" ? formState["gslb"]["gslbSetting"]["serviceIP_master"][siteIndex]["name"] : formState["gslb"]["gslbSetting"]["serviceIP_member"][siteIndex]["name"]}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label bsSize="sm" for={`host-${siteIndex + 1}`} sm={4} style={{ fontSize: "13px", textAlign: "end" }}>
                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                            Host
                            <span id={`slbHost_master-${region}-t-${siteIndex + 1}`} style={{ fontSize: "14px" }}>
                                <strong> ⓘ</strong>
                            </span>
                            <UncontrolledTooltip placement="top" target={`slbHost_master-${region}-t-${siteIndex + 1}`}>
                                {region === "master_site_setting" && `The host is the private IP of site to which the virtual server "${formState["gslb"]["gslbSetting"]["serviceIP_master"][siteIndex]["name"]}" is mapped with. The private IP is defined in Configure Region1 -> Site-${siteIndex + 1} -> Private IP.`}
                                {region === "member_site_setting" && `The host is the private IP of site to which the virtual server "${formState["gslb"]["gslbSetting"]["serviceIP_member"][siteIndex]["name"]}" is mapped with. The private IP is defined in Configure Region2 -> Site-${siteIndex + 1} -> Private IP.`}
                            </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                            <Input
                                bsSize="sm"
                                id={`host-${siteIndex + 1}`}
                                name="host"
                                type="text"
                                placeholder="x.x.x.x"
                                disabled
                                value={region === "master_site_setting" ? formState["gslb"]["master"]["sites"][siteIndex]["site_privateIP"] : formState["gslb"]["member"]["sites"][siteIndex]["site_privateIP"]}
                                valid={isAlphanumeric(formState["gslb"][region][siteIndex]["virtualServer"]["host"])}
                                invalid={!isAlphanumeric(formState["gslb"][region][siteIndex]["virtualServer"]["host"])} />
                        </Col>
                    </FormGroup>
                    {formState["gslb"][region][siteIndex]["virtualServer"]["port_list"].map((portList, pidx) => {
                        return (
                            <div key={`${siteIndex + 1}_${pidx}`}>
                                <div className="d-flex justify-content-end">
                                    {pidx > 0 && <Button className='mb-1' size="sm" disabled={pidx === 0} onClick={() => handleDeletePortList(pidx)}><FaTrashAlt /></Button>}
                                </div>

                                <FormGroup row>
                                    <Label bsSize="sm" for={`portNumberSLB-${pidx + 1}`} sm={4}
                                        style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Port
                                        <span id={`portNumberSLB_member-${region}-${pidx + 1}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`portNumberSLB_member-${region}-${pidx + 1}`}>
                                            {region === "master_site_setting" && `Specify virtual port number for virtual server.`}
                                            {region === "member_site_setting" && `Specify virtual port number for virtual server.`}

                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`portNumberSLB-${pidx + 1}`}
                                            name="port_number"
                                            placeholder="80"
                                            type="number"
                                            value={portList["port_number"]}
                                            valid={isAlphanumeric(portList["port_number"])}
                                            invalid={!isAlphanumeric(portList["port_number"])}
                                            onChange={(e) => handlePortChange(e, pidx)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label bsSize="sm" for={`protocolSLB-${pidx + 1}`} sm={4}
                                        style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Protocol
                                        <span id={`protocolSLB_member-${region}-${pidx + 1}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`protocolSLB_member-${region}-${pidx + 1}`}>
                                            Select protocol for virtual port.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`protocolSLB-${pidx + 1}`}
                                            name="protocol"
                                            placeholder="Select"
                                            type="select"
                                            value={portList["protocol"]}
                                            onChange={(e) => handlePortChange(e, pidx)} >
                                            <option>tcp</option>
                                            <option>udp</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label bsSize="sm" for={`autoSLB${pidx + 1}`} sm={4}
                                        style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Auto NAT (vPort)
                                        <span id={`autoSLB_member-${region}-${pidx + 1}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`autoSLB_member-${region}-${pidx + 1}`}>
                                            Select enable for auto NAT configuration for virtual port.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`autoSLB-${pidx + 1}`}
                                            name="auto"
                                            placeholder="Select"
                                            type="select"
                                            value={portList["auto"]=== 1 ? "enable" : "disable"}
                                            onChange={(e) => handlePortChange(e, pidx)} >
                                            <option>enable</option>
                                            <option>disable</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label bsSize="sm" for={`serviceGroupSLB${pidx + 1}`} sm={4}
                                        style={{ fontSize: "13px", textAlign: "end" }}>
                                        <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                        Service Group
                                        <span id={`serviceGroupSLB_member-${region}-${pidx + 1}`} style={{ fontSize: "14px" }}>
                                            <strong> ⓘ</strong>
                                        </span>
                                        <UncontrolledTooltip placement="top" target={`serviceGroupSLB_member-${region}-${pidx + 1}`}>
                                            Select service group name to bind with virtual port.
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                            bsSize="sm"
                                            id={`serviceGroupSLB-${pidx + 1}`}
                                            name="service_group"
                                            placeholder="Select"
                                            type="select"
                                            value={portList["service_group"]}
                                            valid={isAlphanumeric(portList["service_group"])}
                                            invalid={!isAlphanumeric(portList["service_group"])}
                                            onChange={(e) => handlePortChange(e, pidx)} >
                                            <option selected={true} hidden>Select</option>
                                            {formState["gslb"][region][siteIndex]["service_group"].map((option, gidx) => {
                                                return option["name"] && <option key={`${gidx}_${pidx}`} value={gidx} >{option['name']}</option>
                                            })}
                                        </Input>
                                    </Col>
                                </FormGroup>

                            </div>
                        )
                    })}
                    <div className="d-flex justify-content-end">
                        {<Button className="ms-1 mb-1" size="sm" onClick={() => handleAddPortList()}>Add Port List</Button>}
                    </div>
                </Card>
            </AccordionBody>
        </AccordionItem>
    )
}
export default VirtualServerSLB