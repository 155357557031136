import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, Col, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric } from "../../../../utils/validations";

const Region2VirtualServer = ({ formState, setFormState }) => {
    const handleChange = (e, value) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"][value] = { ...newFormState["gslb"]["gslbSetting"][value], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }
    const handleAddPortList = (value) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"][value]["portList"].push({ "portNumber": "80", "protocol": "tcp" })
        setFormState(newFormState)
    }
    const handleDeletePortList = (pidx, value) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"][value]["portList"].splice(pidx, 1)
        setFormState(newFormState)
    }
    const handlePortChange = (e, pidx, value) => {
        let newFormState = { ...formState }
        newFormState["gslb"]["gslbSetting"][value]["portList"][pidx] = { ...newFormState["gslb"]["gslbSetting"][value]["portList"][pidx], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }
    return (
        <AccordionItem>
            <AccordionHeader targetId="2_2_3">
                Configure GSLB Virtual Server
            </AccordionHeader>
            <AccordionBody accordionId="2_2_3">
                <Card body color="light">
                    <FormGroup row>
                        <Label bsSize="sm" for="name" sm={4}
                            style={{ fontSize: "13px", textAlign: "end" }}>
                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                            Name
                            <span id="slbmname_member-t" style={{ fontSize: "14px" }}>
                                <strong> ⓘ</strong>
                            </span>
                            <UncontrolledTooltip placement="top" target="slbmname_member-t">
                                Specify unique name for gslb virtual server.
                            </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                            <Input
                                bsSize="sm"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="slbVirtualServer"
                                value={formState["gslb"]["gslbSetting"]["virtualServer_member"]["name"]}
                                valid={isAlphanumeric(formState["gslb"]["gslbSetting"]["virtualServer_member"]["name"])}
                                invalid={!isAlphanumeric(formState["gslb"]["gslbSetting"]["virtualServer_member"]["name"])}
                                onChange={(e) => handleChange(e, "virtualServer_member")}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label bsSize="sm" for="memberConPrivateIPData" sm={4}
                            style={{ fontSize: "13px", textAlign: "end" }}>
                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                            Member Controller Private IP
                            <span id="memberConPrivateIPData_member-t" style={{ fontSize: "14px" }}>
                                <strong> ⓘ</strong>
                            </span>
                            <UncontrolledTooltip placement="top" target="memberConPrivateIPData_member-t">
                                {`The private IP is the private IP of member controller and located in Configure Region2 -> Member Controller -> private IP.`}
                            </UncontrolledTooltip>
                        </Label>
                        <Col sm={7}>
                            <Input
                                bsSize="sm"
                                id="memberConPrivateIPData"
                                name="memberConPrivateIPData"
                                type="text"
                                disabled
                                placeholder="X.X.X.X"
                                value={formState['gslb']["member"]["memberController"]["member_privateIP"]}
                            />
                        </Col>
                    </FormGroup>
                    {formState["gslb"]["gslbSetting"]["virtualServer_member"]["portList"].map((item, pidx) => {
                        return <div key={`${pidx}`}>
                            <div className="d-flex justify-content-end">
                                {pidx > 0 && <Button className='mb-1' size="sm" disabled={pidx === 0} onClick={() => handleDeletePortList(pidx, "virtualServer_member")}><FaTrashAlt /></Button>}
                            </div>
                            <FormGroup row>
                                <Label bsSize="sm" for={`portNumber-${pidx + 1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Port
                                    <span id={`slbportNumber_member-tooltip${pidx + 1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`slbportNumber_member-tooltip${pidx + 1}`}>
                                        Specify port number for GSLB virtual server "{formState["gslb"]["gslbSetting"]["virtualServer_member"]["name"]}".
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`portNumber-${pidx + 1}`}
                                        name="portNumber"
                                        placeholder="53"
                                        type="number"
                                        value={item["portNumber"]}
                                        valid={isAlphanumeric(item["portNumber"])}
                                        invalid={!isAlphanumeric(item["portNumber"])}
                                        onChange={(e) => handlePortChange(e, pidx, "virtualServer_member")} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label bsSize="sm" for={`protocol-${pidx + 1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Protocol
                                    <span id={`slbprotocol_member-tooltip${pidx + 1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`slbprotocol_member-tooltip${pidx + 1}`}>
                                        Protocol should be udp only in order to enable gslb.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        bsSize="sm"
                                        id={`protocol-${pidx + 1}`}
                                        name="protocol"
                                        placeholder="Select"
                                        disabled
                                        value={item["protocol"]} />
                                </Col>
                            </FormGroup>
                            <div className="d-flex justify-content-end">
                                {pidx === formState["gslb"]["gslbSetting"]["virtualServer_member"]["portList"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddPortList("virtualServer_member")}>Add Port List</Button>}
                            </div>
                        </div>
                    })}
                </Card>
            </AccordionBody>
        </AccordionItem>
    )
}

export default Region2VirtualServer