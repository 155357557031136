import React from "react"

const AzureHAEditor = ({ formState, vThunderCount }) => {
    return (<>
        {/*Virtual Machine 1 */}
        <div>
            <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_VM_1:
            </code>
            {formState["thunderADC"]["azure"]["ha"]["location"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["location"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["existing_resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\texisting_resource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["existing_resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["instance_name1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["instance_name1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["image"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tsize`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["size"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["admin_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["admin_username"]}</span>
            </code>}
            {/* <-------- Network --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["vnet_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tvirtual_network_vnet`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["vnet_name"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["pub_vip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tpublic_vip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["pub_vip"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["publicIP_name1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tpublic_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["publicIP_name1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["accelerated_networking"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\taccelerated_networking`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["accelerated_networking"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["ip_forwarding"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["nic_mgmt1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nic_mgmt1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["subnet_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["subnet_datain"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["nic_datain1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nic_datain1"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["subnet_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["subnet_dataout"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["nic_dataout1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nic_dataout1"]}</span>
            </code>}
            {/* <-------- Security --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Security_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["ha"]["nsg_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nsg_datain"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["ha"]["nsg_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nsg_dataout"]}</span>
                </code>}

        </div>
        <br />
        {/*Virtual Machine 2 */}
        <div>
            <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_VM_2:
            </code>
            {formState["thunderADC"]["azure"]["ha"]["location"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["location"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["existing_resource_group"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\texisting_resource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["existing_resource_group"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["instance_name2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["instance_name2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["image"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\timage`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["image"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tsize`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["size"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["admin_username"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tusername`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["admin_username"]}</span>
            </code>}
            {/* <-------- Network --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tresource_group`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["vnet_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tvirtual_network_vnet`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["vnet_name"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["publicIP_name2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tpublic_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["publicIP_name2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["accelerated_networking"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\taccelerated_networking`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["accelerated_networking"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["ip_forwarding"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tip_forwarding`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["ip_forwarding"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["nic_mgmt2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nic_mgmt2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["subnet_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["subnet_datain"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["nic_datain2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nic_datain2"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["subnet_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsubnet_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["subnet_dataout"]}</span>
            </code>}
            {formState["thunderADC"]["azure"]["ha"]["nic_dataout2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tnic_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nic_dataout2"]}</span>
            </code>}
            {/* <-------- Security --------->*/}
            {vThunderCount !== "gslb" && <><code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Security_Settings:"}
            </code>
            {formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_management`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["ha"]["nsg_datain"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nsg_datain"]}</span>
                </code>}
                {formState["thunderADC"]["azure"]["ha"]["nsg_dataout"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tnetwork_security_group_data_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["azure"]["ha"]["nsg_dataout"]}</span>
                </code>}
                </>}
        </div>
    </>
    )

}
export default AzureHAEditor