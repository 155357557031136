import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { clouds } from '../../data/parameters';

const ExecuteModal = ({ modal, setModal, cloud }) => {

    const [nestedModal, setNestedModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);

    const handleExecute = () => {
        toggle()
        // deploy(formState)
        //   .then((response) => {
        //     console.log(response.status, response.data)
        //   })
        //   .catch((error) => console.log(error.response.data.error));
    }

    const toggle = () => setModal(!modal);
    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
    };
    const toggleAll = () => {
        toast.success("Thunder created successfully");
        setNestedModal(!nestedModal);
        setCloseAll(true);
    };

    return (
        <div className='d-inline'>
            {/* <Button color="danger" onClick={handleExecute}>
                Click Me
            </Button> */}

            <Button color="primary" size="sm" className="ms-0 mt-2 me-3" onClick={handleExecute} style={{ display: "none" }}>EXECUTE</Button>
            <Modal size="sm" isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{clouds[cloud]}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup size="sm" floating>
                            <Input
                                id="username"
                                name="username"
                                placeholder="Username"
                                type="text"
                                size="sm"
                                autoComplete="off"
                            />
                            <Label for="username">
                                Username
                            </Label>
                        </FormGroup>
                        <FormGroup size="sm" floating>
                            <Input
                                id="password"
                                name="password"
                                placeholder="Password"
                                type="password"
                                size="sm"
                            />
                            <Label for="password">
                                Password
                            </Label>
                        </FormGroup>
                    </Form>
                    <Modal
                        isOpen={nestedModal}
                        toggle={toggleNested}
                        onClosed={closeAll ? toggle : undefined}
                    >
                        <ModalBody>Are you sure? <br />
                            <Button size="sm" className="me-2 mt-2" color="primary" onClick={toggleAll}>
                                Confirm
                            </Button>
                            <Button size="sm" className="mt-2" color="secondary" onClick={toggleNested}>
                                Cancel
                            </Button>
                        </ModalBody>
                    </Modal>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleNested}>
                        Execute
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ExecuteModal
