import React from 'react'
import AwsStandaloneEditor from './AWS/AwsStandaloneEditor'
import AwsHAEditor from './AWS/AwsHAEditor'
import AwsGSLBEditor from './AWS/AwsGSLBEditor'
import AwsCustomEditor from './AWS/AwsCustomEditor'
import GCPEditor from './GCPEditor'
import AzureEditor from './Azure/AzureEditor'
import VMwareEditor from './VMwareEditor'
import OracleStandaloneEditor from './Oracle/OracleStandaloneEditor'
import OracleGslbEditor from './Oracle/OracleGslbEditor'
import OracleHaEditor from './Oracle/OracleHaEditor'
import OracleCustomEditor from './Oracle/OracleCustomEditor'

const DeploymentEditor = ({ formState, product, cloud, vThunderCount }) => {
    if (cloud === "vmware") return <VMwareEditor formState={formState} vThunderCount={vThunderCount} />
    else if (cloud === "gcp") return <GCPEditor formState={formState} vThunderCount={vThunderCount} />
    else if (cloud === "azure") return <AzureEditor formState={formState} vThunderCount={vThunderCount} />
    else if (cloud === "aws") {
        if (vThunderCount === "standalone") {
            return <AwsStandaloneEditor formState={formState} />
        } else if (vThunderCount === "ha") {
            return <AwsHAEditor formState={formState} />
        } else if (vThunderCount === "gslb") {
            return <AwsGSLBEditor formState={formState} />
        } else if(vThunderCount === "custom") {
            return <AwsCustomEditor formState={formState}/>
        }
    }
    else if (cloud === "oracle") {
        if (vThunderCount === "standalone") {
            return <OracleStandaloneEditor formState={formState} />
        } else if (vThunderCount === "ha") {
            return <OracleHaEditor formState={formState} />
        } else if (vThunderCount === "gslb") {
            return <OracleGslbEditor formState={formState} />
        } else if (vThunderCount === "custom") {
            return <OracleCustomEditor formState={formState} />
        }
    }
    else return <code className="d-block" style={{ fontSize: "13px" }}>coming soon..</code>
}

export default DeploymentEditor
