import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardHeader, FormGroup, Label, Col, Input, UncontrolledAccordion, UncontrolledTooltip, CardBody, Row } from "reactstrap";
import { isAlphanumeric } from '../../../../utils/validations';
import CreatableSelect from 'react-select/creatable';
import { oracleImages, oracleRegion, oracleSize } from "../../../../data/parameters";
import Select from 'react-select';

const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className='ms-1 mb-2'>
        <div style={{ fontSize: '14px' }}>{label}</div>
        {(data.version && data.link) && <Row className="m-0" style={{ fontSize: '12px', color: '#888' }}>
            <Col>version-{data.version}</Col>
            <Col className='text-end me-2'><a target="_blank" href={data.link}>more details</a></Col>
        </Row>}
    </div>
);
const FormatOptionLabel = ({ label, version, link }) => {
    return (
        <div className='m-0 p-0'>
            <div style={{ fontSize: '14px' }}>{label}</div>
            {(version && link) &&
                <Row style={{ fontSize: '12px', color: '#888' }}>
                    <Col>version-{version}</Col>
                    <Col className='text-end me-2'><a target="_blank" href={link} style={{ "textDecoration": "none" }}>More Details</a></Col>
                </Row>
            }
        </div>
    );
}

const OracleStandaloneForm = ({ formState, setFormState, version_thunder }) => {
    const handleChange = (e) => {
        let newFormState = { ...formState };
        newFormState["thunderADC"]["oracle"]["standalone"] = { ...newFormState["thunderADC"]["oracle"]["standalone"], [e.target.name]: e.target.value }
        setFormState(newFormState);
    }
    const [securityGrpFields, setSecurityGrpFields] = useState({
        "mgmt_nsg_ids": [
            { value: formState["thunderADC"]["oracle"]["standalone"]["mgmt_nsg_ids"][0], label: formState["thunderADC"]["oracle"]["standalone"]["mgmt_nsg_ids"][0] }
        ],
        "data_nsg_ids": [
            { value: formState["thunderADC"]["oracle"]["standalone"]["data_nsg_ids"][0], label: formState["thunderADC"]["oracle"]["standalone"]["data_nsg_ids"][0] }
        ]
    });

    const handleSecurityChange = (selectedList, fieldName) => {
        const valuesArray = selectedList.map(item => item.value);

        let newSecurityGrpFields = { ...securityGrpFields }
        newSecurityGrpFields[fieldName] = selectedList
        setSecurityGrpFields(newSecurityGrpFields)

        let newFormState = { ...formState };
        newFormState["thunderADC"]["oracle"]["standalone"][fieldName] = valuesArray;
        // console.log(newFormState["thunderADC"]["oracle"]["standalone"]['mgmt_nsg_ids'])
        setFormState(newFormState);
    };
    return (
        <UncontrolledAccordion stayOpen className="pb-2">
            <AccordionItem>
                <AccordionHeader targetId="1">
                    Configure Virtual Machine
                </AccordionHeader>
                <AccordionBody accordionId="1">
                    <Card body color="light" className="pb-0">
                        <FormGroup row>
                            <Label bsSize="sm" for="region" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Region
                                <span id="oracle-1vm-region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-region_t">
                                    {`Specify region identifier where resources will be deployed.`}<br />{`The default value is "us-phoenix-1".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    inputId="region"
                                    name="region"
                                    placeholder="Select a region"
                                    isSearchable
                                    value={oracleRegion.find((region) => region.value === formState["thunderADC"]["oracle"]["standalone"]["region"])}
                                    onChange={(selectedOption) => handleChange({ target: { name: 'region', value: selectedOption.value } })}
                                    options={oracleRegion}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '4px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="compartment_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Compartment ID
                                <span id="oracle-1vm-compartment_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-compartment_id_t">
                                    {`Specify the compartment OCID inside which resources will be deployed. Go to the 'Identity & Security' section in the OCI Console -> click 'Compartments' under Identity and select the desired compartment -> copy OCID under Compartment Information.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="compartment_id"
                                    name="compartment_id"
                                    placeholder="ocid1.compartment.oc1..xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["compartment_id"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["compartment_id"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["compartment_id"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="availability_domain" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Domain
                                <span id="oracle-1vm-availability_domain_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-availability_domain_t">
                                    {`Specify availability domain name.`}<br />{`The default value is "LjsV:PHX-AD-1".`}<br />{`Check availability domain in Placement section while creating an instance.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="availability_domain"
                                    name="availability_domain"
                                    placeholder="LjsV:PHX-AD-1"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["availability_domain"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["availability_domain"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["availability_domain"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="thunder_name_1" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                VM Name
                                <span id="oracle-1vm-vm_name_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-vm_name_t">
                                    {`Specify the virtual machine name.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="thunder_name_1"
                                    name="thunder_name_1"
                                    placeholder="vth1-inst"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_name_1"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_name_1"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["thunder_name_1"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="thunder_image" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Image
                                <span id="oracle-1vm-thunder_image_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-thunder_image_t">
                                    {`Specify the Thunder ACOS image OCID. To find custom Images, Go to the 'Compute' section in the OCI Console -> select 'Custom Images' -> copy the OCID of the desired image.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <CreatableSelect
                                    inputId="thunder_image"
                                    name="thunder_image"
                                    placeholder="Select an image"
                                    isSearchable
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                        return isAlphanumeric(inputValue)
                                    }}
                                    components={{ Option: CustomOption }}
                                    value={oracleImages[version_thunder].find((image) => image.value === formState["thunderADC"]["oracle"]["standalone"]["thunder_image"]) || { "label": formState["thunderADC"]["oracle"]["standalone"]["thunder_image"], "value": formState["thunderADC"]["oracle"]["standalone"]["thunder_image"] }}
                                    onChange={(selectedOption) => handleChange({ target: { name: 'thunder_image', value: selectedOption.value } })}
                                    options={oracleImages[version_thunder]}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '8px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                    formatOptionLabel={FormatOptionLabel}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="thunder_shape" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Shape
                                <span id="oracle-1vm-thunder_shape_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-thunder_shape_t">
                                    {`Specify shape of Thunder instance.`}<br />{`The default value is "VM.Standard.E4.Flex".`}
                                </UncontrolledTooltip>
                            </Label>
                            {/* <Col sm={7}>
                                <Input bsSize="sm"
                                    id="thunder_shape"
                                    name="thunder_shape"
                                    placeholder="VM.Standard.E4.Flex"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_shape"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_shape"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["thunder_shape"]}
                                    onChange={handleChange}
                                />
                                <a href="https://docs.oracle.com/en-us/iaas/Content/Compute/References/computeshapes.htm" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                            </Col> */}
                            <Col sm={7}>
                                <CreatableSelect
                                    inputId="thunder_shape"
                                    name="thunder_shape"
                                    placeholder="Select a shape"
                                    isSearchable
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                        return isAlphanumeric(inputValue)
                                    }}
                                    value={oracleSize.find((shape) => shape.value === formState["thunderADC"]["oracle"]["standalone"]["thunder_shape"])}
                                    onChange={(selectedOption) => handleChange({ target: { name: 'thunder_shape', value: selectedOption.value } })}
                                    options={oracleSize}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '4px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                                <a href="https://docs.oracle.com/en-us/iaas/Content/Compute/References/computeshapes.htm" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label bsSize="sm" for="thunder_active_memory_in_gbs" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Memory (GiB)
                                <span id="oracle-1vm-thunder_active_memory_in_gbs_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-thunder_active_memory_in_gbs_t">
                                    {`Specify memory for Thunder instance in GB, minimum requiremt is 8GB.`}<br />{`The default value is "16".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="thunder_active_memory_in_gbs"
                                    name="thunder_active_memory_in_gbs"
                                    placeholder="16"
                                    required="true"
                                    type="number"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_active_memory_in_gbs"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_active_memory_in_gbs"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["thunder_active_memory_in_gbs"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="thunder_active_ocpus" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                OCPUs (Core)
                                <span id="oracle-1vm-thunder_active_ocpus_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-thunder_active_ocpus_t">
                                    {`Specify number of OCPUs, minimum requirement is 3 OCPUs.`}<br />{`The default value is "3".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="thunder_active_ocpus"
                                    name="thunder_active_ocpus"
                                    placeholder="3"
                                    required="true"
                                    type="number"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_active_ocpus"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["thunder_active_ocpus"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["thunder_active_ocpus"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="private_key_path" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                OCI Key Path
                                <span id="oracle-1vm-private_key_path_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-private_key_path_t">
                                    {`Specify existing or create a new OCI user API Key.`}<br />{`The default value is "/mnt/c/oci_api_key.pem".`}<br />{`Go to OCI -> Identity->Users->User Details->API Keys.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="private_key_path"
                                    name="private_key_path"
                                    placeholder="/mnt/c/oci_api_key.pem"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["private_key_path"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["private_key_path"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["private_key_path"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="vm_key_path" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                VM Key Path
                                <span id="oracle-1vm-vm_key_path_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="oracle-1vm-vm_key_path_t">
                                    {`Specify existing or create a new OCI user API Key. Go to Identity->Users->User Details->API Keys.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="vm_key_path"
                                    name="vm_key_path"
                                    placeholder="vm_key_path"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["vm_key_path"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["vm_key_path"])}
                                    value={formState["thunderADC"]["oracle"]["standalone"]["vm_key_path"]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                    </Card>
                    <UncontrolledAccordion stayOpen className="mb-2">
                        <AccordionItem>
                            <AccordionHeader targetId={`1_1`}>
                                Configure Network Settings
                            </AccordionHeader>
                            <AccordionBody accordionId={`1_1`}>
                                <Card body color="light" className="p-0">
                                    {/* <------------- MANAGEMENT --------------> */}
                                    <CardHeader>
                                        Management
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="mgmt_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet ID
                                                <span id="oracle-1vm-mgmt_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="oracle-1vm-mgmt_subnet_id_t">
                                                    {`Specify the management subnet OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired subnet under Subnets -> copy OCID under Subnet Information.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="mgmt_subnet_id"
                                                    name="mgmt_subnet_id"
                                                    placeholder="ocid1.subnet.oc1.phx.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["mgmt_subnet_id"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["mgmt_subnet_id"])}
                                                    value={formState["thunderADC"]["oracle"]["standalone"]["mgmt_subnet_id"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/* <------------- Data IN --------------> */}
                                    <hr className='mb-0 pb-0' />
                                    <CardHeader>
                                        Data-IN
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="data_in_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet ID
                                                <span id="oracle-1vm-data_in_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="oracle-1vm-data_in_subnet_id_t">
                                                    {`Specify the data-in subnet OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired subnet under Subnets -> copy OCID under Subnet Information.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="data_in_subnet_id"
                                                    name="data_in_subnet_id"
                                                    placeholder="ocid1.subnet.oc1.phx.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["data_in_subnet_id"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["data_in_subnet_id"])}
                                                    value={formState["thunderADC"]["oracle"]["standalone"]["data_in_subnet_id"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/* <------------- Data OUT --------------> */}
                                    <hr className='mb-0 pb-0' />
                                    <CardHeader>
                                        Data-OUT
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="data_out_subnet_id" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet ID
                                                <span id="oracle-1vm-data_out_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target="oracle-1vm-data_out_subnet_id_t">
                                                    {`Specify the data-out subnet OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired subnet under Subnets -> copy OCID under Subnet Information.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="data_out_subnet_id"
                                                    name="data_out_subnet_id"
                                                    placeholder="ocid1.subnet.oc1.phx.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["data_out_subnet_id"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["oracle"]["standalone"]["data_out_subnet_id"])}
                                                    value={formState["thunderADC"]["oracle"]["standalone"]["data_out_subnet_id"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="1_2">
                                Configure Security Settings
                            </AccordionHeader>
                            <AccordionBody accordionId="1_2">
                                <Card body color="light">
                                    <FormGroup row>
                                        <Label bsSize="sm" for={`mgmt_nsg_ids`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group ID (Management)
                                            <span id={`oracle-1vm-mgmt_nsg_ids_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`oracle-1vm-mgmt_nsg_ids_t`}>
                                                {`Specify the management network security group OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired network security group under 'Network Security Groups' -> copy OCID under Network Security Group Information.`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <CreatableSelect
                                                isMulti
                                                isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                                    return isAlphanumeric(inputValue)
                                                }}
                                                value={securityGrpFields["mgmt_nsg_ids"]}
                                                onChange={(selectedOption) => handleSecurityChange(selectedOption, "mgmt_nsg_ids")}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        minHeight: "35px",
                                                        fontSize: "14px",
                                                        borderColor: securityGrpFields["mgmt_nsg_ids"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                                                    }),
                                                }}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for={`data_nsg_ids`} style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group ID (Data-IN/OUT)
                                            <span id={`oracle-1vm-data_nsg_ids_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`oracle-1vm-data_nsg_ids_t`}>
                                                {`Specify the Data-in/out network security group OCID. Go to the 'Networking' section in the OCI Console -> select 'Virtual Cloud Networks' -> click on the desired VCN -> Click on the desired network security group under 'Network Security Groups' -> Copy OCID under Network Security Group Information.`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <CreatableSelect
                                                isMulti
                                                isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                                    return isAlphanumeric(inputValue)
                                                }}
                                                value={securityGrpFields["data_nsg_ids"]}
                                                onChange={(selectedOption) => handleSecurityChange(selectedOption, "data_nsg_ids")}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        minHeight: "35px",
                                                        fontSize: "14px",
                                                        borderColor: securityGrpFields["data_nsg_ids"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                                                    }),
                                                }}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    )
}

export default OracleStandaloneForm