import React from 'react'

const HelmPanel = ({ config }) => {
    return (
        <div className='mt-3'>
            <p className='note mb-0'>
                {config === undefined ? 'Use the following command to create resources and deploy A10 product using terraform.' : 'Use the following command to apply configuration on Thunder using terraform.'}
                <br />
                {`Please refer to the Readme file in the download for more information.`}
                {/* <br />
                {`Please test this out before applying to production.`} */}
            </p>
            <hr className='my-2' />
            <div style={{
                backgroundColor: "rgb(43, 43, 43)",
                color: "rgb(248, 248, 242)"
            }}>
                <pre className="m-0 mb-1 p-2" style={{ color: "rgb(171, 227, 56)", fontSize: "13px" }}>
                    <code className='d-block'>
                        {/* # Initialize terraform <br />
                        &nbsp; terraform init */}
                        terraform init
                    </code>
                    <code className='d-block'>
                        {/* # Command to test without actually creating resources <br />
                        &nbsp; {`terraform plan -var-file <path-of-tfvars>`} */}
                        terraform plan
                    </code>
                    <code className='d-block'>
                        {/* # Command to create resources <br />
                        &nbsp; {`terraform apply <path-of-plan>`} */}
                        terraform apply
                    </code>
                    {/* <code className='d-block'>
                        # Command to delete resources <br />
                        &nbsp; terraform destroy --auto-approve
                    </code> */}
                </pre>
            </div>
        </div>
    )
}

export default HelmPanel
