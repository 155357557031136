import React from "react"

const VMwareEditor = ({ formState, vThunderCount }) => {
    return (
        <>

            {/* Configure vSphere vCenter */}
            <div>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_vSphere_vCenter:
                </code>
                {formState["thunderADC"]["vmware"]["esxi_host"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tesxi_host`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["esxi_host"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vsphere_vcenter_server"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvsphere_vcenter_server`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vsphere_vcenter_server"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vsphere_user"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvsphere_user`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vsphere_user"]}</span>
                </code>}
                {/* {formState["thunderADC"]["vmware"]["vsphere_password"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvsphere_password`}: {formState["thunderADC"]["vmware"]["vsphere_password"]}
                </code>} */}
                {formState["thunderADC"]["vmware"]["vsphere_datacenter"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvsphere_datacenter`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vsphere_datacenter"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vsphere_datastore"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvsphere_datastore`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vsphere_datastore"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vsphere_cluster"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvsphere_cluster`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vsphere_cluster"]}</span>
                </code>}
                <br />
            </div>

            {/* Configure VM-1 */}
            <div>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_1:
                </code>
                {formState["thunderADC"]["vmware"]["vm1"]["vm_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vm_name"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["cpu"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tcpu`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["cpu"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["memory"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tmemory`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["memory"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["disk_label"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_label`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["disk_label"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["disk_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["disk_size"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["disk_provisioning"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_provisioning`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["disk_provisioning"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["local_ovf_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tlocal_ovf_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["local_ovf_path"]}</span>
                </code>}

                {/* <-------- Management --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["vmware"]["vm1"]["eth0_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth0_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["eth0_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["mgmt_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["mgmt_subnet_mask"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["mgmt_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_gateway`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["mgmt_subnet_gateway"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["vsphere_mgmt_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_mgmt_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_mgmt_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["mgmt_adaptor"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_adaptor`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["mgmt_adaptor"]}</span>
                </code>}

                {/* <-------- Data-In --------->*/}
                {formState["thunderADC"]["vmware"]["vm1"]["eth1_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth1_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["eth1_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["data_in_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["data_in_subnet_mask"]}</span>
                </code>}
                {/* {formState["thunderADC"]["vmware"]["vm1"]["data1_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata1_subnet_gateway`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState["thunderADC"]["vmware"]["vm1"]["data1_subnet_gateway"]}</span>
                </code>} */}
                {formState["thunderADC"]["vmware"]["vm1"]["vsphere_data1_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_data1_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_data1_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["data_adaptor_in"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_adaptor_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["data_adaptor_in"]}</span>
                </code>}

                {/* <-------- Data-Out --------->*/}
                {formState["thunderADC"]["vmware"]["vm1"]["eth2_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth2_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["eth2_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["data_out_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["data_out_subnet_mask"]}</span>
                </code>}
                {/* {formState["thunderADC"]["vmware"]["vm1"]["data2_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata2_subnet_gateway`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState["thunderADC"]["vmware"]["vm1"]["data2_subnet_gateway"]}</span>
                </code>} */}
                {formState["thunderADC"]["vmware"]["vm1"]["vsphere_data2_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_data2_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_data2_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm1"]["data_adaptor_out"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_adaptor_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["data_adaptor_out"]}</span>
                </code>}
                <br />
            </div>

            {/* Configure VM-2 */}
            {vThunderCount !== "standalone" && <div>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_2:
                </code>
                {formState["thunderADC"]["vmware"]["vm2"]["vm_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["vm_name"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["cpu"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tcpu`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["cpu"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["memory"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tmemory`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["memory"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["disk_label"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_label`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["disk_label"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["disk_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["disk_size"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["disk_provisioning"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_provisioning`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["disk_provisioning"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["local_ovf_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tlocal_ovf_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["local_ovf_path"]}</span>
                </code>}

                {/* <-------- Management --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["vmware"]["vm2"]["eth0_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth0_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["eth0_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["mgmt_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["mgmt_subnet_mask"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["mgmt_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_gateway`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["mgmt_subnet_gateway"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["vsphere_mgmt_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_mgmt_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_mgmt_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["mgmt_adaptor"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_adaptor`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["mgmt_adaptor"]}</span>
                </code>}

                {/* <-------- Data-In --------->*/}
                {formState["thunderADC"]["vmware"]["vm2"]["eth1_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth1_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["eth1_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["data_in_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["data_in_subnet_mask"]}</span>
                </code>}
                {/* {formState["thunderADC"]["vmware"]["vm2"]["data1_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata1_subnet_gateway`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState["thunderADC"]["vmware"]["vm2"]["data1_subnet_gateway"]}</span>
                </code>} */}
                {formState["thunderADC"]["vmware"]["vm2"]["vsphere_data1_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_data1_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_data1_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["data_adaptor_in"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_adaptor_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["data_adaptor_in"]}</span>
                </code>}

                {/* <-------- Data-Out --------->*/}
                {formState["thunderADC"]["vmware"]["vm2"]["eth2_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth2_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["eth2_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["data_out_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["data_out_subnet_mask"]}</span>
                </code>}
                {/* {formState["thunderADC"]["vmware"]["vm2"]["data2_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata2_subnet_gateway`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState["thunderADC"]["vmware"]["vm2"]["data2_subnet_gateway"]}</span>
                </code>} */}
                {formState["thunderADC"]["vmware"]["vm2"]["vsphere_data2_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_data2_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_data2_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm2"]["data_adaptor_out"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_adaptor_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm2"]["data_adaptor_out"]}</span>
                </code>}
                <br />
            </div>}

            {/* Configure VM-3 */}
            {vThunderCount === "gslb" && <div>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_3:
                </code>
                {formState["thunderADC"]["vmware"]["vm3"]["vm_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["vm_name"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["cpu"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tcpu`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["cpu"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["memory"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tmemory`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["memory"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["disk_label"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_label`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["disk_label"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["disk_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["disk_size"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["disk_provisioning"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tdisk_provisioning`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["disk_provisioning"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["local_ovf_path"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tlocal_ovf_path`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["local_ovf_path"]}</span>
                </code>}

                {/* <-------- Management --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["vmware"]["vm3"]["eth0_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth0_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["eth0_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["mgmt_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["mgmt_subnet_mask"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["mgmt_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_gateway`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["mgmt_subnet_gateway"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["vsphere_mgmt_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_mgmt_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_mgmt_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["mgmt_adaptor"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_adaptor`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["mgmt_adaptor"]}</span>
                </code>}

                {/* <-------- Data-In --------->*/}
                {formState["thunderADC"]["vmware"]["vm3"]["eth1_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth1_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["eth1_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["data_in_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["data_in_subnet_mask"]}</span>
                </code>}
                {/* {formState["thunderADC"]["vmware"]["vm3"]["data1_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata1_subnet_gateway`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState["thunderADC"]["vmware"]["vm3"]["data1_subnet_gateway"]}</span>
                </code>} */}
                {formState["thunderADC"]["vmware"]["vm3"]["vsphere_data1_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_data1_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_data1_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["data_adaptor_in"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_adaptor_in`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["data_adaptor_in"]}</span>
                </code>}

                {/* <-------- Data-Out --------->*/}
                {formState["thunderADC"]["vmware"]["vm3"]["eth2_ipAddress"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teth2_ipAddress`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["eth2_ipAddress"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["data_out_subnet_mask"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_subnet_mask`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["data_out_subnet_mask"]}</span>
                </code>}
                {/* {formState["thunderADC"]["vmware"]["vm3"]["data2_subnet_gateway"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata2_subnet_gateway`}: <span style={{color: "rgb(171, 227, 56)"}}>{formState["thunderADC"]["vmware"]["vm3"]["data2_subnet_gateway"]}</span>
                </code>} */}
                {formState["thunderADC"]["vmware"]["vm3"]["vsphere_data2_network"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tvsphere_data2_network`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm1"]["vsphere_data2_network"]}</span>
                </code>}
                {formState["thunderADC"]["vmware"]["vm3"]["data_adaptor_out"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_adaptor_out`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["vmware"]["vm3"]["data_adaptor_out"]}</span>
                </code>}
            </div>}
        </>
    )
}

export default VMwareEditor
