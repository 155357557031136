import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Card, Col, FormGroup, Button, Label, UncontrolledTooltip, Input } from "reactstrap";
import { FaTrashAlt } from 'react-icons/fa';
import { isAlphanumeric } from "../../../../utils/validations";
const ServiceGroupSLB = ({ formState, setFormState, siteIndex, region }) => {
    // const getServerCountByProtocol = (protocol) => {
    //     return formState["gslb"][region][siteIndex]["server"]
    //         .filter(server => server.port_list.some(port => port.protocol === protocol)).length;
    // }
    // const tcpServerCount = getServerCountByProtocol("tcp");
    // const udpServerCount = getServerCountByProtocol("udp");

    const resetVirtualServerServiceGrp = (idx, newFormState) => {
        newFormState["gslb"][region][siteIndex]["virtualServer"]["port_list"].forEach(member => {
            if (member.service_group === idx) {
                member.service_group = -1
            }
        });
    }

    const handleChange = (e, value) => {
        let newFormState = { ...formState }
        if (e.target.name === "protocol") {
            newFormState["gslb"][region][siteIndex]["service_group"].forEach(ele => {
                ele["member_list"].forEach(member => {
                    member.server_name = -1
                })
            })

            if (newFormState["gslb"][region][siteIndex]["service_group"].length > 1) {
                newFormState["gslb"][region][siteIndex]["service_group"][1]["protocol"] = newFormState["gslb"][region][siteIndex]["service_group"][0]["protocol"]
            }
        }
        newFormState["gslb"][region][siteIndex]["service_group"][value] = {
            ...newFormState["gslb"][region][siteIndex]["service_group"][value],
            [e.target.name]: e.target.value,
        }
        setFormState(newFormState);
    }
    const handleAddServiceGrp = () => {
        let newFormState = { ...formState }
        const protocol = newFormState["gslb"][region][siteIndex]["service_group"][0]["protocol"] === "tcp" ? "udp" : "tcp"
        newFormState["gslb"][region][siteIndex]["service_group"].push({
            "name": "serviceGroup2", "protocol": protocol, member_list: [{ "server_name": -1, "port": "80" }]
        })
        setFormState(newFormState)
    }

    const handleDeleteServiceGrp = () => {
        let newFormState = { ...formState }
        resetVirtualServerServiceGrp(1, newFormState)
        newFormState["gslb"][region][siteIndex]["service_group"].splice(1, 1)
        setFormState(newFormState)
    }

    const handleDeleteMemberList = (pidx, value) => {
        let newFormState = { ...formState }
        newFormState["gslb"][region][siteIndex]["service_group"][value]["member_list"].splice(pidx, 1)
        setFormState(newFormState)
    }
    const handleMemberListChange = (e, pidx, value) => {
        let newFormState = { ...formState }
        if (e.target.name === "server_name") {
            newFormState["gslb"][region][siteIndex]["service_group"][value]["member_list"][pidx] = { ...newFormState["gslb"][region][siteIndex]["service_group"][value]["member_list"][pidx], [e.target.name]: parseInt(e.target.value) }
        } else {
            newFormState["gslb"][region][siteIndex]["service_group"][value]["member_list"][pidx] = { ...newFormState["gslb"][region][siteIndex]["service_group"][value]["member_list"][pidx], [e.target.name]: e.target.value }
        }
        setFormState(newFormState)
    }
    const handleAddMemberList = (value) => {
        let newFormState = { ...formState }
        newFormState["gslb"][region][siteIndex]["service_group"][value]["member_list"].push({ "server_name": -1, "port": "443" })
        setFormState(newFormState)
    }
    return (

        <AccordionItem>
            <AccordionHeader targetId={`${siteIndex}_2`}>
                Configure SLB Service Group
            </AccordionHeader>
            <AccordionBody accordionId={`${siteIndex}_2`}>
                {formState["gslb"][region][siteIndex]["service_group"].length < 2 && <div className="d-flex justify-content-end">
                    <Button className="mb-2" size="sm" onClick={handleAddServiceGrp}>Add Service Group</Button>
                </div>}
                {formState["gslb"][region][siteIndex]["service_group"].map((item, idx) => {
                    return <Card key={`tcp_${siteIndex + 1}`} body color="light" className='mb-1'>
                        <div className="d-flex justify-content-end">
                            {idx > 0 && <Button className='mb-2' size="sm" disabled={idx === 0} onClick={handleDeleteServiceGrp}><FaTrashAlt /></Button>}
                        </div>
                        <FormGroup row>
                            <Label bsSize="sm" for={`tcp_name-${siteIndex + 1}`} sm={4}
                                style={{ fontSize: "13px", textAlign: "end" }}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Name
                                <span id={`tcp_sgname-${region}-t${siteIndex + 1}`} style={{ fontSize: "14px" }}>
                                    <strong> ⓘ</strong>
                                </span>
                                <UncontrolledTooltip placement="top" target={`tcp_sgname-${region}-t${siteIndex + 1}`}>
                                    Specify unique name for service group.
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id={`tcp_name-${siteIndex + 1}`}
                                    name="name"
                                    placeholder="serviceGroup1"
                                    type="text"
                                    value={item["name"]}
                                    valid={isAlphanumeric(item["name"])}
                                    invalid={!isAlphanumeric(item["name"])}
                                    onChange={(e) => handleChange(e, idx)} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for={`tcp_protocol-${siteIndex + 1}`} sm={4}
                                style={{ fontSize: "13px", textAlign: "end" }}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Protocol
                                <span id={`tcp_gslbsgprotocol-${region}-t${siteIndex + 1}`} style={{ fontSize: "14px" }}>
                                    <strong> ⓘ</strong>
                                </span>
                                <UncontrolledTooltip placement="top" target={`tcp_gslbsgprotocol-${region}-t${siteIndex + 1}`}>
                                    {region === "master_site_setting" && `The protocol is located at Configure Region1 -> Site-${siteIndex + 1} -> Configure Site Settings -> Configure SLB Server -> Protocol .`}
                                    {region === "member_site_setting" && `The protocol is located at Configure Region2 -> Site-${siteIndex + 1} -> Configure Site Settings -> Configure SLB Server -> Protocol .`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id={`tcp_protocol-${siteIndex + 1}`}
                                    name="protocol"
                                    type="select"
                                    disabled={idx === 1}
                                    value={item["protocol"]}
                                    valid={isAlphanumeric(item["protocol"])}
                                    invalid={!isAlphanumeric(item["protocol"])}
                                    onChange={(e) => handleChange(e, 0)}>
                                    <option>tcp</option>
                                    <option>udp</option>
                                </Input>
                            </Col>
                        </FormGroup>
                        {item["member_list"].map((member, pidx) => {
                            return (
                                <div key={`tcp_${siteIndex + 1}_${pidx + 1}`}>
                                    <div className="d-flex justify-content-end">
                                        {pidx > 0 && <Button className="mb-1" size="sm" disabled={pidx === 0} onClick={() => handleDeleteMemberList(pidx, 0)}><FaTrashAlt /></Button>}
                                    </div>
                                    <FormGroup row>
                                        <Label bsSize="sm" for={`tcp_name-${pidx + 1}-${siteIndex + 1}`} sm={4}
                                            style={{ fontSize: "13px", textAlign: "end" }}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Server`}
                                            <span id={`tcp_gslbsg-SN-${region}-${siteIndex + 1}_${pidx + 1}`} style={{ fontSize: "14px" }}> <strong> ⓘ</strong> </span>
                                            <UncontrolledTooltip placement="top" target={`tcp_gslbsg-SN-${region}-${siteIndex + 1}_${pidx + 1}`}>
                                                Select server to be added as a member in service group "{member["name"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id={`tcp_name-${pidx + 1}-${siteIndex + 1}`}
                                                name="server_name"
                                                type="select"
                                                value={member["server_name"]}
                                                valid={member["server_name"] !== -1}
                                                invalid={member["server_name"] === -1}
                                                onChange={(e) => handleMemberListChange(e, pidx, idx)}>
                                                <option selected={true} hidden>select</option>
                                                {formState["gslb"][region][siteIndex]["server"].map((option, gidx) => {
                                                    const hasTcpProtocol = option["port_list"].some(port => port["protocol"] === item["protocol"]);
                                                    return hasTcpProtocol && option["name"] && (
                                                        <option key={`${siteIndex}_${gidx}`} value={gidx}>{option["name"]}</option>
                                                    );
                                                })}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for={`tcp_port-${pidx + 1}-${siteIndex + 1}`} sm={4}
                                            style={{ fontSize: "13px", textAlign: "end" }}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            {`Port`}
                                            <span id={`tcp_gslbsg-port-${region}-${siteIndex + 1}_${pidx + 1}`} style={{ fontSize: "14px" }}> <strong> ⓘ</strong> </span>
                                            <UncontrolledTooltip placement="top" target={`tcp_gslbsg-port-${region}-${siteIndex + 1}_${pidx + 1}`}>
                                                Specify port number of member server "{member["name"]}".
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id={`tcp_port-${pidx + 1}-${siteIndex + 1}`}
                                                name="port"
                                                type="number"
                                                value={member["port"]}
                                                valid={member["port"]}
                                                invalid={!member["port"]}
                                                onChange={(e) => handleMemberListChange(e, pidx, idx)} />
                                        </Col>
                                    </FormGroup>
                                    <div className="d-flex justify-content-end">
                                        {pidx === item["member_list"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddMemberList(0)}>Add Member List</Button>}
                                    </div>
                                </div>
                            )
                        })}
                    </Card>
                })}
                {/* {udpServerCount > 0 && (
                    <div>
                        <Card key={`udp_${siteIndex + 1}`} body color="light" className='mb-1'>
                            <FormGroup row>
                                <Label bsSize="sm" for={`udp_name-${siteIndex + 1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Name
                                    <span id={`udp_sgname-tooltip${siteIndex + 1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`udp_sgname-tooltip${siteIndex + 1}`}>
                                        Specify unique name for service group.
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id={`udp_name-${siteIndex + 1}`}
                                        name="name"
                                        placeholder="serviceGroup1"
                                        type="text"
                                        value={formState["gslb"][region][siteIndex]["service_group"][1]["name"]}
                                        valid={isAlphanumeric(formState["gslb"][region][siteIndex]["service_group"][1]["name"])}
                                        invalid={!isAlphanumeric(formState["gslb"][region][siteIndex]["service_group"][1]["name"])}
                                        onChange={(e) => handleChange(e, 1)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label bsSize="sm" for={`udp_protocol-${siteIndex + 1}`} sm={4}
                                    style={{ fontSize: "13px", textAlign: "end" }}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Protocol
                                    <span id={`udp_gslbsgprotocol-tooltip${siteIndex + 1}`} style={{ fontSize: "14px" }}>
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`udp_gslbsgprotocol-tooltip${siteIndex + 1}`}>
                                        {region === "master_site_setting" && `The protocol is located at Configure Region1 -> Site-${siteIndex + 1} -> Configure Site Settings -> Configure SLB Server -> Protocol .`}
                                        {region === "member_site_setting" && `The protocol is located at Configure Region2 -> Site-${siteIndex + 1} -> Configure Site Settings -> Configure SLB Server -> Protocol .`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id={`udp_protocol-${siteIndex + 1}`}
                                        name="protocol"
                                        disabled
                                        value={formState["gslb"][region][siteIndex]["service_group"][1]["protocol"]}
                                        valid={isAlphanumeric(formState["gslb"][region][siteIndex]["service_group"][1]["protocol"])}
                                        invalid={!isAlphanumeric(formState["gslb"][region][siteIndex]["service_group"][1]["protocol"])}
                                    />
                                </Col>
                            </FormGroup>
                            {formState["gslb"][region][siteIndex]["service_group"][1]["member_list"].map((item, pidx) => {
                                return (
                                    <div key={`udp_${siteIndex + 1}_${pidx + 1}`}>
                                        <div className="d-flex justify-content-end">
                                            {pidx > 0 && <Button className="mb-1" size="sm" disabled={pidx === 0} onClick={() => handleDeleteMemberList(pidx, 1)}><FaTrashAlt /></Button>}
                                        </div>
                                        <FormGroup row>
                                            <Label bsSize="sm" for={`udp_name-${pidx + 1}-${siteIndex + 1}`} sm={4}
                                                style={{ fontSize: "13px", textAlign: "end" }}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                {`Server`}
                                                <span id={`udp_gslbsg-SN-${siteIndex + 1}_${pidx + 1}`} style={{ fontSize: "14px" }}> <strong> ⓘ</strong> </span>
                                                <UncontrolledTooltip placement="top" target={`udp_gslbsg-SN-${siteIndex + 1}_${pidx + 1}`}>
                                                    Select server to be added as a member in service group "{formState["gslb"][region][siteIndex]["service_group"][1]["name"]}".
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id={`udp_name-${pidx + 1}-${siteIndex + 1}`}
                                                    name="server_name"
                                                    type="select"
                                                    value={item["server_name"]}
                                                    valid={item["server_name"] !== -1}
                                                    invalid={item["server_name"] === -1}
                                                    onChange={(e) => handleMemberListChange(e, pidx, 1)}>
                                                    <option selected={true} hidden>select</option>
                                                    {formState["gslb"][region][siteIndex]["server"].map((serverOption, gidx) => {

                                                        const hasudpProtocol = serverOption["port_list"].some(port => port["protocol"] === "udp");

                                                        return hasudpProtocol && serverOption["name"] && (
                                                            <option key={`${siteIndex}_${gidx}`} value={gidx}>{serverOption["name"]}</option>
                                                        );
                                                    })}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for={`udp_port-${pidx + 1}-${siteIndex + 1}`} sm={4}
                                                style={{ fontSize: "13px", textAlign: "end" }}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                {`Port`}
                                                <span id={`udp_gslbsg-port-${siteIndex + 1}_${pidx + 1}`} style={{ fontSize: "14px" }}> <strong> ⓘ</strong> </span>
                                                <UncontrolledTooltip placement="top" target={`udp_gslbsg-port-${siteIndex + 1}_${pidx + 1}`}>
                                                    Specify port number of member server "{item["server_name"]}".
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id={`udp_port-${pidx + 1}-${siteIndex + 1}`}
                                                    name="port"
                                                    type="number"
                                                    value={item["port"]}
                                                    valid={item["port"]}
                                                    invalid={!item["port"]}
                                                    onChange={(e) => handleMemberListChange(e, pidx, 1)} />
                                            </Col>
                                        </FormGroup>
                                        <div className="d-flex justify-content-end">
                                            {pidx === formState["gslb"][region][siteIndex]["service_group"][1]["member_list"].length - 1 && <Button className="ms-1 mb-1" size="sm" onClick={() => handleAddMemberList(1)}>Add Member List</Button>}
                                        </div>
                                    </div>
                                )
                            })}
                        </Card>
                    </div>
                )} */}
            </AccordionBody>
        </AccordionItem>
    )
}
export default ServiceGroupSLB