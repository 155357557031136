import React from 'react'

const AwsHAEditor = ({ formState }) => {
    return (
        <div>
            {/* <-----VM-1------> */}
            <>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_1:
                </code>
                {formState["thunderADC"]["aws"]["ha"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["region"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["instance1_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["instance1_name"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["thunder_ami_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_ami_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["thunder_ami_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["thunder_instance_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_instance_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["thunder_instance_size"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["key_pair"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tkey_pair`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["key_pair"]}</span>
                </code>}

                {/* <-------- Management --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["aws"]["ha"]["create_elastic_ip"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tcreate_elastic_ip`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["create_elastic_ip"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["eip_allocation_id_active"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teip_allocation_id_active`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["eip_allocation_id_active"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["mgmt_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["data_in_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["data_in_private_ips_active"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_private_ips_active`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["ha"]["data_in_private_ips_active"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["data_out_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["data_out_private_ips_active"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_private_ips_active`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["ha"]["data_out_private_ips_active"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}

                {/* <-------- Security --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Security:"}
                </code>
                {formState["thunderADC"]["aws"]["ha"]["security_group_mgmt_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tsecurity_group_mgmt_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["ha"]["security_group_mgmt_ids"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["security_group_data_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tsecurity_group_data_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["ha"]["security_group_data_ids"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
            </>

            <br />
            {/* <-----VM-2------> */}
            <>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_2:
                </code>
                {formState["thunderADC"]["aws"]["ha"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["region"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["instance2_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["instance2_name"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["thunder_ami_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_ami_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["thunder_ami_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["thunder_instance_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_instance_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["thunder_instance_size"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["key_pair"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tkey_pair`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["key_pair"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["ha"]["eip_allocation_id_standby"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\teip_allocation_id_standby`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["ha"]["eip_allocation_id_standby"]}</span>
                </code>}
            </>
        </div>
    )
}

export default AwsHAEditor