import React from 'react'

const AwsGSLBEditor = ({ formState }) => {
    return (
        <div>
            {/* <-----VM-1------> */}
            <>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_1:
                </code>
                {formState["thunderADC"]["aws"]["gslb"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["region"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["instance1_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["instance1_name"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_ami_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_instance_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["key_pair"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tkey_pair`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["key_pair"]}</span>
                </code>}

                {/* <-------- Management --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teip_allocation_id_master_1`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_1"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["mgmt_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teip_allocation_id_master_2`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_master_2"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["data_in_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_private_ips_active`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_active"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["data_out_subnet_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_private_ips_active`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_active"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}

                {/* <-------- Security --------->*/}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Security:"}
                </code>
                {formState["thunderADC"]["aws"]["gslb"]["security_group_mgmt_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tsecurity_group_mgmt_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["security_group_mgmt_ids"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["security_group_data_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tsecurity_group_data_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["security_group_data_ids"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
            </>

            <br />
            {/* <-----VM-2------> */}
            <>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_2:
                </code>
                {formState["thunderADC"]["aws"]["gslb"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["region"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["instance2_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["instance2_name"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_ami_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_instance_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["key_pair"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tkey_pair`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["key_pair"]}</span>
                </code>}

                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teip_allocation_id_site1_1`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_1"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teip_allocation_id_site1_2`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site1_2"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_private_ips_site1`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site1"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_private_ips_site1`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_sit1"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
            </>

            <br />
            {/* <-----VM-3------> */}
            <>
                <code className="d-block" style={{ fontSize: "13px" }}>
                    Configure_VM_3:
                </code>
                {formState["thunderADC"]["aws"]["gslb"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["region"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["instance3_name"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tvm_name`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["instance3_name"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_ami_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["thunder_ami_id"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tthunder_instance_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["thunder_instance_size"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["key_pair"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\tkey_pair`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["key_pair"]}</span>
                </code>}
                <code className="d-block" style={{ fontSize: "13px" }}>
                    {"\tConfigure_Network_Settings:"}
                </code>
                {formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_1"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teip_allocation_id_site2_1`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_1"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\teip_allocation_id_site2_2`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["gslb"]["eip_allocation_id_site2_2"]}</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_in_private_ips_site2`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["data_in_private_ips_site2"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
                {formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"] && <code className="d-block" style={{ fontSize: "13px" }}>
                    {`\t\tdata_out_private_ips_site2`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                        {formState["thunderADC"]["aws"]["gslb"]["data_out_private_ips_site2"].map((ele, idx) => {
                            return <>{idx > 0 && ", "}{ele}</>
                        })}
                        ]</span>
                </code>}
            </>
        </div>
    )
}

export default AwsGSLBEditor