import React, { useEffect } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Card, Row, CardBody, CardHeader, Col, FormGroup, Input, Label, UncontrolledAccordion, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric, isNotEmpty } from '../../../../utils/validations';
import { azureRegionImages, azureRegion, azureSize } from "../../../../data/parameters";
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";

const AzureHAForm = ({ formState, setFormState, version_thunder }) => {

    useEffect(() => {
        let newFormState = { ...formState }
        let index = -1;
        Object.keys(azureRegionImages).forEach((version) => {
            if (index === -1) {
                index = azureRegionImages[version].findIndex((image) => {
                    return image.value === formState["thunderADC"]["azure"]["ha"]["image"]
                })
            }
        })
        if (index !== -1) {
            newFormState["thunderADC"]["azure"]["ha"]["image"] = azureRegionImages[version_thunder][index].value
            newFormState["thunderADC"]["azure"]["ha"]["product"] = azureRegionImages[version_thunder][index].product
            setFormState(newFormState)
        }
    }, [version_thunder])

    const handleChange = (e, value) => {
        const newFormState = { ...formState };
        newFormState["thunderADC"]["azure"]["ha"] = { ...newFormState["thunderADC"]["azure"]["ha"], [e.target.name]: e.target.value }
        setFormState(newFormState)
    }
    const updateProductBasedOnImage = (selectedImage) => {
        const correspondingProduct = azureRegionImages[version_thunder].find((image) => image.value === selectedImage);
        if (correspondingProduct) {
            const updatedFormState = { ...formState };
            updatedFormState["thunderADC"]["azure"]["ha"]["product"] = correspondingProduct.product;
            setFormState(updatedFormState);
        }
    };

    const CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps} className='ms-1 mb-2'>
            <div style={{ fontSize: '14px' }}>{label}</div>
            {(data.version && data.link) && <Row className="m-0" style={{ fontSize: '12px', color: '#888' }}>
                <Col>version-{data.version}</Col>
                <Col className='text-end me-2'><a target="_blank" href={data.link} style={{ "textDecoration": "none" }}>More Details</a></Col>
            </Row>}
        </div>
    )
    const FormatOptionLabel = ({ label, version, link }) => {
        return (
            <div className='m-0 p-0'>
                <div style={{ fontSize: '14px' }}>{label}</div>
                {(version && link) &&
                    <Row style={{ fontSize: '12px', color: '#888' }}>
                        <Col>version-{version}</Col>
                        <Col className='text-end me-2'><a target="_blank" href={link} style={{ "textDecoration": "none" }}>More Details</a></Col>
                    </Row>
                }
            </div>
        );
    }
    const isAcceleratedNetworkingSupported = (selectedSize) => {
        const isPresent = azureSize.some((size) => size.value === selectedSize)
        if (!isPresent)
            return true;
        const sizeInfo = azureSize.find((size) => size.value === selectedSize);
        return sizeInfo && sizeInfo.acceleratedNetworking === "Supported";
    };

    return (
        <UncontrolledAccordion stayOpen className="pb-2">
            <AccordionItem>
                <AccordionHeader targetId="1vm">
                    Configure Virtual Machine-1
                </AccordionHeader>
                <AccordionBody accordionId="1vm">
                    <Card body color="light" className="pb-0">
                        <FormGroup row>
                            <Label bsSize="sm" for="location"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Region
                                <span id={`azure_2vm_location_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_location_t_1`}>
                                    {`Choose the region resources will be deployed.`}<br />{`The default value is "East US".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    inputId="location"
                                    name="location"
                                    placeholder="Select a region"
                                    isSearchable
                                    value={azureRegion.find((region) => region.value === formState["thunderADC"]["azure"]["ha"]["location"])}
                                    options={azureRegion}
                                    onChange={(selectedOption) => handleChange({ target: { name: 'location', value: selectedOption.value } })}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '4px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="resource_group"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Resource Group
                                <span id={`azure_2vm_resource_group_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_resource_group_t_1`}>
                                    {`Specify the resource group name where resources will be deployed.`}<br />{`A resource group is a collection of resources that share the same lifecycle, permissions, and policies.`}<br />{`The default value is "vth-rg2".`}<br />{`Go to the Azure portal -> Resource groups.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="resource_group"
                                    name="resource_group"
                                    placeholder="vth-rg1"
                                    required="true"
                                    type="text"
                                    valid={isNotEmpty(formState["thunderADC"]["azure"]["ha"]["resource_group"])}
                                    invalid={!isNotEmpty(formState["thunderADC"]["azure"]["ha"]["resource_group"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["resource_group"]}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="existing_resource_group"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Existing Resource Group
                                <span id={`azure_2vm_existing_resource_group_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_existing_resource_group_t_1`}>
                                    {`Select 'Yes' to enable the use of an existing resource group for deployment.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="existing_resource_group"
                                    name="existing_resource_group"
                                    placeholder="true"
                                    required="true"
                                    type="select"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["existing_resource_group"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["existing_resource_group"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["existing_resource_group"]}
                                    onChange={handleChange}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="instance_name"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                VM Name
                                <span id="azure_2vm_instance_name_t_1" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="azure_2vm_instance_name_t_1">
                                    {`Specify the virtual machine name for vThunder. This will create a new VM with the provided name.`}<br />{`The default value is "vth-inst-1".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="instance_name"
                                    name="instance_name1"
                                    placeholder="vth-inst1"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["instance_name1"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["instance_name1"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["instance_name1"]}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="image"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Image
                                <span id={`azure_2vm_image_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="bottom" target={`azure_2vm_image_t`}>
                                    {`Choose the A10 vThunder image that is right for you and your customers.`}<br />{`A10 Thunder ADC for Advanced Load Balancing - BYOL is set as a default Image ID.`}<br />{`Find more types of thunder images available in different capacities.`}<br />{`For more information, Go to Azure Portal -> Marketplace -> Search for 'vThunder' -> Select Image -> Usage Information + Support -> Plan ID is the Image ID.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    name="image"
                                    isSearchable
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                        return isAlphanumeric(inputValue)
                                    }}
                                    value={azureRegionImages[version_thunder].find(option => option.value === formState["thunderADC"]["azure"]["ha"]["image"]) || { "label": formState["thunderADC"]["azure"]["ha"]["image"], "value": formState["thunderADC"]["azure"]["ha"]["image"] }}
                                    options={azureRegionImages[version_thunder]}
                                    components={{ Option: CustomOption }}
                                    formatOptionLabel={FormatOptionLabel}
                                    onChange={(selectedOption) => {
                                        handleChange({ target: { name: 'image', value: selectedOption.value } });
                                        updateProductBasedOnImage(selectedOption.value);
                                    }}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '8px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '8px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="size"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Size
                                <span id={`azure_2vm_size_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_size_t_1`}>
                                    {`Specify/Choose the available instance or compute type supported for vThunder.`}<br />{`The default instance type is Standard A4mv2, which contains 8 vCPU and 32 GiB of memory.`}<br />{`vThunder requires a minimum of 4 vCPUs , 16 GiB of memory and support of 3 NICs attachment.`}<br />{`Go to the Azure portal -> Virtual machines -> Create a virtual machine -> Size.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    name="size"
                                    isSearchable
                                    value={azureSize.find(option => option.value === formState["thunderADC"]["azure"]["ha"]["size"])}
                                    options={azureSize}
                                    onChange={(selectedOption) => {
                                        handleChange({ target: { name: 'size', value: selectedOption.value } });
                                    }}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '8px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                                <a href="https://azure.microsoft.com/en-in/pricing/details/virtual-machines/series/" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="admin_username"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Username
                                <span id={`azure_2vm_admin_username_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_admin_username_t_1`}>
                                    {`Specify the username. The default value is "vth-user".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="admin_username"
                                    name="admin_username"
                                    placeholder="vth-user"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["admin_username"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["admin_username"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["admin_username"]}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Col>
                        </FormGroup>

                    </Card>
                    <UncontrolledAccordion stayOpen className="mb-2">
                        <AccordionItem>
                            <AccordionHeader targetId={`1vm_1`}>
                                Configure Network Settings
                            </AccordionHeader>
                            <AccordionBody accordionId={`1vm_1`}>
                                <Card body color="light" className="p-0">
                                    <div className="p-3">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="vnet_nsg_rg"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Resource Group
                                                <span id={`azure_2vm_vnet_nsg_rg_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_vnet_nsg_rg_t_1`}>
                                                    {`Specify the resource group name where virtual network, security group and public IP address are already created. The default value is "vth-rg1".`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="vnet_nsg_rg"
                                                    name="vnet_nsg_rg"
                                                    placeholder="vth-user"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="vnet_name"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Virtual Network (VNET)
                                                <span id={`azure_2vm_vnet_name_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_vnet_name_t_1`}>
                                                    {`Specify an existing virtual network name for virtual machines. This should be available within the specified resource group where public IP addresses and security groups are being created. This ARM template will not create a new virtual network. The default value is "vth-vnet". Go to Azure Protal -> Virtual networks -> Virtual_networks_name.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="vnet_name"
                                                    name="vnet_name"
                                                    placeholder="vth-user"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_name"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_name"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["vnet_name"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="publicIP_name"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Public IP
                                                <span id={`azure_2vm_publicIP_name_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_publicIP_name_t_1`}>
                                                    {`Specify an existing public IP name for management traffic. This public IP address should be available within the specified resource group where virtual network and security groups are being created. This will not create a new public IP address for a virtual machine. The default value is "vth-inst1-ip". Go to Azure Protal -> Public IP addresses -> Public_IP_address_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="publicIP_name"
                                                    name="publicIP_name1"
                                                    placeholder="vth-user"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["publicIP_name1"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["publicIP_name1"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["publicIP_name1"]}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="pub_vip"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Public VIP
                                                <span id={`azure_2vm_1_pub_vip_t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_1_pub_vip_t`}>
                                                    {`Select 'Yes' if vip is public IP address, else select 'No' in case vip is private IP address.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="pub_vip"
                                                    name="pub_vip"
                                                    placeholder="true"
                                                    required="true"
                                                    type="select"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["pub_vip"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["pub_vip"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["pub_vip"]}
                                                    onChange={handleChange}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="accelerated_networking"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Accelerated Networking
                                                <span id={`azure_2vm_accelerated_networking_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_accelerated_networking_t_1`}>
                                                    {isAcceleratedNetworkingSupported(formState["thunderADC"]["azure"]["ha"]["size"]) ? (
                                                        <>
                                                        {`Select "Yes" to enable accelerated networking to enable low latency and high throughput on the network interface.`}<br/>
                                                        {`Please ensure that the specified/selected size supports accelerated networking.`}
                                                    </>
                                                    ) : (
                                                        <>
                                                            {`Accelerated networking is not supported for the selected VM instance size.`}<br />
                                                            {`Please choose a different size that supports accelerated networking.`}
                                                        </>
                                                    )}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="accelerated_networking"
                                                    name="accelerated_networking"
                                                    placeholder="true"
                                                    required="true"
                                                    type="select"
                                                    disabled={!isAcceleratedNetworkingSupported(formState["thunderADC"]["azure"]["ha"]["size"])}
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["accelerated_networking"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["accelerated_networking"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["accelerated_networking"]}
                                                    onChange={(e) => handleChange(e)}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                                <a href="https://learn.microsoft.com/en-us/azure/virtual-network/create-vm-accelerated-networking-cli?tabs=windows" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="ip_forwarding"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                IP Forwarding
                                                <span id={`azure_2vm_ip_forwarding_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_ip_forwarding_t_1`}>
                                                    {`Select "Yes" to enabling IP forwarding to allow the virtual machine to forward network traffic between networks, greatly improving its networking performance.`}<br />{`This high-performance path bypasses the host from the datapath, reducing latency, jitter, and CPU utilization, for use with the most demanding network workloads on supported VM types.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="ip_forwarding"
                                                    name="ip_forwarding"
                                                    placeholder="true"
                                                    required="true"
                                                    type="select"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["ip_forwarding"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["ip_forwarding"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["ip_forwarding"]}
                                                    onChange={(e) => handleChange(e)}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                                <a href="https://learn.microsoft.com/en-us/azure/virtual-network/virtual-network-network-interface?tabs=azure-portal#enable-or-disable-ip-forwarding-for-a-network-interface" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    {/*<!---Management--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Management
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_mgmt"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id={`azure_2vm_subnet_mgmt_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_subnet_mgmt_t_1`}>
                                                    {`Specify an existing subnet name that is available within a selected virtual network for management traffic inward to vThunder. This will not create a new subnet. The default value is "subnet-mgmt". Go to Azure Protal -> Virtual networks -> Virtual_networks_name -> subnets`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_mgmt"
                                                    name="subnet_mgmt"
                                                    placeholder="subnet-mgmt"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="nic_mgmt"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Network Interface Card
                                                <span id={`azure_2vm_nic_mgmt_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_nic_mgmt_t_1`}>
                                                    {`Specify a unique network interface card for management traffic. This will create a new network interface card for a virtual machine. The default value is "vth-inst1-mgmt-nic". Go to Azure Protal -> Network interfaces -> Network_interface_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="nic_mgmt"
                                                    name="nic_mgmt1"
                                                    placeholder="vth-inst1-mgmt-nic"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_mgmt1"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_mgmt1"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["nic_mgmt1"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/*<!---Data In--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Data IN
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_datain"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id={`azure_2vm_subnet_datain_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_subnet_datain_t_1`}>
                                                    {`Specify an existing subnet name that is available within a selected virtual network for data traffic inward to vThunder. This will not create a new subnet. The default value is "subnet-data-in". Go to Azure Protal -> Virtual networks -> Virtual_networks_name -> subnets.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_datain"
                                                    name="subnet_datain"
                                                    placeholder="subnet-data-in"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_datain"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_datain"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["subnet_datain"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="nic_datain"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Network Interface Card
                                                <span id={`azure_2vm_nic_datain_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_nic_datain_t_1`}>
                                                    {`Specify a unique network interface card for data inward traffic. This will create a new network interface card for a virtual machine. The default value is "vth-inst1-data-in-nic". Go to Azure Protal -> Network interfaces -> Network_interface_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="nic_datain"
                                                    name="nic_datain1"
                                                    placeholder="vth-inst1-datain-nic"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_datain1"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_datain1"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["nic_datain1"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/*<!---Data OUT--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Data OUT
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_dataout"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id={`azure_2vm_subnet_dataout_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_subnet_dataout_t_1`}>
                                                    {`Specify an existing subnet name that is available within a selected virtual network for data traffic outward to vThunder. This will not create a new subnet. The default value is "subnet-data-out". Go to Azure Protal -> Virtual networks -> Virtual_networks_name -> subnets.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_dataout"
                                                    name="subnet_dataout"
                                                    placeholder="subnet-data-out"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_dataout"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_dataout"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["subnet_dataout"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="nic_dataout"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Network Interface Card
                                                <span id={`azure_2vm_nic_dataout_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_nic_dataout_t_1`}>
                                                    {`Specify a unique network interface card for data outward traffic. This will create a new network interface card for a virtual machine. The default value is "vth-inst1-data-out-nic". Go to Azure Protal -> Network interfaces -> Network_interface_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="nic_dataout"
                                                    name="nic_dataout1"
                                                    placeholder="vth-inst1-dataout-nic"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_dataout1"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_dataout1"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["nic_dataout1"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                    <UncontrolledAccordion stayOpen className="mb-2">
                        <AccordionItem>
                            <AccordionHeader targetId={`1vm_2`}>
                                Configure Security Settings
                            </AccordionHeader>
                            <AccordionBody accordionId={`1vm_2`}>
                                <Card body color="light" >
                                <FormGroup row>
                                        <Label bsSize="sm" for="nsg_mgmt"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Management)
                                            <span id={`azure_3vm_nsg_mgmt_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`azure_3vm_nsg_mgmt_t_1`}>
                                                {`Specify an existing network security group name for management traffic. This network security group should be available within the specified resource group where virtual network and public IP addresses are being created. This will not create a new network security group for a virtual machine. The default value is "vth-mgmt-nsg". Go to Azure Protal -> Network security groups -> Network_security_group_name.`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_mgmt"
                                                name="nsg_mgmt"
                                                placeholder="vth-mgmt-nsg"
                                                required="true"
                                                type="text"
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"])}
                                                value={formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for="nsg_datain"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Data-IN)
                                            <span id={`azure_3vm_nsg_datain_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`azure_3vm_nsg_datain_t_1`}>
                                                {`Specify an existing network security group name for data inward traffic. This network security group should be available within the specified resource group where virtual network and public IP addresses are being created. This will not create a new network security group for a virtual machine. The default value is "vth-data-in-nsg". Go to Azure Protal -> Network security groups -> Network_security_group_name.`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_datain"
                                                name="nsg_datain"
                                                placeholder="vth-mgmt-nsg"
                                                required="true"
                                                type="text"
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_datain"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_datain"])}
                                                value={formState["thunderADC"]["azure"]["ha"]["nsg_datain"]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for="nsg_dataout"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Data-OUT)
                                            <span id={`azure_3vm_nsg_dataout_t_1`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`azure_3vm_nsg_dataout_t_1`}>
                                                {`Specify an existing network security group name for data outward traffic. This network security group should be available within the specified resource group where virtual network and public IP addresses are being created. This will not create a new network security group for a virtual machine. The default value is "vth-data-out-nsg". Go to Azure Protal -> Network security groups -> Network_security_group_name.`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_dataout"
                                                name="nsg_dataout"
                                                placeholder="vth-mgmt-nsg"
                                                required="true"
                                                type="text"
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_dataout"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_dataout"])}
                                                value={formState["thunderADC"]["azure"]["ha"]["nsg_dataout"]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId="2vm">
                    Configure Virtual Machine-2
                </AccordionHeader>
                <AccordionBody accordionId="2vm">
                    <Card body color="light" className="pb-0">
                        <FormGroup row>
                            <Label bsSize="sm" for="location_2vm"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Region
                                <span id={`azure_2vm_location_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_location_t_2`}>
                                    {`The value is defined at Configure Virtual Machine-1 -> Region.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Select
                                    inputId="location_2vm"
                                    name="location"
                                    placeholder="Select a region"
                                    isSearchable
                                    isDisabled
                                    value={azureRegion.find((region) => region.value === formState["thunderADC"]["azure"]["ha"]["location"])}
                                    options={azureRegion}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '14px', // Adjust the font size
                                            lineHeight: '1', // Adjust the line height
                                            padding: '4px', // Adjust the padding
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            fontSize: "14px",
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="resource_group"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Resource Group
                                <span id={`azure_2vm_resource_group_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_resource_group_t_2`}>
                                    {`The value is defined at Configure Virtual Machine-1 -> Resource Group.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="resource_group"
                                    name="resource_group"
                                    placeholder="vth-rg1"
                                    required="true"
                                    type="text"
                                    disabled
                                    valid={isNotEmpty(formState["thunderADC"]["azure"]["ha"]["resource_group"])}
                                    invalid={!isNotEmpty(formState["thunderADC"]["azure"]["ha"]["resource_group"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["resource_group"]}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="existing_resource_group"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Existing Resource Group
                                <span id={`azure_2vm_existing_resource_group_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_existing_resource_group_t_2`}>
                                    {`The value is defined at Configure Virtual Machine-1 -> Existing Resource Group.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="existing_resource_group"
                                    name="existing_resource_group"
                                    placeholder="true"
                                    required="true"
                                    type="select"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["existing_resource_group"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["existing_resource_group"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["existing_resource_group"]}
                                    disabled>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="instance_name"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                VM Name
                                <span id="azure_2vm_instance_name_t_2" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target="azure_2vm_instance_name_t_2">
                                    {`Specify the virtual machine name for vThunder. This will create a new VM with the provided name.`}<br />{`The default value is "vth-inst-2".`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="instance_name"
                                    name="instance_name2"
                                    placeholder="vth-inst2"
                                    required="true"
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["instance_name2"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["instance_name2"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["instance_name2"]}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="image"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Image
                                <span id={`azure_2vm_image_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="bottom" target={`azure_2vm_image_t_2`}>
                                    {`The value is defined at Configure Virtual Machine-1 -> Image.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize='sm'
                                    name="image"
                                    required="true"
                                    disabled
                                    type="text"
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["image"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["image"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["image"]}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="size"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Size
                                <span id={`azure_2vm_size_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_size_t_2`}>
                                    {`The value is defined at Configure Virtual Machine-1 -> Size.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="size"
                                    name="size"
                                    type="select" // Change the type to select
                                    required="true"
                                    disabled
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["size"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["size"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["size"]}
                                >
                                    {azureSize.map(({ label, value }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </Input>
                                <a href="https://azure.microsoft.com/en-in/pricing/details/virtual-machines/series/" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label bsSize="sm" for="admin_username"
                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                Username
                                <span id={`azure_2vm_admin_username_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                <UncontrolledTooltip placement="top" target={`azure_2vm_admin_username_t_2`}>
                                    {`The value is defined at Configure Virtual Machine-1 -> Username.`}
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="admin_username"
                                    name="admin_username"
                                    placeholder="vth-user"
                                    required="true"
                                    type="text"
                                    disabled
                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["admin_username"])}
                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["admin_username"])}
                                    value={formState["thunderADC"]["azure"]["ha"]["admin_username"]}
                                />
                            </Col>
                        </FormGroup>
                    </Card>
                    <UncontrolledAccordion stayOpen className="mb-2">
                        <AccordionItem>
                            <AccordionHeader targetId={`2vm_1`}>
                                Configure Network Settings
                            </AccordionHeader>
                            <AccordionBody accordionId={`2vm_1`}>
                                <Card body color="light" className="p-0">
                                    <div className="p-3">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="vnet_nsg_rg"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Resource Group
                                                <span id={`azure_2vm_vnet_nsg_rg_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_vnet_nsg_rg_t_2`}>
                                                    {`The value is defined at Configure Virtual Machine-1 -> Virtual Network Security Resource Group.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="vnet_nsg_rg"
                                                    name="vnet_nsg_rg"
                                                    placeholder="vth-user"
                                                    required="true"
                                                    type="text"
                                                    disabled
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["vnet_nsg_rg"]}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="vnet_name"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Virtual Network (VNET)
                                                <span id={`azure_2vm_vnet_name_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_vnet_name_t_2`}>
                                                    {`The value is defined at Configure Virtual Machine-1 -> Virtual Network.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="vnet_name"
                                                    name="vnet_name"
                                                    placeholder="vth-user"
                                                    required="true"
                                                    type="text"
                                                    disabled
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_name"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["vnet_name"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["vnet_name"]}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="publicIP_name"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Public IP
                                                <span id={`azure_2vm_publicIP_name_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_publicIP_name_t_2`}>
                                                    {`Specify an existing public IP name for management traffic. This public IP address should be available within the specified resource group where virtual network and security groups are being created. This will not create a new public IP address for a virtual machine. The default value is "vth-inst2-ip". Go to Azure Protal -> Public IP addresses -> Public_IP_address_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="publicIP_name"
                                                    name="publicIP_name2"
                                                    placeholder="vth-user"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["publicIP_name2"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["publicIP_name2"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["publicIP_name2"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="accelerated_networking"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Accelerated Networking
                                                <span id={`azure_2vm_accelerated_networking_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_accelerated_networking_t_2`}>
                                                    {`The value is defined at Configure Virtual Machine-1 -> Accelerated Networking.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="accelerated_networking"
                                                    name="accelerated_networking"
                                                    placeholder="true"
                                                    required="true"
                                                    type="select"
                                                    disabled
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["accelerated_networking"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["accelerated_networking"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["accelerated_networking"]}
                                                    onChange={(e) => handleChange(e, "ha")}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                                <a href="https://learn.microsoft.com/en-us/azure/virtual-network/create-vm-accelerated-networking-cli?tabs=windows" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="ip_forwarding"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                IP Forwarding
                                                <span id={`azure_2vm_ip_forwarding_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_ip_forwarding_t_2`}>
                                                    {`The value is defined at Configure Virtual Machine-1 -> IP Forwarding`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="ip_forwarding"
                                                    name="ip_forwarding"
                                                    placeholder="true"
                                                    required="true"
                                                    type="select"
                                                    disabled
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["ip_forwarding"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["ip_forwarding"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["ip_forwarding"]}
                                                >
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                                <a href="https://learn.microsoft.com/en-us/azure/virtual-network/virtual-network-network-interface?tabs=azure-portal#enable-or-disable-ip-forwarding-for-a-network-interface" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    {/*<!---Management--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Management
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_mgmt"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id={`azure_2vm_subnet_mgmt_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_subnet_mgmt_t_2`}>
                                                    {`The value is defined at Configure Virtual Machine-1 -> Configure Network -> Management -> Subnet.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_mgmt"
                                                    name="subnet_mgmt"
                                                    placeholder="subnet-mgmt"
                                                    required="true"
                                                    type="text"
                                                    disabled
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["subnet_mgmt"]}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="nic_mgmt"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Network Interface Card
                                                <span id={`azure_2vm_nic_mgmt_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_nic_mgmt_t_2`}>
                                                    {`Specify a unique network interface card for management traffic. This will create a new network interface card for a virtual machine. The default value is "vth-inst2-mgmt-nic". Go to Azure Protal -> Network interfaces -> Network_interface_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="nic_mgmt"
                                                    name="nic_mgmt2"
                                                    placeholder="vth-inst1-mgmt-nic"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_mgmt2"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_mgmt2"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["nic_mgmt2"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/*<!---Data In--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Data IN
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_datain"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id={`azure_2vm_subnet_datain_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_subnet_datain_t_2`}>
                                                    {`The value is defined at Configure Virtual Machine-1 -> Configure Network -> Data IN -> Subnet.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_datain"
                                                    name="subnet_datain"
                                                    placeholder="subnet-data-in"
                                                    required="true"
                                                    type="text"
                                                    disabled
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_datain"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_datain"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["subnet_datain"]}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="nic_datain"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Network Interface Card
                                                <span id={`azure_2vm_nic_datain_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_nic_datain_t_2`}>
                                                    {`Specify a unique network interface card for data inward traffic. This will create a new network interface card for a virtual machine. The default value is "vth-inst2-data-in-nic". Go to Azure Protal -> Network interfaces -> Network_interface_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="nic_datain"
                                                    name="nic_datain2"
                                                    placeholder="vth-inst1-datain-nic"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_datain2"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_datain2"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["nic_datain2"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                    {/*<!---Data OUT--->*/}
                                    <hr className='m-0 p-0' />
                                    <CardHeader>
                                        Data OUT
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <FormGroup row>
                                            <Label bsSize="sm" for="subnet_dataout"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Subnet
                                                <span id={`azure_2vm_subnet_dataout_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_subnet_dataout_t_2`}>
                                                    {`The value is defined at Configure Virtual Machine-1 -> Configure Network -> Data OUT -> Subnet.`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="subnet_dataout"
                                                    name="subnet_dataout"
                                                    placeholder="subnet-data-out"
                                                    required="true"
                                                    type="text"
                                                    disabled
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_dataout"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["subnet_dataout"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["subnet_dataout"]}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label bsSize="sm" for="nic_dataout"
                                                rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                                <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                                Network Interface Card
                                                <span id={`azure_2vm_nic_dataout_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                                <UncontrolledTooltip placement="top" target={`azure_2vm_nic_dataout_t_2`}>
                                                    {`Specify a unique network interface card for data outward traffic. This will create a new network interface card for a virtual machine. The default value is "vth-inst2-data-out-nic". Go to Azure Protal -> Network interfaces -> Network_interface_name`}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col sm={7}>
                                                <Input bsSize="sm"
                                                    id="nic_dataout"
                                                    name="nic_dataout2"
                                                    placeholder="vth-inst1-dataout-nic"
                                                    required="true"
                                                    type="text"
                                                    valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_dataout2"])}
                                                    invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nic_dataout2"])}
                                                    value={formState["thunderADC"]["azure"]["ha"]["nic_dataout2"]}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                    <UncontrolledAccordion stayOpen className="mb-2">
                        <AccordionItem>
                            <AccordionHeader targetId={`2vm_2`}>
                                Configure Security Settings
                            </AccordionHeader>
                            <AccordionBody accordionId={`2vm_2`}>
                                <Card body color="light" >
                                <FormGroup row>
                                        <Label bsSize="sm" for="nsg_mgmt"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Management)
                                            <span id={`azure_3vm_nsg_mgmt_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`azure_3vm_nsg_mgmt_t_2`}>
                                                {`The value is defined at Configure Virtual Machine-1 -> Configure Security -> Network Security Group (Management).`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_mgmt"
                                                name="nsg_mgmt"
                                                placeholder="vth-mgmt-nsg"
                                                required="true"
                                                type="text"
                                                disabled
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"])}
                                                value={formState["thunderADC"]["azure"]["ha"]["nsg_mgmt"]}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for="nsg_datain"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Data-IN)
                                            <span id={`azure_3vm_nsg_datain_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`azure_3vm_nsg_datain_t_2`}>
                                                {`The value is defined at Configure Virtual Machine-1 -> Configure Security -> Network Security Group (Data-In).`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_datain"
                                                name="nsg_datain"
                                                placeholder="vth-mgmt-nsg"
                                                required="true"
                                                type="text"
                                                disabled
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_datain"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_datain"])}
                                                value={formState["thunderADC"]["azure"]["ha"]["nsg_datain"]}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label bsSize="sm" for="nsg_dataout"
                                            rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                            <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                            Network Security Group (Data-OUT)
                                            <span id={`azure_3vm_nsg_dataout_t_2`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                            <UncontrolledTooltip placement="top" target={`azure_3vm_nsg_dataout_t_2`}>
                                                {`The value is defined at Configure Virtual Machine-1 -> Configure Security -> Network Security Group (Data-Out).`}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col sm={7}>
                                            <Input bsSize="sm"
                                                id="nsg_dataout"
                                                name="nsg_dataout"
                                                placeholder="vth-mgmt-nsg"
                                                required="true"
                                                type="text"
                                                disabled
                                                valid={isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_dataout"])}
                                                invalid={!isAlphanumeric(formState["thunderADC"]["azure"]["ha"]["nsg_dataout"])}
                                                value={formState["thunderADC"]["azure"]["ha"]["nsg_dataout"]}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Card>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    )
}
export default AzureHAForm