import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, Col, FormGroup, Input, Label, UncontrolledAccordion, UncontrolledTooltip } from 'reactstrap';
import ConfigCredentials from "./ConfigCredentials";
import { isAlphanumeric, isIpValid } from '../../../utils/validations';

const GLMForm = ({ formState, setFormState }) => {
    const [showMore, setShowMore] = useState(false)
    const handleChange = (e) => {
        let updatedFormState = { ...formState }
        updatedFormState["glm"] = { ...updatedFormState["glm"], [e.target.name]: e.target.value }
        setFormState(updatedFormState)

    }
    return (
        <div>
            <ul className='note'>
                <li>This section will help to generate and download automated scripts to apply GLM as a configuration into Thunder using selected infrastructure as a tool (IaC).</li>
                <li>Passwords need to be provided during execution, we do not store any password information.</li>
                <li>Please test this out before applying to production.</li>
                {showMore && <>
                    <li>Thunder ADC should be deployed and in running state.</li>
                    <li>Internally it publish via Thunder aXAPI interface.</li>
                    <li>User should have valid glm credential. For more detail "https://glm.a10networks.com/".</li>
                    <li>An internet connection is required for Thunder device to reach the GLM.</li>
                    <li>Each configuration should be downloaded and executed separately.</li>
                    <li>For more Thunder configuration using terraform, please check <a href="https://github.com/a10networks/terraform-provider-thunder/tree/master" target="_blank">here</a></li>
                </>}
                <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
            </ul>

            <hr />
            <UncontrolledAccordion
                stayOpen
                className='mb-2'
            >
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        Configure Credentials
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <ConfigCredentials formState={formState} setFormState={setFormState} />
                    </AccordionBody>
                </AccordionItem >
                <AccordionItem>
                    <AccordionHeader targetId="2">
                        Configure License
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                        <Card body color="light" >
                            {/* <FormGroup row >
                            <Label bsSize="sm" for="username"
                                style={{ fontSize: "13px", textAlign: "end" }} sm={4} >GLM Username
                                <span
                                    id={`glm-username-t`}
                                    style={{ fontSize: "14px" }}
                                >
                                    <strong> ⓘ</strong>
                                </span>
                                <UncontrolledTooltip placement="top" target={`glm-username-t`}>
                                    GLM portal username
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="username"
                                    name="username"
                                    placeholder="username"
                                    required={true}
                                    type="text" value={formState["glm"]["username"] || ""} onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row >
                            <Label bsSize="sm" for="password"
                                style={{ fontSize: "13px", textAlign: "end" }} sm={4} >GLM Password
                                <span
                                    id={`glm-password-t`}
                                    style={{ fontSize: "14px" }}
                                >
                                    <strong> ⓘ</strong>
                                </span>
                                <UncontrolledTooltip placement="top" target={`glm-password-t`}>
                                    GLM portal password
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="password"
                                    name="password"
                                    disabled
                                    type="text" value="<password>"
                                />
                            </Col>
                        </FormGroup> */}
                            <FormGroup row >
                                <Label bsSize="sm" for="token" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Token
                                    <span
                                        id={`glm-ent-token-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`glm-ent-token-t`}>
                                        {`Specify GLM entitlement token, log in to GLM Portal "https://glm.a10networks.com/" and click on license name -> Overview -> info -> Entitlement Token.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="token"
                                        name="token"
                                        placeholder="vTh1f4baa802"
                                        required={true}
                                        type="text" value={formState["glm"]["token"] || ""}
                                        valid={isAlphanumeric(formState["glm"]["token"])}
                                        invalid={!isAlphanumeric(formState["glm"]["token"])}
                                        onChange={handleChange}
                                    />
                                    <a href="https://glm.a10networks.com/" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label bsSize="sm" for="dns_ip" style={{ fontSize: "13px", textAlign: "end" }} sm={4} >
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    DNS Address
                                    <span
                                        id={`dns_ip-t`}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <strong> ⓘ</strong>
                                    </span>
                                    <UncontrolledTooltip placement="top" target={`dns_ip-t`}>
                                        {`Specify the DNS address to enable an online license using A10 global license Manager(GLM).`}<br /> {`Default value is '8.8.8.8'.`}<br />{`Configure the DNS address using 8.8.8.8 or an in house DNS server.`}<br />{` For more information visit: "https://glm.a10networks.com/licenses/70724/downloads"`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="dns_ip"
                                        name="dns_ip"
                                        placeholder="8.8.8.8"
                                        required={true}
                                        type="text" value={formState["glm"]["dns_ip"] || ""}
                                        valid={isIpValid(formState["glm"]["dns_ip"])}
                                        invalid={!isIpValid(formState["glm"]["dns_ip"])}
                                        onChange={handleChange}
                                    />
                                    <a href="https://glm.a10networks.com/licenses/70724/downloads" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                </Col>
                            </FormGroup>
                            {/* <FormGroup row >
                            <Label bsSize="sm" for="appliance_uuid"
                                style={{ fontSize: "13px", textAlign: "end" }} sm={4} >Appliance UUID
                                <span
                                    id={`glm-uuid-t`}
                                    style={{ fontSize: "14px" }}
                                >
                                    <strong> ⓘ</strong>
                                </span>
                                <UncontrolledTooltip placement="top" target={`glm-uuid-t`}>
                                    Thunder host ID. This can be found via 'show license' command on Thunder CLI.
                                </UncontrolledTooltip>
                            </Label>
                            <Col sm={7}>
                                <Input bsSize="sm"
                                    id="appliance_uuid"
                                    name="appliance_uuid"
                                    placeholder="appliance_uuid"
                                    required={true}
                                    type="text" value={formState["glm"]["appliance_uuid"] || ""} onChange={handleChange}
                                />
                            </Col>
                        </FormGroup> */}
                        </Card>
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </div>

    )
}
export default GLMForm;