import Multiselect from 'multiselect-react-dropdown';
import React from "react";
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import { AccordionBody, AccordionHeader, Row, AccordionItem, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, UncontrolledAccordion, UncontrolledTooltip } from "reactstrap";
import { isAlphanumeric, isSmallCase } from '../../../../utils/validations';
import { gcpRegion, gcpImage, gcpInstanceSize } from '../../../../data/parameters';

const ConfigureVM = ({ formState, setFormState, vm, version_thunder }) => {
    const handleChange = (e) => {
        const newFormState = { ...formState };
        newFormState["thunderADC"]["gcp"][vm] = { ...newFormState["thunderADC"]["gcp"][vm], [e.target.name]: e.target.value }
        if (e.target.name === "zone") {
            newFormState["thunderADC"]["gcp"][vm]["machine_type"] = "n2-standard-4"
        }
        setFormState(newFormState)
    }
    const handleChanges = (fieldName, selectedOption) => {
        const newFormState = { ...formState };
        if (selectedOption) {
            newFormState["thunderADC"]["gcp"][vm][fieldName] = selectedOption.value;
        } else {
            newFormState["thunderADC"]["gcp"][vm][fieldName] = null;
        }
        setFormState(newFormState);
    };
    const handleChangeRegion = (selectedOption) => {
        const newFormState = { ...formState };
        newFormState["thunderADC"]["gcp"][vm] = {
            ...newFormState["thunderADC"]["gcp"][vm],
            region: selectedOption.value, // Use selectedOption.value to get the selected region
        };
        newFormState["thunderADC"]["gcp"][vm]["zone"] = gcpRegion[selectedOption.value]["zone"][0]
        newFormState["thunderADC"]["gcp"][vm]["machine_type"] = "n2-standard-4"
        setFormState(newFormState);
    };

    const options = Object.keys(gcpRegion).map((region) => ({
        value: region,
        label: gcpRegion[region]["label"],
    }));
    const handleSelect = (selectedList, selectedItem) => {
        let newFormState = { ...formState };
        newFormState["thunderADC"]["gcp"][vm]['tags'] = selectedList;
        setFormState(newFormState);
    };
    const CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps} className='ms-1 mb-2'>
            <div style={{ fontSize: '14px' }}>{label}</div>
            {(data.version && data.link) && <Row className="m-0" style={{ fontSize: '12px', color: '#888' }}>
                <Col>version-{data.version}</Col>
                <Col className='text-end me-2'><a target="_blank" href={data.link} style={{ "textDecoration": "none" }}>More Details</a></Col>
            </Row>}
        </div>
    )
    const FormatOptionLabel = ({ label, version, link }) => {
        return (
            <div className='m-0 p-0'>
                <div style={{ fontSize: '14px' }}>{label}</div>
                {(version && link) &&
                    <Row style={{ fontSize: '12px', color: '#888' }}>
                        <Col>version-{version}</Col>
                        <Col className='text-end me-2'><a target="_blank" href={link} style={{ "textDecoration": "none" }}>More Details</a></Col>
                    </Row>
                }
            </div>
        );
    }
    return (<>
        <Card body color="light" className="pb-0">
            <FormGroup row>
                <Label bsSize="sm" for="region"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Region
                    <span id="region_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="region_t">
                        {`Choose the region name where the project/resource will be created. The default value is 'us-west2'. Go to Google cloud console -> click on navigation menu -> Compute Engine -> Settings -> Settings -> Default location -> select Region.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Select
                        id="region"
                        name="region"
                        placeholder="Select a region"
                        value={options.find((option) => option.value === formState["thunderADC"]["gcp"][vm]["region"])}
                        options={options}
                        onChange={handleChangeRegion}
                        isSearchable={true}
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                                fontSize: '14px', // Adjust the font size
                                lineHeight: '1', // Adjust the line height
                                padding: '4px', // Adjust the padding
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                fontSize: "14px",
                            })
                        }}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label bsSize="sm" for="zone"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Zone
                    <span id="zone_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="zone_t">
                        {`Choose the zone name where the project/resource will be created. The default value is 'us-west2-a'. Go to Google cloud console -> click on navigation menu -> Compute Engine -> Settings -> Settings -> Default location -> select Region -> select available zone.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="zone"
                        name="zone"
                        placeholder="us-west2-a"
                        required="true"
                        type="select"
                        value={formState["thunderADC"]["gcp"][vm]["zone"]}
                        onChange={handleChange}
                    >
                        {(gcpRegion[formState["thunderADC"]["gcp"][vm]["region"]]["zone"]).map((zone, idx) => {
                            return (
                                <option key={idx} value={zone} >{zone}</option>
                            )
                        })}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label bsSize="sm" for="project"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Project
                    <span id="project_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="project_t">
                        {`Specify the project ID under which all the resources will be created. The default value is 'vth-project'. Go to Google cloud console -> click on navigation menu -> Cloud overview -> Dashboard -> Project info -> Project ID`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="project"
                        name="project"
                        placeholder="a10networks-public"
                        required="true"
                        type="text"
                        valid={isAlphanumeric(formState["thunderADC"]["gcp"][vm]["project"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["gcp"][vm]["project"])}
                        value={formState["thunderADC"]["gcp"][vm]["project"]}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label bsSize="sm" for="machine_name"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    VM Name
                    <span id="machine_name_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="machine_name_t">
                        {`Specify the virtual machine name. The name should be in small case. The default value is 'vth-inst-1'. The template will create a new VM with the provided name.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="machine_name"
                        name="machine_name"
                        placeholder="vth-inst"
                        required="true"
                        type="text"
                        valid={isSmallCase(formState["thunderADC"]["gcp"][vm]["machine_name"])}
                        invalid={!isSmallCase(formState["thunderADC"]["gcp"][vm]["machine_name"])}
                        value={formState["thunderADC"]["gcp"][vm]["machine_name"]}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label bsSize="sm" for="machine_type"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Type
                    <span id="machine_type_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="machine_type_t">
                        {`Choose the virtual machine instance type. The default is 'n2-standard-4'. Go to Google Cloud Console -> Create Instance -> New VM Instance -> Select Series -> Machine type.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    {/* <Input bsSize="sm"
                        id="machine_type"
                        name="machine_type"
                        placeholder="n2-standard-4"
                        required="true"
                        type="text"
                        valid={isAlphanumeric(formState["thunderADC"]["gcp"][vm]["machine_type"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["gcp"][vm]["machine_type"])}
                        value={formState["thunderADC"]["gcp"][vm]["machine_type"]}
                        onChange={handleChange}
                    /> */}

                    <Input
                        bsSize="sm"
                        id="machine_type"
                        name="machine_type"
                        placeholder="true"
                        required="true"
                        type="select"
                        valid={isAlphanumeric(formState["thunderADC"]["gcp"][vm]["machine_type"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["gcp"][vm]["machine_type"])}
                        value={formState["thunderADC"]["gcp"][vm]["machine_type"]}
                        onChange={handleChange}>
                        {gcpInstanceSize[formState["thunderADC"]["gcp"][vm]["zone"]].map((size, idx) => {
                            return <option value={size} key={idx}>{size}</option>
                        }
                        )}
                    </Input>
                    <a href="https://cloud.google.com/compute/docs/machine-resource" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label bsSize="sm" for="image"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Image
                    <span id="image_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="image_t">
                        {vm === "vm1" && <>{`Choose/Specify the A10 vThunder image. Default value 'projects/mpi-a10networks-public-396315/global/images/vthunder-601-b150-multi-ip-applied-lic'. Go to Google Cloud Console -> Create Instance -> New VM Instance -> Boot Disk -> Change -> Select Public Images/Custom Images -> Select -> Terraform -> Initialize Params -> Image`}</>}
                        {(vm === "vm2" || vm === "vm3") && <>{`The value is defined at Configure Virtual Machine-1 -> Image.`}</>}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    {vm === "vm1" && <>
                        <CreatableSelect
                            name="image"
                            isSearchable
                            options={gcpImage[version_thunder]}
                            components={{ Option: CustomOption }}
                            isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                return isAlphanumeric(inputValue)
                            }}
                            value={gcpImage[version_thunder].find((item) => item.value === formState["thunderADC"]["gcp"][vm]["image"]) || { "label": formState["thunderADC"]["gcp"][vm]["image"], "value": formState["thunderADC"]["gcp"][vm]["image"] }}
                            onChange={(selectedOption) => handleChanges("image", selectedOption)}
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    fontSize: '14px',
                                    lineHeight: '1',
                                    padding: '8px',
                                }),
                                container: (provided, state) => ({
                                    ...provided,
                                    fontSize: "14px",
                                })
                            }}
                            formatOptionLabel={FormatOptionLabel}
                        />
                    </>}
                    {(vm === "vm2" || vm === "vm3") && <>
                        <Input bsSize='sm'
                            name="image"
                            required="true"
                            disabled
                            type="text"
                            valid={isAlphanumeric(formState["thunderADC"]["gcp"]["vm1"]["image"])}
                            invalid={!isAlphanumeric(formState["thunderADC"]["gcp"]["vm1"]["image"])}
                            value={formState["thunderADC"]["gcp"]["vm1"]["image"]}
                        />
                    </>}

                </Col>
            </FormGroup>
            {/* <FormGroup row>
                <Label bsSize="sm" for="image_size"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Image Size(GB)
                    <span id="image_size_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="image_size_t">
                        {vm === "vm1" && <>{`Specify the A10 vThunder image size. The default is '30' GB. Go to Google Cloud Console -> Create Instance -> New VM Instance -> Boot Disk -> Change -> Select Public Images/Custom Images -> Select -> Terraform -> Initialize Params -> Size.`}</>}
                        {(vm === "vm2" || vm === "vm3") && <>{`The value is defined at Configure Virtual Machine-1 -> Image.`}</>}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="image_size"
                        name="image_size"
                        placeholder="30"
                        required="true"
                        type="number"
                        valid={vm === "vm1" && isAlphanumeric(formState["thunderADC"]["gcp"]["vm1"]["image_size"])}
                        invalid={vm === "vm1" && !isAlphanumeric(formState["thunderADC"]["gcp"]["vm1"]["image_size"])}
                        value={formState["thunderADC"]["gcp"]["vm1"]["image_size"]}
                        onChange={handleChange}
                        disabled={vm === "vm2" || vm === "vm3"}
                    />
                </Col>
            </FormGroup> */}
            <FormGroup row>
                <Label bsSize="sm" for="credentials"
                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                    Credentials
                    <span id="credentials_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip placement="top" target="credentials_t">
                        {`Specify the GCP credential file path on your local machine. The credential file should be downloaded on your local machine. Go to Google Cloud Console -> Go to Menu -> IAM & Admin -> Service Accounts -> Create service account -> Input Valid Name -> Create and Continue.`}
                    </UncontrolledTooltip>
                </Label>
                <Col sm={7}>
                    <Input bsSize="sm"
                        id="credentials"
                        name="credentials"
                        placeholder="/usr/local/a10networks-public-ed615f5fd2ad.json"
                        required="true"
                        type="text"
                        valid={isAlphanumeric(formState["thunderADC"]["gcp"][vm]["credentials"])}
                        invalid={!isAlphanumeric(formState["thunderADC"]["gcp"][vm]["credentials"])}
                        value={formState["thunderADC"]["gcp"][vm]["credentials"]}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
        </Card>
        <UncontrolledAccordion stayOpen className="mb-2">
            <AccordionItem>
                <AccordionHeader targetId={`${vm}_1`}>
                    Configure Network Settings
                </AccordionHeader>
                <AccordionBody accordionId={`${vm}_1`}>
                    <Card body color="light" className="p-0">
                        <div className='pt-4 pb-0'>
                            <FormGroup row>
                                <Label bsSize="sm" for="nic_type"
                                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Network Interface Card
                                    <span id="nic_type_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                    <UncontrolledTooltip placement="top" target="nic_type_t">
                                        {`Google Virtual NIC (gVNIC) is a virtual network interface designed specifically for Compute Engine. gVNIC is an alternative to the virtIO-based ethernet driver. The default value is 'VirtIO'.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="nic_type"
                                        name="nic_type"
                                        placeholder="VirtIO"
                                        required="true"
                                        type="text"
                                        disabled
                                        value={formState["thunderADC"]["gcp"][vm]["nic_type"] === "VIRTIO_NET" ? "VirtIO" : ""}
                                        onChange={handleChange}
                                    />
                                    <a href="https://ozlabs.org/~rusty/virtio-spec/virtio-0.9.5.pdf" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label bsSize="sm" for="tags"
                                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Network Tags
                                    <span id="tags_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                    <UncontrolledTooltip placement="top" target="tags_t">
                                        {`Assign network tags to apply firewall rules to specific VM instances. Default values are 'http-server, https-server'.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Multiselect
                                        isObject={false}
                                        options={['http-server', 'https-server']}
                                        onSelect={handleSelect}
                                        onRemove={handleSelect}
                                        selectedValues={formState["thunderADC"]["gcp"][vm]["tags"]}
                                    />
                                    <a href="https://cloud.google.com/vpc/docs/add-remove-network-tags?_ga=2.235273857.-1670686133.1692899999&_gac=1.124763512.1693202982.Cj0KCQjw6KunBhDxARIsAKFUGs-OND32m89jeS1cIpXqu3Q5viMEPF232r4zAXzyVoNxub5f4s1vYMEaAu_hEALw_wcB" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}>Help</a>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label bsSize="sm" for="ip_forwarding"
                                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    IP Forwarding
                                    <span id="ip_forwarding_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                    <UncontrolledTooltip placement="top" target="ip_forwarding_t">
                                        {`Choose Yes to enabling IP forwarding to allow the virtual machine to forward network traffic between networks, greatly improving its networking performance. This high-performance path bypasses the host from the datapath, reducing latency, jitter, and CPU utilization, for use with the most demanding network workloads on supported VM types.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="ip_forwarding"
                                        name="ip_forwarding"
                                        required="true"
                                        type="select"
                                        value={formState["thunderADC"]["gcp"][vm]["ip_forwarding"]}
                                        onChange={handleChange}
                                    >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option></Input>
                                    <a href="https://cloud.google.com/vpc/docs/using-routes?&_ga=2.193263021.-1670686133.1692899999&_gac=1.156637513.1693202982.Cj0KCQjw6KunBhDxARIsAKFUGs-OND32m89jeS1cIpXqu3Q5viMEPF232r4zAXzyVoNxub5f4s1vYMEaAu_hEALw_wcB#canipforward" rel="noopener noreferrer" target="_blank" style={{ float: "right", fontSize: "10px", textDecoration: "none" }}> Help</a>
                                </Col>
                            </FormGroup>
                        </div>
                        {/* <------------- MANAGEMENT --------------> */}
                        <hr className='mb-0 pb-0' />
                        <CardHeader>
                            Management
                        </CardHeader>
                        <CardBody className='pb-0'>
                            <FormGroup row>
                                <Label bsSize="sm" for="mgmt_subnet_id"
                                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Subnet ID
                                    <span id="mgmt_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                    <UncontrolledTooltip placement="top" target="mgmt_subnet_id_t">
                                        {`Specify the ID for the management subnet. The default value is 'projects/a10networks-public/regions/us-west2/subnetworks/subnet-vth-mgmt'. Go to Google cloud console -> click on navigation menu -> VPC network -> VPC networks -> select management VPC -> select subnet -> click on Equivalent REST -> copy selfLink value.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="mgmt_subnet_id"
                                        name="mgmt_subnet_id"
                                        placeholder="projects/a10networks-public/regions/us-west2/subnetworks/subnet-vth-mgmt"
                                        required="true"
                                        type="text"
                                        valid={isAlphanumeric(formState["thunderADC"]["gcp"][vm]["mgmt_subnet_id"])}
                                        invalid={!isAlphanumeric(formState["thunderADC"]["gcp"][vm]["mgmt_subnet_id"])}
                                        value={formState["thunderADC"]["gcp"][vm]["mgmt_subnet_id"]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>
                        </CardBody>
                        {/* <------------- Data IN --------------> */}
                        <hr className='mb-0 pb-0' />
                        <CardHeader>
                            Data-IN
                        </CardHeader>
                        <CardBody className="pb-0">
                            <FormGroup row>
                                <Label bsSize="sm" for="data_in_subnet_id"
                                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Subnet ID
                                    <span id="data_in_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                    <UncontrolledTooltip placement="top" target="data_in_subnet_id_t">
                                        {`Specify the ID for data in the subnet. The default value is 'projects/a10networks-public/regions/us-west2/subnetworks/subnet-vth-data-in'. Go to Google cloud console -> click on navigation menu -> VPC network -> VPC networks -> select data-in VPC -> select subnet -> click on Equivalent REST -> copy selfLink value.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data_in_subnet_id"
                                        name="data_in_subnet_id"
                                        placeholder="projects/a10networks-public/regions/us-west2/subnetworks/subnet-vth-data-in"
                                        required="true"
                                        type="text"
                                        valid={isAlphanumeric(formState["thunderADC"]["gcp"][vm]["data_in_subnet_id"])}
                                        invalid={!isAlphanumeric(formState["thunderADC"]["gcp"][vm]["data_in_subnet_id"])}
                                        value={formState["thunderADC"]["gcp"][vm]["data_in_subnet_id"]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>
                        </CardBody>
                        {/* <------------- Data OUT --------------> */}
                        <hr className='mb-0 pb-0' />
                        <CardHeader>
                            Data-OUT
                        </CardHeader>
                        <CardBody className="pb-0">
                            <FormGroup row>
                                <Label bsSize="sm" for="data_out_subnet_id"
                                    rel="noreferrer" style={{ fontSize: "13px", textAlign: "end" }} sm={4}>
                                    <span style={{ color: "red", fontSize: "16px" }}>*</span>
                                    Subnet ID
                                    <span id="data_out_subnet_id_t" style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                                    <UncontrolledTooltip placement="top" target="data_out_subnet_id_t">
                                        {`Specify the ID for the data out subnet. The default value is 'projects/a10networks-public/regions/us-west2/subnetworks/subnet-vth-data-out'. Go to Google cloud console -> click on navigation menu -> VPC network -> VPC networks -> select data-out VPC -> select subnet -> click on Equivalent REST -> copy selfLink value.`}
                                    </UncontrolledTooltip>
                                </Label>
                                <Col sm={7}>
                                    <Input bsSize="sm"
                                        id="data_out_subnet_id"
                                        name="data_out_subnet_id"
                                        placeholder="projects/a10networks-public/regions/us-west2/subnetworks/subnet-vth-data-out"
                                        required="true"
                                        type="text"
                                        valid={isAlphanumeric(formState["thunderADC"]["gcp"][vm]["data_out_subnet_id"])}
                                        invalid={!isAlphanumeric(formState["thunderADC"]["gcp"][vm]["data_out_subnet_id"])}
                                        value={formState["thunderADC"]["gcp"][vm]["data_out_subnet_id"]}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    </>)
}
export default ConfigureVM