import React, { useState } from 'react';
import { AccordionBody, AccordionHeader, AccordionItem, Button, UncontrolledAccordion } from 'reactstrap';
import ConfigServer from './ConfigServer';
import ConfigServiceGroup from './ConfigServiceGroup';
import ConfigVirtualServer from './ConfigVirtualServer';
import ConfigHttp from './ConfigHttp';
import ConfigCredentials from '../ConfigCredentials';

const SLBForm = ({ formState, setFormState }) => {

    const [showMore, setShowMore] = useState(false)

    return (
        <div>
            <ul className='note'>
                <li>This section will help to generate and download automated scripts to apply new configurations for app servers, web servers, server groups, http template, virtual server into Thunder using selected infrastructure as a tool (IaC).</li>
                {/* <li>This configuration will create and apply new virtual server, service group, http template, server settings into Thunder.</li> */}
                <li>Passwords need to be provided during execution, we do not store any password information.</li>
                <li>Please test this out before applying to production.</li>
                {showMore && <>
                    <li>Thunder ADC should be deployed and in running state.</li>
                    <li>Internally it publish via Thunder aXAPI interface.</li>
                    <li>Each configuration should be downloaded and executed separately.</li>
                    <li>For more Thunder configuration using terraform, please check <a href="https://github.com/a10networks/terraform-provider-thunder/tree/master" target="_blank">here</a></li>
                </>}
                <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
            </ul>

            <hr />
            <UncontrolledAccordion
                stayOpen
                className='mb-2'
            >
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        Configure Credentials
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <ConfigCredentials formState={formState} setFormState={setFormState} />
                    </AccordionBody>
                </AccordionItem >
                <ConfigServer formState={formState} setFormState={setFormState} />
                <ConfigServiceGroup formState={formState} setFormState={setFormState} />
                <ConfigHttp formState={formState} setFormState={setFormState} />
                <ConfigVirtualServer formState={formState} setFormState={setFormState} />
            </UncontrolledAccordion>
        </div>
    )
}

export default SLBForm
