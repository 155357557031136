import React, { useState } from "react";
import { Button, UncontrolledAccordion } from "reactstrap";
import Master from "./Master";
import Member from "./Member";
const GSLBForm = ({ formState, setFormState }) => {
    const [showMore, setShowMore] = useState(false)
    const [siteCount, setSiteCount] = useState(2);
    return (
        <div>
            <ul className='note'>
                <li>This section will help to generate and download automated scripts to apply Global Server Load Balancer configuration across region using selected infrastructure as a tool (IaC).</li>
                <li>Global server load balancer must configured in two region/location and each location have one controller with multiple sites on either clouds or hybrid clouds or on-premise platforms. It can be used for geographical traffic distribution.</li>
                <li>Passwords need to be provided during execution, we do not store any password information.</li>
                <li>Please test this out before applying to production.</li>
                {showMore && <>
                    <li>All Thunder should be  in running state, internally it publish via Thunder aXAPI interface.</li>
                    <li>Thunders can be configured as below.</li>
                    Region1
                    <ul>
                        <li>Thunder acting as Master Controller.</li>
                        <li>Thunder acting as Site 1.</li>
                        <li>Thunder acting as Site 2.</li>
                    </ul>
                    Region2
                    <ul>
                        <li>Thunder acting as Member Controller.</li>
                        <li>Thunder acting as Site 1.</li>
                        <li>Thunder acting as Site 2.</li>
                    </ul>
                    {/* <li>DNS should be configured with active controller.</li> */}
                    <li>Active Thunder is known as the master controller in region1 amd standby controller is known as the member controller in region2.</li>
                    <li>Master controller controls all request and re-direct to sites.</li>
                    <li>Sites has all slb servers configurations and policies for serving traffic.</li>
                    {/* <li>In case region1 is unavailable, region2 activated automatically, only DNS needs to configured with member controller.</li> */}
                    <li>This configuration works for VMware, Azure and AWS platform.</li>
                    <li>Each configuration should be downloaded and executed separately.</li>
                    <li>For more Thunder configuration using terraform, please check <a href="https://github.com/a10networks/terraform-provider-thunder/tree/master" target="_blank">here</a></li>
                </>}
                <Button size="sm" color="link" className="m-0 p-0" onClick={() => setShowMore(!showMore)}>{showMore ? "show less" : "show more"}</Button>
            </ul>
            <hr />
            <UncontrolledAccordion stayOpen className='mb-2'>
                <Master formState={formState} setFormState={setFormState} siteCount={siteCount} setSiteCount={setSiteCount} />
                <Member formState={formState} setFormState={setFormState} siteCount={siteCount} />
                {/* <GSLBSetting formState={formState} setFormState={setFormState} siteCount={siteCount} /> */}
                {/* <SiteSettings formState={formState} setFormState={setFormState} siteCount={siteCount} /> */}
            </UncontrolledAccordion>
        </div>
    )
}
export default GSLBForm