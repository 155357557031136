import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledAccordion, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { configurations, deploymentType } from "../../data/parameters";

const LeftPanel = ({ vThunderCount, setvThunderCount, product, setProduct, setConfig, config, version_thunder, setVersionThunder, version_TOA, setVersionTOA, cloud }) => {

  const desc = {
    ssl: 'This section will help to generate and download automated scripts to apply SSL as a configuration via Thunder aXAPI interface using provided infrastructure tool. Thunder ADC should be deployed and in running state. User should have valid ssl certificate.',
    ha: 'This section will help to generate and download automated scripts to apply High Availability as a configuration via Thunder aXAPI interface using provided infrastructure tool. Thunder ADC should be deployed and in running state. User should have valid credential file according to cloud.',
    gslb: 'This section will help to generate and download automated scripts to apply Global Server Load Balancer for cross availability zone as a configuration via Thunder aXAPI interface using provided infrastructure tool. Thunder ADC should be deployed and in running state for both availability zone',
    glm: 'This section will help to generate and download automated scripts to apply GLM as a configuration via Thunder aXAPI interface using provided infrastructure tool. Thunder ADC should be deployed and in running state. User should have valid glm credential. For more detail "https://glm.a10networks.com/"',
    changepassword: 'This section will help to generate and download automated scripts to apply change password as a configuration via Thunder aXAPI interface using provided infrastructure tool.',
    slb: 'This section will help to generate and download automated scripts to apply new app servers, web servers, server groups, virtual server as a configuration via Thunder aXAPI interface using provided infrastructure tool. Thunder ADC should be deployed and in running state.'
  }

  return (
    <UncontrolledAccordion
      defaultOpen={[
        '1', '2', '3', '4', '5'
      ]}
      stayOpen
    >
      <AccordionItem style={{ fontSize: "14px" }}>
        <AccordionHeader targetId="1">
          <span className="fw-bold"> PRODUCT</span>
        </AccordionHeader>
        <AccordionBody accordionId="1">
          {/* <ButtonGroup vertical className="m-0 w-100"> */}

          <UncontrolledDropdown
            size="sm"
            direction="down"
          >
            <DropdownToggle
              caret
              color="link"
              id={`product-description-t`}
              style={product === "thunderADC" ? { color: "black", textAlign: "left", fontSize: "14px", textDecoration: "none" } : { textAlign: "left", fontSize: "14px", textDecoration: "none" }}
              onClick={() => {
                setConfig(undefined)
                setProduct("thunderADC")
              }}
            >
              {`Thunder® ADC ${version_thunder}`}
              <UncontrolledTooltip placement="right" target={`product-description-t`}>
                Thunder® ADCs (Application Delivery Controllers) are high-performance solutions to accelerate and optimize critical applications to ensure delivery and reliability.
              </UncontrolledTooltip>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setVersionThunder('v5.x')} style={{ fontSize: "14px" }}>
                {`Thunder® ADC v5.x`}
              </DropdownItem>
              <DropdownItem onClick={() => setVersionThunder('v6.x')} style={{ fontSize: "14px" }}>
                {`Thunder® ADC v6.x`}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </AccordionBody>
      </AccordionItem>

      <AccordionItem style={{ fontSize: "14px" }}>
        <AccordionHeader targetId="5">
          <span className="fw-bold"> DEPLOYMENT</span>
        </AccordionHeader>
        <AccordionBody accordionId="5">

          <UncontrolledDropdown
            size="sm"
            direction="down"
          >
            <DropdownToggle
              caret
              color="link"
              onClick={() => setConfig(undefined)}
              style={config === undefined ? { color: "black", textAlign: "left", fontSize: "14px", textDecoration: "none" } : { textAlign: "left", fontSize: "14px", textDecoration: "none" }}
            >
              {config === undefined ? deploymentType[vThunderCount] : <>Select Deployment Type</>}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                id={`standalone-description-t`}
                style={(config !== undefined || vThunderCount !== "standalone") ? { textAlign: "left", fontSize: "14px" } : { textAlign: "left", fontSize: "14px", color: "black" }}
                onClick={() => setvThunderCount("standalone")}
              >
                {deploymentType["standalone"]}
                <UncontrolledTooltip placement="top" target={`standalone-description-t`}>
                  Provision one new virtual Thunder instance.
                </UncontrolledTooltip>
              </DropdownItem>
              {cloud !== "gcp" && <DropdownItem
                id={`ha-description-t`}
                style={(config !== undefined || vThunderCount !== "ha") ? { textAlign: "left", fontSize: "14px" } : { textAlign: "left", fontSize: "14px", color: "black" }}
                onClick={() => setvThunderCount("ha")}
              >
                {deploymentType["ha"]}
                <UncontrolledTooltip placement="top" target={`ha-description-t`}>
                  Provision two new virtual Thunder instance to configure high availability.
                </UncontrolledTooltip>
              </DropdownItem>}
              {cloud !== "gcp" && <DropdownItem
                id={`gslb-description-t`}
                style={(config !== undefined || vThunderCount !== "gslb") ? { textAlign: "left", fontSize: "14px" } : { textAlign: "left", fontSize: "14px", color: "black" }}
                onClick={() => setvThunderCount("gslb")}
              >
                {deploymentType["gslb"]}
                <UncontrolledTooltip placement="top" target={`gslb-description-t`}>
                  Provision three new virtual Thunder instance to configure global server load balancer.
                </UncontrolledTooltip>
              </DropdownItem>}
              {(cloud === "azure" || cloud === "aws" || cloud === "oci") && <>
                <DropdownItem
                  id={`custom-description-t`}
                  style={(config !== undefined || vThunderCount !== "custom") ? { textAlign: "left", fontSize: "14px" } : { textAlign: "left", fontSize: "14px", color: "black" }}
                  onClick={() => setvThunderCount("custom")}
                >
                  {deploymentType["custom"]}
                  <UncontrolledTooltip placement="top" target={`custom-description-t`}>
                    Provision custom number of new virtual Thunder instances.
                  </UncontrolledTooltip>
                </DropdownItem></>}
            </DropdownMenu>

          </UncontrolledDropdown>
        </AccordionBody>
      </AccordionItem>

      <AccordionItem style={{ fontSize: "14px" }}>
        <AccordionHeader targetId="2">
          <span className="fw-bold"> CONFIGURATION</span>
        </AccordionHeader>
        <AccordionBody accordionId="2">

          <UncontrolledDropdown
            size="sm"
            direction="down"
          >
            <DropdownToggle
              caret
              color="link"
              style={config !== undefined ? { color: "black", textAlign: "left", fontSize: "14px", textDecoration: "none" } : { textAlign: "left", fontSize: "14px", textDecoration: "none" }}
            >
              {config !== undefined ? configurations[product][config] : `Select Configuration`}
            </DropdownToggle>
            <DropdownMenu>
              {Object.keys(configurations[product]).map((configuration, idx) => {
                return (
                  <DropdownItem
                    id={`${configuration}-description-t`}
                    size="sm"
                    style={config !== configuration ? { textAlign: "left", fontSize: "14px" } : { textAlign: "left", fontSize: "14px", color: "black" }}
                    key={idx}
                    color="link"
                    onClick={() => { setConfig(configuration) }}
                  >
                    {configurations[product][configuration]}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </AccordionBody>
      </AccordionItem>

      <AccordionItem style={{ fontSize: "14px" }}>
        <AccordionHeader targetId="3">
          <span className="fw-bold"> RESOURCES</span>
        </AccordionHeader>
        <AccordionBody accordionId="3">
          <ButtonGroup vertical className="m-0 w-100">
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://documentation.a10networks.com/oauth2_access#adc-product-documentation"
              target="_blank"
            >
              <span id="acosDoc-t"> Documentation</span>

              <UncontrolledTooltip placement="top" target="acosDoc-t">
                Find out more information about ACOS product documentation.
              </UncontrolledTooltip>
            </Button>
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://documentation.a10networks.com/#product"
              target="_blank"
            >
              <span id="product-t">Products</span>
              <UncontrolledTooltip placement="top" target="product-t">
                Find out more information about ACOS products.
              </UncontrolledTooltip>
            </Button>
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://support.a10networks.com/"
              target="_blank"
            >
              <span id="support-t">Support</span>
              <UncontrolledTooltip placement="top" target="support-t">
                Find out more information regarding product support for ACOS.
              </UncontrolledTooltip>
            </Button>
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://community.a10networks.com/"
              target="_blank"
            >
              <span id="community-t">Community</span>
              <UncontrolledTooltip placement="top" target="community-t">
                Feel free to become a part of our A10 Network community.
              </UncontrolledTooltip>
            </Button>

            {/* <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://community.a10networks.com/"
              target="_blank"
            >
              <span id="disclaimer-t">Disclaimer</span>
              <UncontrolledTooltip placement="top" target="disclaimer-t">
                Open source software disclaimer.
              </UncontrolledTooltip>
            </Button> */}

            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              // href="/LICENSE.txt"
              href="https://www.a10networks.com/company/legal/eula/"
              target="_blank"
            >
              <span id="license-t">License</span>
              <UncontrolledTooltip placement="top" target="license-t">
                End User License and Services Agreement
              </UncontrolledTooltip>
            </Button>
          </ButtonGroup>
        </AccordionBody>
      </AccordionItem>

      <AccordionItem style={{ textAlign: "left", fontSize: "14px" }}>
        <AccordionHeader targetId="4">
          <span className="fw-bold"> MARKETPLACE</span>
        </AccordionHeader>
        <AccordionBody accordionId="4">
          <ButtonGroup vertical className="m-0 w-100">
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://support.a10networks.com/support/axseries"
              target="_blank"
            >
              <span id={`marketPlace-vmware-t`}>
                VMware Marketplace
              </span>
              <UncontrolledTooltip placement="top" target={`marketPlace-vmware-t`}>
                Find out more ACOS public images available on VMware Cloud.
              </UncontrolledTooltip>
            </Button>
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps?search=a10%20adc&page=1"
              target="_blank"
            >
              <span id={`marketPlace-azure-t`}>
                Azure Marketplace
              </span>
              <UncontrolledTooltip placement="top" target={`marketPlace-azure-t`}>
                Find out more ACOS public images available on Microsoft Azure Cloud.
              </UncontrolledTooltip>
            </Button>
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://aws.amazon.com/marketplace/search/results?searchTerms=a10+adc&CREATOR=0cda37b3-aa1a-4c9d-8daf-c396572cc98b&filters=CREATOR"
              target="_blank"
            >
              <span id={`marketPlace-aws-t`}>
                AWS Marketplace
              </span>
              <UncontrolledTooltip placement="top" target={`marketPlace-aws-t`}>
                Find out more ACOS public images available on AWS Cloud.
              </UncontrolledTooltip>
            </Button>
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://console.cloud.google.com/marketplace/browse?q=a10%20adc"
              target="_blank"
            >
              <span id={`marketPlace-gcp-t`}>
                GCP Marketplace
              </span>
              <UncontrolledTooltip placement="top" target={`marketPlace-gcp-t`}>
                Find out more ACOS public images available on GCP Cloud.
              </UncontrolledTooltip>
            </Button>
            <Button
              size="sm"
              style={{ textAlign: "left", fontSize: "14px", }}
              color="link"
              href="https://cloudmarketplace.oracle.com/"
              target="_blank"
            >
              <span id={`marketPlace-oracle-t`}>
                OCI Marketplace
              </span>
              <UncontrolledTooltip placement="top" target={`marketPlace-oracle-t`}>
                Find out more ACOS public images available on Oracle Cloud.
              </UncontrolledTooltip>
            </Button>
          </ButtonGroup>
        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion >
  );
};

export default LeftPanel;
