import React from 'react'

const AwsCustomEditor = ({ formState }) => {
    return (
        <div>
            <code className="d-block" style={{ fontSize: "13px" }}>
                Configure_Custom_VMs:
            </code>
            {formState["thunderADC"]["aws"]["custom"]["region"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tregion`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["region"]}</span>
            </code>}
            {<code className="d-block" style={{ fontSize: "13px" }}>
                {`\tinstance_count`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["instance_count"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["custom"]["instance_names"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tinstance_names`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["aws"]["custom"]["instance_names"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
            {formState["thunderADC"]["aws"]["custom"]["thunder_ami_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tthunder_ami_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["thunder_ami_id"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["custom"]["thunder_instance_size"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tthunder_instance_size`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["thunder_instance_size"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["custom"]["key_pair"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\tkey_pair`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["key_pair"]}</span>
            </code>}

            {/* <-------- Management --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Network:"}
            </code>
            {formState["thunderADC"]["aws"]["custom"]["mgmt_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tmgmt_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["mgmt_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["custom"]["data_in_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_in_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["data_in_subnet_id"]}</span>
            </code>}
            {formState["thunderADC"]["aws"]["custom"]["data_out_subnet_id"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tdata_out_subnet_id`}: <span style={{ color: "rgb(171, 227, 56)" }}>{formState["thunderADC"]["aws"]["custom"]["data_out_subnet_id"]}</span>
            </code>}

            {/* <-------- Security --------->*/}
            <code className="d-block" style={{ fontSize: "13px" }}>
                {"\tConfigure_Security:"}
            </code>
            {formState["thunderADC"]["aws"]["custom"]["security_group_mgmt_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsecurity_group_mgmt_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["aws"]["custom"]["security_group_mgmt_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
            {formState["thunderADC"]["aws"]["custom"]["security_group_data_ids"] && <code className="d-block" style={{ fontSize: "13px" }}>
                {`\t\tsecurity_group_data_ids`}: <span style={{ color: "rgb(171, 227, 56)" }}>[
                    {formState["thunderADC"]["aws"]["custom"]["security_group_data_ids"].map((ele, idx) => {
                        return <>{idx > 0 && ", "}{ele}</>
                    })}
                    ]</span>
            </code>}
        </div>
    )
}

export default AwsCustomEditor