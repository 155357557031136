import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { intialState } from "../data/parameters";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import LeftPanel from "./leftpanel/LeftPanel";
import RightPanel from "./rightpanel/RightPanel";

const Home = () => {
  const [product, setProduct] = useState("thunderADC");
  const [version_thunder, setVersionThunder] = useState("v6.x");
  const [version_TOA, setVersionTOA] = useState("v1.X");
  const [cloud, setCloud] = useState("vmware");
  const [config, setConfig] = useState(undefined)
  const [formState, setFormState] = useState(intialState);
  const [vThunderCount, setvThunderCount] = useState("standalone");

  useEffect(() => {
    setvThunderCount("standalone")
  }, [cloud])


  return (
    <Container fluid>
      <ToastContainer
      // position="bottom-right" autoClose={3000} hideProgressBar={false} closeOnClick pauseOnFocusLoss
      // rtl={false} draggable pauseOnHover
      />
      <Row>
        <Header />
      </Row>
      <Row>
        {/* <Col xs={12} sm={3} className="ps-0"> */}
        <div className="ps-0 pe-1" style={{
          display: "inline-block",
          width: "auto"
        }}>
          <LeftPanel vThunderCount={vThunderCount} setvThunderCount={setvThunderCount} config={config} setConfig={setConfig} product={product} setProduct={setProduct} version_thunder={version_thunder} setVersionThunder={setVersionThunder} version_TOA={version_TOA} setVersionTOA={setVersionTOA} cloud={cloud} />
        </div>
        {/* </Col> */}
        <Col>
          <Row>
            <RightPanel
              config={config}
              cloud={cloud}
              setCloud={setCloud}
              product={product}
              version_thunder={version_thunder}
              version_TOA={version_TOA}
              formState={formState}
              setFormState={setFormState}
              vThunderCount={vThunderCount}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Footer />
      </Row>
    </Container>
  );
};

export default Home;
