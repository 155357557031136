import React, { useState } from "react"
import { AccordionBody, AccordionHeader, AccordionItem, Table, Input, UncontrolledTooltip, Button, UncontrolledAccordion } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa"
import { isIpValid, isAlphanumeric, isSubnetValid, isNotEmpty } from "../../../../utils/validations";
import Region2VirtualServer from "./Region2VirtualServer";
import SLBServer from "./ServerSLB";
import ServiceGroupSLB from "./ServiceGroupSLB";
import VirtualServerSLB from "./VirtualServerSLB";
import Region2ServiceIP from "./Region2ServiceIP";
import CreatableSelect from 'react-select/creatable';
const Member = ({ formState, setFormState, siteCount }) => {
  const [multiValFields, setMultiValFields] = useState({
    "member_next_hop_ip": [...formState["gslb"]["member"]["memberController"]["member_next_hop_ip"]].map((value) => ({ value, label: value })),
    "site_next_hop_ip_0": [...formState["gslb"]["member"]["sites"][0]["site_next_hop_ip"]].map((value) => ({ value, label: value })),
    "site_next_hop_ip_1": [...formState["gslb"]["member"]["sites"][1]["site_next_hop_ip"]].map((value) => ({ value, label: value })),
  });
  const handleChange = (e, thunder) => {
    let newFormState = { ...formState }
    newFormState["gslb"]["member"][thunder] = { ...newFormState["gslb"]["member"][thunder], [e.target.name]: e.target.value }
    setFormState(newFormState)
  };
  const handleSiteChange = (e, index) => {
    let newFormState = { ...formState }
    newFormState["gslb"]["member"]["sites"][index] = { ...newFormState["gslb"]["member"]["sites"][index], [e.target.name]: e.target.value }
    setFormState(newFormState)
  };
  const handleMultiValFieldChange = (selectedList, fieldName, thunder) => {
    const valuesArray = selectedList.map(item => item.value);

    let newMultiValFields = { ...multiValFields }
    newMultiValFields[fieldName] = selectedList
    console.log(fieldName, selectedList)
    setMultiValFields(newMultiValFields)

    let newFormState = { ...formState };
    newFormState["gslb"]["member"][thunder][fieldName] = valuesArray;
    setFormState(newFormState);
  };
  const handleMultiValFieldChangeSite = (selectedList, fieldName, index) => {
    const valuesArray = selectedList.map(item => item.value);

    let newMultiValFields = { ...multiValFields };
    newMultiValFields[fieldName + "_" + index] = selectedList;
    console.log(fieldName, selectedList);
    setMultiValFields(newMultiValFields);

    let newFormState = { ...formState };
    newFormState["gslb"]["member"]["sites"][index][fieldName] = valuesArray;
    setFormState(newFormState);
  };
  return (
    <AccordionItem>
      <AccordionHeader targetId="2">
        Configure Region2 (StandBy-Thunders)
      </AccordionHeader>
      <AccordionBody accordionId="2">

        <AccordionItem>
          <AccordionHeader targetId="2_1">
            Member Controller (Thunder-1)
          </AccordionHeader>
          <AccordionBody accordionId="2_1">
            {/* <h5>Member Controller </h5> */}
            <Table responsive bordered hover striped>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th style={{ fontSize: "14px", width: "40%" }}>Field Name</th>
                  <th style={{ fontSize: "14px", width: "60%" }}>Value</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr key="memberGeoRegion" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Region
                    <span id={`memberGeoRegion-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberGeoRegion-t`} placement="top">
                      Specify the geolocation region for the member controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="memberGeoRegion"
                      name="member_geo_region"
                      placeholder="us"
                      type="text"
                      value={formState['gslb']['member']['memberController']['member_geo_region']}
                      valid={isAlphanumeric(formState['gslb']['member']['memberController']['member_geo_region'])}
                      invalid={!isAlphanumeric(formState['gslb']['member']['memberController']['member_geo_region'])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                <tr key="memberGeoCity" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    City
                    <span id={`memberGeoCity-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberGeoCity-t`} placement="top">
                      Specify the geolocation city for the member controller in the region "{formState['gslb']['member']['memberController']['member_geo_region']}".
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="memberGeoCity"
                      name="member_geo_city"
                      placeholder="california"
                      type="text"
                      value={formState['gslb']['member']['memberController']['member_geo_city']}
                      valid={isAlphanumeric(formState['gslb']['member']['memberController']['member_geo_city'])}
                      invalid={!isAlphanumeric(formState['gslb']['member']['memberController']['member_geo_city'])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr> */}
                <tr key="memberPublicIPMgmt" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Public IP
                    <span id={`memberPublicIPMgmt-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`memberPublicIPMgmt-t`}>
                      Specify public IP of member controller Thunder to connect and apply configuration. IP should belong from management interface.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="memberPublicIPMgmt"
                      name="member_publicIPMgmt"
                      placeholder="53.0.0.1"
                      type="text"
                      value={formState["gslb"]["member"]["memberController"]["member_publicIPMgmt"]}
                      valid={isIpValid(formState["gslb"]["member"]["memberController"]["member_publicIPMgmt"])}
                      invalid={!isIpValid(formState["gslb"]["member"]["memberController"]["member_publicIPMgmt"])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                <tr key="memberUsername" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Username
                    <span id={`memberUsername-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`memberUsername-t`}>
                      Specify username of the Thunder to connect and apply configuration. Default value is 'admin'.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="memberUsername"
                      name="member_username"
                      placeholder="admin"
                      type="text"
                      value={formState["gslb"]["member"]["memberController"]["member_username"]}
                      valid={isAlphanumeric(formState["gslb"]["member"]["memberController"]["member_username"])}
                      invalid={!isAlphanumeric(formState["gslb"]["member"]["memberController"]["member_username"])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                {/* <tr key="memberPassword" style={{ height: "30px" }}>
                <td style={{ fontSize: "12px", padding: "2px" }}>
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  Password
                  <span id={`memberPassword-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                  </span>
                  <UncontrolledTooltip placement="top" target={`memberPassword-t`}>
                    Specify password to login.
                  </UncontrolledTooltip>
                </td>
                <td style={{ padding: "2px" }}>
                  <Input
                    bsSize="sm"
                    id="memberPassword"
                    name="member_password"
                    placeholder="<password>"
                    disabled
                    value={formState["gslb"]["member"]["memberController"]["member_password"]}
                  />
                </td>
              </tr> */}
                {/* <tr key="memberSubnetMgmt" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Subnet-1 <span id={`memberSubnetMgmt-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`memberSubnetMgmt-t`}>
                      Specify CIDR block of management subnet in format a.b.c.d/n.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="memberSubnetMgmt"
                      name="member_subnetMgmt"
                      placeholder="10.0.0.1/24"
                      type="text"
                      value={formState["gslb"]["member"]["memberController"]["member_subnetMgmt"]}
                      valid={isSubnetValid(formState["gslb"]["member"]["memberController"]["member_subnetMgmt"])}
                      invalid={!isSubnetValid(formState["gslb"]["member"]["memberController"]["member_subnetMgmt"])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr> */}
                <tr key="memberPublicIPdata" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Public IP - Data In
                    <span id={`memberPublicIPdata-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`memberPublicIPdata-t`}>
                      Specify the secondary public IP address for data in interface.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="memberPublicIPdata"
                      name="member_publicIPdata"
                      placeholder="53.0.0.5"
                      type="text"
                      value={formState["gslb"]["member"]["memberController"]["member_publicIPdata"]}
                      valid={isIpValid(formState["gslb"]["member"]["memberController"]["member_publicIPdata"])}
                      invalid={!isIpValid(formState["gslb"]["member"]["memberController"]["member_publicIPdata"])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                {/* <tr key="memberSubnetdata" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Subnet-2 <span id={`memberSubnetdata-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`memberSubnetdata-t`}>
                      Specify CIDR block of data-in subnet in format a.b.c.d/n.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="memberSubnetdata"
                      name="member_subnetdata"
                      placeholder="10.0.1.0/24"
                      type="text"
                      value={formState["gslb"]["member"]["memberController"]["member_subnetdata"]}
                      valid={isSubnetValid(formState["gslb"]["member"]["memberController"]["member_subnetdata"])}
                      invalid={!isSubnetValid(formState["gslb"]["member"]["memberController"]["member_subnetdata"])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr> */}
                <tr key="memberPrivateIP" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Private IP
                    <span id={`memberPrivateIP-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                    </span>
                    <UncontrolledTooltip placement="top" target={`memberPrivateIP-t`}>
                      Specify the secondary private IP address of data-in subnet. This IP should be unique and is not assigned to any other member within the GSLB group.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input
                      bsSize="sm"
                      id="memberPrivateIP"
                      name="member_privateIP"
                      placeholder="10.0.0.1"
                      type="text"
                      value={formState["gslb"]["member"]["memberController"]["member_privateIP"]}
                      valid={isIpValid(formState["gslb"]["member"]["memberController"]["member_privateIP"])}
                      invalid={!isIpValid(formState["gslb"]["member"]["memberController"]["member_privateIP"])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                <tr key="memberDns" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    DNS
                    <span id={`memberDns-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberDns-t`} placement="top">
                      Specify the primary DNS address for the member controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="memberDns"
                      name="member_dns"
                      placeholder="8.8.8.8"
                      type="text"
                      value={formState['gslb']['member']['memberController']['member_dns']}
                      valid={isIpValid(formState['gslb']['member']['memberController']['member_dns'])}
                      invalid={!isIpValid(formState['gslb']['member']['memberController']['member_dns'])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                <tr key="memberGroupName" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Group Name
                    <span id={`memberGroupName-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberGroupName-t`} placement="top">
                      Specify the GSLB group name for the member controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="memberGroupName"
                      name="member_group_name"
                      placeholder="group-2"
                      type="text"
                      disabled
                      value={formState['gslb']['master']['masterController']['master_group_name']}
                      valid={isAlphanumeric(formState['gslb']['member']['memberController']['member_group_name'])}
                      invalid={!isAlphanumeric(formState['gslb']['member']['memberController']['member_group_name'])}
                    // onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                <tr key="memberPriority" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Priority
                    <span id={`memberPriority-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberPriority-t`} placement="top">
                      {` Specify priority for the member controller.The priority ranges between 1 to 255. The priority of the master(active) controller is less than the member(standby) controller`}
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="memberPriority"
                      name="member_priority"
                      placeholder="1"
                      type="number"
                      disabled
                      value={formState['gslb']['member']['memberController']['member_priority']}
                    />
                  </td>
                </tr>
                <tr key="memberDestIP" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Destination IP
                    <span id={`memberDestIP-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberDestIP-t`} placement="top">
                      Specify the destination IP address for the member controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="memberDestIP"
                      name="member_destination_ip"
                      placeholder="0.0.0.0"
                      type="text"
                      value={formState['gslb']['member']['memberController']['member_destination_ip']}
                      valid={isIpValid(formState['gslb']['member']['memberController']['member_destination_ip'])}
                      invalid={!isIpValid(formState['gslb']['member']['memberController']['member_destination_ip'])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                <tr key="memberDestIPMask" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Destination IP Mask
                    <span id={`memberDestIPMask-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberDestIPMask-t`} placement="top">
                      Specify the destination IP address mask for the member controller.
                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    <Input bsSize="sm"
                      id="memberDestIPMask"
                      name="member_destination_ip_mask"
                      placeholder="/0"
                      type="text"
                      value={formState['gslb']['member']['memberController']['member_destination_ip_mask']}
                      valid={isAlphanumeric(formState['gslb']['member']['memberController']['member_destination_ip_mask'])}
                      invalid={!isAlphanumeric(formState['gslb']['member']['memberController']['member_destination_ip_mask'])}
                      onChange={(e) => handleChange(e, "memberController")}
                    />
                  </td>
                </tr>
                <tr key="memberNextHopIP" style={{ height: "30px" }}>
                  <td style={{ fontSize: "12px", padding: "2px" }}>
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    Next Hop IP
                    <span id={`memberNextHopIP-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                    <UncontrolledTooltip target={`memberNextHopIP-t`} placement="top">
                      Specify next hop IP address of management and data-in subnet. Next hop IP address is the first IP address of respective subnet.                    </UncontrolledTooltip>
                  </td>
                  <td style={{ padding: "2px" }}>
                    {/* <Input bsSize="sm"
                      id="memberNextHopIP"
                      name="member_next_hop_ip"
                      placeholder="10.0.1.1"
                      type="text"
                      value={formState['gslb']['member']['memberController']['member_next_hop_ip']}
                      valid={isIpValid(formState['gslb']['member']['memberController']['member_next_hop_ip'])}
                      invalid={!isIpValid(formState['gslb']['member']['memberController']['member_next_hop_ip'])}
                      onChange={(e) => handleChange(e, "memberController")}
                    /> */}
                    <CreatableSelect
                      isMulti
                      isValidNewOption={(inputValue, selectValue, selectOptions) => {
                        return isIpValid(inputValue)
                      }}
                      placeholder="Provide multiple IP and press enter.."
                      value={multiValFields["member_next_hop_ip"]}
                      onChange={(selectedOption) => handleMultiValFieldChange(selectedOption, "member_next_hop_ip", "memberController")}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          minHeight: "35px",
                          fontSize: "14px",
                          borderColor: multiValFields["member_next_hop_ip"].length > 0 ? "#198754 !important" : "#dc3545 !important"
                        }),
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <UncontrolledAccordion stayOpen className='mb-2'>
              <AccordionItem>
                <AccordionHeader targetId="2_2">
                  Configure GSLB Settings
                </AccordionHeader>
                <AccordionBody accordionId="2_2">
                  <Region2VirtualServer formState={formState} setFormState={setFormState} />
                  <Region2ServiceIP formState={formState} setFormState={setFormState} />
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

          </AccordionBody>
        </AccordionItem>


        {[...Array(siteCount)].map((item, index) => (
          <div key={index}>
            <AccordionItem key={index}>
              <AccordionHeader targetId={`2_${index + 3}`}>
                Site-{index + 1} (Thunder-{index + 2})
              </AccordionHeader>
              <AccordionBody accordionId={`2_${index + 3}`}>

                {/* <h5>Site-{index + 1}</h5> */}
                <Table responsive bordered hover striped>
                  <thead>
                    <tr style={{ height: "30px" }}>
                      <th style={{ fontSize: "14px", width: "40%" }}>Field Name</th>
                      <th style={{ fontSize: "14px", width: "60%" }}>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={`siteName${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Name
                        <span id={`siteName${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip target={`siteName${index}-r2-t`} placement="top">
                          Specify the name for "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input bsSize="sm"
                          id={`siteName${index}`}
                          name="site_name"
                          placeholder="site"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_name"]}
                          valid={isAlphanumeric(formState["gslb"]["member"]["sites"][index]["site_name"])}
                          invalid={!isAlphanumeric(formState["gslb"]["member"]["sites"][index]["site_name"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    <tr key={`siteGeolocation${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Region
                        <span id={`siteGeolocation${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip target={`siteGeolocation${index}-r2-t`} placement="top">
                          Specify the geolocation region for the "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input bsSize="sm"
                          id={`siteGeolocation${index}`}
                          name="site_geolocation"
                          placeholder="us-east"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_geolocation"]}
                          valid={isNotEmpty(formState["gslb"]["member"]["sites"][index]["site_geolocation"])}
                          invalid={!isNotEmpty(formState["gslb"]["member"]["sites"][index]["site_geolocation"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    <tr key={`sitePublicIPMgmt${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Public IP
                        <span id={`sitePublicIPMgmt${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target={`sitePublicIPMgmt${index}-r2-t`}>
                          Specify the public managemet IP address for "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input
                          bsSize="sm"
                          id={`sitePublicIPMgmt${index}`}
                          name="site_publicIPMgmt"
                          placeholder="53.0.0.1"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_publicIPMgmt"]}
                          valid={isIpValid(formState["gslb"]["member"]["sites"][index]["site_publicIPMgmt"])}
                          invalid={!isIpValid(formState["gslb"]["member"]["sites"][index]["site_publicIPMgmt"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    <tr key={`siteUsername${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Username
                        <span id={`siteUsername${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target={`siteUsername${index}-r2-t`}>
                          Specify the Thunder username for "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input
                          bsSize="sm"
                          id={`siteUsername${index}`}
                          name="site_username"
                          placeholder="admin"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_username"]}
                          valid={isAlphanumeric(formState["gslb"]["member"]["sites"][index]["site_username"])}
                          invalid={!isAlphanumeric(formState["gslb"]["member"]["sites"][index]["site_username"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    {/* <tr key={`sitePassword${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Password
                        <span id={`sitePassword${index}-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target={`sitePassword${index}-t`}>
                          Specify the Thunder password for "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input
                          bsSize="sm"
                          id={`sitePassword${index}`}
                          name="site_password"
                          placeholder="<password>"
                          type="text"
                          disabled
                          value={formState["gslb"]["member"]["sites"][index]["site_password"]}
                        />
                      </td>
                    </tr> */}
                    {/* <tr key={`siteSubnetMgmt${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Subnet-1
                        <span id={`siteSubnetMgmt${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target={`siteSubnetMgmt${index}-r2-t`}>
                          Specify CIDR block of management subnet in format a.b.c.d/n for "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input
                          bsSize="sm"
                          id={`siteSubnetMgmt${index}`}
                          name="site_subnetMgmt"
                          placeholder="10.0.0.1/24"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_subnetMgmt"]}
                          valid={isSubnetValid(formState["gslb"]["member"]["sites"][index]["site_subnetMgmt"])}
                          invalid={!isSubnetValid(formState["gslb"]["member"]["sites"][index]["site_subnetMgmt"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr> */}
                    <tr key={`sitePublicIPdata${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Public IP - Data In
                        <span id={`sitePublicIPdata${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target={`sitePublicIPdata${index}-r2-t`}>
                          Specify the secondary public IP address for data in interface for "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input
                          bsSize="sm"
                          id={`sitePublicIPdata${index}`}
                          name="site_publicIPdata"
                          placeholder="53.0.0.5"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_publicIPdata"]}
                          valid={isIpValid(formState["gslb"]["member"]["sites"][index]["site_publicIPdata"])}
                          invalid={!isIpValid(formState["gslb"]["member"]["sites"][index]["site_publicIPdata"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    {/* <tr key={`siteSubnetdata${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Subnet-2
                        <span id={`siteSubnetdata${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target={`siteSubnetdata${index}-r2-t`}>
                          Specify CIDR block of data-in subnet in format a.b.c.d/n for "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input
                          bsSize="sm"
                          id={`siteSubnetdata${index}`}
                          name="site_subnetdata"
                          placeholder="10.0.1.0/24"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_subnetdata"]}
                          valid={isSubnetValid(formState["gslb"]["member"]["sites"][index]["site_subnetdata"])}
                          invalid={!isSubnetValid(formState["gslb"]["member"]["sites"][index]["site_subnetdata"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr> */}
                    <tr key={`sitePrivateIP${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Private IP
                        <span id={`sitePrivateIP${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong>
                        </span>
                        <UncontrolledTooltip placement="top" target={`sitePrivateIP${index}-r2-t`}>
                          Specify the secondary private IP address of data-in subnet. This IP should be unique and is not assigned to any other member within the GSLB group.
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input
                          bsSize="sm"
                          id={`sitePrivateIP${index}`}
                          name="site_privateIP"
                          placeholder="10.0.0.1"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_privateIP"]}
                          valid={isIpValid(formState["gslb"]["member"]["sites"][index]["site_privateIP"])}
                          invalid={!isIpValid(formState["gslb"]["member"]["sites"][index]["site_privateIP"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    <tr key={`siteDestIP${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Destination IP
                        <span id={`siteDestIP${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip target={`siteDestIP${index}-r2-t`} placement="top">
                          Specify the destination IP address for the "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input bsSize="sm"
                          id={`siteDestIP${index}`}
                          name="site_destination_ip"
                          placeholder="0.0.0.0"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_destination_ip"]}
                          valid={isIpValid(formState["gslb"]["member"]["sites"][index]["site_destination_ip"])}
                          invalid={!isIpValid(formState["gslb"]["member"]["sites"][index]["site_destination_ip"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    <tr key={`siteDestIPMask${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Destination IP Mask
                        <span id={`siteDestIPMask${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip target={`siteDestIPMask${index}-r2-t`} placement="top">
                          Specify the destination IP address mask for the "site {index + 1}".
                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        <Input bsSize="sm"
                          id={`siteDestIPMask${index}`}
                          name="site_destination_ip_mask"
                          placeholder="/0"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_destination_ip_mask"]}
                          valid={isAlphanumeric(formState["gslb"]["member"]["sites"][index]["site_destination_ip_mask"])}
                          invalid={!isAlphanumeric(formState["gslb"]["member"]["sites"][index]["site_destination_ip_mask"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        />
                      </td>
                    </tr>
                    <tr key={`siteNextHopIP${index}`} style={{ height: "30px" }}>
                      <td style={{ fontSize: "12px", padding: "2px" }}>
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        Next Hop IP
                        <span id={`siteNextHopIP${index}-r2-t`} style={{ fontSize: "14px" }}><strong> ⓘ</strong></span>
                        <UncontrolledTooltip target={`siteNextHopIP${index}-r2-t`} placement="top">
                          Specify next hop IP address of management and data-in subnet. Next hop IP address is the first IP address of respective subnet.                        </UncontrolledTooltip>
                      </td>
                      <td style={{ padding: "2px" }}>
                        {/* <Input bsSize="sm"
                          id={`siteNextHopIP${index}`}
                          name="site_next_hop_ip"
                          placeholder="10.0.1.1"
                          type="text"
                          value={formState["gslb"]["member"]["sites"][index]["site_next_hop_ip"]}
                          valid={isIpValid(formState["gslb"]["member"]["sites"][index]["site_next_hop_ip"])}
                          invalid={!isIpValid(formState["gslb"]["member"]["sites"][index]["site_next_hop_ip"])}
                          onChange={(e) => handleSiteChange(e, index)}
                        /> */}
                        <CreatableSelect
                          isMulti
                          isValidNewOption={(inputValue, selectValue, selectOptions) => {
                            return isIpValid(inputValue)
                          }}
                          placeholder="Provide multiple IP and press enter.."
                          value={multiValFields[`site_next_hop_ip_${index}`]}
                          onChange={(selectedOption) => handleMultiValFieldChangeSite(selectedOption, "site_next_hop_ip", index)}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minHeight: "35px",
                              fontSize: "14px",
                              borderColor: multiValFields[`site_next_hop_ip_${index}`].length > 0 ? "#198754 !important" : "#dc3545 !important"
                            }),
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <UncontrolledAccordion stayOpen>
                  <AccordionItem>
                    <AccordionHeader targetId={`2_${index + 3}_1`}>
                      Configure Site Settings
                    </AccordionHeader>
                    <AccordionBody accordionId={`2_${index + 3}_1`}>
                      <SLBServer formState={formState} setFormState={setFormState} siteIndex={index} region={"member_site_setting"} />
                      <ServiceGroupSLB formState={formState} setFormState={setFormState} siteIndex={index} region={"member_site_setting"} />
                      <VirtualServerSLB formState={formState} setFormState={setFormState} siteIndex={index} region={"member_site_setting"} />
                    </AccordionBody>
                  </AccordionItem>
                </UncontrolledAccordion>

              </AccordionBody>
            </AccordionItem>
          </div>
        ))}
      </AccordionBody>
    </AccordionItem>
  )
}
export default Member